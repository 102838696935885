import { useState } from 'react';
import { Link } from 'react-router-dom';
import { FaArrowDown, FaArrowUp } from 'react-icons/fa';
import { Card } from '@/components/ui/card';
import { FaArrowRight, FaArrowLeft } from 'react-icons/fa';
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from '@/components/ui/table';
import { Button } from '@/components/ui/button';

const TableUsers = ({ users, setSearchParams, searchParams, total }) => {
  const [showFilters, setShowFilters] = useState(false);
  const [sort, setSort] = useState({
    sortBy: searchParams.get('sortBy') || 'createdAt', // Default sort by creation date
    order: searchParams.get('order') || 'desc', // Default sort order
  });

  const openFilters = () => {
    setShowFilters(!showFilters);
  };
  const handleRole = (role) => {
    switch (role) {
      case 'ADMIN_ROLE':
        return 'Administrador';
      case 'DENTIST_ROLE':
        return 'Odontologo';
      case 'SELLER_ROLE':
        return 'Laboratorio';
      default:
        return '';
    }
  };

  const limit = searchParams ? Number(searchParams.get('limit')) || 10 : 10;
  const skip = searchParams ? Number(searchParams.get('skip')) || 0 : 0;
  const name = searchParams ? searchParams.get('name') || '' : '';
  const lastname = searchParams ? searchParams.get('lastname') || '' : '';
  const email = searchParams ? searchParams.get('email') || '' : '';
  const role = searchParams ? searchParams.get('role') || '' : '';
  const nif = searchParams ? searchParams.get('nif') || '' : '';
  const colNumber = searchParams ? searchParams.get('colNumber') || '' : '';

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    const newParams = new URLSearchParams(searchParams);
    if (value) {
      newParams.set(name, value);
    } else {
      newParams.delete(name);
    }
    setSearchParams(newParams);
  };

  const handleSort = (field) => {
    const newOrder =
      sort.sortBy === field && sort.order === 'asc' ? 'desc' : 'asc';
    setSort({ sortBy: field, order: newOrder });

    const newParams = new URLSearchParams(searchParams);
    newParams.set('sortBy', field);
    newParams.set('order', newOrder);
    setSearchParams(newParams);
  };

  return (
    <div className="w-full">
      <div className="flex flex-col-reverse md:flex-row justify-between mb-4">
        <div className="flex flex-col md:flex-row mb-4 md:mb-0  gap-1">
          <input
            type="date"
            className="p-2 border border-[#BDCAFE] rounded-md md:mr-2"
            placeholder="Desde"
            aria-label="Start date filter"
          />
          <input
            type="date"
            className="p-2 border border-[#BDCAFE] rounded-md md:mr-2"
            placeholder="Hasta"
            aria-label="End date filter"
          />
          <button
            className="bg-white p-2 rounded-md border border-[#BDCAFE] hover:bg-gray-200"
            onClick={openFilters}
            aria-label="Toggle filters"
            type="button"
          >
            {showFilters ? 'Cerrar filtros' : 'Más filtros'}
          </button>
        </div>

        <div className="relative">Usuarios totales : {total}</div>
      </div>
      {showFilters && (
        <div className="flex flex-col md:flex-row space-y-2 md:space-y-0 md:space-x-1 mb-4 w-full justify-between">
          <input
            type="text"
            name="name"
            placeholder="Nombre"
            value={name}
            onChange={handleInputChange}
            className="p-2 rounded-md md:w-32"
          />
          <input
            type="text"
            name="lastname"
            placeholder="Apellido"
            value={lastname}
            onChange={handleInputChange}
            className="p-2 rounded-md md:w-32"
          />
          <input
            type="text"
            name="email"
            placeholder="Correo"
            value={email}
            onChange={handleInputChange}
            className="p-2 rounded-md md:w-32"
          />
          <input
            type="text"
            name="nif"
            placeholder="NIF"
            value={nif}
            onChange={handleInputChange}
            className="p-2 rounded-md md:w-32"
          />
          <input
            type="text"
            name="colNumber"
            placeholder="Número de colegiado"
            value={colNumber}
            onChange={handleInputChange}
            className="p-2 rounded-md md:w-40"
          />
          <select
            name="role"
            value={role}
            onChange={handleInputChange}
            className="p-2 rounded-md"
            aria-label="Filter by case type"
          >
            <option value="">Role</option>
            <option value="ADMIN_ROLE">Administrador</option>
            <option value="DENTIST_ROLE">Dentista</option>
            <option value="SELLER_ROLE">Laboratorio</option>
          </select>
        </div>
      )}
      <div className="rounded-lg border overflow-hidden">
        <Card className="h-full w-full overflow-x-auto">
          <Table className="table-auto w-full border-collapse border rounded-lg border-gray-300">
            <TableHeader className="bg-[#e9ecf2]">
              <TableRow>
                <TableHead
                  className="px-4 py-2 table-cell text-center "
                  onClick={() => handleSort('name')}
                >
                  Nombre
                  {sort.sortBy === 'name' &&
                    (sort.order === 'asc' ? (
                      <FaArrowUp className="inline ml-2" />
                    ) : (
                      <FaArrowDown className="inline ml-2" />
                    ))}
                </TableHead>
                <TableHead
                  className="px-4 py-2 text-center "
                  onClick={() => handleSort('lastname')}
                >
                  Apellido
                  {sort.sortBy === 'lastname' &&
                    (sort.order === 'asc' ? (
                      <FaArrowUp className="inline ml-2" />
                    ) : (
                      <FaArrowDown className="inline ml-2" />
                    ))}
                </TableHead>
                <TableHead
                  className="px-4 py-2 text-center "
                  onClick={() => handleSort('email')}
                >
                  Correo
                  {sort.sortBy === 'email' &&
                    (sort.order === 'asc' ? (
                      <FaArrowUp className="inline ml-2" />
                    ) : (
                      <FaArrowDown className="inline ml-2" />
                    ))}
                </TableHead>
                <TableHead
                  className="px-4 py-2 table-cell text-center"
                  onClick={() => handleSort('role')}
                >
                  Role
                  {sort.sortBy === 'role' &&
                    (sort.order === 'asc' ? (
                      <FaArrowUp className="inline ml-2" />
                    ) : (
                      <FaArrowDown className="inline ml-2" />
                    ))}
                </TableHead>
                <TableHead className="px-4 py-2 table-cell text-center">
                  Baneado
                </TableHead>
              </TableRow>
            </TableHeader>
            <TableBody>
              {users && users.length > 0 ? (
                users.map((user, index) => (
                  <TableRow key={index} className="border border-1">
                    <TableCell className="px-4 py-2 text-center hover:text-blue-500">
                      <Link to={'/admin/user/' + user?._id}>{user.name}</Link>
                    </TableCell>
                    <TableCell className="px-4 py-2 text-center hover:text-blue-500">
                      <Link to={'/admin/user/' + user?._id}>
                        {user.lastname}
                      </Link>
                    </TableCell>
                    <TableCell className="px-4 py-2 text-center hover:text-blue-500">
                      <Link to={'/admin/user/' + user?._id}>{user.email}</Link>
                    </TableCell>
                    <TableCell className="px-4 py-2 table-cell text-center">
                      {handleRole(user.role)}
                    </TableCell>
                    <TableCell className="px-4 py-2 table-cell text-center">
                      {user.banned ? 'si' : 'no'}
                    </TableCell>
                  </TableRow>
                ))
              ) : (
                <TableRow>
                  <TableCell
                    className="text-center p-4"
                    colSpan={4}
                    aria-label="No users available"
                  >
                    No hay usuarios.
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
          <div className="bg-[#f5f7fa] flex justify-between  md:px-4 py-2 ">
            <Button
              onClick={() =>
                setSearchParams({ limit, skip: Math.max(skip - limit, 0) })
              }
              disabled={skip === 0}
              className="btn-neutral"
              aria-label="Previous page"
            >
              <FaArrowLeft className="inline mr-2" />
              Anterior
            </Button>

            <Button
              onClick={() => setSearchParams({ limit, skip: skip + limit })}
              disabled={skip + limit >= total}
              className="btn-primary"
              aria-label="Next page"
            >
              Siguiente
              <FaArrowRight className="inline ml-2" />
            </Button>
          </div>
        </Card>
      </div>
    </div>
  );
};

export default TableUsers;
