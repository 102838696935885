import React from 'react';
import {
  Accordion,
  AccordionContent,
  AccordionItem,
  AccordionTrigger,
} from '@/components/ui/accordion';

const FAQ = () => {
  const faqs = [
    {
      question: 'Oklusion como Dentista',
      answer:
        'Gracias a Oklusion podrás acceder a una mayor cantidad de profesionales de calidad, usar las técnicas más novedosas y conseguir los mejores precios.',
    },
    {
      question: 'Oklusion como Laboratorio',
      answer:
        'Oklusion da a los laboratorios la posibilidad de acceder a un mercado mucho más grande de dentistas, conseguir más visibilidad y proyectos.',
    },
    {
      question: '¿Cuánto cuesta usar Oklusion como dentista?',
      answer:
        'Pide presupuestos a multitud de laboratorios de manera gratuita. Una vez aceptado un presupuesto, solo se te cobrará esa cantidad y ningún sobrecosto.',
    },
    {
      question: '¿Cuánto cuesta usar Oklusion como laboratorio?',
      answer:
        'Haz tantas propuestas a los dentistas como quieras, una vez aceptadas, Oklusion cobrará una comisión del 15%.',
    },
    {
      question: 'Envíos',
      answer:
        'Oklusion tiene convenio con las empresas de transporte más importantes. Un pedido normal debe llegar en 24-48 horas si no hay incidencias.',
    },
    {
      question: '¿Cómo está mi pedido?',
      answer:
        'Sigue el estado del pedido y comunícate en tiempo real con el laboratorio.',
    },
    {
      question: 'Disputas',
      answer:
        'Estamos contentos si estás contento. En Oklusion puedes pedir revisiones del caso (sin coste extra) si consideras que el trabajo recibido no está a la altura de lo que esperas.',
    },
  ];

  return (
    <div className="flex flex-col items-center justify-center my-4 px-2 md:px-8 bg-white">
      <h1 className="text-2xl sm:text-3xl font-bold text-center mb-6 sm:mb-8 lg:mb-12">
        Preguntas Frecuentes
      </h1>
      <Accordion
        type="single"
        collapsible
        className="w-full md:max-w-lg space-y-2"
      >
        {faqs.map((faq, index) => (
          <AccordionItem
            key={index}
            value={`item-${index}`}
            className="bg-white rounded-md pl-1 md:px-4 justify-start border-none"
          >
            <AccordionTrigger className="text-base sm:text-lg lg:text-xl">
              {faq.question}
            </AccordionTrigger>
            <AccordionContent className="text-sm sm:text-base lg:text-lg">
              {faq.answer}
            </AccordionContent>
          </AccordionItem>
        ))}
      </Accordion>
    </div>
  );
};

export default FAQ;
