import React from 'react';
import { CiTrash } from 'react-icons/ci';
import { LuPencil } from 'react-icons/lu';

const ClinicCardEdit = ({ setClinics, clinic, clinics, index }) => {
  const { clinicName, clinicstreet, city, postalCode, province, country } =
    clinic;

  const handleChange = (e) => {
    const updatedClinics = clinics.map((c, i) =>
      i === index ? { ...c, [e.target.name]: e.target.value } : c,
    );
    setClinics(updatedClinics);
  };

  const handleRemoveClinic = () => {
    const updatedClinics = clinics.filter((_, i) => i !== index);
    setClinics(updatedClinics);
  };

  return (
    <div className="bg-neutral p-4 rounded-md bg-[#f7f9fa] border-2">
      <div className="flex flex-col md:flex-row justify-between items-start md:items-center gap-4">
        <div className="input-label w-full md:w-2/3">
          <label
            htmlFor="clinicName"
            className="block text-md font-medium text-gray-700"
          >
            Nombre de la Clínica
          </label>
          <input
            type="text"
            id="clinicName"
            name="clinicName"
            value={clinicName}
            onChange={handleChange}
            className="input-styles w-full"
            placeholder="Nombre de la clínica"
            required
          />
        </div>
        <div className="flex flex-row gap-4 md:gap-2">
          <button className="text-blue-500 hover:text-green-500">
            <LuPencil />
          </button>
          <button
            className="text-blue-500 hover:text-red-500"
            onClick={handleRemoveClinic}
          >
            <CiTrash />
          </button>
        </div>
      </div>
      <div className="flex flex-col md:flex-row gap-4 mt-4">
        <div className="input-label w-full md:w-1/4">
          <label
            htmlFor="clinicstreet"
            className="block text-sm font-medium text-gray-700"
          >
            Calle
          </label>
          <input
            type="text"
            id="clinicstreet"
            name="clinicstreet"
            value={clinicstreet}
            onChange={handleChange}
            className="input-styles w-full"
            placeholder="Nombre de la calle"
            required
          />
        </div>
        <div className="input-label w-full md:w-1/4">
          <label
            htmlFor="city"
            className="block text-sm font-medium text-gray-700"
          >
            Ciudad
          </label>
          <input
            type="text"
            id="city"
            name="city"
            value={city}
            onChange={handleChange}
            className="input-styles w-full"
            placeholder="Ciudad"
            required
          />
        </div>
        <div className="input-label w-full md:w-1/4">
          <label
            htmlFor="postalCode"
            className="block text-sm font-medium text-gray-700"
          >
            Código Postal
          </label>
          <input
            type="text"
            id="postalCode"
            name="postalCode"
            value={postalCode}
            onChange={handleChange}
            className="input-styles w-full"
            placeholder="Código Postal"
            required
          />
        </div>
        <div className="input-label w-full md:w-1/4">
          <label
            htmlFor="province"
            className="block text-sm font-medium text-gray-700"
          >
            Provincia
          </label>
          <input
            type="text"
            id="province"
            name="province"
            value={province}
            onChange={handleChange}
            className="input-styles w-full"
            placeholder="Provincia"
            required
          />
        </div>
        <div className="input-label w-full md:w-1/4">
          <label
            htmlFor="province"
            className="block text-sm font-medium text-gray-700"
          >
            Pais
          </label>
          <input
            type="text"
            id="country"
            name="country"
            value={country}
            onChange={handleChange}
            className="input-styles w-full"
            placeholder="Pais"
            required
          />
        </div>
      </div>
    </div>
  );
};

export default ClinicCardEdit;
