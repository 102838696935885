import { useEffect, useState, useCallback } from 'react';
import {
  getUsers,
  getUser,
  getProfile,
  updateProfile,
  updateUser,
} from '@/services/auth';
import useUserStore from '@/store/userStore';
import useDemandStore from '@/store/DemandStore';
import { useOutsourcingStore } from '@/store/OutsourcingStore';
import useMessageStore from '@/store/MessageStore';
import useNotificationStore from '@/store/Notificationstore';

export const useFetchUsers = (searchParams) => {
  const [users, setUsers] = useState([]);
  const [total, setTotal] = useState(0);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  const fetchUsers = useCallback(async () => {
    setLoading(true);
    setError(null);
    try {
      const params = Object.fromEntries(searchParams || []);
      const queryString = new URLSearchParams(params).toString();
      const { users, total } = await getUsers({ queryString });
      setUsers(users);
      setTotal(total);
    } catch (error) {
      setError(error.message || 'Error fetching users');
    } finally {
      setLoading(false);
    }
  }, [searchParams]);

  useEffect(() => {
    fetchUsers();
  }, [fetchUsers]);

  return { users, total, loading, error, refetch: fetchUsers };
};

export const useFetchUser = () => {
  const { user, setUser, logout } = useUserStore();
  const { clearDemandStore } = useDemandStore();
  const { clearMessages } = useMessageStore();
  const { clearNotifications } = useNotificationStore();
  const { clearOutsourcingStore } = useOutsourcingStore();

  const handleLogout = () => {
    setUser(null);
    clearDemandStore();
    clearOutsourcingStore();
    clearMessages();
    clearNotifications();
    logout();
  };

  return { user, handleLogout };
};

export const useFetchProfile = () => {
  const [profile, setProfile] = useState(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchProfile = async () => {
      setLoading(true);
      setError(null);
      try {
        const fetchedProfile = await getProfile();
        setProfile(fetchedProfile);
      } catch (error) {
        setError(error.message || 'Error fetching profile');
      } finally {
        setLoading(false);
      }
    };

    fetchProfile();
  }, []);

  return { profile, loading, error };
};

export const useUpdateProfile = () => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  const update = async (profileData) => {
    setLoading(true);
    setError(null);
    try {
      await updateProfile(profileData);
    } catch (error) {
      setError(error.message || 'Error updating profile');
    } finally {
      setLoading(false);
    }
  };

  return { update, loading, error };
};

export const useUpdateUser = () => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  const update = async (userId, userData) => {
    setLoading(true);
    setError(null);
    try {
      await updateUser(userId, userData);
    } catch (error) {
      setError(error.message || 'Error updating user');
    } finally {
      setLoading(false);
    }
  };

  return { update, loading, error };
};
