import React, { useState, useEffect } from 'react';
import { profileActivate } from '@/services/auth';
import { useNavigate } from 'react-router-dom';
import { useUserStore } from '@/store/userStore';
import { CiCreditCard1 } from 'react-icons/ci';
import { FcGoogle } from 'react-icons/fc';
import { TbBrandPaypalFilled } from 'react-icons/tb';
import { FaStripeS, FaArrowRight, FaArrowLeft } from 'react-icons/fa';
import ClinicCard from '@/components/Cards/ClinicCard';
import ContactCard from '@/components/Cards/ContactCard';
import PaypalForm from '@/components/Forms/PaypalForm';
//import StripeForm from '@/components/Forms/StripeForm';
import GoogleForm from '@/components/Forms/GoogleForm';
import CredidCardForm from '@/components/Cards/CredidCardForm';
import RegistroImg from '@/assets/Registro.png';

const RegisterPost = () => {
  const { user, token, setUser } = useUserStore();
  const [name, setName] = useState('');
  const [lastname, setLastname] = useState('');
  const [phone, setPhone] = useState('');
  const [nif, setNif] = useState('');
  const [colNumber, setColNumber] = useState('');
  const [razonSocial, setRazonSocial] = useState('');
  const [clinics, setClinics] = useState([
    {
      clinicName: '',
      postalCode: '',
      clinicstreet: '',
      province: '',
      city: '',
      country: '',
    },
  ]);

  const [cardData, setCardData] = useState({
    cardNumber: '',
    expiryDate: '',
    cvv: '',
  });
  const [paypalData, setPaypalData] = useState('');
  const [stripeData, setStripeData] = useState('');
  const [googleData, setGoogleData] = useState('');
  const [showCardForm, setShowCardForm] = useState(false);
  const [showPaypalForm, setShowPaypalForm] = useState(false);
  const [showStripeForm, setShowStripeForm] = useState(false);
  const [showGoogleForm, setShowGoogleForm] = useState(false);
  const [services, setServices] = useState({
    protesis: {
      material: {
        Acrilico: false,
        AcrilicoDen: false,
        AcrilicoFer: false,
        Composite: false,
        DisilicatoLitio: false,
        DisilicatoLitioCar: false,
        Feldespatica: false,
        Impreso3D: false,
        Impreso3Dden: false,
        // Amber: false,
        // Emax: false,
        // Ceramica: false,
        OtrosCar: false,
        OtrosCorona: false,
        otrosFerulas: false,
        Metalceramica: false,
        PMMA: false,
        Resina: false,
        Zirconio: false,
        ZirconioCar: false,
      },
      Mockup: false,
      onImplant: false,
      withBar: false,
      selectedOption: {
        Dentadura: false,
        Férulas: false,
        Corona: false,
        Carillas: false,
      },
      refuerzoType: {
        Malla: false,
        RefuerzoForjado: false,
      },
      materialBarra: {
        // CRCO: false,
        // TI: false,
        CRCOden: false,
        Metal: false,
        Plastico: false,
        Otros: false,
        otrosDen: false,
        Resina: false,
        TIden: false,
        Zirconio: false,
      },
      selectTipoBarra: {
        Fresada: false,
        Impreso3D: false,
        Colada: false,
      },
      ferulasType: {
        Ferulaquirurgica: false,
        Feruladescarga: false,
        Ferulamuscular: false,
      },
    },
    ortodoncia: {
      selectedOption: {
        OrthodonticAppliances: false,
        Aligners: false,
        Retainers: false,
      },
      subSelection: {
        QuadHelix: false,
        Expansor: false,
        Invisalign: false,
        KLine: false,
        AngelAlign: false,
        Fijo: false,
        Movil: false,
      },
    },
    productos: {
      ProtectorBucal: false,
      AntiRonquido: false,
      Grillz: false,
      Modelo: false,
      ReparacionRebase: false,
      Biomaterial: false,
    },
  });
  const [error, setError] = useState('');
  const [loading, setLoading] = useState(false);
  const [firtscreen, setFirtscreen] = useState(true);
  const navigate = useNavigate();

  const handleSubmit = async (e) => {
    e.preventDefault();

    //check if any of clinic data is missing
    for (let clinic of clinics) {
      if (
        !clinic.clinicName ||
        !clinic.postalCode ||
        !clinic.clinicstreet ||
        !clinic.province ||
        !clinic.city ||
        !clinic.country
      ) {
        setError('Please fill all clinic data.');
        return;
      }
    }

    setLoading(true);
    setError('');

    try {
      const userData = {
        name,
        lastname,
        labName: user.role === 'SELLER_ROLE' ? clinics[0].clinicName : null,
        nif,
        colNumber,
        razonSocial,
        clinics,
        phone,
        services,
        paymentMethod: {
          cardData: {
            cardData,
          },
          paypalData: {
            paypalData,
          },
          stripeData: {
            stripeData,
          },
          googleData: {
            googleData,
          },
        },
      };

      const response = await profileActivate(userData);
      if (response.user) {
        console.log('response', response);
        setError('');
        setUser(response.user, token);
        navigate('/');
      }
    } catch (err) {
      setError(
        err.response?.data?.msg || 'Registration failed. Please try again.',
      );
    } finally {
      setLoading(false);
    }
  };

  const handlePage = () => {
    //if  clinics legth
    if (clinics.length < 1) {
      setError('Please add at least one clinic.');
      return;
    }
    setFirtscreen(!firtscreen);
  };

  const handlePaymentSelection = (method) => {
    setShowCardForm(method === 'card');
    setShowGoogleForm(method === 'google');
    setShowPaypalForm(method === 'paypal');
    setShowStripeForm(method === 'stripe');
  };

  useEffect(() => {
    if (!user) {
      navigate('/login');
    }
    if (user && user.active) {
      navigate('/dashboard');
    }
  }, [user]);

  const handleName = (material) => {
    switch (material) {
      case 'ZirconioCar':
        return 'Zirconio';
      case 'DisilicatoLitioCar':
        return 'Disilicato de litio';
      case 'OtrosCar':
        return 'Otros';
      default:
        return material;
    }
  };

  return (
    <div className="flex items-center justify-center min-h-screen bg-gray-100 p-5">
      <div className="flex w-full  lg:w-3/4 max-w-6xl  rounded-lg overflow-hidden">
        {firtscreen && (
          <div
            className="w-1/2 rounded-lg bg-cover bg-center"
            style={{
              backgroundImage: `url(${RegistroImg})`,
              backgroundPosition: 'center',
              backgroundRepeat: 'no-repeat',
              backgroundSize: 'cover',
            }}
          ></div>
        )}
        <div
          className={`w-full  ${
            !firtscreen && user.role === 'DENTIST_ROLE' && 'lg:w-1/2'
          } 
          ${firtscreen && 'lg:w-1/2'} 
          py-8 px-4 rounded-lg  items-center justify-center gap-2 `}
        >
          <h2 className="text-2xl font-bold mb-6 text-blue-800 ">
            Bienvenido a Oklusion
          </h2>
          {error && (
            <div className="bg-red-200 text-red-700 p-2 rounded-md mb-4">
              {error}
            </div>
          )}
          <form onSubmit={handleSubmit}>
            {firtscreen && (
              <div>
                <ContactCard
                  name={name}
                  setName={setName}
                  lastname={lastname}
                  setLastname={setLastname}
                  phone={phone}
                  setPhone={setPhone}
                  user={user}
                  nif={nif}
                  setNif={setNif}
                  colNumber={colNumber}
                  setColNumber={setColNumber}
                  razonSocial={razonSocial}
                  setRazonSocial={setRazonSocial}
                />

                {clinics.map((clinic, index) => (
                  <ClinicCard
                    key={index}
                    clinic={clinic}
                    clinics={clinics}
                    setClinics={setClinics}
                    index={index}
                  />
                ))}

                <div className=" right-4 mt-6 text-center flex justify-end ">
                  {user.role === 'DENTIST_ROLE' && (
                    <button
                      className="btn-primary  rounded-lg flex flex-row gap-5"
                      type="submit"
                      disabled={
                        name === '' ||
                        lastname === '' ||
                        phone === '' ||
                        nif === '' ||
                        clinics.length < 1 ||
                        colNumber === '' ||
                        razonSocial === '' ||
                        clinics[0].city === '' ||
                        clinics[0].clinicName === '' ||
                        clinics[0].postalCode === '' ||
                        clinics[0].province === '' ||
                        clinics[0].clinicstreet === '' ||
                        clinics[0].country === ''
                      }
                    >
                      Registrar
                    </button>
                  )}
                  {user.role === 'SELLER_ROLE' && (
                    <button
                      className="btn-primary  rounded-lg flex flex-row gap-5"
                      onClick={handlePage}
                      disabled={
                        name === '' ||
                        lastname === '' ||
                        phone === '' ||
                        nif === '' ||
                        clinics.length < 1 ||
                        colNumber === '' ||
                        razonSocial === '' ||
                        clinics[0].city === '' ||
                        clinics[0].clinicName === '' ||
                        clinics[0].postalCode === '' ||
                        clinics[0].province === '' ||
                        clinics[0].clinicstreet === '' ||
                        clinics[0].country === ''
                      }
                    >
                      Siguiente <FaArrowRight className="pt-1" />
                    </button>
                  )}
                </div>
              </div>
            )}
            {!firtscreen && user.role === 'DENTIST_ROLE' && (
              <div>
                <div>
                  <label
                    htmlFor="name"
                    className="block text-sm font-medium text-gray-700"
                  >
                    Metodo de pago
                  </label>
                  <div className="flex gap-4">
                    <div className="flex-1">
                      <button
                        type="button"
                        className="mt-1 block w-25 p-2 border border-gray-300 rounded-lg shadow-sm focus:ring-blue-500 focus:border-blue-500 
                        hover:bg-slate-100
                        "
                        placeholder="Tarjeta"
                        onClick={() => handlePaymentSelection('card')}
                      >
                        <CiCreditCard1 />
                      </button>
                      <label
                        htmlFor="name"
                        className="block text-sm font-medium text-gray-700"
                      >
                        Tarjeta
                      </label>
                    </div>
                    <div className="flex-1">
                      <button
                        type="button"
                        className="mt-1 block w-25 p-2 border border-gray-300 rounded-lg shadow-sm focus:ring-blue-500 focus:border-blue-500"
                        placeholder="Google Pay"
                        onClick={() => handlePaymentSelection('google')}
                      >
                        <FcGoogle />
                      </button>
                      <label
                        htmlFor="name"
                        className="block text-sm font-medium text-gray-700"
                      >
                        Google Pay
                      </label>
                    </div>
                    <div className="flex-1">
                      <button
                        type="button"
                        className="mt-1 block w-25 p-2 border border-gray-300 rounded-lg shadow-sm focus:ring-blue-500 focus:border-blue-500"
                        placeholder="PayPal"
                        onClick={() => handlePaymentSelection('paypal')}
                      >
                        <TbBrandPaypalFilled color="blue" />
                      </button>
                      <label
                        htmlFor="name"
                        className="block text-sm font-medium text-gray-700"
                      >
                        Paypal
                      </label>
                    </div>
                    <div className="flex-1">
                      <button
                        type="button"
                        className="mt-1 block w-25 p-2 border border-gray-300 rounded-lg shadow-sm focus:ring-blue-500 focus:border-blue-500"
                        placeholder="Stripe"
                        onClick={() => handlePaymentSelection('stripe')}
                      >
                        <FaStripeS color="blue" />
                      </button>
                      <label
                        htmlFor="name"
                        className="block text-sm font-medium text-gray-700"
                      >
                        Stripe
                      </label>
                    </div>
                  </div>
                </div>

                {showCardForm && (
                  <CredidCardForm
                    cardData={cardData}
                    setCardData={setCardData}
                  />
                )}

                {/* Google Pay Form */}
                {showGoogleForm && (
                  <GoogleForm
                    googleData={googleData}
                    setGoogleData={setGoogleData}
                  />
                )}

                {/* PayPal Form */}
                {showPaypalForm && (
                  <PaypalForm
                    paypalData={paypalData}
                    setPaypalData={setPaypalData}
                  />
                )}

                {/* Stripe Form */}
                {/* {showStripeForm && (
                  <StripeForm
                    stripeData={stripeData}
                    setStripeData={setStripeData}
                  />
                )} */}

                <div className=" flex flex-row mt-6 justify-between gap-2">
                  <button
                    className="bg-blue-300 w-1/2  flex flex-row justify-center gap-2 text-white px-4 py-2 rounded-lg hover:bg-blue-600 transition-colors duration-300"
                    onClick={handlePage}
                  >
                    <FaArrowLeft color="white " />
                    Anterior
                  </button>
                  <button
                    type="submit"
                    className="bg-blue-500 w-1/2 text-white px-4 py-2 rounded-lg hover:bg-blue-600 transition-colors duration-300"
                    disabled={loading}
                  >
                    {loading ? 'Registering...' : 'Register'}
                  </button>
                </div>
                {error && <div className="mt-4 text-red-500">{error}</div>}
              </div>
            )}

            {!firtscreen && user.role === 'SELLER_ROLE' && (
              <div>
                <section className="bg-white p-1 md:p-6 border border-gray-200 rounded-lg shadow-md">
                  <div>
                    <p className="text-gray-600 text-sm mb-6 px-4">
                      Seleccione los tipos de trabajo que realiza en su
                      laboratorio.
                    </p>

                    <div className="space-y-8">
                      {/* Prótesis Options */}
                      <div className="bg-[#355dfc] px-2 py-3  md:p-6 border  rounded-lg">
                        <h4 className="text-lg font-bold text-white mb-4">
                          Prótesis
                        </h4>

                        <div className="bg-white px-2 md:px-8 w-full font-semibold py-2 rounded-md my-2 border-2">
                          <h3 className="font-bold">
                            Corona / Puente / Arcada completa
                          </h3>
                          <div className="flex gap-4 mt-2 flex-wrap">
                            <div className="flex items-center space-x-2 py-2 md:pr-1 md:pl-2 rounded-md">
                              <input
                                type="checkbox"
                                onChange={(e) =>
                                  setServices({
                                    ...services,
                                    protesis: {
                                      ...services.protesis,
                                      Mockup: e.target.checked,
                                    },
                                  })
                                }
                                checked={services.protesis.Mockup}
                                className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                              />
                              <label className="text-sm font-medium leading-none peer-disabled:cursor-not-allowed peer-disabled:opacity-70">
                                Prueba estética
                              </label>
                            </div>
                            <div className="flex items-center space-x-2 py-2 md:pr-1 md:pl-2 rounded-md">
                              <input
                                type="checkbox"
                                onChange={(e) =>
                                  setServices({
                                    ...services,
                                    protesis: {
                                      ...services.protesis,
                                      onImplant: e.target.checked,
                                    },
                                  })
                                }
                                checked={services.protesis.onImplant}
                                className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                              />
                              <label className="text-sm font-medium leading-none peer-disabled:cursor-not-allowed peer-disabled:opacity-70">
                                Sobre Implante
                              </label>
                            </div>
                          </div>
                          Material:
                          <div className="flex flex-wrap md:gap-4 mt-2">
                            <div className="flex items-center space-x-2 py-2 pr-1 pl-1 rounded-md ">
                              <input
                                type="checkbox"
                                onChange={(e) =>
                                  setServices({
                                    ...services,
                                    protesis: {
                                      ...services.protesis,
                                      material: {
                                        ...services.protesis.material,
                                        Zirconio: e.target.checked,
                                      },
                                    },
                                  })
                                }
                                checked={services.protesis.material.Zirconio}
                                className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                              />
                              <label className="text-sm font-medium leading-none peer-disabled:cursor-not-allowed peer-disabled:opacity-70">
                                Zirconio
                              </label>
                            </div>
                            <div className="flex items-center space-x-2 py-2 pr-1 pl-1 rounded-md">
                              <input
                                type="checkbox"
                                onChange={(e) =>
                                  setServices({
                                    ...services,
                                    protesis: {
                                      ...services.protesis,
                                      material: {
                                        ...services.protesis.material,
                                        Metalceramica: e.target.checked,
                                      },
                                    },
                                  })
                                }
                                checked={
                                  services.protesis.material.Metalceramica
                                }
                                className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                              />
                              <label className="text-sm font-medium leading-none peer-disabled:cursor-not-allowed peer-disabled:opacity-70">
                                Metal / Cerámica
                              </label>
                            </div>
                            <div className="flex items-center space-x-2 py-2 pr-1 pl-1 rounded-md">
                              <input
                                type="checkbox"
                                onChange={(e) =>
                                  setServices({
                                    ...services,
                                    protesis: {
                                      ...services.protesis,
                                      material: {
                                        ...services.protesis.material,
                                        Composite: e.target.checked,
                                      },
                                    },
                                  })
                                }
                                checked={services.protesis.material.Composite}
                                className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                              />
                              <label className="text-sm font-medium leading-none peer-disabled:cursor-not-allowed peer-disabled:opacity-70">
                                Composite
                              </label>
                            </div>
                            <div className="flex items-center space-x-2 py-2 pr-1 pl-1 rounded-md">
                              <input
                                type="checkbox"
                                onChange={(e) =>
                                  setServices({
                                    ...services,
                                    protesis: {
                                      ...services.protesis,
                                      material: {
                                        ...services.protesis.material,
                                        Impreso3D: e.target.checked,
                                      },
                                    },
                                  })
                                }
                                checked={services.protesis.material.Impreso3D}
                                className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                              />
                              <label className="text-sm font-medium leading-none peer-disabled:cursor-not-allowed peer-disabled:opacity-70">
                                Impresión 3D
                              </label>
                            </div>
                            <div className="flex items-center space-x-2 py-2 pr-1 pl-1 rounded-md">
                              <input
                                type="checkbox"
                                onChange={(e) =>
                                  setServices({
                                    ...services,
                                    protesis: {
                                      ...services.protesis,
                                      material: {
                                        ...services.protesis.material,
                                        Acrilico: e.target.checked,
                                      },
                                    },
                                  })
                                }
                                checked={services.protesis.material.Acrilico}
                                className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                              />
                              <label className="text-sm font-medium leading-none peer-disabled:cursor-not-allowed peer-disabled:opacity-70">
                                Acrílico
                              </label>
                            </div>
                            <div className="flex items-center space-x-2 py-2 pr-1 pl-1 rounded-md">
                              <input
                                type="checkbox"
                                onChange={(e) =>
                                  setServices({
                                    ...services,
                                    protesis: {
                                      ...services.protesis,
                                      material: {
                                        ...services.protesis.material,
                                        DisilicatoLitio: e.target.checked,
                                      },
                                    },
                                  })
                                }
                                checked={
                                  services.protesis.material.DisilicatoLitio
                                }
                                className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                              />
                              <label className="text-sm font-medium leading-none peer-disabled:cursor-not-allowed peer-disabled:opacity-70">
                                Disilicato de litio
                              </label>
                            </div>
                            <div className="flex items-center space-x-2 py-2 pr-1 pl-1 rounded-md">
                              <input
                                type="checkbox"
                                onChange={(e) =>
                                  setServices({
                                    ...services,
                                    protesis: {
                                      ...services.protesis,
                                      material: {
                                        ...services.protesis.material,
                                        Resina: e.target.checked,
                                      },
                                    },
                                  })
                                }
                                checked={services.protesis.material.Resina}
                                className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                              />
                              <label className="text-sm font-medium leading-none peer-disabled:cursor-not-allowed peer-disabled:opacity-70">
                                Resina
                              </label>
                            </div>
                            <div className="flex items-center space-x-2 py-2 pr-1 pl-1 rounded-md">
                              <input
                                type="checkbox"
                                onChange={(e) =>
                                  setServices({
                                    ...services,
                                    protesis: {
                                      ...services.protesis,
                                      material: {
                                        ...services.protesis.material,
                                        OtrosCorona: e.target.checked,
                                      },
                                    },
                                  })
                                }
                                checked={services.protesis.material.OtrosCorona}
                                className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                              />
                              <label className="text-sm font-medium leading-none peer-disabled:cursor-not-allowed peer-disabled:opacity-70">
                                Otros
                              </label>
                            </div>
                          </div>
                          <div className="py-5 px-2 mx-5 rounded-md">
                            <h3>Estructura</h3>
                            Material:
                            <div className="flex gap-4 mt-2 flex-wrap">
                              {/* <div className="flex items-center space-x-2 py-2 pr-1 pl-2 rounded-md">
                        <input
                          type="checkbox"
                          onChange={(e) =>
                            setServices({
                              ...services,
                              protesis: {
                                ...services.protesis,
                                materialBarra: {
                                  ...services.protesis.materialBarra,
                                  CRCO: e.target.checked,
                                },
                              },
                            })
                          }
                          checked={services.protesis.materialBarra.CRCO}
                          className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                        />
                        <label className="text-sm font-medium leading-none peer-disabled:cursor-not-allowed peer-disabled:opacity-70">
                          CrCo
                        </label>
                      </div>
                      <div className="flex items-center space-x-2 py-2 pr-1 pl-2 rounded-md">
                        <input
                          type="checkbox"
                          onChange={(e) =>
                            setServices({
                              ...services,
                              protesis: {
                                ...services.protesis,
                                materialBarra: {
                                  ...services.protesis.materialBarra,
                                  TI: e.target.checked,
                                },
                              },
                            })
                          }
                          checked={services.protesis.materialBarra.TI}
                          className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                        />
                        <label className="text-sm font-medium leading-none peer-disabled:cursor-not-allowed peer-disabled:opacity-70">
                          Ti
                        </label>
                      </div> */}
                              <div className="flex items-center space-x-2 py-2 pr-1 pl-2 rounded-md">
                                <input
                                  type="checkbox"
                                  onChange={(e) =>
                                    setServices({
                                      ...services,
                                      protesis: {
                                        ...services.protesis,
                                        materialBarra: {
                                          ...services.protesis.materialBarra,
                                          Metal: e.target.checked,
                                        },
                                      },
                                    })
                                  }
                                  checked={
                                    services.protesis.materialBarra.Metal
                                  }
                                  className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                                />
                                <label className="text-sm font-medium leading-none peer-disabled:cursor-not-allowed peer-disabled:opacity-70">
                                  Metal
                                </label>
                              </div>
                              <div className="flex items-center space-x-2 py-2 pr-1 pl-2 rounded-md">
                                <input
                                  type="checkbox"
                                  onChange={(e) =>
                                    setServices({
                                      ...services,
                                      protesis: {
                                        ...services.protesis,
                                        materialBarra: {
                                          ...services.protesis.materialBarra,
                                          Plastico: e.target.checked,
                                        },
                                      },
                                    })
                                  }
                                  checked={
                                    services.protesis.materialBarra.Plastico
                                  }
                                  className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                                />
                                <label className="text-sm font-medium leading-none peer-disabled:cursor-not-allowed peer-disabled:opacity-70">
                                  Plástico
                                </label>
                              </div>

                              <div className="flex items-center space-x-2 py-2 pr-1 pl-2 rounded-md">
                                <input
                                  type="checkbox"
                                  onChange={(e) =>
                                    setServices({
                                      ...services,
                                      protesis: {
                                        ...services.protesis,
                                        materialBarra: {
                                          ...services.protesis.materialBarra,
                                          Resina: e.target.checked,
                                        },
                                      },
                                    })
                                  }
                                  checked={
                                    services.protesis.materialBarra.Resina
                                  }
                                  className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                                />
                                <label className="text-sm font-medium leading-none peer-disabled:cursor-not-allowed peer-disabled:opacity-70">
                                  Resina
                                </label>
                              </div>

                              <div className="flex items-center space-x-2 py-2 pr-1 pl-2 rounded-md">
                                <input
                                  type="checkbox"
                                  onChange={(e) =>
                                    setServices({
                                      ...services,
                                      protesis: {
                                        ...services.protesis,
                                        materialBarra: {
                                          ...services.protesis.materialBarra,
                                          Zirconio: e.target.checked,
                                        },
                                      },
                                    })
                                  }
                                  checked={
                                    services.protesis.materialBarra.Zirconio
                                  }
                                  className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                                />
                                <label className="text-sm font-medium leading-none peer-disabled:cursor-not-allowed peer-disabled:opacity-70">
                                  Zirconio
                                </label>
                              </div>

                              <div className="flex items-center space-x-2 py-2 pr-1 pl-2 rounded-md">
                                <input
                                  type="checkbox"
                                  onChange={(e) =>
                                    setServices({
                                      ...services,
                                      protesis: {
                                        ...services.protesis,
                                        materialBarra: {
                                          ...services.protesis.materialBarra,
                                          Otros: e.target.checked,
                                        },
                                      },
                                    })
                                  }
                                  checked={
                                    services.protesis.materialBarra.Otros
                                  }
                                  className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                                />
                                <label className="text-sm font-medium leading-none peer-disabled:cursor-not-allowed peer-disabled:opacity-70">
                                  Otros
                                </label>
                              </div>
                            </div>
                            Tipo de estructura:
                            <div className="flex gap-4 mt-2 flex-wrap">
                              <div className="flex items-center space-x-2 py-2 pr-1 pl-2 rounded-md">
                                <input
                                  type="checkbox"
                                  onChange={(e) =>
                                    setServices({
                                      ...services,
                                      protesis: {
                                        ...services.protesis,
                                        selectTipoBarra: {
                                          ...services.protesis.selectTipoBarra,
                                          Fresada: e.target.checked,
                                        },
                                      },
                                    })
                                  }
                                  checked={
                                    services.protesis.selectTipoBarra.Fresada
                                  }
                                  className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                                />
                                <label className="text-sm font-medium leading-none peer-disabled:cursor-not-allowed peer-disabled:opacity-70">
                                  Fresada
                                </label>
                              </div>
                              <div className="flex items-center space-x-2 py-2 pr-1 pl-2 rounded-md">
                                <input
                                  type="checkbox"
                                  onChange={(e) =>
                                    setServices({
                                      ...services,
                                      protesis: {
                                        ...services.protesis,
                                        selectTipoBarra: {
                                          ...services.protesis.selectTipoBarra,
                                          Impreso3D: e.target.checked,
                                        },
                                      },
                                    })
                                  }
                                  checked={
                                    services.protesis.selectTipoBarra.Impreso3D
                                  }
                                  className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                                />
                                <label className="text-sm font-medium leading-none peer-disabled:cursor-not-allowed peer-disabled:opacity-70">
                                  Impresa 3D
                                </label>
                              </div>

                              <div className="flex items-center space-x-2 py-2 pr-1 pl-2 rounded-md">
                                <input
                                  type="checkbox"
                                  onChange={(e) =>
                                    setServices({
                                      ...services,
                                      protesis: {
                                        ...services.protesis,
                                        selectTipoBarra: {
                                          ...services.protesis.selectTipoBarra,
                                          Colada: e.target.checked,
                                        },
                                      },
                                    })
                                  }
                                  checked={
                                    services.protesis.selectTipoBarra.Colada
                                  }
                                  className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                                />
                                <label className="text-sm font-medium leading-none peer-disabled:cursor-not-allowed peer-disabled:opacity-70">
                                  Colada
                                </label>
                              </div>
                            </div>
                          </div>
                        </div>

                        <div className="bg-white px-4 sm:px-8 w-full font-semibold py-2 rounded-md my-2 border-2">
                          <h3 className="font-bold">
                            Carillas / Incrustación / Onlay
                          </h3>
                          <p>Material:</p>
                          <div className="flex flex-wrap gap-4 mt-2">
                            {[
                              // 'Amber',
                              // 'Emax',
                              // 'Ceramica',
                              'Feldespatica',
                              'ZirconioCar',
                              'DisilicatoLitioCar',
                              'OtrosCar',
                            ].map((material) => (
                              <div
                                key={material}
                                className="flex items-center space-x-1 py-2 pr-1 pl-1 rounded-md"
                              >
                                <input
                                  type="checkbox"
                                  onChange={(e) =>
                                    setServices({
                                      ...services,
                                      protesis: {
                                        ...services.protesis,
                                        material: {
                                          ...services.protesis.material,
                                          [material]: e.target.checked,
                                        },
                                      },
                                    })
                                  }
                                  checked={services.protesis.material[material]}
                                  className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                                />
                                <label className="text-sm font-medium leading-none peer-disabled:cursor-not-allowed peer-disabled:opacity-70">
                                  {handleName(material)}
                                </label>
                              </div>
                            ))}
                          </div>
                        </div>

                        <div className="bg-white px-4 md:px-8 w-full font-semibold py-2 rounded-md my-2 border-2">
                          <h3 className="font-bold ">
                            Dentadura / Parcial / Esquelético
                          </h3>
                          <div className="mt-2">
                            <div className="text-sm md:text-base">
                              Material:
                            </div>
                            <div className="flex flex-wrap gap-4 mt-2">
                              <div className="flex items-center space-x-2">
                                <input
                                  type="checkbox"
                                  onChange={(e) =>
                                    setServices({
                                      ...services,
                                      protesis: {
                                        ...services.protesis,
                                        material: {
                                          ...services.protesis.material,
                                          AcrilicoDen: e.target.checked,
                                        },
                                      },
                                    })
                                  }
                                  checked={
                                    services.protesis.material.AcrilicoDen
                                  }
                                  className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500"
                                />
                                <label className="text-sm font-medium leading-none">
                                  Acrílico
                                </label>
                              </div>
                              <div className="flex items-center space-x-2">
                                <input
                                  type="checkbox"
                                  onChange={(e) =>
                                    setServices({
                                      ...services,
                                      protesis: {
                                        ...services.protesis,
                                        material: {
                                          ...services.protesis.material,
                                          Impreso3Dden: e.target.checked,
                                        },
                                      },
                                    })
                                  }
                                  checked={
                                    services.protesis.material.Impreso3Dden
                                  }
                                  className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500"
                                />
                                <label className="text-sm font-medium leading-none">
                                  Impresión 3D
                                </label>
                              </div>
                            </div>

                            <div className="text-sm md:text-base mt-4">
                              Refuerzo:
                            </div>
                            <div className="flex flex-wrap gap-4 mt-2">
                              <div className="flex items-center space-x-2">
                                <input
                                  type="checkbox"
                                  onChange={(e) =>
                                    setServices({
                                      ...services,
                                      protesis: {
                                        ...services.protesis,
                                        refuerzoType: {
                                          ...services.protesis.refuerzoType,
                                          Malla: e.target.checked,
                                        },
                                      },
                                    })
                                  }
                                  checked={services.protesis.refuerzoType.Malla}
                                  className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500"
                                />
                                <label className="text-sm font-medium leading-none">
                                  Malla
                                </label>
                              </div>
                              <div className="flex items-center space-x-2">
                                <input
                                  type="checkbox"
                                  onChange={(e) =>
                                    setServices({
                                      ...services,
                                      protesis: {
                                        ...services.protesis,
                                        refuerzoType: {
                                          ...services.protesis.refuerzoType,
                                          RefuerzoForjado: e.target.checked,
                                        },
                                      },
                                    })
                                  }
                                  checked={
                                    services.protesis.refuerzoType
                                      .RefuerzoForjado
                                  }
                                  className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500"
                                />
                                <label className="text-sm font-medium leading-none">
                                  Refuerzo forjado
                                </label>
                              </div>
                            </div>

                            <div className="text-sm md:text-base mt-4">
                              Barra:
                            </div>
                            <div className="flex flex-wrap gap-4 mt-2">
                              <div className="flex items-center space-x-2">
                                <input
                                  type="checkbox"
                                  onChange={(e) =>
                                    setServices({
                                      ...services,
                                      protesis: {
                                        ...services.protesis,
                                        materialBarra: {
                                          ...services.protesis.materialBarra,
                                          CRCOden: e.target.checked,
                                        },
                                      },
                                    })
                                  }
                                  checked={
                                    services.protesis.materialBarra.CRCOden
                                  }
                                  className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500"
                                />
                                <label className="text-sm font-medium leading-none">
                                  CrCo
                                </label>
                              </div>
                              <div className="flex items-center space-x-2">
                                <input
                                  type="checkbox"
                                  onChange={(e) =>
                                    setServices({
                                      ...services,
                                      protesis: {
                                        ...services.protesis,
                                        materialBarra: {
                                          ...services.protesis.materialBarra,
                                          TIden: e.target.checked,
                                        },
                                      },
                                    })
                                  }
                                  checked={
                                    services.protesis.materialBarra.TIden
                                  }
                                  className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500"
                                />
                                <label className="text-sm font-medium leading-none">
                                  Ti
                                </label>
                              </div>
                              <div className="flex items-center space-x-2">
                                <input
                                  type="checkbox"
                                  onChange={(e) =>
                                    setServices({
                                      ...services,
                                      protesis: {
                                        ...services.protesis,
                                        materialBarra: {
                                          ...services.protesis.materialBarra,
                                          otrosDen: e.target.checked,
                                        },
                                      },
                                    })
                                  }
                                  checked={
                                    services.protesis.materialBarra.otrosDen
                                  }
                                  className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500"
                                />
                                <label className="text-sm font-medium leading-none">
                                  Otros
                                </label>
                              </div>
                            </div>
                          </div>
                        </div>

                        <div className="bg-white px-8 w-full font-semibold py-2 rounded-md my-2 border-2">
                          <h3 className="font-bold">Férulas</h3>
                          Tipo:
                          <div className="flex flex-wrap gap-4 mt-2">
                            <div className="flex items-center space-x-2 py-2 pr-1 pl-2 rounded-md w-1/2 md:w-auto">
                              <input
                                type="checkbox"
                                onChange={(e) =>
                                  setServices({
                                    ...services,
                                    protesis: {
                                      ...services.protesis,
                                      ferulasType: {
                                        ...services.protesis.ferulasType,
                                        Ferulaquirurgica: e.target.checked,
                                      },
                                    },
                                  })
                                }
                                checked={
                                  services.protesis.ferulasType.Ferulaquirurgica
                                }
                                className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                              />
                              <label className="text-sm font-medium leading-none peer-disabled:cursor-not-allowed peer-disabled:opacity-70">
                                Férula quirúrgica
                              </label>
                            </div>
                            <div className="flex items-center space-x-2 py-2 pr-1 pl-2 rounded-md w-1/2 md:w-auto">
                              <input
                                type="checkbox"
                                onChange={(e) =>
                                  setServices({
                                    ...services,
                                    protesis: {
                                      ...services.protesis,
                                      ferulasType: {
                                        ...services.protesis.ferulasType,
                                        Feruladescarga: e.target.checked,
                                      },
                                    },
                                  })
                                }
                                checked={
                                  services.protesis.ferulasType.Feruladescarga
                                }
                                className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                              />
                              <label className="text-sm font-medium leading-none peer-disabled:cursor-not-allowed peer-disabled:opacity-70">
                                Férula descarga
                              </label>
                            </div>
                            <div className="flex items-center space-x-2 py-2 pr-1 pl-2 rounded-md w-1/2 md:w-auto">
                              <input
                                type="checkbox"
                                onChange={(e) =>
                                  setServices({
                                    ...services,
                                    protesis: {
                                      ...services.protesis,
                                      ferulasType: {
                                        ...services.protesis.ferulasType,
                                        Ferulamuscular: e.target.checked,
                                      },
                                    },
                                  })
                                }
                                checked={
                                  services.protesis.ferulasType.Ferulamuscular
                                }
                                className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                              />
                              <label className="text-sm font-medium leading-none peer-disabled:cursor-not-allowed peer-disabled:opacity-70">
                                Férula muscular
                              </label>
                            </div>
                          </div>
                          Material:
                          <div className="flex gap-4 mt-2 flex-wrap">
                            <div className="flex items-center space-x-2 py-2 pr-1 pl-2 rounded-md w-1/2 md:w-auto">
                              <input
                                type="checkbox"
                                onChange={(e) =>
                                  setServices({
                                    ...services,
                                    protesis: {
                                      ...services.protesis,
                                      material: {
                                        ...services.protesis.material,
                                        AcrilicoFer: e.target.checked,
                                      },
                                    },
                                  })
                                }
                                checked={services.protesis.material.AcrilicoFer}
                                className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                              />
                              <label className="text-sm font-medium leading-none peer-disabled:cursor-not-allowed peer-disabled:opacity-70">
                                Acrílico
                              </label>
                            </div>
                            <div className="flex items-center space-x-2 py-2 pr-1 pl-2 rounded-md w-1/2 md:w-auto">
                              <input
                                type="checkbox"
                                onChange={(e) =>
                                  setServices({
                                    ...services,
                                    protesis: {
                                      ...services.protesis,
                                      material: {
                                        ...services.protesis.material,
                                        PMMA: e.target.checked,
                                      },
                                    },
                                  })
                                }
                                checked={services.protesis.material.PMMA}
                                className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                              />
                              <label className="text-sm font-medium leading-none peer-disabled:cursor-not-allowed peer-disabled:opacity-70">
                                PMMA
                              </label>
                            </div>
                            <div className="flex items-center space-x-2 py-2 pr-1 pl-2 rounded-md w-1/2 md:w-auto">
                              <input
                                type="checkbox"
                                onChange={(e) =>
                                  setServices({
                                    ...services,
                                    protesis: {
                                      ...services.protesis,
                                      material: {
                                        ...services.protesis.material,
                                        otrosFerulas: e.target.checked,
                                      },
                                    },
                                  })
                                }
                                checked={
                                  services.protesis.material.otrosFerulas
                                }
                                className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                              />
                              <label className="text-sm font-medium leading-none peer-disabled:cursor-not-allowed peer-disabled:opacity-70">
                                Otros
                              </label>
                            </div>
                          </div>
                        </div>
                      </div>

                      {/* Ortodoncia Options */}
                      <div className="bg-[#c09cff] px-2 py-6 md:p-6 border rounded-lg">
                        <h4 className="text-lg font-bold mb-4">Ortodoncia</h4>

                        <div className="bg-white font-semibold px-5 md:px-10 w-full py-2 rounded-md my-2 border-2 gap-10">
                          Aparatos Ortodoncia:
                          <div className="flex flex-wrap gap-4 md:gap-10 mt-2 py-2">
                            {[
                              'QuadHelix',
                              'Expansor',
                              'SureSmile',
                              'Otros',
                            ].map((item) => (
                              <div
                                key={item}
                                className="flex items-center space-x-2"
                              >
                                <input
                                  type="checkbox"
                                  onChange={(e) =>
                                    setServices({
                                      ...services,
                                      ortodoncia: {
                                        ...services.ortodoncia,
                                        subSelection: {
                                          ...services.ortodoncia.subSelection,
                                          [item]: e.target.checked,
                                        },
                                      },
                                    })
                                  }
                                  checked={
                                    services.ortodoncia.subSelection[item]
                                  }
                                  className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500"
                                />
                                <label className="text-sm font-medium leading-none">
                                  {item}
                                </label>
                              </div>
                            ))}
                          </div>
                          Ortodoncia Invisible:
                          <div className="flex flex-wrap gap-4 md:gap-10 mt-2 py-2">
                            {[
                              'Invisalign',
                              'KLine',
                              'AngelAlign',
                              'Geniova',
                              'OtrosOrtInv',
                            ].map((item) => (
                              <div
                                key={item}
                                className="flex items-center space-x-2"
                              >
                                <input
                                  type="checkbox"
                                  onChange={(e) =>
                                    setServices({
                                      ...services,
                                      ortodoncia: {
                                        ...services.ortodoncia,
                                        subSelection: {
                                          ...services.ortodoncia.subSelection,
                                          [item]: e.target.checked,
                                        },
                                      },
                                    })
                                  }
                                  checked={
                                    services.ortodoncia.subSelection[item]
                                  }
                                  className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500"
                                />
                                <label className="text-sm font-medium leading-none">
                                  {item}
                                </label>
                              </div>
                            ))}
                          </div>
                          Retenedor:
                          <div className="flex flex-wrap gap-4 md:gap-10 mt-2 py-2">
                            {['Fijo', 'Movil'].map((item) => (
                              <div
                                key={item}
                                className="flex items-center space-x-2"
                              >
                                <input
                                  type="checkbox"
                                  onChange={(e) =>
                                    setServices({
                                      ...services,
                                      ortodoncia: {
                                        ...services.ortodoncia,
                                        subSelection: {
                                          ...services.ortodoncia.subSelection,
                                          [item]: e.target.checked,
                                        },
                                      },
                                    })
                                  }
                                  checked={
                                    services.ortodoncia.subSelection[item]
                                  }
                                  className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500"
                                />
                                <label className="text-sm font-medium leading-none">
                                  {item}
                                </label>
                              </div>
                            ))}
                          </div>
                        </div>
                      </div>

                      {/* Productos Options */}
                      <div className="bg-[#f7ed8d] px-2 py-6 md:p-6 border rounded-lg">
                        <h4 className="text-lg font-bold mb-4">Otros</h4>

                        <div className="bg-white px-1 w-full py-2 rounded-md my-2 border-2">
                          <div className="flex gap-1 mt-2 flex-wrap">
                            <div className="flex items-center space-x-1  py-2 pr-1 pl-2 rounded-md">
                              <input
                                type="checkbox"
                                onChange={(e) =>
                                  setServices({
                                    ...services,
                                    productos: {
                                      ...services.productos,
                                      ProtectorBucal: e.target.checked,
                                    },
                                  })
                                }
                                checked={services.productos.ProtectorBucal}
                                className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500"
                              />
                              <label className="text-sm font-medium">
                                Protector Bucal
                              </label>
                            </div>
                            <div className="flex items-center space-x-1  py-2 pr-1 pl-2 rounded-md">
                              <input
                                type="checkbox"
                                onChange={(e) =>
                                  setServices({
                                    ...services,
                                    productos: {
                                      ...services.productos,
                                      AntiRonquido: e.target.checked,
                                    },
                                  })
                                }
                                checked={services.productos.AntiRonquido}
                                className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500"
                              />
                              <label className="text-sm font-medium">
                                Anti Ronquido
                              </label>
                            </div>
                            <div className="flex items-center space-x-1  py-2 pr-1 pl-2 rounded-md">
                              <input
                                type="checkbox"
                                onChange={(e) =>
                                  setServices({
                                    ...services,
                                    productos: {
                                      ...services.productos,
                                      Grillz: e.target.checked,
                                    },
                                  })
                                }
                                checked={services.productos.Grillz}
                                className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500"
                              />
                              <label className="text-sm font-medium">
                                Grillz
                              </label>
                            </div>
                            <div className="flex items-center space-x-1  py-2 pr-1 pl-2 rounded-md">
                              <input
                                type="checkbox"
                                onChange={(e) =>
                                  setServices({
                                    ...services,
                                    productos: {
                                      ...services.productos,
                                      Modelo: e.target.checked,
                                    },
                                  })
                                }
                                checked={services.productos.Modelo}
                                className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500"
                              />
                              <label className="text-sm font-medium">
                                Modelo
                              </label>
                            </div>
                            <div className="flex items-center space-x-1  py-2 pr-1 pl-2 rounded-md">
                              <input
                                type="checkbox"
                                onChange={(e) =>
                                  setServices({
                                    ...services,
                                    productos: {
                                      ...services.productos,
                                      ReparacionRebase: e.target.checked,
                                    },
                                  })
                                }
                                checked={services.productos.ReparacionRebase}
                                className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500"
                              />
                              <label className="text-sm font-medium">
                                Reparación / Rebase
                              </label>
                            </div>
                            <div className="flex items-center space-x-1  py-2 pr-1 pl-2 rounded-md">
                              <input
                                type="checkbox"
                                onChange={(e) =>
                                  setServices({
                                    ...services,
                                    productos: {
                                      ...services.productos,
                                      Biomaterial: e.target.checked,
                                    },
                                  })
                                }
                                checked={services.productos.Biomaterial}
                                className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500"
                              />
                              <label className="text-sm font-medium">
                                Biomaterial
                              </label>
                            </div>
                            <div className="flex items-center space-x-1  py-2 pr-1 pl-2 rounded-md">
                              <input
                                type="checkbox"
                                onChange={(e) =>
                                  setServices({
                                    ...services,
                                    productos: {
                                      ...services.productos,
                                      Otros: e.target.checked,
                                    },
                                  })
                                }
                                checked={services.productos.Otros}
                                className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500"
                              />
                              <label className="text-sm font-medium">
                                Otros
                              </label>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="flex justify-end mt-8">
                    <button
                      className="bg-blue-500 hover:bg-blue-700 text-white font-semibold py-2 px-4 rounded"
                      type="submit"
                    >
                      Guardar
                    </button>
                  </div>
                </section>
              </div>
            )}
          </form>
        </div>
      </div>
    </div>
  );
};

export default RegisterPost;
