import { useEffect, useState, useCallback } from 'react';
import {
  getNotifications,
  markNotificationAsRead,
} from '@/services/notification';

import { useUserStore } from '@/store/userStore';
import { toast } from 'react-toastify';

export const useFetchNotifications = (searchParams) => {
  const { user } = useUserStore();
  const [notifications, setNotifications] = useState([]);
  const [total, setTotal] = useState(0);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  const fetchNotifications = useCallback(async () => {
    setLoading(true);
    setError(null);

    try {
      const params = Object.fromEntries(searchParams);
      const queryString = new URLSearchParams(params).toString();
      let fetcher;

      fetcher = getNotifications;
      const data = await fetcher({ queryString });
      setNotifications(data.notifications);
      setTotal(data.total);
    } catch (error) {
      setError(error.message || 'Failed to fetch referrals');
      console.error('Failed to fetch referrals:', error);
    } finally {
      setLoading(false);
    }
  }, [searchParams, user.role]);

  useEffect(() => {
    fetchNotifications();
  }, [searchParams]);

  return { notifications, loading, error, total, fetchNotifications };
};

export const useMarkNotificationAsRead = () => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  const markAsRead = async (notificationId) => {
    setLoading(true);
    setError(null);

    try {
      await markNotificationAsRead(notificationId);
    } catch (error) {
      setError(error.message || 'Failed to mark notification as read');
      console.error('Failed to mark notification as read:', error);
    } finally {
      setLoading(false);
    }
  };

  return { markAsRead, loading, error };
};
