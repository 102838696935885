import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import LogoOklusion from '@/assets/Logo Oklusion.png';
import { MdOutlineEmail } from 'react-icons/md';
import { getDocs } from '@/services/doc';

const Footer = () => {
  const [docs, setDocs] = useState([]);

  useEffect(() => {
    const fetchDocs = async () => {
      const { docs } = await getDocs({});
      setDocs(docs);
    };

    fetchDocs();
  }, []);
  return (
    <div className="px-4 md:px-20 py-10 bg-white flex flex-col space-y-5 mt-0">
      <Link to="/" className="hover:text-gray-300">
        <img
          src={LogoOklusion}
          alt="Logo Oklusion"
          className="h-8 w-auto object-contain"
        />
      </Link>

      {/* <p className="w-full md:w-1/3 text-sm text-gray-600">
        Lorem ipsum dolor sit amet consectetur elit lorem ipsum dolor sit amet.
      </p> */}

      <p className="flex flex-row gap-2 items-center text-gray-600">
        <MdOutlineEmail className="text-xl" /> hola@oklusion.com
      </p>

      <div className="grid grid-cols-2 md:grid-cols-5 gap-3 text-sm">
        {docs.map((doc) => {
          return (
            <Link
              to={`doc/${doc.slug}`}
              className="hover:underline text-[#1D2939]"
              target="_blank"
              key={doc._id}
            >
              {doc.title}
            </Link>
          );
        })}
        {/* <Link
          to="doc/condiciones-generales"
          className="hover:underline text-[#1D2939]"
          target="_blank"
        >
          Aviso legal
        </Link>
        <Link
          to="doc/politicas"
          className="hover:underline text-[#1D2939]"
          target="_blank"
        >
          Política de privacidad
        </Link>
        <Link
          to="doc/cookies"
          className="hover:underline text-[#1D2939]"
          target="_blank"
        >
          Política de cookies
        </Link>
        <Link
          to="doc/condiciones-uso"
          className="hover:underline text-[#1D2939]"
          target="_blank"
        >
          Condiciones de uso
        </Link>
        <Link
          to="doc/condiciones-venta"
          className="hover:underline text-[#1D2939]"
          target="_blank"
        >
          Condiciones de Venta o Contratación
        </Link> */}
      </div>

      <hr className="my-6 h-px border-t-0 bg-transparent bg-gradient-to-r from-transparent via-neutral-500 to-transparent opacity-25 dark:via-neutral-400" />

      <p className="text-sm text-gray-500">
        © 2024 Oklusion. Todos los derechos reservados.
      </p>
    </div>
  );
};

export default Footer;
