import React from 'react';

const Cookies = () => {
  return (
    <div className="px-12 py-8 text-justify bg-white text-gray-900 leading-relaxed max-w-4xl mx-auto">
      <h1 className="text-3xl font-bold mb-6 text-center">
        Política de Cookies
      </h1>

      <h2 className="text-2xl font-semibold mt-8 mb-4">1. Introducción</h2>
      <p className="mb-6 indent-8">
        En Oklusion, utilizamos cookies para mejorar tu experiencia en nuestra
        plataforma. Esta política de cookies explica qué son las cookies, cómo
        las utilizamos y cómo puedes gestionarlas.
      </p>

      <h2 className="text-2xl font-semibold mt-8 mb-4">
        2. ¿Qué son las Cookies?
      </h2>
      <p className="mb-6 indent-8">
        Las cookies son pequeños archivos de texto que se almacenan en tu
        dispositivo cuando visitas una página web. Permiten que la web recuerde
        tus acciones y preferencias (como inicio de sesión, idioma, tamaño de
        fuente y otras preferencias de visualización) durante un período de
        tiempo, para que no tengas que volver a configurarlas cada vez que
        vuelvas al sitio o navegues de una página a otra.
      </p>

      <h2 className="text-2xl font-semibold mt-8 mb-4">
        3. Tipos de Cookies que Utilizamos
      </h2>
      <p className="mb-6 indent-8">
        En Oklusion utilizamos los siguientes tipos de cookies:
      </p>
      <ul className="list-disc ml-8 mb-6">
        <li>
          <strong>Cookies Esenciales:</strong> Son necesarias para el
          funcionamiento de la plataforma y no pueden ser desactivadas en
          nuestros sistemas.
        </li>
        <li>
          <strong>Cookies de Rendimiento:</strong> Nos permiten contar las
          visitas y fuentes de tráfico para poder medir y mejorar el rendimiento
          de nuestra plataforma.
        </li>
        <li>
          <strong>Cookies Funcionales:</strong> Permiten que la plataforma
          proporcione una funcionalidad y personalización mejoradas.
        </li>
        <li>
          <strong>Cookies de Publicidad:</strong> Estas cookies pueden ser
          establecidas por nuestros socios publicitarios para crear un perfil de
          tus intereses y mostrarte anuncios relevantes.
        </li>
      </ul>

      <h2 className="text-2xl font-semibold mt-8 mb-4">
        4. Cookies de Terceros
      </h2>
      <p className="mb-6 indent-8">
        En algunos casos, utilizamos cookies de terceros proporcionadas por
        servicios como Stripe (para pagos) y Sendcloud (para envíos). Estas
        cookies son gestionadas por los respectivos proveedores y no tenemos
        control directo sobre ellas.
      </p>

      <h2 className="text-2xl font-semibold mt-8 mb-4">
        5. Gestión de Cookies
      </h2>
      <p className="mb-6 indent-8">
        Puedes gestionar tus preferencias de cookies a través de la
        configuración de tu navegador. A continuación, te proporcionamos enlaces
        a las instrucciones para gestionar cookies en los navegadores más
        comunes:
      </p>
      <ul className="list-disc ml-8 mb-6">
        <li>
          <strong>Google Chrome</strong>
        </li>
        <li>
          <strong>Mozilla Firefox</strong>
        </li>
        <li>
          <strong>Safari</strong>
        </li>
        <li>
          <strong>Microsoft Edge</strong>
        </li>
      </ul>
      <p className="mb-6 indent-8">
        Ten en cuenta que si decides bloquear o desactivar cookies, es posible
        que algunas funciones de la plataforma no estén disponibles o no
        funcionen correctamente.
      </p>

      <h2 className="text-2xl font-semibold mt-8 mb-4">
        6. Cambios en la Política de Cookies
      </h2>
      <p className="mb-6 indent-8">
        Nos reservamos el derecho de modificar esta política de cookies en
        cualquier momento. Te notificaremos cualquier cambio a través de nuestra
        plataforma o por correo electrónico.
      </p>

      <h2 className="text-2xl font-semibold mt-8 mb-4">7. Contacto</h2>
      <p className="mb-6 indent-8">
        Si tienes alguna pregunta sobre nuestra política de cookies, puedes
        contactarnos en:
      </p>
      <ul className="list-disc ml-8 mb-6">
        <li>
          <strong>Correo Electrónico:</strong> info@oklusion.com
        </li>
        <li>
          <strong>Teléfono:</strong> +34 123 456 789
        </li>
      </ul>
    </div>
  );
};

export default Cookies;
