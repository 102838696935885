import { Routes, Route } from 'react-router-dom';
import ChatLayout from '@/components/Layouts/ChatLayout';
import CasePage from './CasePage.jsx';
import CasesPage from './CasesPage.jsx';
import ChatPage from './ChatPage.jsx';
import NewCase from './NewCase.jsx';
import OfferPage from './OfferPage.jsx';
// import OrderPage from './OrderPage.jsx';
import Revisions from './Revisions.jsx';
import NewCaseBillPage from './NewCaseBillPage.jsx';
import CaseBillPage from './CaseBillPage.jsx';
import BillLab from './billLab.jsx';

const CasesRoutes = () => {
  return (
    <Routes>
      <Route path="/" element={<CasesPage />} />
      <Route element={<ChatLayout />}>
        <Route path="/:caseId" element={<CasePage />} />
        <Route path="/chat/:chatId" element={<ChatPage />} />
        <Route path="/offer/:offerId" element={<OfferPage />} />
        <Route path="/revisions/:caseId" element={<Revisions />} />
        <Route path="/createBill/:caseId" element={<NewCaseBillPage />} />
        <Route path="/billLabs/:billId" element={<BillLab />} />
        <Route path="/bill/:billId" element={<CaseBillPage />} />
      </Route>

      <Route path="/newcase" element={<NewCase />} />
    </Routes>
  );
};

export default CasesRoutes;
