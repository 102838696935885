import React from 'react';
import { useUserStore } from '@/store/userStore';

const ClinicCard = ({ setClinics, clinic, clinics, index }) => {
  const { user } = useUserStore();
  const handleChange = (e) => {
    const updatedClinics = clinics.map((c, i) =>
      i === index ? { ...c, [e.target.name]: e.target.value } : c,
    );
    setClinics(updatedClinics);
  };
  const handleRemoveClinic = (index) => {
    const updatedClinics = clinics.filter((_, i) => i !== index);
    setClinics(updatedClinics);
  };

  return (
    <div className="space-y-1 my-2">
      {/* <label
        htmlFor="clinicID"
        className="block text-lg font-medium text-gray-700 py-2"
      >
        {user.role !== 'SELLER_ROLE'
          ? 'Datos de la clínica '
          : 'Datos del laboratorio'}
      </label> */}
      <div className="flex gap-4">
        <div className="flex-1">
          <label
            htmlFor="clinicName"
            className="block text-sm font-medium text-gray-700"
          >
            {user.role !== 'SELLER_ROLE'
              ? 'Nombre de la Clínica '
              : 'Nombre del Laboratorio'}
          </label>
          <input
            type="text"
            id="clinicName"
            name="clinicName"
            value={clinic.clinicName}
            onChange={handleChange}
            className="mt-1 block w-full p-2 border border-gray-300 rounded-lg shadow-sm focus:ring-blue-500 focus:border-blue-500"
            placeholder={
              user.role !== 'SELLER_ROLE'
                ? 'Nombre de la Clínica '
                : 'Nombre del Laboratorio'
            }
            required
          />
        </div>
      </div>
      <label
        htmlFor="via"
        className="block text-sm font-medium text-gray-700 py-2"
      >
        Dirección
        {/* {user.role !== 'SELLER_ROLE' && (
          <button
            type="button"
            className="ml-2 text-red-500 hover:text-red-700 justify-end"
            onClick={() => handleRemoveClinic(index)}
          >
            Eliminar
          </button>
        )} */}
      </label>
      <div className="flex gap-4 mt-4">
        <div className="flex-1">
          <label
            htmlFor="postalCode"
            className="block text-sm font-medium text-gray-700"
          >
            Código Postal
          </label>
          <input
            type="text"
            id="postalCode"
            name="postalCode"
            value={clinic.postalCode}
            onChange={handleChange}
            className="mt-1 block w-full p-2 border border-gray-300 rounded-lg shadow-sm focus:ring-blue-500 focus:border-blue-500"
            placeholder="Código Postal"
            required
          />
        </div>
        <div className="flex-1">
          <label
            htmlFor="clinicstreet"
            className="block text-sm font-medium text-gray-700"
          >
            Calle
          </label>
          <input
            type="text"
            id="clinicstreet"
            name="clinicstreet"
            value={clinic.clinicstreet}
            onChange={handleChange}
            className="mt-1 block w-full p-2 border border-gray-300 rounded-lg shadow-sm focus:ring-blue-500 focus:border-blue-500"
            placeholder="Nombre de la calle"
            required
          />
        </div>
      </div>
      <div className="flex gap-4 mt-4">
        <div className="flex-1">
          <label
            htmlFor="province"
            className="block text-sm font-medium text-gray-700"
          >
            Provincia
          </label>
          <input
            type="text"
            id="province"
            name="province"
            value={clinic.province}
            onChange={handleChange}
            className="mt-1 block w-full p-2 border border-gray-300 rounded-lg shadow-sm focus:ring-blue-500 focus:border-blue-500"
            placeholder="Provincia"
            required
          />
        </div>
        <div className="flex-1">
          <label
            htmlFor="city"
            className="block text-sm font-medium text-gray-700"
          >
            Ciudad
          </label>
          <input
            type="text"
            id="city"
            name="city"
            value={clinic.city}
            onChange={handleChange}
            className="mt-1 block w-full p-2 border border-gray-300 rounded-lg shadow-sm focus:ring-blue-500 focus:border-blue-500"
            placeholder="Ciudad"
            required
          />
        </div>
      </div>
      <div className="flex gap-4 mt-4">
        <div className="flex-1">
          <label
            htmlFor="province"
            className="block text-sm font-medium text-gray-700"
          >
            Pais
          </label>
          <input
            type="text"
            id="country"
            name="country"
            value={clinic.country}
            onChange={handleChange}
            className="mt-1 block w-full p-2 border border-gray-300 rounded-lg shadow-sm focus:ring-blue-500 focus:border-blue-500"
            placeholder="Pais"
            required
          />
        </div>
      </div>
    </div>
  );
};

export default ClinicCard;
