import React from 'react';
import { Outlet } from 'react-router-dom';
import Sidebar from './Sidebar';

export default function guideLayout() {
  return (
    <div className="flex flex-grow h-full">
      {/* Sidebar */}
      <Sidebar />

      {/* Main content with Outlet */}
      <main className="flex-grow w-full p-4 h-full">
        <Outlet />
      </main>
    </div>
  );
}
