import { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import {
  FaArrowDown,
  FaArrowUp,
  FaArrowRight,
  FaArrowLeft,
} from 'react-icons/fa';
import { useUserStore } from '@/store/userStore';
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from '@/components/ui/table';
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from '@/components/ui/select';
import { Button } from '@/components/ui/button';
import { Card } from '@/components/ui/card';
import { Input } from '@/components/ui/input';
import { Label } from '@/components/ui/label';
import { CiMoneyBill } from 'react-icons/ci';
import { Download } from 'lucide-react';

import { handlecaseType } from '@/utils/handlecaseType';

import { statusStyles } from '@/constants/caseType';

const TableCases = ({
  demands,
  outsourcings,
  setSearchParams,
  searchParams,
  total,
}) => {
  const { user } = useUserStore();
  const [search, setSearch] = useState('');
  const [showFilters, setShowFilters] = useState(false);
  const [roleType, setRoleType] = useState('');
  const [sort, setSort] = useState({
    sortBy: searchParams.get('sortBy') || 'createdAt', // Default sort by creation date
    order: searchParams.get('order') || 'desc', // Default sort order
  });

  const limit = searchParams ? Number(searchParams.get('limit')) || 15 : 15;
  const skip = searchParams ? Number(searchParams.get('skip')) || 0 : 0;
  const status = searchParams ? searchParams.get('status') || '' : '';
  const caseType = searchParams ? searchParams.get('caseType') || '' : '';
  const caseName = searchParams ? searchParams.get('caseName') || '' : '';
  const startDate = searchParams ? searchParams.get('startDate') || '' : '';
  const endDate = searchParams ? searchParams.get('endDate') || '' : '';
  const searchByNotification = searchParams
    ? searchParams.get('searchByNotification') || false
    : false;
  const includeAsSeller = searchParams
    ? searchParams.get('includeAsSeller') || ''
    : '';
  const includeAsUser = searchParams
    ? searchParams.get('includeAsUser') || ''
    : '';

  const handleStatus = (status) => {
    const statusData = statusStyles[status];
    return statusData ? (
      <div
        className={`w-fit p-1 rounded-md   ${statusData.bg} ${statusData.text}`}
      >
        {statusData.label}
      </div>
    ) : null;
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    const newParams = new URLSearchParams(searchParams);
    if (value) {
      newParams.set(name, value);
    } else {
      newParams.delete(name);
    }
    setSearchParams(newParams);
  };
  const handleSort = (field) => {
    const newOrder =
      sort.sortBy === field && sort.order === 'asc' ? 'desc' : 'asc';
    setSort({ sortBy: field, order: newOrder });

    const newParams = new URLSearchParams(searchParams);
    newParams.set('sortBy', field);
    newParams.set('order', newOrder);
    setSearchParams(newParams);
  };

  const handleSelectChange = (name, value) => {
    const newParams = new URLSearchParams(searchParams);
    if (value) {
      newParams.set(name, value);
    } else {
      newParams.delete(name);
    }
    setSearchParams(newParams);
  };

  const handleNotifications = (data) => {
    const notifications = data.notifications;
    const notification = notifications.find(
      (n) => n.user.toString() === user?._id,
    );
    if (notification && notification.count > 0) {
      return notification.count;
    } else {
      return 0;
    }
  };

  useEffect(() => {
    const newParams = new URLSearchParams(searchParams);
    if (!outsourcings) return;

    switch (roleType) {
      // case 'all':
      //   newParams.set('includeAsSeller', 'true');
      //   newParams.set('includeAsUser', 'true');
      //   break;
      case 'seller':
        newParams.set('includeAsSeller', 'true');
        newParams.set('includeAsUser', 'false');
        break;
      case 'user':
        newParams.set('includeAsSeller', 'false');
        newParams.set('includeAsUser', 'true');
        break;
      default:
        newParams.delete('includeAsSeller');
        newParams.delete('includeAsUser');
        break;
    }

    setSearchParams(newParams);
  }, [roleType, searchParams, setSearchParams]);

  return (
    <div className="overflow-x-auto">
      <div className="flex flex-col md:flex-row space-y-2 md:space-y-0 md:space-x-2 my-4">
        <input
          type="date"
          className="p-2 border border-[#BDCAFE] rounded-md h-10 w-full md:w-auto font-urbanist"
          placeholder="Desde"
          aria-label="Start date filter"
          value={startDate}
          name="startDate"
          onChange={handleInputChange}
        />
        <input
          type="date"
          className="p-2 border border-[#BDCAFE] rounded-md h-10 w-full md:w-auto font-urbanist"
          placeholder="Hasta"
          aria-label="End date filter"
          value={endDate}
          name="endDate"
          onChange={handleInputChange}
        />

        <input
          type="text"
          name="caseName"
          placeholder="Nombre del caso"
          value={caseName}
          onChange={handleInputChange}
          className="p-2 border border-[#BDCAFE] rounded-md h-10 w-full md:w-auto font-urbanist"
          aria-label="Filter by case number"
        />

        <Select
          value={caseType}
          onValueChange={(value) => handleSelectChange('caseType', value)}
          className="w-full md:w-auto"
          aria-label="Filter by case type"
        >
          <SelectTrigger className="w-full md:w-[180px] px-2 h-10 border border-[#BDCAFE] rounded-md font-urbanist">
            <SelectValue placeholder="Tipo de caso" />
          </SelectTrigger>
          <SelectContent className="font-urbanist">
            <SelectItem value="*">Todos</SelectItem>
            <SelectItem value="Corona">
              Corona / Puente / Arcada completa
            </SelectItem>
            <SelectItem value="Carillas">
              Carillas / Incrustación / onlay
            </SelectItem>
            <SelectItem value="Dentadura">
              Dentadura / Parcial / Esquelético
            </SelectItem>
            <SelectItem value="Férulas">Férulas</SelectItem>
            <SelectItem value="OrthodonticAppliances">
              Aparatos Ortodoncia
            </SelectItem>
            <SelectItem value="Aligners">Ortodoncia Invisible</SelectItem>
            <SelectItem value="Retainers">Retenedor</SelectItem>
            <SelectItem value="ProtectorBucal">Protector Bucal</SelectItem>
            <SelectItem value="AntiRonquido">Anti Ronquido</SelectItem>
            <SelectItem value="Grillz">Grillz</SelectItem>
            <SelectItem value="Modelo">Modelo</SelectItem>
            <SelectItem value="ReparacionRebase">
              Reparación / Rebase
            </SelectItem>
            <SelectItem value="Biomaterial">Biomaterial</SelectItem>
          </SelectContent>
        </Select>

        <Select
          value={status}
          onValueChange={(value) => handleSelectChange('status', value)}
          aria-label="Filter by status"
          className="w-full md:w-auto"
        >
          <SelectTrigger className="w-full md:w-[180px] px-2 h-10 border border-[#BDCAFE] rounded-md font-urbanist">
            <SelectValue placeholder="Estado" />
          </SelectTrigger>
          <SelectContent>
            <SelectItem value="pending">Pendiente de propuesta</SelectItem>
            <SelectItem value="design">Diseño</SelectItem>
            <SelectItem value="onfabrication">Fabricación</SelectItem>
            <SelectItem value="sended">Enviado</SelectItem>
            <SelectItem value="inReview">Revisión</SelectItem>
            <SelectItem value="CaseReopened">Caso reabierto</SelectItem>
            <SelectItem value="accepted">Completado</SelectItem>
          </SelectContent>
        </Select>
        {outsourcings && (
          //select
          <Select
            value={roleType}
            onValueChange={(value) => setRoleType(value)}
            aria-label="Filter by status"
            className="w-full md:w-auto"
          >
            <SelectTrigger className="w-full md:w-[180px] px-2 h-10 border border-[#BDCAFE] rounded-md font-urbanist">
              <SelectValue placeholder="Tipo de trabajo" />
            </SelectTrigger>
            <SelectContent>
              <SelectItem value="all">Todos</SelectItem>
              <SelectItem value="user">Pedidos realizados</SelectItem>
              <SelectItem value="seller">Pedidos recibidos</SelectItem>
            </SelectContent>
          </Select>
        )}

        <div className="flex items-center space-x-2 font-urbanist">
          <Label htmlFor="following">Notificaciones:</Label>
          <Input
            type="checkbox"
            name="following"
            checked={searchByNotification}
            onChange={(e) =>
              handleSelectChange('searchByNotification', e.target.checked)
            }
            aria-label="Filter by Seguimiento"
          />
        </div>
      </div>

      <div className="rounded-lg border w-full">
        <Card className="h-full  ">
          <Table className="table-auto   border border-1 rounded-md ">
            <TableHeader className="bg-[#e9ecf2] table-text font-bold font-urbanist">
              <TableRow>
                {/* <TableHead
                  className="px-4 py-2  hidden lg:table-cell  "
                  onClick={() => handleSort('createdAt')}
                >
                  Fecha de creación
                </TableHead> */}
                {/* <TableHead className="px-4 py-2  table-cell  ">
                  ID
                </TableHead> */}
                <TableHead
                  className="px-4 py-2 table-cell  "
                  onClick={() => handleSort('deliveryDate')}
                >
                  Fecha de entrega
                  {sort.sortBy === 'deliveryDate' &&
                    (sort.order === 'asc' ? (
                      <FaArrowUp className="inline ml-2" />
                    ) : (
                      <FaArrowDown className="inline ml-2" />
                    ))}
                </TableHead>

                {user.role != 'DENTIST_ROLE' && (
                  <TableHead
                    className="px-4 py-2  table-cell  "
                    onClick={() => handleSort('clinicName')}
                  >
                    Cliente
                    {sort.sortBy === 'clinicName' &&
                      (sort.order === 'asc' ? (
                        <FaArrowUp className="inline ml-2" />
                      ) : (
                        <FaArrowDown className="inline ml-2" />
                      ))}
                  </TableHead>
                )}

                <TableHead
                  className="px-4 py-2     "
                  onClick={() => handleSort('caseType')}
                >
                  Tipo de caso
                  {sort.sortBy === 'caseType' &&
                    (sort.order === 'asc' ? (
                      <FaArrowUp className="inline ml-2" />
                    ) : (
                      <FaArrowDown className="inline ml-2" />
                    ))}
                </TableHead>
                <TableHead
                  className="px-4 py-2  table-cell  "
                  onClick={() => handleSort('description')}
                >
                  Descripción
                  {sort.sortBy === 'description' &&
                    (sort.order === 'asc' ? (
                      <FaArrowUp className="inline ml-2" />
                    ) : (
                      <FaArrowDown className="inline ml-2" />
                    ))}
                </TableHead>

                {/* <TableHead className="px-4 py-2  hidden lg:table-cell  ">
                  Notificaciones
                </TableHead> */}

                <TableHead
                  className="px-4 py-2  "
                  onClick={() => handleSort('status')}
                >
                  Estado
                  {sort.sortBy === 'status' &&
                    (sort.order === 'asc' ? (
                      <FaArrowUp className="inline ml-2" />
                    ) : (
                      <FaArrowDown className="inline ml-2" />
                    ))}
                </TableHead>
                <TableHead
                  className="px-4 py-2  "
                  onClick={() => handleSort('bills')}
                >
                  Facturas
                  {sort.sortBy === 'bills' &&
                    (sort.order === 'asc' ? (
                      <FaArrowUp className="inline ml-2" />
                    ) : (
                      <FaArrowDown className="inline ml-2" />
                    ))}
                </TableHead>
              </TableRow>
            </TableHeader>
            <TableBody className="font-inter">
              {demands &&
                demands.map((demand, index) => (
                  <TableRow key={index} className="border border-1">
                    {/* <TableCell className="px-4 py-2  hidden lg:table-cell ">
                      {new Date(demand.createdAt).toLocaleDateString()}
                    </TableCell> */}
                    {/* <TableCell className="px-4 py-2  table-cell ">
                      {demand._id}
                    </TableCell> */}
                    <TableCell className="px-4 py-2  table-cell  items-center relative ">
                      {handleNotifications(demand) > 0 && (
                        <span className="absolute top-4 left-2 inline-block w-2 h-2 bg-red-500 text-white rounded-full text-sm   items-center justify-center"></span>
                      )}
                      {new Date(demand.deliveryDate).toLocaleDateString()}
                    </TableCell>
                    {/* <TableCell className="px-4 py-2   hover:text-blue-500">
                      {demand.status !== 'accepted' &&
                        (user.role === 'ADMIN_ROLE' ? (
                          <Link to={'/admin/cases/' + demand._id}>
                            {demand.caseName}
                          </Link>
                        ) : (
                          <Link to={'/cases/' + demand._id}>
                            {demand.caseName}
                          </Link>
                        ))}

                      {demand.status === 'accepted' &&
                        (user.role === 'ADMIN_ROLE' ? (
                          <Link
                            to={
                              '/admin/order/' +
                              findOrder(demand.selectedLaboratorios)
                            }
                          >
                            {demand.caseName}ss
                          </Link>
                        ) : (
                          <Link
                            to={
                              '/order/' + findOrder(demand.selectedLaboratorios)
                            }
                          >
                            {demand.caseName}
                          </Link>
                        ))}
                    </TableCell> */}
                    {user.role != 'DENTIST_ROLE' && (
                      <TableCell className="px-4 py-2  table-cell ">
                        {demand.user?.name} {demand.user?.lastname}
                      </TableCell>
                    )}
                    <TableCell className="px-4 py-2  ">
                      {handlecaseType(demand)}
                    </TableCell>
                    <TableCell className="px-4 py-4 table-cell overflow-hidden hover:text-blue-500 w-[50%]">
                      {user.role === 'ADMIN_ROLE' ? (
                        <Link to={'/admin/cases/' + demand._id}>
                          <>
                            <h4>
                              <strong>
                                {demand.caseName.substring(0, 50)}
                              </strong>{' '}
                            </h4>
                            <p>{demand.description.substring(0, 200)}</p>
                          </>
                        </Link>
                      ) : (
                        <Link to={'/cases/' + demand._id}>
                          <>
                            <h4>
                              <strong>
                                {demand.caseName.substring(0, 50)}
                              </strong>{' '}
                            </h4>
                            <p>{demand.description.substring(0, 200)}</p>
                          </>
                        </Link>
                      )}
                    </TableCell>

                    {/* <TableCell className="px-4 py-4  hidden lg:table-cell  overflow-hidden">
                      {handleNotifications(demand)}
                    </TableCell> */}

                    <TableCell className={`px-4 py-2  items-center  `}>
                      {handleStatus(demand.status)}
                    </TableCell>
                    <TableCell className={`px-4 py-2  items-center  `}>
                      {demand.billLabCase && (
                        <a
                          href={demand.billLabCase.file.url}
                          target="_blank"
                          className="text-blue-500"
                          download
                        >
                          <Download />
                        </a>
                      )}
                    </TableCell>
                  </TableRow>
                ))}
              {outsourcings &&
                outsourcings.map((outsourcing, index) => (
                  <TableRow key={index} className="border border-1">
                    <TableCell className="px-4 py-2  table-cell  items-center relative ">
                      {handleNotifications(outsourcing) > 0 && (
                        <span className="absolute top-4 left-2 inline-block w-2 h-2 bg-red-500 text-white rounded-full text-sm   items-center justify-center"></span>
                      )}
                      {new Date(outsourcing.deliveryDate).toLocaleDateString()}
                    </TableCell>

                    {user.role != 'DENTIST_ROLE' && (
                      <TableCell className="px-4 py-2  table-cell ">
                        {outsourcing.user?.name} {outsourcing.user?.lastname}
                      </TableCell>
                    )}
                    <TableCell className="px-4 py-2  ">
                      {handlecaseType(outsourcing)}
                    </TableCell>
                    <TableCell className="px-4 py-4 table-cell overflow-hidden hover:text-blue-500 w-[50%]">
                      {user.role === 'ADMIN_ROLE' ? (
                        <Link to={'/admin/outsourcings/' + outsourcing._id}>
                          <>
                            <h4>
                              <strong>
                                {outsourcing.caseName.substring(0, 50)}
                              </strong>{' '}
                            </h4>
                            <p>{outsourcing.description.substring(0, 200)}</p>
                          </>
                        </Link>
                      ) : (
                        <Link to={'/outsourcing/' + outsourcing._id}>
                          <>
                            <h4>
                              <strong>
                                {outsourcing.caseName.substring(0, 50)}
                              </strong>{' '}
                            </h4>
                            <p>{outsourcing.description.substring(0, 200)}</p>
                          </>
                        </Link>
                      )}
                    </TableCell>

                    <TableCell className={`px-4 py-2  items-center  `}>
                      {handleStatus(outsourcing.status)}
                    </TableCell>
                    <TableCell className={`px-4 py-2  items-center  `}>
                      {outsourcing.billLabCase && (
                        <a
                          href={outsourcing.billLabCase.file.url}
                          target="_blank"
                          className="text-blue-500"
                          download
                        >
                          <Download />
                        </a>
                      )}
                    </TableCell>
                  </TableRow>
                ))}
              {!demands && !outsourcings && (
                <TableRow className="border border-1">
                  <TableCell className="px-4 py-2  table-cell ">
                    No hay casos
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
          <div className="bg-[#f5f7fa] flex justify-between px-4 py-2 ">
            <Button
              onClick={() =>
                setSearchParams({ limit, skip: Math.max(skip - limit, 0) })
              }
              disabled={skip === 0}
              className="btn-neutral"
              aria-label="Previous page"
            >
              <FaArrowLeft className="inline mr-2" />
              Anterior
            </Button>

            <Button
              onClick={() => setSearchParams({ limit, skip: skip + limit })}
              disabled={skip + limit >= total}
              className="btn-primary"
              aria-label="Next page"
            >
              Siguiente
              <FaArrowRight className="inline ml-2" />
            </Button>
          </div>
        </Card>
      </div>
    </div>
  );
};

export default TableCases;
