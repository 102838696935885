import React, { useState, useEffect } from 'react';
import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle,
  DialogFooter,
} from '@/components/ui/dialog';
import { Button } from '@/components/ui/button';
import { Textarea } from '@/components/ui/textarea';
import { toast } from 'react-toastify';
import { Rating } from '@material-tailwind/react';
import { updateReviewAsAdmin, getReview } from '@/services/review';
import { set } from 'date-fns';

const ReviewModalEdit = ({ reviewId, isOpen, setIsOpen, setEdited }) => {
  const [description, setDescription] = useState('');
  const [isPrivate, setPrivate] = useState(false);
  const [rating, setRating] = useState(0);
  const [loading, setLoading] = useState(false);

  const handleModalClose = () => {
    setIsOpen(false);
  };

  const resetForm = () => {
    setDescription('');
    setRating(0);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!description || !rating) {
      return toast.error('Por favor complete todos los campos');
    }

    const reviewData = {
      description,
      rating,
      isPrivate,
    };

    try {
      setLoading(true);
      const response = await updateReviewAsAdmin({ id: reviewId, reviewData });
      if (response) {
        resetForm();
        handleModalClose();
        toast.success('Reseña editada con éxito');
        setEdited(true);
      }
    } catch (error) {
      toast.error('Error al editar la reseña');
      console.error('Error creating review:', error);
    } finally {
      setLoading(false);
    }
  };
  useEffect(() => {
    const fetchReview = async () => {
      try {
        const { review } = await getReview({ id: reviewId });
        setDescription(review.description);
        setRating(review.rating);
        setPrivate(review.isPrivate);
      } catch (error) {
        console.error('Error fetching review:', error);
      }
    };

    if (reviewId) {
      fetchReview();
    }
  }, [reviewId]);

  return (
    <Dialog open={isOpen} onOpenChange={handleModalClose}>
      <DialogContent>
        <DialogHeader>
          <DialogTitle>Valora tu experiencia</DialogTitle>
        </DialogHeader>

        <form onSubmit={handleSubmit}>
          <div className="mb-4">
            <div className="flex justify-start mb-4">
              <Rating
                unratedColor="blue"
                ratedColor="blue"
                value={rating}
                onChange={(value) => setRating(value)}
              />
            </div>
          </div>

          <div className="mb-4">
            <Textarea
              id="description"
              value={description}
              onChange={(e) => setDescription(e.target.value)}
              placeholder="Escribe tu valoración"
              required
              className="p-4 w-full border-2 rounded-md"
            />
          </div>
          <div className="flex items-center space-x-2 mb-4">
            <input
              id="dentist"
              type="checkbox"
              checked={isPrivate}
              onChange={() => setPrivate(!isPrivate)}
              className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
            />
            <label className="text-sm font-medium leading-none peer-disabled:cursor-not-allowed peer-disabled:opacity-70">
              Publicar anónimo
            </label>
          </div>

          <DialogFooter>
            <Button
              type="submit"
              disabled={!rating || !description || loading}
              className={`w-full text-white hover:bg-blue-400 ${
                !rating || !description
                  ? 'bg-gray-400 cursor-not-allowed'
                  : 'bg-blue-500'
              }`}
            >
              {loading ? 'Cargando' : 'Guardar'}
            </Button>
          </DialogFooter>
        </form>
      </DialogContent>
    </Dialog>
  );
};

export default ReviewModalEdit;
