import { useEffect, useState, useCallback } from 'react';
import {
  getbillOutsourcingBuyersAsSeller,
  getbillOutsourcingBuyers,
  getbillOutsourcingBuyersAsAdmin,
} from '@/services/billOutsourcingBuyer';
import { useUserStore } from '@/store/userStore';

// Hook for fetching multiple demands
export const useFetchBillOutsourcingBuyers = (searchParams) => {
  const { user } = useUserStore();
  const [billOutsourcingBuyers, setBillOutsourcingBuyers] = useState([]);
  const [total, setTotal] = useState(0);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  const fetchBillOutsourcingBuyers = useCallback(async () => {
    setLoading(true);
    setError(null); // Reset error state

    try {
      const params = Object.fromEntries(searchParams || []);
      const queryString = new URLSearchParams(params).toString();
      let fetcher;

      if (user.role === 'SELLER_ROLE') {
        fetcher = getbillOutsourcingBuyers;
      } else {
        fetcher = getbillOutsourcingBuyersAsAdmin;
      }
      const data = await fetcher({ queryString });
      setBillOutsourcingBuyers(data.billlabcases);
      setTotal(data.total);
    } catch (error) {
      setError(error.message || 'Failed to fetch demands');
      console.error('Failed to fetch demands:', error);
    } finally {
      setLoading(false);
    }
  }, [searchParams, user.role]);

  useEffect(() => {
    fetchBillOutsourcingBuyers();
  }, [fetchBillOutsourcingBuyers]);

  return {
    billOutsourcingBuyers,
    loading,
    error,
    total,
    fetchBillOutsourcingBuyers,
  };
};

// export const useFetchBillLabCasesAsBuyer = (searchParams) => {
//   const { user } = useUserStore();
//   const [billLabCases, setBillLabCases] = useState([]);
//   const [totalBillLabCases, setTotalBillLabCases] = useState(0);
//   const [loading, setLoading] = useState(true);
//   const [error, setError] = useState(null);

//   const fetchBillOutsourcingBuyers = useCallback(async () => {
//     setLoading(true);
//     setError(null); // Reset error state

//     try {
//       const params = Object.fromEntries(searchParams || []);
//       const queryString = new URLSearchParams(params).toString();
//       let fetcher;

//       fetcher = getbillOutsourcingBuyers;

//       const data = await fetcher({ queryString });
//       setBillLabCases(data.billlabcases);
//       setTotalBillLabCases(data.total);
//     } catch (error) {
//       setError(error.message || 'Failed to fetch demands');
//       console.error('Failed to fetch demands:', error);
//     } finally {
//       setLoading(false);
//     }
//   }, [searchParams, user.role]);

//   useEffect(() => {
//     fetchBillOutsourcingBuyers();
//   }, [fetchBillOutsourcingBuyers]);

//   return {
//     billLabCases,
//     loading,
//     error,
//     totalBillLabCases,
//     fetchBillOutsourcingBuyers,
//   };
// };
