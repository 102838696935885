import React, { useState, useEffect } from 'react';
import {
  protesisList,
  listColor,
  MaterialCorona,
  MaterialCarillas,
  MaterialDentadura,
  TipodeFerula,
  MateriaFedula,
  MaterialBarra,
  MaterialTipoBarra,
  RefuerzoList,
  MaterialBarraDen,
} from '@/constants/caseType';

import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from '@/components/ui/select';

import { Checkbox } from '@/components/ui/checkbox';
import { Label } from '@/components/ui/label';

const ImplantSelection = ({
  selectedImplant,
  setSelectedImplant,
  selectedImplantTemp,
}) => {
  const [selectedOption, setSelectedOption] = useState(
    selectedImplantTemp.selectedOption || '',
  );
  const [color, setColor] = useState(selectedImplantTemp.color || '');
  const [material, setMaterial] = useState(selectedImplantTemp.material || '');

  const [Mockup, setMockup] = useState(selectedImplantTemp.Mockup || false);
  const [onImplant, setOnImplant] = useState(
    selectedImplantTemp.onImplant || false,
  );
  const [withBar, setWithBar] = useState(selectedImplantTemp.withBar || false);
  const [refuerzoType, setRefuerzoType] = useState(
    selectedImplantTemp.refuerzoType || '',
  );
  const [ferulasType, setFerulasType] = useState(
    selectedImplantTemp.ferulasType || '',
  );
  const [materialBarra, setMaterialBarra] = useState(
    selectedImplantTemp.materialBarra || '',
  );
  const [selectTipoBarra, setSelectTipoBarra] = useState(
    selectedImplantTemp.selectTipoBarra || '',
  );
  const [error, setError] = useState('');

  useEffect(() => {
    if (selectedOption) {
      setError('');

      const updatedImplant = {
        selectedOption,
        color,
        material,
      };

      if (selectedOption === 'Corona') {
        updatedImplant.Mockup = Mockup;
        updatedImplant.onImplant = onImplant;
        updatedImplant.withBar = withBar;
        if (withBar) {
          updatedImplant.materialBarra = materialBarra;
          updatedImplant.selectTipoBarra = selectTipoBarra;
        }
      } else if (selectedOption === 'Dentadura') {
        updatedImplant.refuerzoType = refuerzoType;
        updatedImplant.selectTipoBarra = selectTipoBarra;
        updatedImplant.materialBarra = materialBarra;
      } else if (selectedOption === 'Férulas') {
        updatedImplant.ferulasType = ferulasType;
      }

      setSelectedImplant(updatedImplant);
    }
  }, [
    selectedOption,
    color,
    material,
    onImplant,
    Mockup,
    withBar,
    materialBarra,
    selectTipoBarra,
    refuerzoType,
    ferulasType,
    setSelectedImplant,
  ]);

  return (
    <div className="mx-auto">
      {selectedOption === 'Corona' && (
        <>
          <div className="flex flex-col md:flex-row gap-2 my-2">
            <div className="form-grid w-full md:w-1/2">
              <Label htmlFor="color">Color</Label>
              <Select
                id="color"
                value={color}
                onValueChange={setColor}
                required
              >
                <SelectTrigger className="w-full px-2  h-10 border border-[#BDCAFE] rounded-md ">
                  <SelectValue placeholder="Seleccione una opción" />
                </SelectTrigger>
                <SelectContent>
                  {listColor.map((color) => (
                    <SelectItem key={color} value={color}>
                      {color}
                    </SelectItem>
                  ))}
                </SelectContent>
              </Select>
            </div>
            <div className="form-grid w-full md:w-1/2">
              <Label htmlFor="material">Material</Label>
              <Select
                id="material"
                value={material}
                onValueChange={setMaterial}
                required
              >
                <SelectTrigger className="w-full px-2  h-10 border border-[#BDCAFE] rounded-md ">
                  <SelectValue placeholder="Seleccione una opción" />
                </SelectTrigger>
                <SelectContent>
                  {MaterialCorona.map((material) => (
                    <SelectItem key={material.value} value={material.value}>
                      {material.name}
                    </SelectItem>
                  ))}
                </SelectContent>
              </Select>
            </div>
          </div>

          <div className="flex flex-col md:flex-row gap-2">
            <div className="my-3 flex flex-row gap-2">
              <Label htmlFor="Mockup">Prueba estética</Label>
              <Checkbox
                id="Mockup"
                checked={Mockup}
                onCheckedChange={setMockup}
              />
            </div>
            <div className="my-3 flex flex-row gap-2">
              <Label htmlFor="onImplant">Sobre implante</Label>
              <Checkbox
                id="onImplant"
                checked={onImplant}
                onCheckedChange={setOnImplant}
              />
            </div>
            <div className="my-3 flex flex-row gap-2">
              <Label htmlFor="withBar">Con estructura</Label>
              <Checkbox
                id="withBar"
                checked={withBar}
                onCheckedChange={setWithBar}
              />
            </div>
          </div>

          {withBar && (
            <div className="flex flex-col md:flex-row gap-2 my-2">
              <div className="form-grid w-full md:w-1/2">
                <Label htmlFor="withBarMaterial">Material estructura</Label>
                <Select
                  id="withBarMaterial"
                  value={materialBarra}
                  onValueChange={setMaterialBarra}
                >
                  <SelectTrigger className="w-full px-2  h-10 border border-[#BDCAFE] rounded-md ">
                    <SelectValue placeholder="Seleccione una opción" />
                  </SelectTrigger>
                  <SelectContent>
                    {MaterialBarra.map((material) => (
                      <SelectItem key={material.value} value={material.value}>
                        {material.name}
                      </SelectItem>
                    ))}
                  </SelectContent>
                </Select>
              </div>
              <div className="form-grid w-full md:w-1/2">
                <Label htmlFor="withBarType">Tipo de estructura</Label>
                <Select
                  id="withBarType"
                  value={selectTipoBarra}
                  onValueChange={setSelectTipoBarra}
                >
                  <SelectTrigger className="w-full px-2  h-10 border border-[#BDCAFE] rounded-md ">
                    <SelectValue placeholder="Seleccione una opción" />
                  </SelectTrigger>
                  <SelectContent>
                    {MaterialTipoBarra.map((material) => (
                      <SelectItem key={material.value} value={material.value}>
                        {material.name}
                      </SelectItem>
                    ))}
                  </SelectContent>
                </Select>
              </div>
            </div>
          )}
        </>
      )}

      {selectedOption === 'Carillas' && (
        <div className="flex flex-col md:flex-row gap-2">
          <div className="form-grid w-full md:w-1/2">
            <Label htmlFor="color">Color</Label>
            <Select id="color" value={color} onValueChange={setColor} required>
              <SelectTrigger className="w-full px-2  h-10 border border-[#BDCAFE] rounded-md ">
                <SelectValue placeholder="Seleccione una opción" />
              </SelectTrigger>
              <SelectContent>
                {listColor.map((color) => (
                  <SelectItem key={color} value={color}>
                    {color}
                  </SelectItem>
                ))}
              </SelectContent>
            </Select>
          </div>
          <div className="form-grid w-full md:w-1/2">
            <Label htmlFor="Material">Material</Label>
            <Select
              id="material"
              value={material}
              onValueChange={setMaterial}
              required
            >
              <SelectTrigger className="w-full px-2  h-10 border border-[#BDCAFE] rounded-md ">
                <SelectValue placeholder="Seleccione una opción" />
              </SelectTrigger>
              <SelectContent>
                {MaterialCarillas.map((material) => (
                  <SelectItem key={material.value} value={material.value}>
                    {material.name}
                  </SelectItem>
                ))}
              </SelectContent>
            </Select>
          </div>
        </div>
      )}

      {selectedOption === 'Dentadura' && (
        <>
          <div className="flex flex-col md:flex-row gap-2 my-2">
            <div className="form-grid w-full md:w-1/2">
              <Label htmlFor="color">Color</Label>
              <Select
                id="color"
                value={color}
                onValueChange={setColor}
                required
              >
                <SelectTrigger className="w-full px-2  h-10 border border-[#BDCAFE] rounded-md ">
                  <SelectValue placeholder="Seleccione una opción" />
                </SelectTrigger>
                <SelectContent>
                  {listColor.map((color) => (
                    <SelectItem key={color} value={color}>
                      {color}
                    </SelectItem>
                  ))}
                </SelectContent>
              </Select>
            </div>
            <div className="form-grid w-full md:w-1/2">
              <Label htmlFor="material">Material</Label>
              <Select
                id="material"
                value={material}
                onValueChange={setMaterial}
                required
              >
                <SelectTrigger className="w-full px-2  h-10 border border-[#BDCAFE] rounded-md ">
                  <SelectValue placeholder="Seleccione una opción" />
                </SelectTrigger>
                <SelectContent>
                  {MaterialDentadura.map((material) => (
                    <SelectItem key={material.value} value={material.value}>
                      {material.name}
                    </SelectItem>
                  ))}
                </SelectContent>
              </Select>
            </div>
          </div>
          <div className="flex flex-col md:flex-row gap-2">
            <div className="form-grid w-full md:w-1/2">
              <Label htmlFor="refuerzoType">Tipo de refuerzo</Label>
              <Select
                id="refuerzoType"
                value={refuerzoType}
                onValueChange={setRefuerzoType}
                required
              >
                <SelectTrigger className="w-full px-2  h-10 border border-[#BDCAFE] rounded-md ">
                  <SelectValue placeholder="Selecciona un tipo de refuerzo" />
                </SelectTrigger>
                <SelectContent>
                  {RefuerzoList.map((refuerzo) => (
                    <SelectItem key={refuerzo.value} value={refuerzo.value}>
                      {refuerzo.name}
                    </SelectItem>
                  ))}
                </SelectContent>
              </Select>
            </div>
            <div className="form-grid w-full md:w-1/2">
              <Label htmlFor="materialBarra">Material barra</Label>
              <Select
                id="materialBarra"
                className="input-styles"
                value={materialBarra}
                onValueChange={setMaterialBarra}
                required
              >
                <SelectTrigger className="w-full px-2  h-10 border border-[#BDCAFE] rounded-md ">
                  <SelectValue placeholder="Seleccione una opción" />
                </SelectTrigger>
                <SelectContent>
                  {MaterialBarraDen.map((material) => (
                    <SelectItem key={material.value} value={material.value}>
                      {material.name}
                    </SelectItem>
                  ))}
                </SelectContent>
              </Select>
            </div>
          </div>
        </>
      )}

      {selectedOption === 'Férulas' && (
        <div className="flex flex-col md:flex-row gap-2">
          <div className="form-grid w-full md:w-1/2">
            <Label htmlFor="color">Tipo de Férula</Label>
            <Select
              id="ferulasType"
              value={ferulasType}
              onValueChange={setFerulasType}
            >
              <SelectTrigger className="w-full px-2  h-10 border border-[#BDCAFE] rounded-md ">
                <SelectValue placeholder="Seleccione una opción" />
              </SelectTrigger>
              <SelectContent>
                {TipodeFerula.map((ferula) => (
                  <SelectItem key={ferula.value} value={ferula.value}>
                    {ferula.name}
                  </SelectItem>
                ))}
              </SelectContent>
            </Select>
          </div>
          <div className="form-grid w-full md:w-1/2">
            <Label htmlFor="Material">Material</Label>
            <Select
              id="materialFerulas"
              className="input-styles"
              value={material}
              onValueChange={setMaterial}
            >
              <SelectTrigger className="w-full px-2  h-10 border border-[#BDCAFE] rounded-md ">
                <SelectValue placeholder="Seleccione una opción" />
              </SelectTrigger>
              <SelectContent>
                {MateriaFedula.map((material) => (
                  <SelectItem key={material} value={material.value}>
                    {material.name}
                  </SelectItem>
                ))}
              </SelectContent>
            </Select>
          </div>
        </div>
      )}

      {error && <p className="text-red-600">{error}</p>}
    </div>
  );
};

export default ImplantSelection;
