import React, { useEffect } from 'react';
import { Button } from '@/components/ui/button';
import { Card, CardContent } from '@/components/ui/card';
import { Label } from '@/components/ui/label';

const CookiesNotificationModal = ({ setAllowCookies, setAskCookies }) => {
  const handleClose = () => {
    setAllowCookies(false);
    setAskCookies(true);
  };

  const handleAllowCookies = () => {
    setAllowCookies(true);
    setAskCookies(true);
  };

  // Gestión del enfoque para accesibilidad
  useEffect(() => {
    const firstFocusableElement = document.querySelector(
      '.accept-cookies-button',
    );
    if (firstFocusableElement) {
      firstFocusableElement.focus();
    }
  }, []);

  return (
    <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50">
      <div className="w-full max-w-lg p-4">
        <Card className="relative shadow-lg rounded-lg overflow-hidden">
          <CardContent className="p-6 bg-white rounded-lg">
            <div className="text-left px-6">
              <Label
                variant="h4"
                className="text-xl font-bold text-gray-900 mb-4"
              >
                Notificación de Cookies
              </Label>
              <p className="text-base font-normal text-gray-600 mb-6">
                Usamos cookies para mejorar tu experiencia de navegación y
                ofrecer contenido personalizado. Al continuar utilizando este
                sitio web, aceptas nuestro uso de cookies de acuerdo con nuestra
                Política de Cookies. Puedes gestionar tus preferencias haciendo
                clic en "Configuración".
              </p>

              {/* Botones centrados */}
              <div className="flex flex-col sm:flex-row justify-center gap-4">
                <Button
                  size="lg"
                  className="w-full sm:w-auto bg-blue-600 hover:bg-blue-500 text-white font-semibold py-3 px-6 rounded-md accept-cookies-button"
                  onClick={handleAllowCookies}
                  aria-label="Aceptar cookies"
                >
                  Aceptar Cookies
                </Button>
                <Button
                  variant="outline"
                  size="lg"
                  className="w-full sm:w-auto border border-gray-300 text-gray-600 hover:bg-gray-100 py-3 px-6 rounded-md"
                  onClick={handleClose}
                  aria-label="Rechazar cookies"
                >
                  No Aceptar
                </Button>
              </div>

              {/* Enlace para más información */}
              <Label className="text-sm text-gray-500 mt-6 block">
                <a
                  href="doc/cookies"
                  className="underline text-blue-500 hover:text-blue-700"
                  target="_blank"
                >
                  Aprende más sobre nuestra política de cookies
                </a>
              </Label>
            </div>
          </CardContent>
        </Card>
      </div>
    </div>
  );
};

export default CookiesNotificationModal;
