import React, { useEffect, useState } from 'react';
import { Dialog, DialogContent, DialogHeader } from '@/components/ui/dialog';
import { Button } from '@/components/ui/button';
import ReviewCard from './ReviewCard';
import { getSeller } from '@/services/demand';
import { Avatar, AvatarFallback, AvatarImage } from '@/components/ui/avatar';
import { Label } from '@/components/ui/label';

const ReviewLabCard = ({ laboratoryId, isOpen, setIsOpen }) => {
  const [laboratory, setLaboratory] = useState({});
  const [loading, setLoading] = useState(true);

  const handleDialogClose = () => {
    setIsOpen(false);
  };

  const fetchLaboratory = async () => {
    try {
      setLoading(true);
      const { user } = await getSeller({ id: laboratoryId });
      setLaboratory(user);
    } catch (error) {
      console.error('Error fetching laboratory data:', error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (laboratoryId && isOpen) {
      fetchLaboratory();
    }
  }, [isOpen, laboratoryId]);

  if (!isOpen) return null;

  return (
    <Dialog open={isOpen} onOpenChange={handleDialogClose}>
      <DialogContent className="max-w-4xl    overflow-y-auto bg-[#f5f7fa]">
        {loading ? (
          <div className="w-full text-center text-lg">
            Cargando detalles del laboratorio...
          </div>
        ) : (
          <div className="flex flex-col lg:flex-row gap-6">
            {/* Laboratory Details Section */}
            <div className="w-full lg:w-1/3 bg-white rounded-lg p-6 border border-1">
              <div className="flex items-center gap-4 mb-4">
                <Avatar>
                  <AvatarImage
                    src={laboratory.avatar}
                    alt={`${laboratory.labName}`}
                  />
                  <AvatarFallback>Lab</AvatarFallback>
                </Avatar>
                <div className="flex flex-col ">
                  <Label className="font-bold text-lg mt-2 sm:mt-0 font-urbanist">
                    {laboratory.labName}
                  </Label>
                  <Label className="text-sm mt-2 sm:mt-0 font-inter">
                    Nº Colegiado: {laboratory.colNumber}
                  </Label>
                </div>
              </div>

              {/* About Section */}
              <div className="mt-4">
                <h6 className="font-semibold text-lg mb-2 font-urbanist">
                  Acerca de
                </h6>
                <p className="text-gray-700 font-inter">
                  {laboratory.description || 'Información no disponible.'}
                </p>
              </div>
            </div>

            {/* Reviews Section */}
            <div className="w-full lg:w-2/3">
              <div className="max-h-[400px] overflow-y-auto">
                {laboratory.reviews && laboratory.reviews.length > 0 ? (
                  laboratory.reviews.map((review) => (
                    <ReviewCard key={review._id} review={review} />
                  ))
                ) : (
                  <p className="text-gray-500">Sin Valoraciones disponibles</p>
                )}
              </div>
            </div>
          </div>
        )}
      </DialogContent>
    </Dialog>
  );
};

export default ReviewLabCard;
