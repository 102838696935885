import { useEffect, useState } from 'react';
import { getCommissionPlansOfUser } from '@/services/commissionplan';

export const useFetchCommissionsUser = (id, searchParams) => {
  const [commissionPlans, setCommissions] = useState([]);
  const [total, setTotal] = useState(0);
  const [loading, setLoading] = useState(true);

  const fetchCommissions = async () => {
    setLoading(true);
    try {
      const params = Object.fromEntries([...searchParams]);
      const queryString = new URLSearchParams(params).toString();
      const { commissionPlans, total } = await getCommissionPlansOfUser({
        userId: id,
        queryString,
      });
      setCommissions(commissionPlans);
      setTotal(total);
    } catch (error) {
      console.error('Error fetching commissions:', error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchCommissions();
  }, [id, searchParams.toString()]);

  return { commissionPlans, total, loading, fetchCommissions };
};
