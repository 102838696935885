import React, { useState, useEffect, useRef } from 'react';
import { Link, useParams, useSearchParams } from 'react-router-dom';
import { Button } from '@/components/ui/button';
import ProgressStepper from '@/components/Cards/ProgressStepper';
import CaseDetailCard from '@/components/Cards/CaseDetailCard';
import useUserStore from '@/store/userStore';
import { useFetchDemand, useUpdateDemand } from '@/hooks/useDemand';
import io from 'socket.io-client';
import { toast } from 'react-toastify';
import RevisionModal from '@/components/Modals/RevisionModal';
import ReviewModal from '@/components/Modals/ReviewModal';
import { getPaymentIntent, createPayment } from '@/services/payment.service';
import { Progress } from '@/components/ui/progress';

const CasePage = () => {
  const { user } = useUserStore();
  const { caseId } = useParams();
  const [searchParams] = useSearchParams();
  const sessionId = searchParams.get('session_id');
  const offerId = searchParams.get('offerId');

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isReviewModalOpen, setIsReviewModalOpen] = useState(false);
  const socket = useRef(null);
  const { loading: loadingButton, updateDemand } = useUpdateDemand();
  const {
    demand,
    loadingDemand: loading,
    fetchDemand,
    error: errorDemand,
  } = useFetchDemand(caseId);

  useEffect(() => {
    const API_BASE_URL = import.meta.env.VITE_API_BASE_URL;
    socket.current = io(API_BASE_URL);
    socket.current.emit('setup', { user });
    socket.current.emit('joinRoom', caseId);

    const handleReceiveOffer = (offer) => {
      fetchDemand();
    };
    const handleReceiveOrder = (order) => {
      fetchDemand();
    };
    const handleReceiveUpdateOrder = (order) => {
      fetchDemand();
    };
    const handleReceiveUpdateCase = (demand) => {
      fetchDemand();
    };
    const handleReceiveAcceptedBillLabCaseSeller = (billLabCase) => {
      fetchDemand();
    };
    const handleReceiveAcceptedBillLabCaseBuller = (billLabCase) => {
      fetchDemand();
    };
    const handleReceiveRejectedBillLabCase = (billLabCase) => {
      fetchDemand();
    };

    socket.current.on('receiveOffer', handleReceiveOffer);
    socket.current.on('receiveOrder', handleReceiveOrder);
    socket.current.on('receiveUpdateOrder', handleReceiveUpdateOrder);
    socket.current.on('receiveUpdateCase', handleReceiveUpdateCase);
    socket.current.on(
      'receiveAcceptedBillLabCaseSeller',
      handleReceiveAcceptedBillLabCaseSeller,
    );
    socket.current.on(
      'receiveAcceptedBillLabCaseBuller',
      handleReceiveAcceptedBillLabCaseBuller,
    );
    socket.current.on(
      'receiveRejectedBillLabCase',
      handleReceiveRejectedBillLabCase,
    );

    return () => {
      socket.current.emit('leaveRoom', caseId);
      socket.current.off('receiveOffer', handleReceiveOffer);
      socket.current.off('receiveOrder', handleReceiveOrder);
      socket.current.off('receiveUpdateOrder', handleReceiveUpdateOrder);
      socket.current.off('receiveUpdateCase', handleReceiveUpdateCase);
      socket.current.off(
        'receiveAcceptedBillLabCaseSeller',
        handleReceiveAcceptedBillLabCaseSeller,
      );
      socket.current.off(
        'receiveAcceptedBillLabCaseBuller',
        handleReceiveAcceptedBillLabCaseBuller,
      );
      socket.current.off(
        'receiveRejectedBillLabCase',
        handleReceiveRejectedBillLabCase,
      );
      socket.current.disconnect();
    };
  }, [caseId]);

  const handleStatusChange = async (newStatus) => {
    try {
      await updateDemand(demand._id, { status: newStatus });

      const statusMessages = {
        onfabrication: 'Orden en fabricación',
        sended: 'Orden enviada',
        completed: 'Orden lista',
        accepted: 'Trabajo Completado',
        inReview: 'En revisión',
        design: 'En diseño',
      };

      if (statusMessages[newStatus]) {
        socket.current.emit('updateCase', {
          demand,
          status: statusMessages[newStatus],
          user,
        });
      }
    } catch (error) {
      console.error('Error updating order status:', error);
      toast.error('Error al actualizar el estado del pedido');
    } finally {
      fetchDemand();
    }
  };

  const renderActionButton = () => {
    if (!demand) {
      return null;
    }
    const actions = {
      SELLER_ROLE: {
        design: ['onfabrication', 'Marcar en fabricación'],
        onfabrication: ['sended', 'Enviar'],
      },
      DENTIST_ROLE: {
        sended: ['accepted', 'Trabajo Completado'],
        // accepted: ['sended', 'Reabrir caso'],
      },
    };

    const [nextStatus, label] = actions[user.role]?.[demand.status] || [];

    if (nextStatus && label) {
      return (
        <button
          type="button"
          className="bg-blue-500 w-full md:w-80 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded ml-auto flex items-center justify-center font-inter"
          onClick={() => handleStatusChange(nextStatus)}
          disabled={loadingButton}
        >
          {loadingButton ? (
            <>
              <svg
                className="animate-spin h-5 w-5 mr-2 text-white"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
              >
                <circle
                  className="opacity-25"
                  cx="12"
                  cy="12"
                  r="10"
                  stroke="currentColor"
                  strokeWidth="4"
                ></circle>
              </svg>
              Cargando...
            </>
          ) : (
            label
          )}
        </button>
      );
    }

    return null;
  };

  const handleStatusCancel = async () => {
    const confirmed = window.confirm(
      '¿Estás seguro de que quieres cerrar este caso?',
    );
    if (confirmed) {
      try {
        await handleStatusChange('canceled');
      } catch (error) {
        console.error('Error updating order status:', error);
      }
    }
  };

  useEffect(() => {
    const handlePaymentSuccess = async () => {
      try {
        if (sessionId) {
          // Obtén los detalles del pago desde Stripe o tu backend
          const data = {
            sessionId,
          };
          const paymentIntent = await getPaymentIntent(data);

          if (paymentIntent) {
            const paymentData = {
              sessionId,
              demandId: caseId,
              offerId,
            };
            // Envía los datos de pago a tu backend para registrarlos
            await createPayment(paymentData);
            fetchDemand();
            toast.success('Pago realizado con éxito');
          }
        }
      } catch (error) {
        console.error('Error updating order status:', error);
      }
    };

    handlePaymentSuccess();
  }, [sessionId]);

  if (loading && !demand) {
    return (
      <div className="flex justify-center items-center min-h-screen font-inter">
        <Button variant="text">
          <Progress value={33} />
          Cargando...
        </Button>
      </div>
    );
  }

  if (errorDemand && !loading) {
    return <div className="text-center font-inter">Caso no encontrado.</div>;
  }

  return (
    <div className="casePage">
      <RevisionModal
        caseId={caseId}
        isOpen={isModalOpen}
        setIsOpen={setIsModalOpen}
        handleStatusChange={handleStatusChange}
      />
      <ReviewModal
        caseId={caseId}
        isOpen={isReviewModalOpen}
        setIsOpen={setIsReviewModalOpen}
        fetchDemand={fetchDemand}
      />
      <div className="flex flex-col gap-3 ">
        <ProgressStepper />
        <CaseDetailCard />

        <div className="flex flex-col md:flex-row w-full justify-end md:space-x-1 space-y-2 md:space-y-0 font-inter">
          {user.role === 'DENTIST_ROLE' && demand.status === 'sended' && (
            <button
              type="button"
              className="btn-neutral w-full md:w-80 text-white font-bold py-2 px-4 rounded flex items-center justify-center"
              onClick={() => setIsModalOpen(true)}
            >
              Solicitar revisión
            </button>
          )}
          {renderActionButton()}
          {user.role === 'DENTIST_ROLE' && demand.status === 'accepted' && (
            <button
              type="button"
              className="btn-neutral w-full md:w-80 text-white font-bold py-2 px-4 rounded flex items-center justify-center"
              onClick={() => handleStatusChange('sended')}
            >
              Reabrir caso
            </button>
          )}
          {user.role === 'DENTIST_ROLE' &&
            demand.status === 'accepted' &&
            !demand.review && (
              <button
                type="button"
                className="bg-blue-500 w-full md:w-80 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded flex items-center justify-center"
                onClick={() => setIsReviewModalOpen(true)}
              >
                Valora tu experiencia
              </button>
            )}
          {user.role === 'SELLER_ROLE' && demand.status === 'inReview' && (
            // <button
            //   type="button"
            //   className="bg-red-500 w-full md:w-80 hover:bg-red-700 text-white font-bold py-2 px-4 rounded flex items-center justify-center"
            //   onClick={() => setIsModalOpen(true)}
            // >
            //   Responder Revisión
            // </button>
            <Link
              to={`/cases/revisions/${demand._id}`}
              className="bg-blue-500 w-full md:w-80 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded flex items-center justify-center"
            >
              Ver revisión
            </Link>
          )}
          {user.role === 'SELLER_ROLE' &&
            demand.status === 'accepted' &&
            !demand.billLabCase && (
              <Link
                to={`/cases/createBill/${demand._id}`}
                className="bg-blue-500 w-full md:w-80 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded flex items-center justify-center"
              >
                Adjuntar factura
              </Link>
            )}
        </div>
      </div>
    </div>
  );
};

export default CasePage;
