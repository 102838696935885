import axios from 'axios';

const API_BASE_URL = import.meta.env.VITE_API_BASE_URL;

const axiosInstance = axios.create({
  baseURL: `${API_BASE_URL}/api`,
  headers: {
    'Content-Type': 'application/json',
  },
  withCredentials: true,
});

axiosInstance.interceptors.request.use(
  (config) => {
    const token = localStorage.getItem('token');
    if (token) {
      config.headers['Authorization'] = `Bearer ${token}`;
    }
    return config;
  },
  (error) => Promise.reject(error),
);

axiosInstance.interceptors.response.use(
  (response) => response,
  (error) => {
    if (error.response && error.response.status === 401) {
      console.error('Unauthorized, redirecting to login');
      localStorage.removeItem('token');
    }
    return Promise.reject(error);
  },
);

export const createRevision = async (revisionData) => {
  try {
    const response = await axiosInstance.post('/revisions', revisionData);
    const data = response.data;
    return data;
  } catch (error) {
    console.error('Error creating order:', error);
    throw error;
  }
};

export const getRevisionsByDemand = async (demandId) => {
  try {
    const response = await axiosInstance.get(`/revisions/demand/${demandId}`);
    const data = response.data;
    return data;
  } catch (error) {
    console.error('Error fetching revisions:', error);
    throw error;
  }
};

export const createRevisionResponse = async (data) => {
  try {
    const response = await axiosInstance.post(
      '/revisions/createRevisionResponse',
      data,
    );
    const responseData = response.data;
    return responseData;
  } catch (error) {
    console.error('Error creating revision response:', error);
    throw error;
  }
};

//Outsorsing

export const createRevisionOutsorsing = async (revisionData) => {
  try {
    const response = await axiosInstance.post(
      '/revisions/outsourcing',
      revisionData,
    );
    const data = response.data;
    return data;
  } catch (error) {
    console.error('Error creating order:', error);
    throw error;
  }
};

export const getRevisionsByOutsorsing = async (outsourcinId) => {
  try {
    const response = await axiosInstance.get(
      `/revisions/outsourcing/${outsourcinId}`,
    );
    const data = response.data;
    return data;
  } catch (error) {
    console.error('Error fetching revisions:', error);
    throw error;
  }
};

export const createRevisionOutsorsingResponse = async (data) => {
  try {
    const response = await axiosInstance.post(
      '/revisions/outsourcing/createRevisionResponse',
      data,
    );
    const responseData = response.data;
    return responseData;
  } catch (error) {
    console.error('Error creating revision response:', error);
    throw error;
  }
};
