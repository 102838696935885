import React from 'react';
import RatingCard from '@/components/ui/RatingCard';
import { Avatar, AvatarFallback, AvatarImage } from '@/components/ui/avatar';
import { handlecaseType } from '@/utils/handlecaseType';
import { useUserStore } from '@/store/userStore';

const ReviewCard = ({ review, handleModal }) => {
  const { user } = useUserStore();
  const handleDays = (date) => {
    const today = new Date();
    const reviewDate = new Date(date);
    const diffTime = Math.abs(today - reviewDate);
    const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
    return diffDays === 1 ? '1 day' : `${diffDays} days`;
  };

  return (
    <div className="flex flex-col gap-3 p-4 border bg-white border-gray-200 rounded-md shadow-sm transition-shadow hover:shadow-lg">
      <div className="flex justify-between items-center">
        <div className="flex items-center gap-2">
          <strong> {handlecaseType(review?.demand)}</strong>
          <RatingCard rating={Math.ceil(review.rating)} />
        </div>
        <div className="text-sm text-gray-500">
          {handleDays(review.createdAt)} ago
        </div>
      </div>
      <p className="text-gray-800">
        {review.description || 'No comments provided'}
      </p>

      {!review.isPrivate && (
        <div className="flex items-center gap-2">
          <Avatar>
            <AvatarImage
              src={review.user?.avatar}
              alt={`${review.user?.name}`}
            />
            <AvatarFallback>CN</AvatarFallback>
          </Avatar>

          <div className="text-gray-700">
            <span className="font-semibold font-urbanist">
              {review.user?.name} {review.user?.lastname}
            </span>
          </div>
        </div>
      )}
      {user?.role === 'ADMIN_ROLE' && (
        <button
          onClick={() => handleModal(review._id)}
          className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded"
        >
          Editar
        </button>
      )}
    </div>
  );
};

export default ReviewCard;
