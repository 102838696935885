import React, { useState, useEffect, useCallback, useRef } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { Button } from '@/components/ui/button';
import { toast } from 'react-toastify';
import { FaDownload } from 'react-icons/fa';
import { Card, CardContent, CardHeader, CardTitle } from '@/components/ui/card';

import {
  getBillingDataAsAdmin,
  rejectBillOutsourcingBuyer,
  acceptBillOutsourcingBuyer,
} from '@/services/billOutsourcingBuyer';
import useUserStore from '@/store/userStore';
import io from 'socket.io-client';

const BillOutsorcingBuyersAdmin = () => {
  const { user } = useUserStore();
  const { caseId } = useParams();
  const socket = useRef(null);
  const navigate = useNavigate();
  const [billingData, setBillingData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [showForm, setShowForm] = useState(false);
  const [showFormBill, setShowFormBill] = useState(false);
  const [description, setDescription] = useState('');

  const fetchData = useCallback(async () => {
    try {
      setLoading(true);
      const { billData } = await getBillingDataAsAdmin(caseId);
      setBillingData(billData);
    } catch (error) {
      toast.error('Error al cargar los datos de facturación');
    } finally {
      setLoading(false);
    }
  }, [caseId]);

  useEffect(() => {
    const API_BASE_URL = import.meta.env.VITE_API_BASE_URL;
    socket.current = io(API_BASE_URL);
    socket.current.emit('setup', { user });
    socket.current.emit('joinRoom', caseId);
    return () => {
      socket.current.emit('leaveRoom', caseId);
      socket.current.disconnect();
    };
  }, [caseId]);

  const handleAccept = async () => {
    try {
      const billLabData = {};
      const { billOutsourcingBuyerUpdated } = await acceptBillOutsourcingBuyer(
        billingData.outsourcing.billOutsourcingBuyer._id,
        billLabData,
      );
      if (billOutsourcingBuyerUpdated) {
        toast.success('Caso actualizado correctamente');
        socket.current.emit('acceptedBillLabCase', {
          billLabCase: billOutsourcingBuyerUpdated,
        });
        navigate('/admin/billLabCases');
      }
    } catch (error) {
      toast.error('Error al aceptar el caso');
    }
  };

  const handleReject = async () => {
    if (!description) return toast.error('Por favor, ingrese una descripción');

    try {
      const { billoutsourcingbuyerUpdated } = await rejectBillOutsourcingBuyer(
        billingData.outsourcing.billOutsourcingBuyer._id,
        { status: 'rejected', description },
      );
      if (billoutsourcingbuyerUpdated) {
        toast.success('Caso actualizado correctamente');
        socket.current.emit('rejectedBillLabCase', {
          billLabCase: billoutsourcingbuyerUpdated,
        });
        setShowForm(false);
        navigate('/admin/billLabCases');
      }
    } catch (error) {
      toast.error('Error al rechazar el caso');
    }
  };

  const getFileName = (url) => url.substring(url.lastIndexOf('/') + 1);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  if (loading) {
    return (
      <div className="flex justify-center items-center min-h-screen font-inter">
        <div className="animate-spin h-10 w-10 border-4 border-blue-500 border-t-transparent rounded-full"></div>
      </div>
    );
  }

  if (!billingData) {
    return (
      <div className="flex justify-center items-center min-h-screen font-inter">
        <h1 className="text-2xl font-bold">Caso no encontrado</h1>
      </div>
    );
  }

  return (
    <div className="casePage">
      <div className="flex flex-col gap-3">
        <Card className="py-4 px-2 gap-8 rounded-lg bg-white ">
          <CardHeader>
            <CardTitle>
              Factura del caso {billingData.outsourcing.caseName}
            </CardTitle>
          </CardHeader>
          <CardContent className="w-full flex flex-col md:flex-row">
            <div className="w-full flex flex-col gap-2 items-center">
              <BillInfo billingData={billingData} />
              {billingData.outsourcing.billOutsourcingBuyer.file.url && (
                <FilePreview
                  fileData={billingData.outsourcing.billOutsourcingBuyer.file}
                  getFileName={getFileName}
                />
              )}
            </div>
          </CardContent>
        </Card>

        {billingData.outsourcing.billOutsourcingBuyer.status === 'created' &&
          !showForm &&
          !showFormBill && (
            <ActionButtons
              setShowForm={setShowForm}
              setShowFormBill={setShowFormBill}
              handleAccept={handleAccept}
            />
          )}
        {showForm && (
          <RejectForm
            handleReject={handleReject}
            description={description}
            setDescription={setDescription}
          />
        )}
      </div>
    </div>
  );
};

const BillInfo = ({ billingData }) => (
  <div className="bg-gray-100 p-4 rounded-lg shadow-md mb-6 w-full ">
    <h2 className="text-lg font-semibold mb-2">Información de la Factura</h2>
    <div className="flex flex-row  justify-between">
      <div>
        <p>
          <strong>Nombre:</strong> {billingData.outsourcing?.user?.name}{' '}
          {billingData.outsourcing?.user?.lastname}
        </p>
        <p>
          <strong>Razón Social:</strong>{' '}
          {billingData.outsourcing?.user?.razonSocial}
        </p>
        <p>
          <strong>NIF:</strong> {billingData.outsourcing?.user?.nif}
        </p>
        <p>
          <strong>Teléfono:</strong> {billingData.outsourcing?.user?.phone}
        </p>
        <p>
          <strong>Total a facturar:</strong> €{' '}
          {billingData.outsourcing.selectedLaboratorios[0].offer.total}
        </p>
      </div>
      <div>
        <h4 className="font-semibold">Dirección</h4>
        <p>{billingData.outsourcing?.clinic?.clinicstreet}</p>
        <p>{billingData.outsourcing?.clinic?.postalCode}</p>
        <p>{billingData.outsourcing?.clinic?.province}</p>
        <p>{billingData.outsourcing?.clinic?.city}</p>
        <p>{billingData.outsourcing?.clinic?.country}</p>
      </div>
    </div>
  </div>
);

const FilePreview = ({ fileData, getFileName }) => (
  <div className="md:px-10 md:w-1/2">
    {fileData.mimetype.startsWith('image/') && (
      <>
        <img
          src={fileData.url}
          alt="Attachment"
          className="md:px-10 mb-2 cursor-pointer"
        />
        <a
          href={fileData.url}
          target="_blank"
          rel="noopener noreferrer"
          className="text-blue-500 mt-2 inline-block"
          download
        >
          <FaDownload className="inline mr-2" /> Descargar factura
        </a>
      </>
    )}
    {fileData.mimetype.startsWith('application/') && (
      <>
        <iframe
          src={fileData.url}
          title="Document Viewer"
          className="w-full h-auto mb-2"
        />
        <a
          href={fileData.url}
          download={getFileName(fileData.url)}
          className="text-blue-500"
        >
          <FaDownload className="inline mr-2" /> Descargar documento
        </a>
      </>
    )}
  </div>
);

const ActionButtons = ({ setShowForm, setShowFormBill, handleAccept }) => (
  <div className="flex flex-col md:flex-row gap-2 justify-end">
    <Button
      type="button"
      onClick={handleAccept}
      className="bg-blue-500 w-full md:w-80 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded flex items-center justify-center"
    >
      Aprobar
    </Button>
    <Button
      type="button"
      onClick={() => setShowForm(true)}
      className="bg-red-500 w-full md:w-80 hover:bg-red-700 text-white font-bold py-2 px-4 rounded flex items-center justify-center"
    >
      Rechazar
    </Button>
  </div>
);

const RejectForm = ({ handleReject, description, setDescription }) => (
  <form
    onSubmit={(e) => {
      e.preventDefault();
      handleReject();
    }}
    className="flex flex-col gap-2"
  >
    <label htmlFor="description" className="font-semibold">
      Descripción:
    </label>
    <textarea
      id="description"
      value={description}
      onChange={(e) => setDescription(e.target.value)}
      className="border border-gray-300 rounded p-2"
      rows="3"
      required
    />
    <Button type="submit" className="bg-red-500 hover:bg-red-700 text-white">
      Enviar Rechazo
    </Button>
  </form>
);

export default BillOutsorcingBuyersAdmin;
