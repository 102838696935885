import React, { useState, useEffect } from 'react';
import { useSearchParams } from 'react-router-dom';
import { Link, useNavigate } from 'react-router-dom';
import { register } from '@/services/auth';
import { useUserStore } from '@/store/userStore';
import { toast } from 'react-toastify';
import LogoOklusion from '@/assets/Login.png';
import { Button } from '@/components/ui/button';
import { Input } from '@/components/ui/input';
import { Label } from '@/components/ui/label';

const RegisterPage = () => {
  const { user } = useUserStore();
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [role, setRole] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [error, setError] = useState('');
  const [searchParams, setSearchParams] = useSearchParams();
  const [referralCode, setReferralCode] = useState('');
  const navigate = useNavigate();

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (password.length < 8) {
      setError('La clave tiene que tener almenos 8 caracteres');
      return;
    }
    if (password !== confirmPassword) {
      setError('Passwords do not match');
      return;
    }

    if (!role) {
      setError('Please select a role');
      return;
    }

    //randon number between 1 and 10

    try {
      const response = await register({
        email,
        password,
        role,
        referralCode,
      });
      if (response.user) {
        setError('');
        setEmail('');
        setPassword('');
        setConfirmPassword('');
        setRole('');
        toast.success('Registro Exitoso');
        navigate('/');
      }
    } catch (err) {
      toast.error(
        err.response.data.msg || 'Registration failed. Please try again.',
      );
      setError(
        err.response.data.msg || 'Registration failed. Please try again.',
      );
    }
  };

  const selectRole = (selectedRole) => {
    setRole((prevRole) => (prevRole === selectedRole ? '' : selectedRole));
  };

  useEffect(() => {
    const referral = searchParams.get('referralCode');

    if (referral) {
      setReferralCode(referral);
    }

    if (user) {
      navigate('/dashboard'); // Redirigir si el usuario ya está logueado
    }
  }, [user, navigate]);

  return (
    <div className="flex items-center justify-center min-h-screen bg-gray-100 p-8">
      <div className="flex w-full  lg:w-3/4 max-w-6xl shadow-lg rounded-lg overflow-hidden">
        <div
          className="hidden lg:flex w-1/2 items-center justify-center min-h-full "
          style={{
            backgroundImage: `url(${LogoOklusion})`,
            backgroundPosition: 'center',
            backgroundRepeat: 'no-repeat',
            backgroundSize: 'cover',
          }}
        >
          {/* <Label className="text-white text-center flex justify-center text-4xl font-bold  max-w-14">
            Bienvenido
          </Label> */}
        </div>
        <div className="w-full lg:w-1/2 bg-white p-8 rounded-lg  items-center justify-center gap-2">
          <div className="max-w-sm mx-auto">
            <h2 className="text-2xl font-bold text-center mb-6">
              Bienvenido a Oklusion
            </h2>
            <form className="space-y-1" onSubmit={handleSubmit}>
              <div className="form-grid">
                <Label htmlFor="email">Email</Label>
                <Input
                  type="email"
                  id="email"
                  name="email"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  className="input-styles"
                  placeholder="you@example.com"
                  required
                />
              </div>
              <div className="form-grid">
                <Label htmlFor="password">Contraseña</Label>
                <Input
                  type="password"
                  id="password"
                  name="password"
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                  className="input-styles"
                  placeholder="••••••••"
                  required
                />
              </div>
              <div className="form-grid">
                <Label htmlFor="confirmPassword">Confirmar contraseña</Label>
                <Input
                  type="password"
                  id="confirmPassword"
                  name="confirmPassword"
                  value={confirmPassword}
                  onChange={(e) => setConfirmPassword(e.target.value)}
                  className="input-styles"
                  placeholder="••••••••"
                  required
                />
              </div>

              <div className="my-4">
                <Label htmlFor="category">Seleccione su categoria</Label>
                <div className="flex gap-4 mt-2 flex-row justify-between">
                  {/* Checkbox para Clinica */}

                  <div className="flex items-center space-x-2 border border-blue-500 py-2 pr-10 pl-2 rounded-md">
                    <input
                      id="seller"
                      type="checkbox"
                      onChange={() => selectRole('SELLER_ROLE')}
                      checked={role === 'SELLER_ROLE'}
                      className="w-4 h-4 text-blue-600 bg-blue-500 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                      value="SELLER_ROLE"
                    />
                    <label
                      htmlFor="seller"
                      className="text-sm font-medium leading-none peer-disabled:cursor-not-allowed peer-disabled:opacity-70"
                    >
                      Laboratorio
                    </label>
                  </div>

                  {/* Checkbox para Dentista */}
                  <div className="flex items-center space-x-2 border border-blue-500 py-2 pr-10 pl-2 rounded-md">
                    <input
                      id="dentist"
                      type="checkbox"
                      onChange={() => selectRole('DENTIST_ROLE')}
                      checked={role === 'DENTIST_ROLE'}
                      className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                      value="DENTIST_ROLE"
                    />
                    <label
                      htmlFor="dentist"
                      className="text-sm font-medium leading-none peer-disabled:cursor-not-allowed peer-disabled:opacity-70"
                    >
                      Dentista
                    </label>
                  </div>
                </div>
              </div>

              <div className="flex items-center my-2">
                <Input
                  type="checkbox"
                  id="terms"
                  name="terms"
                  className="h-4 w-4 text-blue-600 focus:ring-blue-500 border-gray-300 rounded"
                  required
                />
                <Label
                  htmlFor="terms"
                  className="ml-2 block text-sm text-gray-900"
                >
                  Acepto las{' '}
                  <Link
                    to="doc/condiciones-generales"
                    className="text-blue-600 hover:underline"
                    target="_blank"
                  >
                    Condiciones de servicio
                  </Link>{' '}
                  y{' '}
                  <Link
                    to="/politicas"
                    className="text-blue-600 hover:underline"
                    target="_blank"
                  >
                    Política de privacidad
                  </Link>
                </Label>
              </div>

              <Button
                type="submit"
                className="w-full py-4 px-4 bg-black text-white rounded-lg hover:bg-gray-800 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-opacity-50"
              >
                Registrarme
              </Button>
            </form>
            {error && <p className="text-red-500 text-sm mt-2">{error}</p>}
            <div className="mt-6 flex flex-col gap-4">
              <div>
                Ya estas registrado?{' '}
                <Link
                  to="/login"
                  className="text-black hover:text-blue-500 underline"
                >
                  Inicia session
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default RegisterPage;
