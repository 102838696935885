import { useSearchParams } from 'react-router-dom';
import TablebillLabs from '@/components/Tables/TablebillLabs';
import { useFetchBillLabs } from '@/hooks/useBill';

const BillLabsAdmin = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const { billLabs, loading, totalBillLabs } = useFetchBillLabs(searchParams);

  if (loading) return <p>Loading...</p>;

  return (
    <div className="">
      <section>
        <TablebillLabs
          billLabs={billLabs}
          total={totalBillLabs}
          setSearchParams={setSearchParams}
          searchParams={searchParams}
        />
      </section>
    </div>
  );
};

export default BillLabsAdmin;
