import React, { useState } from 'react';
import { Label } from '@/components/ui/label';

const ProductSelection = ({ selectedProduct, setSelectedProduct }) => {
  return (
    <div className=" w-full  my-4">
      <Label htmlFor="email">{selectedProduct}</Label>
    </div>
  );
};

export default ProductSelection;
