import React from 'react';

const CondicionesVenta = () => {
  return (
    <div className="px-12 py-8 sm:px-4 text-justify bg-white text-gray-900 leading-relaxed max-w-4xl mx-auto">
      <h1 className="text-3xl font-bold mb-6 text-center">
        Condiciones de Venta o Contratación
      </h1>

      <h2 className="text-2xl font-semibold mt-8 mb-4">1. Introducción</h2>
      <p className="mb-6 indent-8">
        Las presentes condiciones de venta o contratación regulan la compra de
        productos y servicios a través de la plataforma Oklusion (en adelante,
        “la Plataforma”), gestionada por Oklusion S.L. Al realizar una compra en
        la Plataforma, aceptas estas condiciones de venta.
      </p>

      <h2 className="text-2xl font-semibold mt-8 mb-4">
        2. Información General
      </h2>
      <ul className="list-disc ml-8 mb-6">
        <li>
          <strong>Titular:</strong> Oklusion S.L.
        </li>
        <li>
          <strong>NIF:</strong> B12345678
        </li>
        <li>
          <strong>Domicilio Social:</strong> Calle Ejemplo, 123, 08001
          Barcelona, España
        </li>
        <li>
          <strong>Correo Electrónico:</strong> info@oklusion.com
        </li>
        <li>
          <strong>Teléfono:</strong> +34 123 456 789
        </li>
      </ul>

      <h2 className="text-2xl font-semibold mt-8 mb-4">3. Proceso de Compra</h2>
      <p className="mb-6 indent-8">
        Para realizar una compra en la Plataforma, el usuario debe seguir los
        siguientes pasos:
      </p>
      <ul className="list-decimal ml-8 mb-6">
        <li>
          Seleccionar el producto o servicio deseado y añadirlo al carrito de
          compra.
        </li>
        <li>Revisar el carrito de compra y proceder al pago.</li>
        <li>Introducir los datos de pago y confirmar la compra.</li>
        <li>Recibir una confirmación de la compra por correo electrónico.</li>
      </ul>

      <h2 className="text-2xl font-semibold mt-8 mb-4">4. Precios y Pagos</h2>
      <ul className="list-disc ml-8 mb-6">
        <li>
          <strong>Precios:</strong> Los precios de los productos y servicios se
          indican en euros (€) e incluyen los impuestos aplicables. Los precios
          pueden estar sujetos a cambios sin previo aviso.
        </li>
        <li>
          <strong>Métodos de Pago:</strong> Los pagos se gestionan a través de
          Stripe y se aceptan las principales tarjetas de crédito y débito.
        </li>
        <li>
          <strong>Comisiones:</strong> La Plataforma cobra una comisión por cada
          venta realizada, la cual se detalla en el momento de la compra.
        </li>
      </ul>

      <h2 className="text-2xl font-semibold mt-8 mb-4">5. Envíos</h2>
      <ul className="list-disc ml-8 mb-6">
        <li>
          <strong>Plataforma de Envíos:</strong> Los envíos se coordinan
          mediante Sendcloud.
        </li>
        <li>
          <strong>Plazos de Entrega:</strong> Los plazos de entrega varían según
          el destino y el tipo de envío seleccionado. La información específica
          se proporciona durante el proceso de compra.
        </li>
        <li>
          <strong>Costes de Envío:</strong> Los costes de envío se calculan en
          función del peso, tamaño y destino del paquete y se añaden al precio
          total de la compra.
        </li>
      </ul>

      <h2 className="text-2xl font-semibold mt-8 mb-4">
        6. Devoluciones y Reembolsos
      </h2>
      <ul className="list-disc ml-8 mb-6">
        <li>
          <strong>Política de Devoluciones:</strong> Los usuarios tienen derecho
          a devolver los productos en un plazo de 14 días naturales desde la
          recepción del pedido, siempre que los productos estén en perfectas
          condiciones y en su embalaje original.
        </li>
        <li>
          <strong>Proceso de Devolución:</strong> Para gestionar una devolución,
          el usuario debe contactar con el servicio de atención al cliente en
          info@oklusion.com y seguir las instrucciones proporcionadas.
        </li>
        <li>
          <strong>Reembolsos:</strong> Una vez recibidos y verificados los
          productos devueltos, se procederá al reembolso del importe
          correspondiente a través del mismo método de pago utilizado en la
          compra.
        </li>
      </ul>

      <h2 className="text-2xl font-semibold mt-8 mb-4">
        7. Protección de Datos
      </h2>
      <p className="mb-6 indent-8">
        Oklusion S.L. cumple con la normativa vigente en materia de protección
        de datos personales. Para más información, consulta nuestra Política de
        Privacidad.
      </p>

      <h2 className="text-2xl font-semibold mt-8 mb-4">
        8. Propiedad Intelectual
      </h2>
      <p className="mb-6 indent-8">
        Todos los contenidos de la Plataforma, incluyendo textos, gráficos,
        imágenes, diseño y software, son propiedad de Oklusion S.L. o de sus
        licenciantes, y están protegidos por las leyes de propiedad intelectual
        e industrial. Queda prohibida la reproducción, distribución y
        comunicación pública, incluida su modalidad de puesta a disposición, de
        la totalidad o parte de los contenidos de esta web, con fines
        comerciales, en cualquier soporte y por cualquier medio técnico, sin la
        autorización de Oklusion S.L.
      </p>

      <h2 className="text-2xl font-semibold mt-8 mb-4">
        9. Limitación de Responsabilidad
      </h2>
      <p className="mb-6 indent-8">
        Oklusion S.L. no se hace responsable de los daños y perjuicios de
        cualquier naturaleza que pudieran derivarse del uso de la Plataforma,
        incluyendo errores u omisiones en los contenidos, falta de
        disponibilidad de la Plataforma o la transmisión de virus o programas
        maliciosos.
      </p>

      <h2 className="text-2xl font-semibold mt-8 mb-4">10. Modificaciones</h2>
      <p className="mb-6 indent-8">
        Oklusion S.L. se reserva el derecho de efectuar sin previo aviso las
        modificaciones que considere oportunas en la Plataforma, pudiendo
        cambiar, suprimir o añadir tanto los contenidos y servicios que se
        presten a través de la misma como la forma en la que estos aparezcan
        presentados o localizados en la Plataforma.
      </p>

      <h2 className="text-2xl font-semibold mt-8 mb-4">
        11. Legislación Aplicable y Jurisdicción
      </h2>
      <p className="mb-6 indent-8">
        La relación entre Oklusion S.L. y el usuario se regirá por la normativa
        española vigente y cualquier controversia se someterá a los juzgados y
        tribunales de la ciudad de Barcelona.
      </p>
    </div>
  );
};

export default CondicionesVenta;
