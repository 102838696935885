import axios from 'axios';

const API_BASE_URL = import.meta.env.VITE_API_BASE_URL;

const axiosInstance = axios.create({
  baseURL: `${API_BASE_URL}/api`,
  headers: {
    'Content-Type': 'application/json',
  },
  withCredentials: true,
});

axiosInstance.interceptors.request.use(
  (config) => {
    const token = localStorage.getItem('token');
    if (token) {
      config.headers['Authorization'] = `Bearer ${token}`;
    }
    return config;
  },
  (error) => Promise.reject(error),
);

axiosInstance.interceptors.response.use(
  (response) => response,
  (error) => {
    if (error.response && error.response.status === 401) {
      console.error('Unauthorized, redirecting to login');
      localStorage.removeItem('token');
    }
    return Promise.reject(error);
  },
);

export const createReview = async (reviewData) => {
  try {
    const response = await axiosInstance.post('/reviews', reviewData);
    const data = response.data;
    return data;
  } catch (error) {
    console.error('Error creating order:', error);
    throw error;
  }
};

export const getReview = async ({ id }) => {
  try {
    const response = await axiosInstance.get(`/reviews/${id}`);
    const data = response.data;
    return data;
  } catch (error) {
    console.error('Error fetching review:', error);
    throw error;
  }
};

export const getReviews = async ({ queryString }) => {
  try {
    const response = await axiosInstance.get(`/reviews?${queryString}`);
    const data = response.data;
    return data;
  } catch (error) {
    console.error('Error fetching reviews:', error);
    throw error;
  }
};

export const getReviewsAsAdmin = async ({ queryString }) => {
  try {
    const response = await axiosInstance.get(`/reviews/asAdmin?${queryString}`);
    const data = response.data;
    return data;
  } catch (error) {
    console.error('Error fetching reviews:', error);
    throw error;
  }
};

export const updateReviewAsAdmin = async ({ id, reviewData }) => {
  try {
    const response = await axiosInstance.put(
      `/reviews/${id}/asAdmin`,
      reviewData,
    );
    const data = response.data;
    return data;
  } catch (error) {
    console.error('Error updating review:', error);
    throw error;
  }
};
