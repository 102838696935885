import React, { useState, useEffect } from 'react';
import { useParams, useSearchParams } from 'react-router-dom';
import { getCommissionPlansOfUser } from '@/services/commissionplan';
import TablecommissionPlan from '@/components/Tables/TablecommissionPlan';
import CommissionPlanFormModal from '@/components/Modals/CommissionPlanFormModal';
import { useFetchCommissionsUser } from '@/hooks/useCommissionPlan';

const UserCommissionPlans = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const [isOpen, setIsOpen] = useState(false);

  const { id } = useParams();

  const { commissionPlans, total, fetchCommissions } = useFetchCommissionsUser(
    id,
    searchParams,
  );

  // Open modal to add a new commission plan
  const handleAddClick = () => {
    setIsOpen(true);
  };

  return (
    <div>
      <section>
        <h3 className="py-3">Planes de Comisiones</h3>
        <CommissionPlanFormModal
          isOpen={isOpen}
          setIsOpen={setIsOpen}
          id={id}
          fetchCommissions={fetchCommissions}
        />

        <div className="flex justify-end my-2">
          <button
            onClick={handleAddClick}
            className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded"
          >
            Agregar
          </button>
        </div>

        <TablecommissionPlan
          CommissionPlans={commissionPlans}
          total={total}
          setSearchParams={setSearchParams}
          searchParams={searchParams}
          fetchCommissions={fetchCommissions}
        />
      </section>
    </div>
  );
};

export default UserCommissionPlans;
