import React from 'react';
import { Input } from '@/components/ui/input'; // Adjust based on your project's structure
import { Label } from '@/components/ui/label'; // ShadCN Label

const PaypalForm = ({ paypalData, setPaypalData }) => {
  return (
    <div className="mt-4">
      <Label htmlFor="paypalEmail" className="block text-sm font-medium">
        PayPal Email
      </Label>
      <Input
        type="email"
        id="paypalEmail"
        value={paypalData.email}
        onChange={(e) =>
          setPaypalData({ ...paypalData, email: e.target.value })
        }
        className="mt-1 block w-full"
      />
    </div>
  );
};

export default PaypalForm;
