import React, { useEffect, useState } from 'react';
import { getDocs } from '@/services/doc';

const GuideHome = () => {
  const [docs, setDocs] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState('');

  useEffect(() => {
    const fetchCategories = async () => {
      try {
        const { docs } = await getDocs({ queryString: '' });
        setDocs(docs);
      } catch (error) {
        console.error('Error fetching categories:', error);
        setError('Error al cargar las categorías'); // Set an error message
      } finally {
        setLoading(false); // Stop loading regardless of success or error
      }
    };

    fetchCategories();
  }, []);

  if (loading) return <div>Cargando...</div>; // Show loading state
  if (error) return <div>{error}</div>; // Show error state

  return (
    <div className="max-w-4xl mx-auto p-6 bg-white rounded-lg shadow-md">
      <h1 className="text-3xl font-bold mb-4 text-gray-800">
        Bienvenido a la Sección documentos
      </h1>
      <p className="text-lg mb-6 text-gray-700">
        Aquí encontrarás una variedad de documentos Navega por los documentos a
        continuación para encontrar la guía que mejor se adapte a tus
        necesidades.
      </p>

      <h2 className="text-2xl font-semibold mb-2 text-gray-800">
        Categorías de Guías:
      </h2>
      {docs.length > 0 ? (
        <ul className="list-disc list-inside space-y-2">
          {docs.map((doc) => (
            <li key={doc._id} className="text-gray-600">
              {doc.title}
            </li>
          ))}
        </ul>
      ) : (
        <p className="text-gray-600">No hay Documentos disponibles.</p>
      )}
    </div>
  );
};

export default GuideHome;
