import { useEffect, useState, useRef } from 'react';
import { Link, redirect, useParams } from 'react-router-dom';
import ContactCardEdit from '@/components/Cards/ContactCardEdit';
import ClinicCardEdit from '@/components/Cards/ClinicCardEdit';
import {
  getUser,
  updateUser,
  banUser,
  unactiveuserAsAdmin,
} from '@/services/auth';
import { LuPencil } from 'react-icons/lu';
import { FaPlus } from 'react-icons/fa';
import { toast } from 'react-toastify';
import { FaCheckCircle } from 'react-icons/fa';
import ProfileEditCard from '@/components/Cards/ProfileEditCard';
import io from 'socket.io-client';
import { useNotificationStore } from '@/store/Notificationstore';
import { updateDemandNotifications } from '@/services/demand';
import { useUserStore } from '@/store/userStore';

const userProfile = () => {
  const { id } = useParams();
  const { user } = useUserStore();
  const socket = useRef(null);
  const [name, setName] = useState('');
  const { addNotification } = useNotificationStore();
  const [lastname, setLastname] = useState('');
  const [labName, setLabName] = useState('');
  const [nif, setNif] = useState('');
  const [razonSocial, setRazonSocial] = useState('');
  const [colNumber, setColNumber] = useState('');
  const [email, setEmail] = useState('');
  const [phone, setPhone] = useState('');
  const [avatar, setAvatar] = useState('');
  const [sellerCommission, setSellerCommission] = useState(15);
  const [reciveCases, setReciveCases] = useState(false); // For reciveCases
  const [outsourcing, setOutsourcing] = useState(false);
  const [active, setActive] = useState(true);
  const [editNif, setEditNif] = useState(false);
  const [avatarPreview, setAvatarPreview] = useState('');
  const [clinics, setClinics] = useState([
    {
      clinicID: '',
      clinicName: '',
      via: '',
      clinicstreet: '',
      clinicPhone: '',
      postalCode: '',
      province: '',
      city: '',
    },
  ]);
  const [role, setRole] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [passwordCriteria, setPasswordCriteria] = useState({
    length: false,
    // lowercase: false,
    // uppercase: false,
    // specialChar: false,
  });
  const [error, setError] = useState('');
  const [allowToReciveCases, setAllowToReciveCases] = useState(false);

  const avatarInputRef = useRef(null);

  const handleAvatarClick = () => {
    avatarInputRef.current.click();
  };

  const handleAvatarChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setAvatarPreview(reader.result);
        setAvatar(file); // Save the file to state for submission
      };
      reader.readAsDataURL(file); // Reads the file and triggers onloadend
    }
  };
  const handleSubmit = async (e) => {
    e.preventDefault();
    const updatedProfile = {
      name,
      lastname,
      email,
      phone,
      avatar,
      reciveCases,
      clinics,
      nif,
      razonSocial,
      colNumber,
      role,
      newPassword,
      active,
      sellerCommission,
      outsourcing,
      allowToReciveCases,
    };
    try {
      if (newPassword !== confirmPassword) {
        setError('Las contraseñas no coinciden');
        return;
      }
      const response = await updateUser(id, updatedProfile);
      if (response) {
        toast.success('Perfil actualizado correctamente');
        fetchProfile();
      }
    } catch (error) {
      console.error('Error updating profile:', error);
      toast.error('Error al actualizar el perfil');
    }
  };

  const handleBanuser = async () => {
    try {
      const confirmed = window.confirm(
        '¿Estás seguro de que quieres banear al usuario?',
      );
      if (!confirmed) {
        return;
      }

      const response = await banUser(id);
      if (response) {
        toast.success('Usuario baneado correctamente');
        redirect('/admin/users');
        //banUser
        socket.current.emit('banUser', {
          userId: id,
        });
      }
    } catch (error) {
      console.error('Error updating profile:', error);
      toast.error('Error al actualizar el perfil');
    }
  };

  const handleunactiveuserAsAdmin = async () => {
    try {
      const confirmed = window.confirm(
        '¿Estás seguro de que quieres desactivar al usuario?',
      );
      if (!confirmed) {
        return;
      }

      const response = await unactiveuserAsAdmin(id);
      if (response) {
        toast.success('Usuario desactivado correctamente');
        fetchProfile();
        socket.current.emit('banUser', {
          userId: id,
        });
      }
    } catch (error) {
      console.error('Error updating profile:', error);
      toast.error('Error al actualizar el perfil');
    }
  };

  const fetchProfile = async () => {
    const profile = await getUser(id);
    setName(profile.name);
    setLastname(profile.lastname);
    setEmail(profile.email);
    setPhone(profile.phone);
    setAvatar(profile.avatar);
    setClinics(profile.clinics);
    setReciveCases(profile.reciveCases);
    setRole(profile.role);
    setActive(profile.active);
    setSellerCommission(profile.sellerCommission);
    setLabName(profile.labName);
    setNif(profile.nif);
    setRazonSocial(profile.razonSocial);
    setColNumber(profile.colNumber);
    setOutsourcing(profile.outsourcing);
    setAllowToReciveCases(profile.allowToReciveCases);
  };

  useEffect(() => {
    fetchProfile();
  }, [id]);

  useEffect(() => {
    const API_BASE_URL = import.meta.env.VITE_API_BASE_URL;
    socket.current = io(API_BASE_URL);

    socket.current.emit('setup', { user });

    const handleReceiveMessage = async (receivedMessage) => {
      toast.success('Nuevo mensaje recibido');
      addNotification({
        message: 'Nuevo mensaje recibido',
        type: 'message',
        chat: receivedMessage.chat,
      });

      await updateDemandNotifications({ id: receivedMessage.chat });
    };

    const handleReceiveDemand = (demand) => {
      toast.success('Nuevo caso recibido');
      addNotification({
        message: 'Nuevo caso recibido',
        type: 'demand',
        demand: demand._id,
      });
    };

    const handleReceiveOffer = (offer) => {
      toast.success('Nueva oferta recibida');
      addNotification({
        message: 'Nueva oferta recibida',
        type: 'offer',
        offer: offer._id,
      });
    };

    const handleReceiveOrder = (order) => {
      toast.success('Tu oferta fue aceptada');
      addNotification({
        message: 'Tu oferta fue aceptada',
        type: 'order',
        order: order._id,
      });
    };
    const handleReceiveUpdateOrder = (order) => {
      addNotification({
        message: 'Pedido actualizado',
        type: 'order',
        order: order._id,
      });
    };
    const handleReceiveUpdateCase = (demand) => {
      toast.success('Caso actualizado');
      addNotification({
        message: 'Caso actualizado',
        type: 'demand',
        demand: demand._id,
      });
    };
    const handleReceiveAcceptedBillLabCaseSeller = (billLabCase) => {
      toast.success('Factura aprobada');
      addNotification({
        message: 'Factura aprobada',
        type: 'billLabCase',
        billLabCase: billLabCase._id,
      });
    };
    const handleReceiveAcceptedBillLabCaseBuller = (billLabCase) => {
      toast.success('Factura generada');
      addNotification({
        message: 'Factura generada',
        type: 'billLabCase',
        billLabCase: billLabCase._id,
      });
    };
    const handleReceiveRejectedBillLabCase = (billLabCase) => {
      toast.success('Factura rechazada');
      addNotification({
        message: 'Factura rechazada',
        type: 'billLabCase',
        billLabCase: billLabCase._id,
      });
    };

    socket.current.on('receiveMessage', handleReceiveMessage);
    socket.current.on('receiveDemand', handleReceiveDemand);
    socket.current.on('receiveOffer', handleReceiveOffer);
    socket.current.on('receiveOrder', handleReceiveOrder);
    socket.current.on('receiveUpdateOrder', handleReceiveUpdateOrder);
    socket.current.on('receiveUpdateCase', handleReceiveUpdateCase);
    socket.current.on(
      'receiveAcceptedBillLabCaseSeller',
      handleReceiveAcceptedBillLabCaseSeller,
    );
    socket.current.on(
      'receiveAcceptedBillLabCaseBuller',
      handleReceiveAcceptedBillLabCaseBuller,
    );
    socket.current.on(
      'receiveRejectedBillLabCase',
      handleReceiveRejectedBillLabCase,
    );

    return () => {
      socket.current.off('receiveMessage', handleReceiveMessage);
      socket.current.off('receiveDemand', handleReceiveDemand);
      socket.current.off('receiveOffer', handleReceiveOffer);
      socket.current.off('receiveOrder', handleReceiveOrder);
      socket.current.off('receiveUpdateOrder', handleReceiveUpdateOrder);
      socket.current.off('receiveUpdateCase', handleReceiveUpdateCase);
      socket.current.off(
        'receiveAcceptedBillLabCaseSeller',
        handleReceiveAcceptedBillLabCaseSeller,
      );
      socket.current.off(
        'receiveAcceptedBillLabCaseBuller',
        handleReceiveAcceptedBillLabCaseBuller,
      );
      socket.current.off(
        'receiveRejectedBillLabCase',
        handleReceiveRejectedBillLabCase,
      );
      socket.current.disconnect();
    };
  }, []);

  const handleChange = (e) => {
    const { name, value } = e.target;

    if (name === 'newPassword') {
      setNewPassword(value);
      setPasswordCriteria({
        length: value.length >= 8,
        // lowercase: /[a-z]/.test(value),
        // uppercase: /[A-Z]/.test(value),
        // specialChar: /[!@#$%^&*(),.?":{}|<>]/.test(value),
      });
    } else {
      setConfirmPassword(value);
    }
  };

  return (
    <div className="gap-4">
      <form onSubmit={handleSubmit}>
        <section className="flex flex-row justify-between items-center  gap-4 section-profile">
          <ProfileEditCard
            handleAvatarClick={handleAvatarClick}
            avatarPreview={avatarPreview}
            avatar={avatar}
            avatarInputRef={avatarInputRef}
            handleAvatarChange={handleAvatarChange}
            name={name}
            setName={setName}
            lastname={lastname}
            setLastname={setLastname}
            email={email}
            setEmail={setEmail}
            phone={phone}
            setPhone={setPhone}
            nif={nif}
            setNif={setNif}
            razonSocial={razonSocial}
            setRazonSocial={setRazonSocial}
            colNumber={colNumber}
            setColNumber={setColNumber}
            editNif={editNif}
          />
          <div className="flex flex-col">
            <button
              className="bg-[#dbe2ff] hover:bg-blue-200 font-bold py-2 px-4 rounded flex flex-row gap-3 items-center"
              type="button"
              onClick={() => setEditNif(!editNif)}
            >
              Editar
              <LuPencil />
            </button>
            <button
              className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 my-10 px-4 rounded"
              type="submit"
            >
              Guardar
            </button>
          </div>
        </section>

        <section className="bg-white p-4 border-2 my-2 flex flex-row justify-between">
          <div className="inline-flex items-center  gap-2">
            <label
              htmlFor="sellerCommission"
              className="block text-sm font-medium text-gray-700"
            >
              Comisión de venta
            </label>
            <input
              type="number"
              id="sellerCommission"
              name="sellerCommission"
              value={sellerCommission}
              onChange={(e) => setSellerCommission(e.target.value)}
              className="mt-1 block w-full p-2 border border-gray-300 rounded-lg shadow-sm focus:ring-blue-500 focus:border-blue-500"
              min={0}
            />
            <span className="ml-2">%</span>
          </div>
          <Link
            className="bg-[#dbe2ff] hover:bg-blue-200 font-bold py-2 px-4 rounded flex flex-row gap-3 items-center"
            to={`/admin/user/${id}/commissionplans`}
          >
            Ver planes
          </Link>
        </section>

        <section className="bg-white p-4 border-2 my-2">
          <button
            className="bg-yellow-500 hover:bg-blue-200 font-bold py-2 px-4 rounded flex flex-row gap-3 items-center"
            type="button"
            onClick={handleunactiveuserAsAdmin}
          >
            Borrar cuenta
          </button>
        </section>

        <section className="bg-white p-4 border-2 my-2">
          <button
            className="bg-red-500 hover:bg-blue-200 font-bold py-2 px-4 rounded flex flex-row gap-3 items-center"
            type="button"
            onClick={handleBanuser}
          >
            Banear
          </button>
        </section>

        {role === 'SELLER_ROLE' && (
          <section className="bg-white p-4 border-2 my-2 flex flex-row gap-2">
            <div
              className="inline-flex items-center "
              onClick={() => {
                if (clinics.length === 0) {
                  toast.error(
                    'Por favor, añada una dirección antes de recibir casos.',
                  );
                }
              }}
            >
              <input
                type="checkbox"
                checked={reciveCases}
                onChange={(e) => setReciveCases(e.target.checked)}
                className="form-checkbox text-blue-600"
                disabled={clinics.length === 0 || !allowToReciveCases} // Deshabilitado si no hay clínicas
              />
              <span className="ml-2">Recibir casos</span>
            </div>

            <div
              className="inline-flex items-center "
              onClick={() => {
                if (clinics.length === 0) {
                  toast.error(
                    'Por favor, añada una dirección antes de recibir casos.',
                  );
                }
              }}
            >
              <input
                type="checkbox"
                checked={outsourcing}
                onChange={(e) => setOutsourcing(e.target.checked)}
                className="form-checkbox text-blue-600"
                disabled={clinics.length === 0} // Deshabilitado si no hay clínicas
              />
              <span className="ml-2">Ser outsourcing</span>
            </div>

            <div
              className="inline-flex items-center "
              onClick={() => {
                if (clinics.length === 0) {
                  toast.error(
                    'Por favor, añada una dirección antes de recibir casos.',
                  );
                }
              }}
            >
              <input
                type="checkbox"
                checked={allowToReciveCases}
                onChange={(e) => setAllowToReciveCases(e.target.checked)}
                className="form-checkbox text-blue-600"
                disabled={clinics.length === 0} // Deshabilitado si no hay clínicas
              />
              <span className="ml-2">Permitir que publique</span>
            </div>
          </section>
        )}

        <section className="bg-white p-4 border-2 my-2">
          <h3 className="font-bold  mb-4">Actualizar contraseña</h3>

          <div className="flex gap-4">
            <div className="flex-1">
              <label
                htmlFor="newPassword"
                className="block text-sm font-medium text-gray-700"
              >
                Nueva contraseña
              </label>
              <input
                type="password"
                id="newPassword"
                name="newPassword"
                value={newPassword}
                onChange={handleChange}
                className="mt-1 block w-full p-2 border border-gray-300 rounded-lg shadow-sm focus:ring-blue-500 focus:border-blue-500"
                placeholder="Nueva contraseña"
              />
            </div>

            <div className="flex-1">
              <label
                htmlFor="confirmPassword"
                className="block text-sm font-medium text-gray-700"
              >
                Confirmar contraseña
              </label>
              <input
                type="password"
                id="confirmPassword"
                name="confirmPassword"
                value={confirmPassword}
                onChange={handleChange}
                className="mt-1 block w-full p-2 border border-gray-300 rounded-lg shadow-sm focus:ring-blue-500 focus:border-blue-500"
                placeholder="Confirmar contraseña"
              />
            </div>
          </div>
          <div className="mt-2 grid grid-cols-2 gap-2  w-1/2">
            {/* <div className="flex items-start">
              <FaCheckCircle
                className={`mr-2 ${
                  passwordCriteria.lowercase
                    ? 'text-green-500'
                    : 'text-gray-400'
                }`}
              />
              <span
                className={`text-sm ${
                  passwordCriteria.lowercase
                    ? 'text-green-500'
                    : 'text-gray-700'
                }`}
              >
                Una letra minúscula
              </span>
            </div> */}

            {/* <div className="flex items-start">
              <FaCheckCircle
                className={`mr-2 ${
                  passwordCriteria.uppercase
                    ? 'text-green-500'
                    : 'text-gray-400'
                }`}
              />
              <span
                className={`text-sm ${
                  passwordCriteria.uppercase
                    ? 'text-green-500'
                    : 'text-gray-700'
                }`}
              >
                Una letra mayúscula
              </span>
            </div> */}
            {/* <div className="flex items-start">
              <FaCheckCircle
                className={`mr-2 ${
                  passwordCriteria.specialChar
                    ? 'text-green-500'
                    : 'text-gray-400'
                }`}
              />
              <span
                className={`text-sm ${
                  passwordCriteria.specialChar
                    ? 'text-green-500'
                    : 'text-gray-700'
                }`}
              >
                Un carácter especial
              </span>
            </div> */}
            <div className="flex items-start">
              <FaCheckCircle
                className={`mr-2 ${
                  passwordCriteria.length ? 'text-green-500' : 'text-gray-400'
                }`}
              />
              <span
                className={`text-sm ${
                  passwordCriteria.length ? 'text-green-500' : 'text-gray-700'
                }`}
              >
                8 caracteres o más
              </span>
            </div>
          </div>

          {error && <p className="text-red-500 text-sm mt-2">{error}</p>}
        </section>

        <section className="bg-white p-4 border-2 ">
          <option>Cambiar el rol</option>
          <select
            value={role}
            onChange={(e) => setRole(e.target.value)}
            className="w-full p-2 border border-gray-300 rounded-lg shadow-sm focus:ring-blue-500 focus:border-blue-500"
          >
            <option value="ADMIN_ROLE">Administrador</option>
            <option value="SELLER_ROLE">Laboratorio</option>
            <option value="DENTIST_ROLE">Dentista/Odontologo</option>
          </select>
        </section>

        <section className="bg-white p-4 border-2 my-2">
          <div className="flex flex-row justify-between items-center">
            <h1 className="font-bold py-2">Direcciones</h1>
            {role !== 'SELLER_ROLE' ||
              (clinics.length === 0 && (
                <button
                  className="bg-[#dbe2ff] hover:bg-blue-200 font-bold py-2 px-4 rounded flex flex-row gap-3 items-center"
                  type="button"
                  onClick={() => {
                    setClinics([
                      ...clinics,
                      {
                        clinicID: '',
                        clinicName: '',
                        via: '',
                        clinicstreet: '',
                        clinicPhone: '',
                        postalCode: '',
                        province: '',
                        city: '',
                      },
                    ]);
                  }}
                >
                  Añadir dirección
                  <FaPlus />
                </button>
              ))}
          </div>
          {clinics.map((clinic, index) => (
            <div key={index} className="mb-6">
              <ClinicCardEdit
                clinic={clinic}
                clinics={clinics}
                setClinics={setClinics}
                index={index}
              />
            </div>
          ))}
          <div className="flex justify-end">
            <button
              className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2  px-4 rounded"
              type="submit"
            >
              Guardar
            </button>
          </div>
        </section>
      </form>
    </div>
  );
};

export default userProfile;
