import React, { useState, useEffect } from 'react';
import TeethShowblue from '../TeethShowblue';
import TeethSel from '../TeethSel';
import { CardContent } from '@/components/ui/card';
import { useOutsourcingStore } from '@/store/OutsourcingStore';
import useUserStore from '@/store/userStore';
import { ScrollArea } from '@/components/ui/scroll-area';
import { Label } from '@/components/ui/label';
import { useUpdateOutsourcing } from '@/hooks/useOutsourcing';

const TeethOutsourcingCard = () => {
  const { user } = useUserStore();
  const { outsourcing } = useOutsourcingStore(); // Getting outsourcing from the store
  const [caseName, setCaseName] = useState(outsourcing.caseName);
  const [selectedTeeth, setSelectedTeeth] = useState(outsourcing.selectedTeeth);
  const [description, setDescription] = useState(outsourcing.description);

  const { loading, updateOutsourcing } = useUpdateOutsourcing();

  useEffect(() => {
    setSelectedTeeth(outsourcing.selectedTeeth);
    setDescription(outsourcing.description);
    setCaseName(outsourcing.caseName);
  }, [outsourcing]);

  const handleUpdateOutsourcing = async () => {
    try {
      await updateOutsourcing(outsourcing._id, {
        selectedTeeth,
        description,
        caseName,
      });
      window.location.reload();
    } catch (error) {
      console.error('Error updating outsourcing:', error);
    }
  };
  if (!outsourcing) {
    return <div>Loading...</div>;
  }

  return (
    <div className="border-none">
      <CardContent>
        {outsourcing.selectedTeeth && (
          <div>
            {user.role === 'ADMIN_ROLE' ? (
              <TeethSel
                selectedTeeth={selectedTeeth || []}
                setSelectedTeeth={setSelectedTeeth}
              />
            ) : (
              <TeethShowblue
                selectedTeeth={selectedTeeth || []}
                setSelectedTeeth={setSelectedTeeth}
              />
            )}
          </div>
        )}

        {user.role === 'ADMIN_ROLE' && (
          <div className="max-w-lg mt-4">
            <Label
              variant="subtitle1"
              className="mb-2 font-bold text-blue-gray-800"
            >
              Nombre del caso
            </Label>
            <input
              type="text"
              value={caseName}
              onChange={(e) => setCaseName(e.target.value)}
              className="border border-gray-300 rounded-md p-2 w-full"
              placeholder="Enter case name"
            />

            <Label
              variant="subtitle1"
              className="mb-2 font-bold text-blue-gray-800"
            >
              Descripción
            </Label>

            <textarea
              value={description}
              onChange={(e) => setDescription(e.target.value)}
              className="border border-gray-300 rounded-md p-2 w-full"
              placeholder="Enter description"
              rows={4}
            />
            <button
              onClick={handleUpdateOutsourcing}
              className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded mt-4"
              disabled={loading}
            >
              {loading ? 'Cargando...' : 'Actualizar'}
            </button>
          </div>
        )}
      </CardContent>
    </div>
  );
};

export default TeethOutsourcingCard;
