import { Navigate, Route, Routes } from 'react-router-dom';
import MainLayout from '@/components/Layouts/MainLayout';

export const RoutesWithNotFound = ({ children }) => {
  return (
    <Routes>
      {children}
      <Route path="*" element={<Navigate to="/404" />} />
      <Route element={<MainLayout />}>
        <Route path="/404" element={<h1>Página no encontrada</h1>} />
      </Route>
    </Routes>
  );
};
