import React from 'react';
import { Stepper, Step, Typography } from '@material-tailwind/react';
import { CiCircleCheck } from 'react-icons/ci';
import { LuCircleDot } from 'react-icons/lu';

const StepperOrder = ({ order }) => {
  const statusSteps = [
    'pending', // Step 0
    'onfabrication', // Step 1
    'sended', // Step 2
    'completed', // Step 3
  ];

  const handleStatus = (status) => {
    switch (status) {
      case 'pending':
        return 'Propuesta';
      case 'onfabrication':
        return 'Fabricando';
      case 'sended':
        return 'Enviado';
      case 'completed':
        return 'Completado';
      case 'canceled':
        return 'Cancelado';
      default:
        return 'Desconocido';
    }
  };

  const currentStep = statusSteps.indexOf(order.status);

  return (
    <div className="w-full  px-10 py-4 h-44 min-h-fit flex flex-col bg-white  rounded-md border-2">
      <Typography
        variant="h4"
        color="blue-gray"
        className="mb-4 text-center md:text-left"
      >
        Proceso de la orden
      </Typography>
      {order.status !== 'inReview' ? (
        <Stepper
          activeStep={currentStep}
          className="flex flex-wrap justify-between gap-3"
        >
          {statusSteps.map((status, index) => (
            <Step key={index} className="relative flex flex-col items-center">
              {index <= currentStep ? (
                <CiCircleCheck
                  color="blue"
                  className="h-4 w-8 md:h-10 md:w-10"
                />
              ) : (
                <LuCircleDot color="gray" className="h-10 w-10" />
              )}
              <div className="absolute -bottom-[3.5rem] w-20 ">
                <Typography
                  variant="h6"
                  color={currentStep === index ? 'blue' : 'gray'}
                >
                  Paso {index + 1}
                </Typography>
                <Typography
                  color={currentStep === index ? 'blue' : 'gray'}
                  className="font-normal text-sm "
                >
                  {handleStatus(status)}
                </Typography>
              </div>
            </Step>
          ))}
        </Stepper>
      ) : (
        <Typography variant="h6" color="red" className="mt-2 text-center">
          Orden en revision
        </Typography>
      )}
    </div>
  );
};

export default StepperOrder;
