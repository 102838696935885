import React from 'react';
import { Link } from 'react-router-dom';
import { useMarkNotificationAsRead } from '@/hooks/useNotification';

const NotificationCard = ({ notification }) => {
  const { title, message, createdAt, link, read } = notification;
  const { markAsRead } = useMarkNotificationAsRead();

  const handleDays = (date) => {
    const today = new Date();
    const reviewDate = new Date(date);
    const diffTime = Math.abs(today - reviewDate);
    const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
    return diffDays < 4 ? 'Hoy' : `${diffDays} dias`;
  };

  const handleClick = () => {
    markAsRead(notification._id);
  };

  return (
    <Link to={link} onClick={handleClick}>
      <div
        className={`notification-card bg-white shadow-md p-4 rounded-lg mb-3 flex flex-row justify-between hover:bg-gray-100 transition-colors duration-200 ${
          read ? 'opacity-50' : ''
        }`}
      >
        <div className="flex flex-col mb-2">
          <h2 className="text-lg font-semibold">{title}</h2>
          {/* Render message as HTML */}
          <p
            className="text-gray-700"
            dangerouslySetInnerHTML={{ __html: message }}
          ></p>
          <small className="text-gray-500">{handleDays(createdAt)}</small>
        </div>
      </div>
    </Link>
  );
};

export default NotificationCard;
