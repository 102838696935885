import React from 'react';
import { Card } from '@/components/ui/card';
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from '@/components/ui/table';

import {
  selectedOptionList,
  MaterialList,
  otrosList,
} from '@/constants/caseType';

const handleSelectedOption = (selectedOption) => {
  // check material in protesisList where material ==selectedOption and return name

  const protesis = selectedOptionList.find(
    (protesis) => protesis.selectedOption == selectedOption,
  );
  if (!protesis) {
    return 'N/a';
  }
  return protesis.name;
};
const handleSelectedMaterial = (selectedMaterial) => {
  // check material in protesisList where material ==selectedOption and return name

  const protesis = MaterialList.find(
    (protesis) => protesis.value == selectedMaterial,
  );
  if (!protesis) {
    return 'N/a';
  }
  return protesis.name;
};

const handleProducts = (selectedProduct) => {
  // check material in protesisList where material ==selectedOption and return name

  const protesis = otrosList.find(
    (protesis) => protesis.value == selectedProduct,
  );
  if (!protesis) {
    return 'N/a';
  }
  return protesis.name;
};

const CaseTypeCard = ({ demand }) => {
  if (!demand) {
    return null;
  }

  const renderTable = (data) => (
    <div className=" rounded-md font-urbanist">
      <Table>
        <TableHeader>
          <TableRow>
            <TableHead className="font-bold text-xs font-urbanist">
              Información
            </TableHead>
            <TableHead className="font-bold text-xs font-urbanist"></TableHead>
          </TableRow>
        </TableHeader>
        <TableBody>
          {Object.entries(data).map(([key, value]) => (
            <TableRow key={key} className="bg-white">
              <TableCell className="text-xs font-bold font-inter">
                {key}
              </TableCell>
              <TableCell className="text-xs font-inter">{value}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </div>
  );

  if (demand.selectedImplant) {
    const implantData = {
      'Típo de trabajo': handleSelectedOption(
        demand.selectedImplant.selectedOption,
      ),
      Material: handleSelectedMaterial(demand.selectedImplant.material),
      Color: demand.selectedImplant.color || 'N/a',
      'Prueba estética': demand.selectedImplant.Mockup ? 'Si' : 'No',
      'Sobre implante': demand.selectedImplant.onImplant ? 'Si' : 'No',
      'Barra sobre implante': demand.selectedImplant.withBar ? 'Si' : 'No',
      'Material de la Barra':
        demand.selectedImplant.selectMaterialBarra || 'N/a',
      'Tipo de Barra': demand.selectedImplant.selectTipoBarra || 'N/a',
    };
    return renderTable(implantData);
  }

  if (demand.ortodonciaReq) {
    const ortodonciaData = {
      'Típo de trabajo': handleSelectedOption(
        demand.ortodonciaReq.selectedOption,
      ),
      Subselección: demand.ortodonciaReq.subSelection,
    };
    return renderTable(ortodonciaData);
  }

  if (demand.selectedProduct) {
    const productData = {
      'Típo de trabajo': handleProducts(demand.selectedProduct),
    };
    return renderTable(productData);
  }

  return null;
};

export default CaseTypeCard;
