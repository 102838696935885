import { NavLink, useNavigate, Link } from 'react-router-dom';
import { useDemandStore } from '@/store/DemandStore';
import { useUserStore } from '@/store/userStore';
import { useState } from 'react';
import OfferFormModal from '@/components/Modals/OfferFormModal';
import NeedHelpModal from '@/components/Modals/NeedHelpModal';
import { useFetchDemands } from '@/hooks/useDemand';

import {
  Select,
  SelectContent,
  SelectGroup,
  SelectItem,
  SelectLabel,
  SelectTrigger,
  SelectValue,
} from '@/components/ui/select';
import TableCaseSidabar from '@/components/Tables/TableCaseSidabar';

import {
  Sidebar,
  SidebarContent,
  SidebarHeader,
  SidebarFooter,
  SidebarMenu,
  SidebarMenuItem,
} from '@/components/ui/sidebar';
import LogoOklusion from '@/assets/Logo Oklusion.png';

function AppSidebar() {
  const { demand } = useDemandStore();
  const { user } = useUserStore();
  // const [demands, setDemands] = useState([]);
  const [isOpen, setIsOpen] = useState(false);
  const [isOpenHelp, setIsOpenHelp] = useState(false);

  const [searchParams, setSearchParams] = useState('');
  const navigate = useNavigate();

  const { demands } = useFetchDemands(searchParams);

  // const fetchData = async () => {
  //   const params = { skip: 0, limit: 10 };
  //   const queryString = new URLSearchParams(params).toString();
  //   setLoading(true);
  //   setError(null);

  //   try {
  //     const data =
  //       user.role === 'SELLER_ROLE'
  //         ? await getDemandsAsSeller({ queryString })
  //         : await getDemands({ queryString });
  //     setDemands(data.demands);
  //   } catch (err) {
  //     setError('Failed to fetch demands');
  //   } finally {
  //     setLoading(false);
  //   }
  // };

  // useEffect(() => {
  //   if (user.role) fetchData();
  // }, [user.role]);

  const handleStatusOffer = (status) => {
    switch (status) {
      case 'pending':
        return 'Propuesta';
      case 'accepted':
        return user.role === 'SELLER_ROLE' ? 'Pedido  y dirección' : 'Pedido';
      default:
        return user.role === 'SELLER_ROLE' ? 'Pedido  y dirección' : 'Pedido';
    }
  };

  const handleOpenModalOffer = () => {
    setIsOpen(true);
  };

  const CustomNavLink = ({ to, children }) => (
    <NavLink
      to={to}
      className={({ isActive }) =>
        `p-2 min-w-max rounded-md text-blue-500 hover:underline ${
          isActive ? 'bg-blue-500 text-white' : ''
        }`
      }
    >
      {children}
    </NavLink>
  );

  const handleNotifications = (chat) => {
    const notifications = chat.notifications;
    if (!notifications) {
      return 0;
    }

    const notification = notifications.find(
      (n) => n.user.toString() === user?._id,
    );

    // Check if the user is already on the chat page
    const isChatOpen = window.location.pathname === `/cases/chat/${chat._id}`;

    // If the chat is open, return 0 to hide the icon
    if (isChatOpen) {
      return 0;
    }

    if (notification && notification.count > 0) {
      return notification.count;
    } else {
      return 0;
    }
  };

  const handleBill = (status) => {
    switch (status) {
      case 'billed':
        return true;
      case 'accepted':
        return true;
      default:
        return false;
    }
  };

  if (!demand) return null;

  return (
    <Sidebar className="sideBar-size">
      <SidebarHeader className="mx-auto">
        <div className="flex justify-start w-full md:w-auto  pb-2">
          <h1 className="text-xl font-bold">
            <Link
              to={user ? '/dashboard' : '/'}
              className="hover:text-gray-300"
            >
              <img
                src={LogoOklusion}
                alt="Logo Oklusion"
                className="h-5 w-30"
              />
            </Link>
          </h1>
        </div>
      </SidebarHeader>
      <SidebarContent className="p-4 md:p-0  ">
        {user.role === 'SELLER_ROLE' && isOpen && (
          <OfferFormModal
            demand={demand}
            isOpen={isOpen}
            setIsOpen={setIsOpen}
            user={user}
          />
        )}
        <NeedHelpModal isOpen={isOpenHelp} setIsOpen={setIsOpenHelp} />

        {/* Case Select Dropdown */}
        <div className="font-urbanist">
          <Select
            variant="outlined"
            size="lg"
            onValueChange={(value) => navigate(`/cases/${value}`)}
            value={demand._id}
          >
            <SelectTrigger>
              <SelectValue placeholder="ver un caso" />
            </SelectTrigger>
            <SelectContent>
              <SelectGroup>
                <SelectLabel className="font-urbanist">Casos</SelectLabel>
                {demands.map((demand) => (
                  <SelectItem
                    key={demand._id}
                    value={demand._id}
                    className="hover:bg-[#dbe2ff] w-full px-8 font-urbanist"
                  >
                    {demand?.caseName.substring(0, 23)}
                  </SelectItem>
                ))}
              </SelectGroup>
            </SelectContent>
          </Select>
        </div>

        {/* Sidebar Table */}
        <TableCaseSidabar demand={demand} />

        {/* Links */}
        <SidebarMenu className="bg-[#e9ecf2]  rounded-md md:p-2 font-urbanist overflow-x-auto">
          <SidebarMenuItem className="flex flex-col  px-2 md:px-4 bg-[#e9ecf2] w-full ">
            <CustomNavLink to={`/cases/${demand._id}`}>Detalles</CustomNavLink>
          </SidebarMenuItem>

          {demand.selectedLaboratorios &&
            demand.selectedLaboratorios.map((laboratorio) => (
              <SidebarMenuItem
                key={laboratorio._id}
                className="flex flex-col px-2 md:px-4 w-full"
              >
                {user.role !== 'SELLER_ROLE' && demand.status === 'pending' && (
                  <p className="text-left text-sm font-semibold mb-1">
                    <strong>{laboratorio.seller?.labName}</strong>
                  </p>
                )}
                {user.role === 'SELLER_ROLE' &&
                  !laboratorio.offer &&
                  laboratorio.seller?._id == user._id && (
                    <button
                      className="bg-blue-300 p-2 text-white hover:bg-blue-700 w-full text-left pl-2 rounded-md mb-1"
                      onClick={handleOpenModalOffer}
                    >
                      Propuesta
                    </button>
                  )}

                {laboratorio.offer && (
                  <div className="flex flex-col">
                    <CustomNavLink to={`/cases/offer/${laboratorio.offer._id}`}>
                      {/* {demand.status === 'pending' ? 'Propuesta' : 'Pedido'} */}
                      {handleStatusOffer(demand.status)}
                    </CustomNavLink>
                    <CustomNavLink to={`/cases/revisions/${demand._id}`}>
                      Revisiones
                    </CustomNavLink>
                  </div>
                )}
                {user.role === 'DENTIST_ROLE' && (
                  <CustomNavLink to={`/cases/chat/${laboratorio.chat._id}`}>
                    <div className="flex flex-row items-center gap-2 relative">
                      Chat
                      {handleNotifications(laboratorio.chat) > 0 && (
                        <span className="absolute -top-1 -left-3 inline-block w-2 h-2 bg-red-500 text-white rounded-full text-sm   items-center justify-center"></span>
                      )}
                    </div>
                  </CustomNavLink>
                )}
                {user.role === 'SELLER_ROLE' &&
                  laboratorio.seller?._id == user._id && (
                    <CustomNavLink to={`/cases/chat/${laboratorio.chat._id}`}>
                      <div className="flex flex-row items-center gap-2 relative">
                        Chat
                        {handleNotifications(laboratorio.chat) > 0 && (
                          <span className="absolute -top-1 -left-3 inline-block w-2 h-2 bg-red-500 text-white rounded-full text-sm   items-center justify-center"></span>
                        )}
                      </div>
                    </CustomNavLink>
                  )}

                {handleBill(demand.billLabCase?.status) &&
                  user.role === 'DENTIST_ROLE' && (
                    <div className="flex flex-col">
                      <CustomNavLink
                        to={`/cases/bill/${
                          demand.billLabCase?._id || demand.billLabCase
                        }`}
                      >
                        Factura
                      </CustomNavLink>
                    </div>
                  )}

                {demand.billLabCase?.seller == user?._id &&
                  user.role === 'SELLER_ROLE' && (
                    <div className="flex flex-col">
                      <CustomNavLink
                        to={`/cases/bill/${
                          demand.billLabCase?._id || demand.billLabCase
                        }`}
                      >
                        Factura
                      </CustomNavLink>
                    </div>
                  )}
              </SidebarMenuItem>
            ))}
        </SidebarMenu>
      </SidebarContent>

      <SidebarFooter>
        <button
          className="btn-neutral p-2 w-full text-left pl-2 rounded-md mb-1"
          onClick={() => setIsOpenHelp(true)}
        >
          ¿Necesitas ayuda?
        </button>
      </SidebarFooter>
    </Sidebar>
  );
}

export default AppSidebar;
