import { create } from 'zustand';
import { persist } from 'zustand/middleware';

// MessageStore
export const useMessageStore = create(
  persist(
    (set) => ({
      messages: [],
      addMessage: (message) =>
        set((state) => ({
          messages: [...state.messages, message],
        })),
      removeMessage: (id) =>
        set((state) => ({
          messages: state.messages.filter((message) => message.id !== id),
        })),
      updateMessage: (id, newText) =>
        set((state) => ({
          messages: state.messages.map((message) =>
            message.id === id ? { ...message, text: newText } : message,
          ),
        })),
      clearMessages: () =>
        set(() => ({
          messages: [],
        })),
    }),
    {
      name: 'messages',
    },
  ),
);

export default useMessageStore;
