import { useState, useEffect } from 'react';
import { useSearchParams } from 'react-router-dom';
import ReviewCard from '@/components/Cards/ReviewCard';
import { useFetchReviews } from '@/hooks/useReview';
import ReviewModalEdit from '@/components/Modals/ReviewModalEdit';

const ReviewsAdmin = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const { reviews, loading, fetchReviews } = useFetchReviews(searchParams);
  const [reviewId, setReviewId] = useState(null);
  const [isOpen, setIsOpen] = useState(false);
  const [edited, setEdited] = useState(false);

  const handleModal = (id) => {
    setReviewId(id);
    setIsOpen(true);
  };

  useEffect(() => {
    if (edited) {
      fetchReviews();
      setEdited(false);
    }
  }, [edited, fetchReviews]);

  return (
    <div>
      <div className="flex flex-col gap-4 mt-4">
        <ReviewModalEdit
          isOpen={isOpen}
          setIsOpen={setIsOpen}
          reviewId={reviewId}
          setEdited={setEdited}
        />
        {reviews && reviews.length > 0 ? (
          reviews.map((review) => (
            <ReviewCard
              key={review._id}
              review={review}
              handleModal={handleModal}
            />
          ))
        ) : (
          <p className="text-gray-500">No reviews available yet.</p>
        )}
      </div>
    </div>
  );
};

export default ReviewsAdmin;
