import { useParams } from 'react-router-dom';
import { Button } from '@/components/ui/button';
import { Card, CardContent } from '@/components/ui/card';
import { FaDownload } from 'react-icons/fa';
import useUserStore from '@/store/userStore';
import { useFetchBillLab, useDeleteBillLab } from '@/hooks/useBill';
import BillStepper from '@/components/Cards/BillStepper';

const BillLapPage = () => {
  const { user } = useUserStore();
  const { billId } = useParams();

  const { billingData, loading, demand, outsourcing } = useFetchBillLab(billId);
  const { handleDelete } = useDeleteBillLab(billId);

  const getFileName = (url) => {
    return url.substring(url.lastIndexOf('/') + 1);
  };

  // const handleDelete = async () => {
  //   try {
  //     //confirm to prossed
  //     if (!window.confirm('¿Estás seguro de eliminar esta factura?')) {
  //       return;
  //     }
  //     const response = await deletebillLab(billId);
  //     if (response) {
  //       toast.success('Factura eliminada con éxito');
  //       navigate('/admin/billLabs');
  //     }
  //   } catch (error) {
  //     console.error('Error deleting billing data:', error);
  //   }
  // };

  // const handleCreate = async () => {
  //   try {
  //     const billLabCaseId = billId;
  //     const billLabData = {
  //       amount: billingData.amount,
  //     };
  //     const response = await createbillLab(billLabCaseId, billLabData);
  //     if (response) {
  //       toast.success('Factura creada con éxito');
  //     }
  //   } catch (error) {
  //     console.error('Error creating billing data:', error);
  //   }
  // };

  if (loading) {
    return (
      <div className="flex justify-center items-center min-h-screen font-inter">
        <Button variant="text">
          <svg
            className="animate-spin h-5 w-5 mr-2 text-white" /* svg details */
          />
          Cargando...
        </Button>
      </div>
    );
  }

  return (
    <div className="casePage">
      <div className="flex flex-col gap-3 ">
        {user.role == 'SELLER_ROLE' && (
          <BillStepper BillLabCase={billingData} />
        )}

        {billingData.file?.url && (
          <Card className="py-4 px-2  gap-8 rounded-lg bg-white ">
            <CardContent className="w-full flex flex-col md:flex-row p-0">
              <div className="mx-auto">
                {billingData.file.mimetype.startsWith('image/') && (
                  <>
                    <img
                      src={billingData.file.url}
                      alt="Message Attachment"
                      className="w-[10rem] h-[10rem] md:w-1/2 md:h-auto mb-2 cursor-pointer"
                    />
                    <a
                      href={billingData.file.url}
                      target="_blank"
                      rel="noopener noreferrer"
                      className="text-blue-500 mt-2 inline-block"
                      download
                    >
                      <FaDownload className="inline mr-2" />
                      Descargar factura
                    </a>
                  </>
                )}

                {/* Document Files */}
                {billingData.file.mimetype.startsWith('application/') && (
                  <>
                    <iframe
                      src={billingData.file.url}
                      title="Document Viewer"
                      className="w-full h-auto mb-2"
                    />
                    {/* Download Button for Documents */}
                    <a
                      href={billingData.file.url}
                      download={getFileName(billingData.file.url)}
                      className="text-blue-500"
                    >
                      <FaDownload className="inline mr-2" />
                      Descargar documento
                    </a>
                  </>
                )}

                {outsourcing && (
                  <div className="bg-gray-100 p-4 rounded-lg shadow-md mb-6 w-full">
                    <div className="  text-sm text-gray-700 flex  flex-col md:flex-row gap-4 justify-between">
                      {/* Billing details */}
                      <div>
                        <p>
                          <strong>Nombre del laboratorio:</strong>{' '}
                          {outsourcing?.billLabCase?.seller?.labName || 'N/A'}
                        </p>
                        <p>
                          <strong>Nombre:</strong>{' '}
                          {outsourcing?.billLabCase?.seller?.name}{' '}
                          {outsourcing?.billLabCase?.seller?.lastname || 'N/A'}
                        </p>
                        <p>
                          <strong>Razón Social:</strong>{' '}
                          {outsourcing?.billLabCase?.seller?.razonSocial ||
                            'N/A'}
                        </p>
                        <p>
                          <strong>NIF:</strong>{' '}
                          {outsourcing?.billLabCase?.seller?.nif || 'N/A'}
                        </p>
                        <p>
                          <strong>Teléfono:</strong>{' '}
                          {outsourcing?.billLabCase?.seller?.phone || 'N/A'}
                        </p>
                        <p>
                          <strong>Monto a facturar por comisión:</strong> €{' '}
                          {outsourcing?.selectedLaboratorios[0]?.offer
                            ?.commission || 'N/A'}
                        </p>
                      </div>

                      <div>
                        {/* Address details */}
                        <h4 className="font-semibold mt-4">Dirección</h4>
                        <p>
                          {outsourcing?.billLabCase?.seller?.clinics[0]
                            ?.clinicstreet || 'N/A'}
                        </p>
                        <p>
                          {outsourcing?.billLabCase?.seller?.clinics[0]
                            ?.postalCode || 'N/A'}
                        </p>
                        <p>
                          {outsourcing?.billLabCase?.seller?.clinics[0]
                            ?.province || 'N/A'}
                        </p>
                        <p>
                          {outsourcing?.billLabCase?.seller?.clinics[0]?.city ||
                            'N/A'}
                        </p>
                      </div>
                    </div>
                  </div>
                )}
                {demand && (
                  <div className="bg-gray-100 p-4 rounded-lg shadow-md mb-6 w-full">
                    <div className="  text-sm text-gray-700 flex  flex-col md:flex-row gap-4 justify-between">
                      {/* Billing details */}
                      <div>
                        <p>
                          <strong>Nombre del laboratorio:</strong>{' '}
                          {demand?.billLabCase?.seller?.labName || 'N/A'}
                        </p>
                        <p>
                          <strong>Nombre:</strong>{' '}
                          {demand?.billLabCase?.seller?.name}{' '}
                          {demand?.billLabCase?.seller?.lastname || 'N/A'}
                        </p>
                        <p>
                          <strong>Razón Social:</strong>{' '}
                          {demand?.billLabCase?.seller?.razonSocial || 'N/A'}
                        </p>
                        <p>
                          <strong>NIF:</strong>{' '}
                          {demand?.billLabCase?.seller?.nif || 'N/A'}
                        </p>
                        <p>
                          <strong>Teléfono:</strong>{' '}
                          {demand?.billLabCase?.seller?.phone || 'N/A'}
                        </p>
                        <p>
                          <strong>Monto a facturar por comisión:</strong> €{' '}
                          {demand?.selectedLaboratorios[0]?.offer?.commission ||
                            'N/A'}
                        </p>
                      </div>

                      <div>
                        {/* Address details */}
                        <h4 className="font-semibold mt-4">Dirección</h4>
                        <p>
                          {demand?.billLabCase?.seller?.clinics[0]
                            ?.clinicstreet || 'N/A'}
                        </p>
                        <p>
                          {demand?.billLabCase?.seller?.clinics[0]
                            ?.postalCode || 'N/A'}
                        </p>
                        <p>
                          {demand?.billLabCase?.seller?.clinics[0]?.province ||
                            'N/A'}
                        </p>
                        <p>
                          {demand?.billLabCase?.seller?.clinics[0]?.city ||
                            'N/A'}
                        </p>
                      </div>
                    </div>
                  </div>
                )}
                <div>Monto {billingData.amount}</div>
              </div>
            </CardContent>
          </Card>
        )}
      </div>
      <div className="flex justify-end gap-2 mt-4">
        {user.role == 'ADMIN_ROLE' && (
          <>
            <Button variant="destructive" onClick={handleDelete}>
              Eliminar
            </Button>
          </>
        )}
      </div>
    </div>
  );
};

export default BillLapPage;
