import React, { useState } from 'react';
import { NavLink, useNavigate, Link } from 'react-router-dom';
import {
  User,
  Lock,
  Mail,
  Receipt,
  Store,
  Star,
  Users,
  AlertTriangle,
  Download,
} from 'lucide-react';
import { cn } from '@/lib/utils';
import { useUserStore } from '@/store/userStore';
import { unactiveUser } from '@/services/auth';
import { Button } from '@/components/ui/button';
import {
  Sidebar,
  SidebarContent,
  SidebarFooter,
  SidebarMenu,
  SidebarMenuItem,
  SidebarHeader,
} from '@/components/ui/sidebar';
import {
  AlertDialog,
  AlertDialogAction,
  AlertDialogCancel,
  AlertDialogContent,
  AlertDialogDescription,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogTitle,
  AlertDialogTrigger,
} from '@/components/ui/alert-dialog';
import { toast } from 'react-toastify';

const navItems = [
  { to: '/setting/myprofile', icon: User, label: 'Perfil' },
  { to: '/setting/security', icon: Lock, label: 'Seguridad' },
  { to: '/setting/notificationSettings', icon: Mail, label: 'Notificaciones' },
];

const buyerNavItems = [
  { to: '/setting/mybills', icon: Download, label: 'Facturas' },
];

const sellerNavItems = [
  { to: '/setting/jobtipe', icon: Store, label: 'Tipo de trabajo' },
  {
    to: '/setting/mybillslaborary',
    icon: Download,
    label: 'Facturas emitidas',
  },
  { to: '/setting/myLabbills', icon: Download, label: 'Facturas recibidas' },
  // {
  //   to: '/setting/mybillsoutsourcingBuyer',
  //   icon: Download,
  //   label: 'Facturas Labs vendedores',
  // },
  // {
  //   to: '/setting/mybillsoutsourcingSeller',
  //   icon: Download,
  //   label: 'Facturas Labs Pagina',
  // },
  { to: '/setting/myreviews', icon: Star, label: 'Mis reseñas' },
  { to: '/setting/myreferrals', icon: Users, label: 'Mis referidos' },
];
import LogoOklusion from '@/assets/Logo Oklusion.png';
function SidebarLink({ to, icon: Icon, label }) {
  return (
    <SidebarMenuItem>
      <NavLink
        to={to}
        className={({ isActive }) =>
          cn(
            'flex items-center gap-3 rounded-lg px-3 py-2 text-sm font-medium hover:underline',
            isActive ? 'bg-[#0224ab] text-white' : 'hover:bg-muted',
          )
        }
      >
        <Icon className="h-4 w-4" />
        {label}
      </NavLink>
    </SidebarMenuItem>
  );
}

export function AppSidebar() {
  const [isDeleting, setIsDeleting] = useState(false);
  const navigate = useNavigate();
  const { user, logout } = useUserStore();

  const handleDeleteUser = async () => {
    setIsDeleting(true);
    try {
      const response = await unactiveUser();
      if (response) {
        toast.success('Cuenta eliminada correctamente.');
        logout();
        navigate('/');
      }
    } catch (error) {
      toast.error(
        'No se pudo eliminar la cuenta. Por favor, inténtelo de nuevo.',
      );
    } finally {
      setIsDeleting(false);
    }
  };

  return (
    <Sidebar className="border-r bg-white text-black px-7">
      <SidebarContent className="p-4 md:p-2 bg-white">
        <SidebarHeader className="mx-auto">
          <div className="flex justify-start w-full md:w-auto  pb-2">
            <h1 className="text-xl font-bold">
              <Link
                to={user ? '/dashboard' : '/'}
                className="hover:text-gray-300"
              >
                <img
                  src={LogoOklusion}
                  alt="Logo Oklusion"
                  className="h-5 w-30"
                />
              </Link>
            </h1>
          </div>
        </SidebarHeader>
        <SidebarMenu>
          {navItems.map((item) => (
            <SidebarLink key={item.to} {...item} />
          ))}
          {user?.role === 'DENTIST_ROLE' &&
            buyerNavItems.map((item) => (
              <SidebarLink key={item.to} {...item} />
            ))}
          {user?.role === 'SELLER_ROLE' &&
            sellerNavItems.map((item) => (
              <SidebarLink key={item.to} {...item} />
            ))}
        </SidebarMenu>
      </SidebarContent>
      <SidebarFooter className="p-4 bg-white">
        <AlertDialog>
          <AlertDialogTrigger asChild>
            <Button
              variant="destructive"
              className="w-full"
              disabled={isDeleting}
            >
              <AlertTriangle className="mr-2 h-4 w-4" />
              {isDeleting ? 'Eliminando...' : 'Eliminar cuenta'}
            </Button>
          </AlertDialogTrigger>
          <AlertDialogContent>
            <AlertDialogHeader>
              <AlertDialogTitle>¿Estás seguro?</AlertDialogTitle>
              <AlertDialogDescription>
                Esta acción no se puede deshacer. Esto eliminará permanentemente
                tu cuenta y removerá tus datos de nuestros servidores.
              </AlertDialogDescription>
            </AlertDialogHeader>
            <AlertDialogFooter>
              <AlertDialogCancel>Cancelar</AlertDialogCancel>
              <AlertDialogAction
                onClick={handleDeleteUser}
                disabled={isDeleting}
              >
                {isDeleting ? 'Eliminando...' : 'Continuar'}
              </AlertDialogAction>
            </AlertDialogFooter>
          </AlertDialogContent>
        </AlertDialog>
      </SidebarFooter>
    </Sidebar>
  );
}
