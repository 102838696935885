import { useState } from 'react';
import { cn } from '@/lib/utils';
import { Card } from '@/components/ui/card';
import { BillLabCasestatusSteps } from '@/constants/caseType';

export default function BillStepper({ BillLabCase }) {
  const handleStatus = (status) => {
    switch (status) {
      case 'created':
        return 'Creado';
      case 'accepted':
        return 'Aceptado';
      case 'rejected':
        return 'Rechazado';
      case 'reSended':
        return 'Reenviado';
      case 'canceled':
        return 'Cancelado';
      default:
        return 'Creado';
    }
  };
  const currentStep = BillLabCasestatusSteps.indexOf(BillLabCase.status);

  const [activeStep, setActiveStep] = useState(currentStep);

  // const handleNext = () => {
  //   setActiveStep((prev) => Math.min(prev + 1, statusSteps.length));
  // };

  // const handlePrev = () => {
  //   setActiveStep((prev) => Math.max(prev - 1, 1));
  // };

  const getProgressWidth = () => {
    if (BillLabCasestatusSteps.length > 1) {
      const stepWidth =
        (activeStep / (BillLabCasestatusSteps.length - 1)) * 100;
      return `${stepWidth}%`;
    }
    return '100%';
  };
  return (
    <Card className="w-full p-4 h-[100px] gap-8 rounded-lg bg-white">
      {BillLabCase.status === 'created' && (
        <div className="relative flex justify-between mb-8">
          {BillLabCasestatusSteps.map((step, index) => (
            <div
              key={index}
              className="flex flex-col items-center relative z-10"
            >
              <div
                className={cn(
                  'w-10 h-10 rounded-full flex items-center justify-center text-sm  md:font-medium',
                  index <= activeStep
                    ? 'bg-[#0224ab] text-primary-foreground'
                    : 'bg-[#DCE3FF] text-secondary-foreground',
                )}
              >
                {index + 1}
              </div>
              <div className="mt-2 font-urbanist text-[10px] md:text-sm font-medium text-center text-muted-foreground max-w-[100px]">
                {handleStatus(step)}
              </div>
            </div>
          ))}
          <div className="absolute top-5 left-0 right-0 h-1 bg-secondary -translate-y-1/2" />
          <div
            className="absolute top-5 left-0 h-1 bg-[#0224ab] -translate-y-1/2 transition-all duration-300 ease-in-out"
            style={{ width: getProgressWidth() }}
          />
        </div>
      )}
      {BillLabCase.status === 'accepted' && (
        <div className="relative flex justify-between mb-8">
          {BillLabCasestatusSteps.map((step, index) => (
            <div
              key={index}
              className="flex flex-col items-center relative z-10"
            >
              <div
                className={cn(
                  'w-10 h-10 rounded-full flex items-center justify-center text-sm  md:font-medium',
                  index <= activeStep
                    ? 'bg-[#0224ab] text-primary-foreground'
                    : 'bg-[#DCE3FF] text-secondary-foreground',
                )}
              >
                {index + 1}
              </div>
              <div className="mt-2 font-urbanist text-[10px] md:text-sm font-medium text-center text-muted-foreground max-w-[100px]">
                {handleStatus(step)}
              </div>
            </div>
          ))}
          <div className="absolute top-5 left-0 right-0 h-1 bg-secondary -translate-y-1/2" />
          <div
            className="absolute top-5 left-0 h-1 bg-[#0224ab] -translate-y-1/2 transition-all duration-300 ease-in-out"
            style={{ width: getProgressWidth() }}
          />
        </div>
      )}

      {BillLabCase.status === 'reSended' && (
        <div className="flex flex-col items-center relative z-10">
          <div
            className={cn(
              'w-10 h-10 rounded-full flex items-center justify-center text-sm font-medium',
              'bg-[#0224ab] text-primary-foreground',
            )}
          >
            1
          </div>
          <div className="mt-2 font-urbanist text-sm font-medium text-center text-muted-foreground max-w-[100px]">
            En revisión
          </div>
        </div>
      )}
      {BillLabCase.status === 'rejected' && (
        <div className="flex flex-col items-center relative z-10">
          <div
            className={cn(
              'w-10 h-10 rounded-full flex items-center justify-center text-sm font-medium',
              'bg-[#ab2c02] text-primary-foreground',
            )}
          >
            1
          </div>
          <div className="mt-2 font-urbanist text-sm font-medium text-center text-muted-foreground max-w-[100px]">
            Rechazado
          </div>
        </div>
      )}
    </Card>
  );
}
