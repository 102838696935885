import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { FaCheckCircle } from 'react-icons/fa';
import { updatePassword } from '@/services/auth';
import { toast } from 'react-toastify';

const Security = () => {
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [error, setError] = useState('');
  const [loading, setLoading] = useState(false);
  const [passwordCriteria, setPasswordCriteria] = useState({
    length: false,
  });
  const navigate = useNavigate();

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (newPassword !== confirmPassword) {
      setError('Las contraseñas no coinciden');
      return;
    }
    try {
      setLoading(true);
      const response = await updatePassword({ password, newPassword });
      if (response) {
        setError('');
        setPassword('');
        setConfirmPassword('');
        toast.success('Contraseña cambiada con éxito');
      }
    } catch (error) {
      if (error.response && error.response.status === 400) {
        setError('Contraseña actual incorrecta');
      }
    } finally {
      setLoading(false);
    }
  };

  const handleForgotPassword = () => {
    navigate('/forgotpassword');
  };

  const handleChange = (e) => {
    const { name, value } = e.target;

    if (name === 'newPassword') {
      setNewPassword(value);
      setPasswordCriteria({
        length: value.length >= 8,
      });
    } else if (name === 'confirmPassword') {
      setConfirmPassword(value);
    } else if (name === 'password') {
      setPassword(value);
    }
  };

  return (
    <div className="bg-white border-2 p-8 rounded-lg">
      <form onSubmit={handleSubmit}>
        <h3 className="text-lg mb-4">Actualizar contraseña</h3>

        <div className="flex flex-col gap-4 md:flex-row md:gap-4">
          <div className="flex-1">
            <label
              htmlFor="password"
              className="block text-sm font-medium text-gray-700"
            >
              Contraseña actual
            </label>
            <input
              type="password"
              id="password"
              name="password"
              value={password}
              onChange={handleChange}
              className="input-styles w-full"
              placeholder="Contraseña actual"
              required
            />
          </div>

          <div className="flex-1">
            <label
              htmlFor="newPassword"
              className="block text-sm font-medium text-gray-700"
            >
              Nueva contraseña
            </label>
            <input
              type="password"
              id="newPassword"
              name="newPassword"
              value={newPassword}
              onChange={handleChange}
              className="input-styles w-full"
              placeholder="Nueva contraseña"
              required
            />
          </div>

          <div className="flex-1">
            <label
              htmlFor="confirmPassword"
              className="block text-sm font-medium text-gray-700"
            >
              Confirmar contraseña
            </label>
            <input
              type="password"
              id="confirmPassword"
              name="confirmPassword"
              value={confirmPassword}
              onChange={handleChange}
              className="input-styles w-full"
              placeholder="Confirmar contraseña"
              required
            />
          </div>
        </div>

        <div className="mt-2 grid grid-cols-2 gap-2 w-full md:w-1/2">
          <div className="flex items-start">
            <FaCheckCircle
              className={`mr-2 ${
                passwordCriteria.length ? 'text-green-500' : 'text-gray-400'
              }`}
            />
            <span
              className={`text-sm ${
                passwordCriteria.length ? 'text-green-500' : 'text-gray-700'
              }`}
            >
              Mínimo 8 caracteres
            </span>
          </div>
        </div>

        <div className="mt-4 text-left">
          <button
            type="button"
            onClick={handleForgotPassword}
            className="text-sm text-black hover:text-blue-500 underline focus:outline-none"
          >
            Olvidé mi contraseña
          </button>
        </div>
        {error && <p className="text-red-500 text-sm mt-2">{error}</p>}

        <div className="mt-4 flex justify-end">
          <button
            type="submit"
            className="btn-primary px-10"
            disabled={loading}
          >
            {loading ? 'Cargando' : 'Actualizar contraseña'}
          </button>
        </div>
      </form>
    </div>
  );
};

export default Security;
