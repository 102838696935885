import React from 'react';
import { Input } from '@/components/ui/input';
import { Label } from '@/components/ui/label';

const CreditCardForm = ({ cardData, setCardData }) => {
  return (
    <div className="mt-4 space-y-4">
      <div>
        <Label htmlFor="cardNumber" className="text-sm font-medium">
          Número de tarjeta
        </Label>
        <Input
          type="text"
          id="cardNumber"
          value={cardData.cardNumber}
          onChange={(e) =>
            setCardData({ ...cardData, cardNumber: e.target.value })
          }
          className="mt-1"
        />
      </div>

      <div className="flex flex-row gap-4">
        <div className="w-1/2">
          <Label htmlFor="expiryDate" className="text-sm font-medium">
            Fecha de expiración
          </Label>
          <Input
            type="text"
            id="expiryDate"
            placeholder="MM/YY"
            maxLength={4}
            value={cardData.expiryDate}
            onChange={(e) =>
              setCardData({ ...cardData, expiryDate: e.target.value })
            }
            className="mt-1"
          />
        </div>

        <div className="w-1/2">
          <Label htmlFor="cvv" className="text-sm font-medium">
            CVV
          </Label>
          <Input
            type="text"
            id="cvv"
            maxLength={3}
            value={cardData.cvv}
            onChange={(e) => setCardData({ ...cardData, cvv: e.target.value })}
            className="mt-1"
          />
        </div>
      </div>
    </div>
  );
};

export default CreditCardForm;
