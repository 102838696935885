import React, { useState, useEffect } from 'react';
import { useParams, Link } from 'react-router-dom';
import { getGuideById } from '@/services/guide';
import useUserStore from '@/store/userStore';

const GuideContent = () => {
  const { user } = useUserStore();
  const { guideId } = useParams();
  const [guide, setGuide] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  const fetchGuide = async () => {
    try {
      setLoading(true);
      const { guide } = await getGuideById(guideId);
      setGuide(guide);
    } catch (err) {
      console.error('Error fetching guide:', err);
      setError('Hubo un problema al cargar la guía.');
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchGuide();
  }, [guideId]);

  if (loading) {
    return <p className="text-center text-lg text-gray-500">Cargando...</p>;
  }

  if (error) {
    return <p className="text-center text-lg text-red-500">{error}</p>;
  }

  if (!guide) {
    return (
      <p className="text-center text-lg text-gray-500">
        No se encontró la guía.
      </p>
    );
  }

  return (
    <div className="max-w-4xl mx-auto p-6 bg-white rounded-lg shadow-md">
      <h1 className="text-3xl font-bold mb-6 text-gray-800">{guide.title}</h1>
      <div
        className="prose max-w-none"
        dangerouslySetInnerHTML={{ __html: guide.content }}
      />
      {/* Render the Edit button if the user has the ADMIN_ROLE */}
      {user?.role === 'ADMIN_ROLE' && (
        <div className="mt-6">
          <Link
            to={`/guides/edit/${guideId}`}
            className="px-4 py-2 bg-blue-600 text-white rounded hover:bg-blue-700"
          >
            Editar Guía
          </Link>
        </div>
      )}
    </div>
  );
};

export default GuideContent;
