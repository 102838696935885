import React, { useState, useEffect } from 'react';
import {
  ortodonciaList,
  ortoType,
  AlignersList,
  tipoList,
} from '../../constants/caseType';
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from '@/components/ui/select';
import { Label } from '@/components/ui/label';

const OrtodonciaMenu = ({
  ortodonciaReq,
  setOrtodonciaReq,
  ortodonciaReqTemp,
}) => {
  const [selectedOption, setSelectedOption] = useState(
    ortodonciaReqTemp?.selectedOption || '',
  );
  const [type, setType] = useState('');
  const [treatment, setTreatment] = useState('');
  const [tipo, setTipo] = useState('');
  const [error, setError] = useState('');

  useEffect(() => {
    if (selectedOption) {
      let valid = true;
      let subSelection = '';

      switch (selectedOption) {
        case 'OrthodonticAppliances':
          if (type) subSelection = type;
          else valid = false;
          break;
        case 'Aligners':
          if (treatment) subSelection = treatment;
          else valid = false;
          break;
        case 'Retainers':
          if (tipo) subSelection = tipo;
          else valid = false;
          break;
        default:
          valid = false;
      }

      if (valid) {
        setOrtodonciaReq({ selectedOption, subSelection });
        setError('');
      } else {
        setError('Por favor, complete todos los campos requeridos.');
        setOrtodonciaReq(null);
      }
    } else {
      setOrtodonciaReq(null);
    }
  }, [selectedOption, type, treatment, tipo, setOrtodonciaReq]);

  return (
    <div className="mx-auto my-2">
      {selectedOption === 'OrthodonticAppliances' && (
        <div className="form-grid">
          <Label htmlFor="type">Tipo</Label>
          <Select id="type" value={type} onValueChange={setType} required>
            <SelectTrigger className="w-full px-2  h-10 border border-[#BDCAFE] rounded-md ">
              <SelectValue placeholder="Seleccione una opción" />
            </SelectTrigger>
            <SelectContent>
              {ortoType.map((option) => (
                <SelectItem key={option.value} value={option.value}>
                  {option.name}
                </SelectItem>
              ))}
            </SelectContent>
          </Select>
        </div>
      )}

      {selectedOption === 'Aligners' && (
        <div className="form-grid">
          <Label htmlFor="treatment">Tratamiento</Label>
          <Select
            id="treatment"
            value={treatment}
            onValueChange={setTreatment}
            required
          >
            <SelectTrigger className="w-full px-2  h-10 border border-[#BDCAFE] rounded-md ">
              <SelectValue placeholder="Seleccione una opción" />
            </SelectTrigger>

            <SelectContent>
              {AlignersList.map((option) => (
                <SelectItem key={option.value} value={option.value}>
                  {option.name}
                </SelectItem>
              ))}
            </SelectContent>
          </Select>
        </div>
      )}

      {selectedOption === 'Retainers' && (
        <div className="form-grid">
          <Label htmlFor="tipo">Tipo</Label>
          <Select id="tipo" value={tipo} onValueChange={setTipo} required>
            <SelectTrigger className="w-full px-2  h-10 border border-[#BDCAFE] rounded-md ">
              <SelectValue placeholder="Seleccione una opción" />
            </SelectTrigger>
            <SelectContent>
              {tipoList.map((option) => (
                <SelectItem key={option.value} value={option.value}>
                  {option.name}
                </SelectItem>
              ))}
            </SelectContent>
          </Select>
        </div>
      )}

      {error && <p className="text-red-500 text-center">{error}</p>}
    </div>
  );
};

export default OrtodonciaMenu;
