import { Routes, Route } from 'react-router-dom';
import GuideLayout from '@/components/Layouts/GuideLayout';
import { AdminGuard } from '@/guards/AdminGuard';
import Guide from './guide';
import GuideEditor from './guideEditor';
import GuideCreate from './GuideCreate';
import GuideCategoryCreate from './guideCategoryCreate';
import GuideHome from './Home';

const GuideRoutes = () => {
  return (
    <Routes>
      <Route element={<GuideLayout />}>
        <Route index element={<GuideHome />} />
        <Route path="/:guideId" element={<Guide />} />
        <Route element={<AdminGuard />}>
          <Route path="/category/create" element={<GuideCategoryCreate />} />
          <Route path="/create" element={<GuideCreate />} />
          <Route path="/edit/:guideId" element={<GuideEditor />} />
        </Route>
      </Route>
    </Routes>
  );
};

export default GuideRoutes;
