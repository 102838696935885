import { useSearchParams } from 'react-router-dom';
import TableCases from '@/components/Tables/TableCases';
import { useFetchDemands } from '@/hooks/useDemand';
import OrderCard from '@/components/Cards/OrderCard';
import Dashboard3 from '@/assets/Dashboard3.png';
const CasesPage = () => {
  const [searchParams, setSearchParams] = useSearchParams();

  const { demands, total, totalDemands } = useFetchDemands(searchParams);

  return (
    <div className="">
      <section>
        <OrderCard
          title="Cantidad de pedidos"
          number={totalDemands}
          img={Dashboard3}
        />
        <TableCases
          demands={demands}
          seller={false}
          total={total}
          setSearchParams={setSearchParams}
          searchParams={searchParams}
        />
      </section>
    </div>
  );
};

export default CasesPage;
