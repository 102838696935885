import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { getDocs } from '@/services/doc';

const Sidebar = () => {
  const [docs, setDocs] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchDocs = async () => {
      setLoading(true);
      setError(null); // Reset error state on new fetch
      try {
        const params = { skip: 0, limit: 50 };
        const queryString = new URLSearchParams(params).toString();
        const { docs } = await getDocs({ queryString });
        setDocs(docs);
      } catch (error) {
        console.error('Error fetching docs:', error);
        setError('Error al cargar las docs. Intente nuevamente más tarde.');
      } finally {
        setLoading(false);
      }
    };

    fetchDocs();
  }, []);

  return (
    <div className="flex flex-col h-full min-h-screen w-64 bg-gray-800 text-white p-4 overflow-y-auto">
      <div className="mb-4"></div>
      {loading ? (
        <p className="text-center">Cargando guías...</p>
      ) : error ? (
        <p className="text-red-400 text-center">{error}</p>
      ) : (
        docs.map((doc) => (
          <Link
            key={doc.id}
            to={`/doc/${doc.slug}`}
            className="block py-2 px-4 rounded hover:bg-gray-700"
          >
            {doc.title}
          </Link>
        ))
      )}
    </div>
  );
};

export default Sidebar;
