import { useEffect, useState, useCallback } from 'react';
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';
import {
  getbillLab,
  getbillLabAsSeller,
  getbillLabs,
  getbillLabsAsAdmin,
  deletebillLab,
} from '@/services/billLab';
import useDemandStore from '@/store/DemandStore';
import useOutsourcingStore from '@/store/OutsourcingStore';
import { useUserStore } from '@/store/userStore';

export const useFetchBillLab = (billId) => {
  const { user } = useUserStore();
  const { demand, setDemand } = useDemandStore();
  const { outsourcing, setOutsourcing } = useOutsourcingStore();
  const [billingData, setBillingData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  const fetchBillLab = useCallback(async () => {
    if (!billId) return;

    setLoading(true);
    setError(null);

    try {
      const fetcher = user.role === 'SELLER_ROLE' ? getbillLab : getbillLab;
      const { demand, billlab, outsourcing } = await fetcher(billId);
      setBillingData(billlab);
      setDemand(demand || null);
      setOutsourcing(outsourcing || null);
    } catch (error) {
      setError(error.message || 'Failed to fetch BillLab');
      console.error('Failed to fetch BillLab:', error);
    } finally {
      setLoading(false);
    }
  }, [billId, user.role, setDemand]);

  useEffect(() => {
    fetchBillLab();
  }, [fetchBillLab]);

  return { demand, outsourcing, billingData, loading, error, fetchBillLab };
};

export const useFetchBillLabs = (searchParams) => {
  const { user } = useUserStore();
  const [billLabs, setBillLabs] = useState([]);
  const [totalBillLabs, setTotalBillLabs] = useState(0);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  const fetchBillLabs = useCallback(async () => {
    setLoading(true);
    setError(null); // Reset error state

    try {
      const params = Object.fromEntries(searchParams || []);
      const queryString = new URLSearchParams(params).toString();
      let fetcher;

      if (user.role === 'SELLER_ROLE') {
        fetcher = getbillLabAsSeller;
      } else if (user.role === 'DENTIST_ROLE') {
        fetcher = getbillLabs;
      } else {
        fetcher = getbillLabsAsAdmin;
      }
      const data = await fetcher({ queryString });
      setBillLabs(data.billLabs);
      setTotalBillLabs(data.total);
    } catch (error) {
      setError(error.message || 'Failed to fetch demands');
      console.error('Failed to fetch demands:', error);
    } finally {
      setLoading(false);
    }
  }, [searchParams, user.role]);

  useEffect(() => {
    fetchBillLabs();
  }, [fetchBillLabs]);

  return { billLabs, loading, error, totalBillLabs, fetchBillLabs };
};

export const useDeleteBillLab = (billId) => {
  const navigate = useNavigate();
  const handleDelete = async () => {
    try {
      //confirm to prossed
      if (!window.confirm('¿Estás seguro de eliminar esta factura?')) {
        return;
      }
      const response = await deletebillLab(billId);
      if (response) {
        toast.success('Factura eliminada con éxito');
        navigate('/admin/billLabs');
      }
    } catch (error) {
      console.error('Error deleting billing data:', error);
    }
  };
  return { handleDelete };
};
