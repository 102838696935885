import { Routes, Route } from 'react-router-dom';
import DocLayout from '@/components/Layouts/DocLayout';
import { AdminGuard } from '@/guards/AdminGuard';
import Doc from './doc';
import DocEditor from './docEditor';
import DocCreate from './docCreate';
import DocHome from './Home';

const DocRoutes = () => {
  return (
    <Routes>
      <Route path="/:docId" element={<Doc />} />
      <Route element={<DocLayout />}>
        <Route index element={<DocHome />} />
        <Route element={<AdminGuard />}>
          <Route path="/create" element={<DocCreate />} />
          <Route path="/edit/:docId" element={<DocEditor />} />
        </Route>
      </Route>
    </Routes>
  );
};

export default DocRoutes;
