import { useEffect, useState, useCallback } from 'react';
import {
  getbillLabCasesAsSeller,
  getbillLabCases,
  getbillLabCasesAsAdmin,
} from '@/services/billLabCase';
// import useDemandStore from '@/store/DemandStore';
import { useUserStore } from '@/store/userStore';

// export const useFetchDemand = (demandId) => {
//   const { user } = useUserStore();
//   const { demand, setDemand } = useDemandStore();
//   const [loadingDemand, setLoadingDemand] = useState(true);
//   const [error, setError] = useState(null);

//   const fetchDemand = useCallback(async () => {
//     if (!demandId) return;

//     setLoadingDemand(true);
//     setError(null);

//     try {
//       const fetcher =
//         user.role === 'SELLER_ROLE' ? getDemandByIdAsSeller : getDemandById;
//       const { demand: demandData } = await fetcher(demandId);

//       if (demandData) {
//         setDemand(demandData);
//       } else {
//         throw new Error('Demand not found');
//       }
//     } catch (error) {
//       setError(error.message || 'Failed to fetch demand');
//       console.error('Failed to fetch demand:', error);
//     } finally {
//       setLoadingDemand(false);
//     }
//   }, [demandId, user.role, setDemand]);

//   useEffect(() => {
//     fetchDemand();
//   }, [fetchDemand]);

//   return { demand, loadingDemand, error, fetchDemand };
// };

// Hook for fetching multiple demands
export const useFetchBillLabCases = (searchParams) => {
  const { user } = useUserStore();
  const [billLabCases, setBillLabCases] = useState([]);
  const [totalBillLabCases, setTotalBillLabCases] = useState(0);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  const fetchBillLabCases = useCallback(async () => {
    setLoading(true);
    setError(null); // Reset error state

    try {
      const params = Object.fromEntries(searchParams || []);
      const queryString = new URLSearchParams(params).toString();
      let fetcher;

      if (user.role === 'SELLER_ROLE') {
        fetcher = getbillLabCasesAsSeller;
      } else if (user.role === 'DENTIST_ROLE') {
        fetcher = getbillLabCases;
      } else {
        fetcher = getbillLabCasesAsAdmin;
      }
      const data = await fetcher({ queryString });
      setBillLabCases(data.billlabcases);
      setTotalBillLabCases(data.total);
    } catch (error) {
      setError(error.message || 'Failed to fetch demands');
      console.error('Failed to fetch demands:', error);
    } finally {
      setLoading(false);
    }
  }, [searchParams, user.role]);

  useEffect(() => {
    fetchBillLabCases();
  }, [fetchBillLabCases]);

  return { billLabCases, loading, error, totalBillLabCases, fetchBillLabCases };
};
