import { Routes, Route } from 'react-router-dom';
import ProfileLayout from '@/components/Layouts/ProfileLayout';
import CreditStorePage from './CreditStorePage.jsx';
import JobTipe from './JobTipe.jsx';
import MyBills from './MyBills.jsx';
import MyLabBills from './MyLabBills.jsx';
import MyBillsLaborary from './MyBillsLaborary.jsx';
// import MyBillsOutsourcingBuyer from './MyBillsOutsourcingBuyer.jsx';
import MyProfile from './MyProfile.jsx';
import MyReferrals from './MyReferrals.jsx';
import MyReviews from './MyReviews.jsx';
import Notifications from './Notifications.jsx';
import NotificationsSettings from './NotificationsSettings.jsx';
import PaymentSettings from './PaymentSettings.jsx';
import RegisterPost from './RegisterPost.jsx';
import Security from './Security.jsx';
import { SellerGuard } from '@/guards/SellerGuard.jsx';

const SettingRoutes = () => {
  return (
    <Routes>
      <Route element={<ProfileLayout />}>
        <Route path="myprofile" element={<MyProfile />} />
        <Route path="security" element={<Security />} />
        <Route
          path="notificationSettings"
          element={<NotificationsSettings />}
        />
        <Route path="notifications" element={<Notifications />} />

        <Route element={<SellerGuard />}>
          <Route path="creditstore" element={<CreditStorePage />} />
          <Route path="paymentmethods" element={<PaymentSettings />} />
          <Route path="jobtipe" element={<JobTipe />} />
          <Route path="myreviews" element={<MyReviews />} />
          <Route path="myreferrals" element={<MyReferrals />} />
          <Route path="myLabbills" element={<MyLabBills />} />
          <Route path="mybillslaborary" element={<MyBillsLaborary />} />
        </Route>
        <Route path="mybills" element={<MyBills />} />
      </Route>
      <Route path="activeProfile" element={<RegisterPost />} />
    </Routes>
  );
};

export default SettingRoutes;
