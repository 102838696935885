import React from 'react';

const CondicionesUso = () => {
  return (
    <div className="px-12 py-8 sm:px-4 text-justify bg-white text-gray-900 leading-relaxed max-w-4xl mx-auto">
      <h1 className="text-3xl font-bold mb-6 text-center">
        Condiciones de Uso
      </h1>

      <h2 className="text-2xl font-semibold mt-8 mb-4">1. Introducción</h2>
      <p className="mb-6 indent-8">
        Las presentes condiciones de uso regulan el acceso y la utilización de
        la plataforma Oklusion (en adelante, “la Plataforma”), un marketplace
        gestionado por Oklusion S.L. Al acceder y utilizar la Plataforma,
        aceptas cumplir con estas condiciones de uso.
      </p>

      <h2 className="text-2xl font-semibold mt-8 mb-4">
        2. Información General
      </h2>
      <ul className="list-disc ml-8 mb-6">
        <li>
          <strong>Titular:</strong> Oklusion S.L.
        </li>
        <li>
          <strong>NIF:</strong> B12345678
        </li>
        <li>
          <strong>Domicilio Social:</strong> Calle Ejemplo, 123, 08001
          Barcelona, España
        </li>
        <li>
          <strong>Correo Electrónico:</strong> info@oklusion.com
        </li>
        <li>
          <strong>Teléfono:</strong> +34 123 456 789
        </li>
      </ul>

      <h2 className="text-2xl font-semibold mt-8 mb-4">
        3. Acceso y Uso de la Plataforma
      </h2>
      <p className="mb-6 indent-8">
        El acceso a la Plataforma es gratuito, salvo en lo relativo al coste de
        la conexión a través de la red de telecomunicaciones suministrada por el
        proveedor de acceso contratado por los usuarios. El uso de ciertos
        servicios puede estar sujeto a condiciones particulares que, según el
        caso, sustituyen, completan y/o modifican las presentes condiciones de
        uso.
      </p>

      <h2 className="text-2xl font-semibold mt-8 mb-4">
        4. Registro de Usuarios
      </h2>
      <p className="mb-6 indent-8">
        Para acceder a ciertos servicios, es necesario registrarse en la
        Plataforma. El usuario se compromete a proporcionar información veraz y
        actualizada. El usuario es responsable de mantener la confidencialidad
        de su cuenta y contraseña, así como de todas las actividades que se
        realicen bajo su cuenta.
      </p>

      <h2 className="text-2xl font-semibold mt-8 mb-4">5. Uso del Servicio</h2>
      <p className="mb-6 indent-8">
        El usuario se compromete a utilizar la Plataforma de conformidad con la
        ley, la moral, el orden público y las presentes condiciones de uso. En
        particular, se compromete a no utilizar la Plataforma para:
      </p>
      <ul className="list-disc ml-8 mb-6">
        <li>
          Realizar actividades ilícitas, ilegales o contrarias a la buena fe y
          al orden público.
        </li>
        <li>
          Difundir contenidos o propaganda de carácter racista, xenófobo,
          pornográfico, etc.
        </li>
        <li>
          Provocar daños en los sistemas físicos y lógicos de Oklusion S.L.
        </li>
        <li>Introducir o difundir virus informáticos.</li>
      </ul>

      <h2 className="text-2xl font-semibold mt-8 mb-4">
        6. Condiciones de Venta
      </h2>
      <p className="mb-6 indent-8">
        La Plataforma cobra una comisión por cada venta realizada. Los pagos se
        gestionan a través de Stripe y los envíos se coordinan mediante
        Sendcloud. Las condiciones específicas de venta se detallan en las
        condiciones particulares de cada transacción.
      </p>

      <h2 className="text-2xl font-semibold mt-8 mb-4">
        7. Protección de Datos
      </h2>
      <p className="mb-6 indent-8">
        Oklusion S.L. cumple con la normativa vigente en materia de protección
        de datos personales. Para más información, consulta nuestra Política de
        Privacidad.
      </p>

      <h2 className="text-2xl font-semibold mt-8 mb-4">
        8. Propiedad Intelectual
      </h2>
      <p className="mb-6 indent-8">
        Todos los contenidos de la Plataforma son propiedad de Oklusion S.L. o
        de sus licenciantes, protegidos por las leyes de propiedad intelectual.
        Está prohibida la reproducción o distribución sin autorización.
      </p>

      <h2 className="text-2xl font-semibold mt-8 mb-4">
        9. Limitación de Responsabilidad
      </h2>
      <p className="mb-6 indent-8">
        Oklusion S.L. no se hace responsable de los daños que pudieran derivarse
        del uso de la Plataforma, incluyendo errores u omisiones en los
        contenidos.
      </p>

      <h2 className="text-2xl font-semibold mt-8 mb-4">10. Modificaciones</h2>
      <p className="mb-6 indent-8">
        Oklusion S.L. se reserva el derecho de efectuar sin previo aviso las
        modificaciones que considere oportunas en la Plataforma.
      </p>

      <h2 className="text-2xl font-semibold mt-8 mb-4">
        11. Legislación Aplicable y Jurisdicción
      </h2>
      <p className="mb-6 indent-8">
        La relación entre Oklusion S.L. y el usuario se regirá por la normativa
        española vigente y cualquier controversia se someterá a los juzgados y
        tribunales de la ciudad de Barcelona.
      </p>
    </div>
  );
};

export default CondicionesUso;
