import React from 'react';
import { protesisList, ortodonciaList, otrosList } from '@/constants/caseType';
import { Card } from '@/components/ui/card';
import { Label } from '@/components/ui/label';
import { Table, TableBody, TableCell, TableRow } from '@/components/ui/table';

const CaseTypeSelectionPage = ({
  setCaseType,
  setPage,
  setSelectedImplant,
  setSelectedProduct,
  setOrtodonciaReq,
  setSearchParams,
}) => {
  return (
    <div className="bg-white p-4 md:p-8 h-full md:h-svh">
      <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-6 pt-10">
        <Card className="flex flex-col p-4 bg-[#355dfc] shadow-md rounded-lg hover:shadow-lg transition-shadow duration-300">
          <Label
            variant="h5"
            className="text-center mb-4 text-lg md:text-xl font-bold text-white "
          >
            Prótesis
          </Label>
          <div className="flex-grow ">
            <Table className="w-full  bg-white rounded-md">
              <TableBody>
                {protesisList.map((protesis, index) => (
                  <TableRow key={index}>
                    <TableCell className=" border-transparent rounded-md border  py-3 px-1  md:px-3 text-center  hover:bg-gray-100">
                      <Label
                        className="text-xs md:text-base text-[#1e50d9] hover:bg-blue-50 hover:text-blue-900 truncate w-full text-left"
                        onClick={() => {
                          setCaseType('protesis');
                          setPage(1);
                          setSelectedImplant({
                            selectedOption: protesis.selectedOption,
                          });
                          setSearchParams({
                            caseType: 'protesis',
                          });
                          setOrtodonciaReq(null);
                          setSelectedProduct(null);
                        }}
                      >
                        {protesis.name}
                      </Label>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </div>
        </Card>

        {/* Ortodoncia Section */}
        <Card className="flex flex-col p-4 bg-[#c29eff] shadow-md rounded-lg hover:shadow-lg transition-shadow duration-300">
          <Label
            variant="h5"
            className="text-center mb-4 text-lg md:text-xl font-bold "
          >
            Ortodoncia
          </Label>
          <div className="flex-grow">
            <Table className="w-full  bg-white rounded-md">
              <TableBody>
                {ortodonciaList.map((ortodoncia, index) => (
                  <TableRow key={index}>
                    <TableCell className=" border-transparent rounded-md border  py-3 px-1  md:px-3 text-center  hover:bg-gray-100">
                      <Label
                        className="text-sm md:text-base text-[#1c1c1c] hover:bg-green-50 hover:text-green-900 truncate w-full text-left"
                        style={{
                          overflow: 'hidden',
                          textOverflow: 'ellipsis',
                          whiteSpace: 'nowrap',
                        }}
                        onClick={() => {
                          setCaseType('ortodoncia');
                          setPage(1);
                          setOrtodonciaReq({
                            selectedOption: ortodoncia.selectedOption,
                          });
                          setSearchParams({
                            caseType: 'ortodoncia',
                          });
                          setSelectedProduct(null);
                          setSelectedImplant(null);
                        }}
                      >
                        {ortodoncia.name}
                      </Label>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </div>
        </Card>

        {/* Otros Section */}
        <Card className="flex flex-col p-4 bg-[#f7ed8d] shadow-md rounded-lg hover:shadow-lg transition-shadow duration-300">
          <Label
            variant="h5"
            className="text-center mb-4 text-lg md:text-xl font-  bold text-yellow-800"
          >
            Otros
          </Label>
          <div className="flex-grow">
            <Table className="w-full  bg-white rounded-md">
              <TableBody>
                {otrosList.map((otros, index) => (
                  <TableRow key={index}>
                    <TableCell className=" border-transparent rounded-md border  py-3 px-1  md:px-3 text-center  hover:bg-gray-100">
                      <Label
                        className="text-sm md:text-base text-[#a16006] hover:bg-yellow-50 hover:text-yellow-900 truncate w-full text-left"
                        style={{
                          overflow: 'hidden',
                          textOverflow: 'ellipsis',
                          whiteSpace: 'nowrap',
                        }}
                        onClick={() => {
                          setCaseType('productos');
                          setPage(1);
                          setSelectedProduct(otros.value);
                          setSearchParams({
                            caseType: 'productos',
                          });
                          setOrtodonciaReq(null);
                          setSelectedImplant(null);
                        }}
                      >
                        {otros.name}
                      </Label>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </div>
        </Card>
      </div>
    </div>
  );
};

export default CaseTypeSelectionPage;
