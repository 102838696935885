import React, { useState, useEffect } from 'react';
import TeethShowblue from '../TeethShowblue';
import TeethSel from '../TeethSel';
import { CardContent } from '@/components/ui/card';
import { useDemandStore } from '@/store/DemandStore';
import useUserStore from '@/store/userStore';
import { ScrollArea } from '@/components/ui/scroll-area';
import { Label } from '@/components/ui/label';
import { useUpdateDemand } from '@/hooks/useDemand';

const TeethDemandCard = () => {
  const { user } = useUserStore();
  const { demand } = useDemandStore(); // Getting demand from the store
  const [caseName, setCaseName] = useState(demand.caseName);
  const [selectedTeeth, setSelectedTeeth] = useState(
    demand.selectedTeeth || [],
  );
  const [description, setDescription] = useState(demand.description);

  const { loading, updateDemand } = useUpdateDemand();

  useEffect(() => {
    setSelectedTeeth(demand.selectedTeeth);
    setDescription(demand.description);
    setCaseName(demand.caseName);
  }, [demand]);

  const handleUpdateDemand = async () => {
    try {
      await updateDemand(demand._id, { selectedTeeth, description, caseName });
      window.location.reload();
    } catch (error) {
      console.error('Error updating demand:', error);
    }
  };

  if (!demand) {
    return <div>Loading...</div>;
  }

  return (
    <div className="border-none">
      <CardContent>
        {demand.selectedTeeth && (
          <div>
            {user.role === 'ADMIN_ROLE' ? (
              <TeethSel
                selectedTeeth={selectedTeeth || []}
                setSelectedTeeth={setSelectedTeeth}
              />
            ) : (
              <TeethShowblue
                selectedTeeth={selectedTeeth || []}
                setSelectedTeeth={setSelectedTeeth}
              />
            )}
          </div>
        )}

        {user.role === 'ADMIN_ROLE' && (
          <div className="max-w-lg mt-4">
            <Label
              variant="subtitle1"
              className="mb-2 font-bold text-blue-gray-800"
            >
              Nombre del caso
            </Label>
            <input
              type="text"
              value={caseName}
              onChange={(e) => setCaseName(e.target.value)}
              className="border border-gray-300 rounded-md p-2 w-full"
              placeholder="Enter case name"
            />

            <Label
              variant="subtitle1"
              className="mb-2 font-bold text-blue-gray-800"
            >
              Descripción
            </Label>

            <textarea
              value={description}
              onChange={(e) => setDescription(e.target.value)}
              className="border border-gray-300 rounded-md p-2 w-full"
              placeholder="Enter description"
              rows={4}
            />
            <button
              onClick={handleUpdateDemand}
              className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded mt-4"
              disabled={loading}
            >
              {loading ? 'Cargando...' : 'Actualizar'}
            </button>
          </div>
        )}
      </CardContent>
    </div>
  );
};

export default TeethDemandCard;
