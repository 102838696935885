import { create } from 'zustand';
import { persist } from 'zustand/middleware';

export const useUserStore = create(
  persist(
    (set) => ({
      user: null,
      token: null,
      allowCookies: false,
      showCookiesModal: false,
      askCookies: false,
      setShowCookiesModal: (showCookiesModal) => set({ showCookiesModal }),
      setAllowCookies: (allowCookies) => set({ allowCookies }),
      setAskCookies: (askCookies) => set({ askCookies }),
      setUser: (user, token) =>
        set({ user, token }, localStorage.setItem('token', token)),
      logout: () => {
        set({ user: null, token: null });
        localStorage.removeItem('token');
      },
      error: null,
      setError: (error) => set({ error }),
      clearError: () => set({ error: null }),
      loading: false,
      setLoading: (loading) => set({ loading }),
    }),
    {
      name: 'user-storage',
    },
  ),
);

export default useUserStore;
