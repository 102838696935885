import { useSearchParams } from 'react-router-dom';
import TableUsers from '@/components/Tables/TableUsers';
import { useFetchUsers } from '@/hooks/useUser';

const UsersPage = () => {
  const [searchParams, setSearchParams] = useSearchParams();

  const { users, total, loading } = useFetchUsers(searchParams);
  if (loading && !users) return <p>Loading...</p>;

  return (
    <div>
      {users ? (
        <TableUsers
          users={users}
          total={total}
          searchParams={searchParams}
          setSearchParams={setSearchParams}
        />
      ) : (
        <p>No users found</p>
      )}
    </div>
  );
};

export default UsersPage;
