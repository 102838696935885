import axios from 'axios';

const API_BASE_URL = import.meta.env.VITE_API_BASE_URL;

const axiosInstance = axios.create({
  baseURL: `${API_BASE_URL}/api`,
  headers: {
    'Content-Type': 'application/json',
  },
  withCredentials: true,
});

axiosInstance.interceptors.request.use(
  (config) => {
    const token = localStorage.getItem('token');
    if (token) {
      config.headers['Authorization'] = `Bearer ${token}`;
    }
    return config;
  },
  (error) => Promise.reject(error),
);

axiosInstance.interceptors.response.use(
  (response) => response,
  (error) => {
    if (error.response && error.response.status === 401) {
      console.error('Unauthorized, redirecting to login');
      localStorage.removeItem('token');
    }
    return Promise.reject(error);
  },
);

export const createDemand = async (demandData) => {
  try {
    const response = await axiosInstance.post('/demands', demandData);
    const data = response.data;
    return data;
  } catch (error) {
    console.error('Error creating demand:', error);
    throw error;
  }
};

export const getSellers = async ({
  queryString,
  selectedImplant,
  ortodonciaReq,
  selectedProduct,
}) => {
  try {
    const response = await axiosInstance.post(
      `/demands/sellers?${queryString}`, // Pass query string in the URL
      {
        selectedImplant,
        ortodonciaReq,
        selectedProduct, // Send data as the request body
      },
    );
    const data = response.data;
    return data;
  } catch (error) {
    console.error('Error getting sellers:', error);
    throw error;
  }
};

export const getSeller = async ({ id }) => {
  try {
    const response = await axiosInstance.get(`/demands/sellers/${id}`);
    return response.data;
  } catch (error) {
    console.error('Error getting seller:', error);
    throw error;
  }
};
export const followSeller = async (id) => {
  try {
    const response = await axiosInstance.put(`/demands/sellers/${id}/follow`);
    return response.data;
  } catch (error) {
    console.error('Error getting seller:', error);
    throw error;
  }
};

export const getDemands = async ({ queryString }) => {
  try {
    const response = await axiosInstance.get(`/demands?${queryString}`);
    return response.data;
  } catch (error) {
    console.error('Error getting demands:', error);
    throw error;
  }
};

export const getDemandsAsSeller = async ({ queryString }) => {
  try {
    const response = await axiosInstance.get(
      `/demands/asSeller?${queryString}`,
    );
    const data = response.data;
    return data;
  } catch (error) {
    console.error('Error getting demands:', error);
    throw error;
  }
};

export const getDemandsAsAdmin = async ({ queryString }) => {
  try {
    const response = await axiosInstance.get(`/demands/asAdmin?${queryString}`);
    const data = response.data;
    return data;
  } catch (error) {
    console.error('Error getting demands:', error);
    throw error;
  }
};

export const getDemandById = async (id) => {
  try {
    const response = await axiosInstance.get(`/demands/${id}`);
    const data = response.data;
    return data;
  } catch (error) {
    console.error('Error getting demand by id:', error);
    throw error;
  }
};
export const getDemandByIdAsSeller = async (id) => {
  try {
    const response = await axiosInstance.get(`/demands/asSeller/${id}`);
    const data = response.data;
    return data;
  } catch (error) {
    console.error('Error getting demand by id:', error);
    throw error;
  }
};
export const getDemandByIdAsAdmin = async (id) => {
  try {
    const response = await axiosInstance.get(`/demands/asAdmin/${id}`);
    const data = response.data;
    return data;
  } catch (error) {
    console.error('Error getting demand by id:', error);
    throw error;
  }
};
export const editDemandAsAdmin = async (id, demandData) => {
  try {
    const response = await axiosInstance.put(
      `/demands/asAdmin/${id}`,
      demandData,
    );
    const data = response.data;
    return data;
  } catch (error) {
    console.error('Error editing demand:', error);
    throw error;
  }
};

export const updateDemand = async (id, demandData) => {
  try {
    const response = await axiosInstance.put(`/demands/${id}`, demandData);
    const data = response.data;
    return data;
  } catch (error) {
    console.error('Error updating demand:', error);
    throw error;
  }
};
export const updateDemandNotifications = async ({ id }) => {
  try {
    const response = await axiosInstance.put(
      `/demands/updateCaseNotifications/${id}`,
    );
    const data = response.data;
    return data;
  } catch (error) {
    console.error('Error updating demand:', error);
    throw error;
  }
};
