import React, { useState, useEffect, useRef, useCallback } from 'react';
import { useParams } from 'react-router-dom';
import io from 'socket.io-client';
import { toast } from 'react-toastify';
import { CiImageOn } from 'react-icons/ci';
import { FaLocationArrow, FaTrashAlt } from 'react-icons/fa';
import { Button } from '@/components/ui/button';
import { createMessageOutsourcing } from '@/services/message';
import {
  getOutsourcingById,
  updateOutsourcingNotifications,
} from '@/services/outsourcing';
// import { getChat } from '@/services/chat';
import useUserStore from '@/store/userStore';
import useOutsourcingStore from '@/store/OutsourcingStore';
import { ScrollArea } from '@/components/ui/scroll-area';
import MessageBubble from '@/components/Cards/MessageBubble';
import { useNotificationStore } from '@/store/Notificationstore';
import AudioRecorder from '@/components/AudioRecorder';
import { useFetchChatOutsourcing } from '@/hooks/useChat';

const ChatPage = () => {
  const { user, logout } = useUserStore();
  const { addNotification } = useNotificationStore();
  const { setOutsourcing } = useOutsourcingStore();
  const { chatId } = useParams();
  const [messageLoading, setMessageLoading] = useState(false);
  const [error, setError] = useState(null);
  const [newMessage, setNewMessage] = useState('');
  const [file, setFile] = useState(null);
  const messagesEndRef = useRef(null);
  const socket = useRef(null);
  const fileInputRef = useRef(null);
  const [audioFile, setAudioFile] = useState(null);
  const [audioURL, setAudioURL] = useState('');

  const scrollToBottom = useCallback(() => {
    messagesEndRef.current?.scrollIntoView({ behavior: 'smooth' });
  }, []);

  const { chat, loading, messages, setMessages } =
    useFetchChatOutsourcing(chatId);

  useEffect(() => {
    const API_BASE_URL = import.meta.env.VITE_API_BASE_URL;
    socket.current = io(API_BASE_URL);

    // socket.current.emit('joinRoom', chatId);
    socket.current.emit('setup', { user });

    const handleReceiveMessage = async (receivedMessage) => {
      if (receivedMessage.chat == chatId) {
        setMessages((prevMessages) => [...prevMessages, receivedMessage]);
        scrollToBottom();
      }
    };

    socket.current.on('receiveMessage', handleReceiveMessage);

    return () => {
      socket.current.emit('leaveRoom', chatId);
      socket.current.off('receiveMessage', handleReceiveMessage);
      socket.current.disconnect();
    };
  }, [chatId, scrollToBottom]);

  const fetchOutsourcing = async (outsourcingId) => {
    try {
      const { outsourcing } = await getOutsourcingById(outsourcingId);
      await setOutsourcing(outsourcing);
    } catch (error) {
      console.error('Failed to fetch outsourcing:', error);
      toast.error('Error fetching outsourcing data.');
    }
  };

  useEffect(() => {
    if (chat) fetchOutsourcing(chat.outsourcing);
  }, [chat]);

  useEffect(() => {
    scrollToBottom();
  }, [messages, scrollToBottom]);

  const handleFileUpload = (e) => {
    const selectedFile = e.target.files[0];
    if (selectedFile) {
      if (selectedFile.size > 10000000) {
        setError('File size should be less than 10mb');
        return;
      }
      setFile(selectedFile);
    }
  };

  const triggerFileInput = () => {
    fileInputRef.current.click();
  };

  const handleRemoveFile = () => {
    setFile(null);
    setAudioFile(null);
    setAudioURL('');
  };

  const handleCreateMessage = async (e) => {
    e.preventDefault();
    if (!chat || !chat._id) {
      setError('Chat is not loaded yet. Please try again.');
      return;
    }
    if (!newMessage && !audioFile && !file) {
      return;
    }
    try {
      if (audioFile) {
        setFile(audioFile); // Ensure you're setting file correctly here
        setAudioFile(null);
      }
      const messageData = {
        chat: chat._id,
        text: newMessage.trim(),
        file: file || audioFile, // Ensure `file` is either a file object or null, not an invalid object
      };
      setMessageLoading(true);
      const { message } = await createMessageOutsourcing(messageData);

      if (message) {
        socket.current.emit('sendMessage', { message, chat });
        setMessages((prevMessages) => [...prevMessages, message]);
        setMessageLoading(false);
        setFile(null);
        setAudioFile(null);
        scrollToBottom();
        setNewMessage('');
      }
    } catch (error) {
      const errorMessage =
        error.response?.data?.message ||
        'Failed to send message. Please try again.';
      setError(errorMessage);
      toast.error(errorMessage); // Notify user of error
      setMessageLoading(false);
    }
  };

  if (loading) {
    return (
      <div className="flex justify-center items-center min-h-screen font-inter">
        <Button variant="text">
          <svg className="animate-spin h-5 w-5 mr-2 text-white" />
          Cargando...
        </Button>
      </div>
    );
  }
  if (!chat && !loading) {
    return (
      <div className="flex justify-center items-center min-h-screen">
        <p className="text-red-500">Chat no encontrado</p>
      </div>
    );
  }

  if (error) {
    return (
      <div className="flex justify-center items-center min-h-screen">
        <p className="text-red-500">{error}</p>
      </div>
    );
  }

  return (
    <div className="flex flex-col h-screen w-full">
      <ScrollArea className="flex-grow overflow-y-auto px-1 md:px-4">
        <div className="messages-container flex flex-col space-y-4 py-4 px-1">
          {messages.map((message, index) => (
            <MessageBubble
              key={index}
              message={message}
              isCurrentUser={message.sender._id === user?._id}
            />
          ))}
          <div ref={messagesEndRef} />
        </div>
      </ScrollArea>

      <div className="sticky bottom-0 w-full bg-gray-50 py-4 flex items-center">
        {!audioFile ? (
          <input
            type="text"
            name="message"
            placeholder="Type a message..."
            value={newMessage}
            onChange={(e) => setNewMessage(e.target.value)}
            onKeyDown={(e) => e.key === 'Enter' && handleCreateMessage(e)}
            className="flex-1 p-3 border border-gray-300 rounded-l-full mx-2 bg-white"
            aria-label="Message input"
          />
        ) : (
          <audio controls src={audioURL} className="w-full" />
        )}

        <button
          className="p-2 text-gray-600 hover:text-gray-900"
          onClick={triggerFileInput}
          aria-label="Upload file"
        >
          <CiImageOn size={25} color={file ? 'blue' : 'gray'} />
        </button>

        {file && !audioFile && (
          <button
            className="p-2 text-red-600 hover:text-red-800"
            onClick={handleRemoveFile}
            aria-label="Remove file"
          >
            <FaTrashAlt size={25} />
          </button>
        )}
        {!file && audioFile && (
          <button
            className="p-2 text-red-600 hover:text-red-800"
            onClick={handleRemoveFile}
            aria-label="Remove file"
          >
            <FaTrashAlt size={25} />
          </button>
        )}

        <AudioRecorder
          setAudioFile={setAudioFile}
          audioFile={audioFile}
          onStop={(audio) => setAudioFile(audio)}
          audioURL={audioURL}
          setAudioURL={setAudioURL}
        />

        <input
          ref={fileInputRef}
          type="file"
          onChange={handleFileUpload}
          style={{ display: 'none' }}
          accept="image/png, image/jpeg, image/gif, image/jpg"
        />

        <button
          className="p-2 text-blue-600 hover:text-blue-800"
          onClick={handleCreateMessage}
          disabled={messageLoading || (!newMessage && !audioFile && !file)}
          aria-label="Send message"
        >
          <FaLocationArrow size={25} />
        </button>
      </div>
    </div>
  );
};

export default ChatPage;
