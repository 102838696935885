import { useState } from 'react';
import { FaArrowDown, FaArrowUp } from 'react-icons/fa';
import { Card } from '@/components/ui/card';
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from '@/components/ui/table';
import { Label } from '@/components/ui/label';

const TableReferrals = ({ users, setSearchParams, searchParams, total }) => {
  const [showFilters, setShowFilters] = useState(false);
  const [sort, setSort] = useState({
    sortBy: searchParams.get('sortBy') || 'createdAt', // Default sort by creation date
    order: searchParams.get('order') || 'desc', // Default sort order
  });

  const limit = searchParams ? Number(searchParams.get('limit')) || 5 : 5;
  const skip = searchParams ? Number(searchParams.get('skip')) || 0 : 0;

  const handleSort = (field) => {
    const newOrder =
      sort.sortBy === field && sort.order === 'asc' ? 'desc' : 'asc';
    setSort({ sortBy: field, order: newOrder });

    const newParams = new URLSearchParams(searchParams);
    newParams.set('sortBy', field);
    newParams.set('order', newOrder);
    setSearchParams(newParams);
  };

  return (
    <div className="overflow-x-auto w-full">
      <div className="rounded-lg border overflow-hidden w-full">
        <Card className="h-full w-full">
          <Table className="table-fixed w-full border-collapse border rounded-lg border-gray-300">
            <TableHeader className="bg-[#e9ecf2]">
              <TableRow>
                <TableHead
                  className="px-4 py-2 hidden lg:table-cell text-center cursor-pointer"
                  onClick={() => handleSort('name')}
                >
                  Nombre
                  {sort.sortBy === 'name' &&
                    (sort.order === 'asc' ? (
                      <FaArrowUp className="inline ml-2" />
                    ) : (
                      <FaArrowDown className="inline ml-2" />
                    ))}
                </TableHead>

                <TableHead
                  className="px-4 py-2 text-center cursor-pointer"
                  onClick={() => handleSort('lastname')}
                >
                  Apellido
                  {sort.sortBy === 'lastname' &&
                    (sort.order === 'asc' ? (
                      <FaArrowUp className="inline ml-2" />
                    ) : (
                      <FaArrowDown className="inline ml-2" />
                    ))}
                </TableHead>

                <TableHead
                  className="px-4 py-2 text-center cursor-pointer"
                  onClick={() => handleSort('email')}
                >
                  Correo
                  {sort.sortBy === 'email' &&
                    (sort.order === 'asc' ? (
                      <FaArrowUp className="inline ml-2" />
                    ) : (
                      <FaArrowDown className="inline ml-2" />
                    ))}
                </TableHead>
              </TableRow>
            </TableHeader>
            <TableBody>
              {users &&
                users.map((user, index) => (
                  <TableRow key={index} className="border border-1">
                    <TableCell className="px-4 py-2 text-center">
                      {user.name}
                    </TableCell>
                    <TableCell className="px-4 py-2 text-center">
                      {user.lastname}
                    </TableCell>
                    <TableCell className="px-4 py-2 text-center">
                      {user.email}
                    </TableCell>
                  </TableRow>
                ))}
            </TableBody>
          </Table>
          <div className="bg-[#f5f7fa] flex flex-col md:flex-row justify-between px-4 py-2 border-collapse ">
            <button
              onClick={() =>
                setSearchParams({ limit, skip: Math.max(skip - limit, 0) })
              }
              disabled={skip === 0}
              className="btn-neutral"
              aria-label="Previous page"
            >
              Anterior
            </button>

            <button
              onClick={() => setSearchParams({ limit, skip: skip + limit })}
              disabled={skip + limit >= total}
              className="btn-primary mt-2 md:mt-0"
              aria-label="Next page"
            >
              Siguiente
            </button>
          </div>
        </Card>
      </div>
    </div>
  );
};

export default TableReferrals;
