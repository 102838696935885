import { useEffect, useRef } from 'react';
import { useSearchParams, useNavigate } from 'react-router-dom';
import TableCases from '@/components/Tables/TableCases';
import useNotificationStore from '@/store/Notificationstore';
import { useFetchDemands } from '@/hooks/useDemand';
import io from 'socket.io-client';
import { toast } from 'react-toastify';
import { useUserStore } from '@/store/userStore';
import { updateDemandNotifications } from '@/services/demand';

const CasesPage = () => {
  const { user, logout } = useUserStore();
  const navigate = useNavigate();
  const { addNotification } = useNotificationStore();
  const [searchParams, setSearchParams] = useSearchParams();
  const socket = useRef(null);

  const {
    demands,
    totalDemands: total,
    fetchDemands,
  } = useFetchDemands(searchParams);

  useEffect(() => {
    const API_BASE_URL = import.meta.env.VITE_API_BASE_URL;
    socket.current = io(API_BASE_URL);

    socket.current.emit('setup', { user });

    const handleReceiveMessage = async (receivedMessage) => {
      fetchDemands();
    };

    const handleReceiveDemand = (demand) => {
      fetchDemands();
    };

    const handleReceiveOffer = (offer) => {
      fetchDemands();
    };

    const handleReceiveOrder = (order) => {
      fetchDemands();
    };
    const handleReceiveUpdateOrder = (order) => {
      fetchDemands();
    };
    const handleReceiveUpdateCase = (demand) => {
      fetchDemands();
    };
    const handleReceiveAcceptedBillLabCaseSeller = (billLabCase) => {
      fetchDemands();
    };
    const handleReceiveAcceptedBillLabCaseBuller = (billLabCase) => {
      fetchDemands();
    };
    const handleReceiveRejectedBillLabCase = (billLabCase) => {
      fetchDemands();
    };

    socket.current.on('receiveMessage', handleReceiveMessage);
    socket.current.on('receiveDemand', handleReceiveDemand);
    socket.current.on('receiveOffer', handleReceiveOffer);
    socket.current.on('receiveOrder', handleReceiveOrder);
    socket.current.on('receiveUpdateOrder', handleReceiveUpdateOrder);
    socket.current.on('receiveUpdateCase', handleReceiveUpdateCase);
    socket.current.on(
      'receiveAcceptedBillLabCaseSeller',
      handleReceiveAcceptedBillLabCaseSeller,
    );
    socket.current.on(
      'receiveAcceptedBillLabCaseBuller',
      handleReceiveAcceptedBillLabCaseBuller,
    );
    socket.current.on(
      'receiveRejectedBillLabCase',
      handleReceiveRejectedBillLabCase,
    );

    return () => {
      socket.current.off('receiveMessage', handleReceiveMessage);
      socket.current.off('receiveDemand', handleReceiveDemand);
      socket.current.off('receiveOffer', handleReceiveOffer);
      socket.current.off('receiveOrder', handleReceiveOrder);
      socket.current.off('receiveUpdateOrder', handleReceiveUpdateOrder);
      socket.current.off('receiveUpdateCase', handleReceiveUpdateCase);
      socket.current.off(
        'receiveAcceptedBillLabCaseSeller',
        handleReceiveAcceptedBillLabCaseSeller,
      );
      socket.current.off(
        'receiveAcceptedBillLabCaseBuller',
        handleReceiveAcceptedBillLabCaseBuller,
      );
      socket.current.off(
        'receiveRejectedBillLabCase',
        handleReceiveRejectedBillLabCase,
      );
      socket.current.disconnect();
    };
  }, []);

  useEffect(() => {
    if (!user) {
      navigate('/login');
    }
    if (user && !user.active) {
      navigate('/activeProfile');
    }
  }, [user]);

  return (
    <div className="casePage">
      <TableCases
        demands={demands}
        seller={false}
        total={total}
        setSearchParams={setSearchParams}
        searchParams={searchParams}
      />
    </div>
  );
};

export default CasesPage;
