import { useEffect, useState, useCallback } from 'react';
import {
  getDemandById,
  getDemandByIdAsSeller,
  getDemandByIdAsAdmin,
  getDemandsAsSeller,
  getDemands,
  getDemandsAsAdmin,
  updateDemand as updateDemandService,
} from '@/services/demand';
import useDemandStore from '@/store/DemandStore';
import { useUserStore } from '@/store/userStore';

// Hook for fetching a single demand
export const useFetchDemand = (demandId) => {
  const { user } = useUserStore();
  const { demand, setDemand } = useDemandStore();
  const [loadingDemand, setLoadingDemand] = useState(true);
  const [error, setError] = useState(null);

  const fetchDemand = useCallback(async () => {
    if (!demandId) return;

    setLoadingDemand(true);
    setError(null);

    try {
      // const fetcher =
      //   user.role === 'SELLER_ROLE' ? getDemandByIdAsSeller : getDemandById;
      let fetcher;

      switch (user.role) {
        case 'SELLER_ROLE':
          fetcher = getDemandByIdAsSeller;
          break;
        case 'DENTIST_ROLE':
          fetcher = getDemandById;
          break;
        case 'ADMIN_ROLE':
          fetcher = getDemandByIdAsAdmin;
          break;
        default:
          throw new Error('Invalid user role');
      }

      const { demand: demandData } = await fetcher(demandId);
      if (demandData) {
        setDemand(demandData);
        // Don't log the demand here; log it in a useEffect when the state updates
      } else {
        setDemand(null);
        throw new Error('Demand not found');
      }
    } catch (error) {
      setError(error.message || 'Failed to fetch demand');
      setDemand(null);
    } finally {
      setLoadingDemand(false);
    }
  }, [demandId, user.role, setDemand]);

  useEffect(() => {
    if (demandId) {
      fetchDemand();
    }
  }, [fetchDemand, demandId]);

  return { user, demand, loadingDemand, error, fetchDemand };
};

// Hook for fetching multiple demands
export const useFetchDemands = (searchParams) => {
  const { user } = useUserStore();
  const [demands, setDemands] = useState([]);
  const [totalDemands, setTotalDemands] = useState(0);
  const [loadingDemands, setLoadingDemands] = useState(true);
  const [error, setError] = useState(null);

  const fetchDemands = useCallback(async () => {
    setLoadingDemands(true);
    setError(null); // Reset error state

    try {
      const params = Object.fromEntries(searchParams);
      const queryString = new URLSearchParams(params).toString();
      let fetcher;

      if (user.role === 'SELLER_ROLE') {
        fetcher = getDemandsAsSeller;
      } else if (user.role === 'DENTIST_ROLE') {
        fetcher = getDemands;
      } else {
        fetcher = getDemandsAsAdmin;
      }

      const data = await fetcher({ queryString });
      setDemands(data.demands);
      setTotalDemands(data.total);
    } catch (error) {
      setError(error.message || 'Failed to fetch demands');
      console.error('Failed to fetch demands:', error);
    } finally {
      setLoadingDemands(false);
    }
  }, [searchParams, user.role]);

  useEffect(() => {
    fetchDemands();
  }, [fetchDemands]);

  return { demands, loadingDemands, error, totalDemands, fetchDemands };
};

// Hook for updating a demand
export const useUpdateDemand = () => {
  const [demand, setDemand] = useState(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  const updateDemand = useCallback(async (demandId, data) => {
    setLoading(true);
    setError(null); // Reset error state

    try {
      const { demand: updatedDemand } = await updateDemandService(
        demandId,
        data,
      );
      if (updatedDemand) {
        setDemand(updatedDemand);
      } else {
        throw new Error('Error updating demand');
      }
    } catch (error) {
      setError(error.message || 'Error updating demand');
      console.error('Error updating demand:', error);
    } finally {
      setLoading(false);
    }
  }, []);

  return { demand, loading, error, updateDemand };
};
