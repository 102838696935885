import React from 'react';

const ContactCard = ({
  name,
  setName,
  lastname,
  setLastname,
  phone,
  setPhone,
  nif,
  setNif,
  colNumber,
  setColNumber,
  razonSocial,
  setRazonSocial,
}) => {
  return (
    <div>
      <div className="space-y-2">
        <label
          htmlFor="name"
          className="block text-sm font-medium text-gray-700 "
        >
          Datos de Contacto
        </label>
        <div className="flex gap-2">
          <div className="flex-1">
            <label
              htmlFor="name"
              className="block text-sm font-medium text-gray-700"
            >
              Nombre
            </label>
            <input
              type="text"
              id="name"
              name="name"
              value={name}
              onChange={(e) => setName(e.target.value)}
              className="mt-1 block w-full p-2 border border-gray-300 rounded-lg shadow-sm focus:ring-blue-500 focus:border-blue-500"
              placeholder="Nombre"
              required
            />
          </div>

          <div className="flex-1">
            <label
              htmlFor="lastname"
              className="block text-sm font-medium text-gray-700"
            >
              Apellido
            </label>
            <input
              type="text"
              id="lastname"
              name="lastname"
              value={lastname}
              onChange={(e) => setLastname(e.target.value)}
              className="mt-1 block w-full p-2 border border-gray-300 rounded-lg shadow-sm focus:ring-blue-500 focus:border-blue-500"
              placeholder="Apellido"
              required
            />
          </div>
        </div>
        <div className="flex gap-2 ">
          <div className="flex-1">
            <label
              htmlFor="clinicID"
              className="block text-sm font-medium text-gray-700"
            >
              Número de colegiado
            </label>
            <input
              type="text"
              id="clinicID"
              value={colNumber}
              onChange={(e) => setColNumber(e.target.value)}
              className="mt-1 block w-full p-2 border border-gray-300 rounded-lg shadow-sm focus:ring-blue-500 focus:border-blue-500"
              placeholder="Número de colegiado"
              required
            />
          </div>
          <div className="flex-1">
            <label
              htmlFor="clinicID"
              className="block text-sm font-medium text-gray-700"
            >
              Razón social
            </label>
            <input
              type="text"
              id="razonSocial"
              value={razonSocial}
              onChange={(e) => setRazonSocial(e.target.value)}
              className="mt-1 block w-full p-2 border border-gray-300 rounded-lg shadow-sm focus:ring-blue-500 focus:border-blue-500"
              placeholder="Razón social"
              required
            />
          </div>
        </div>
        <div className="flex gap-2 ">
          <div className="flex-1">
            <label
              htmlFor="phone"
              className="block text-sm font-medium text-gray-700"
            >
              Teléfono
            </label>
            <input
              type="text"
              id="phone"
              name="phone"
              value={phone}
              onChange={(e) => setPhone(e.target.value)}
              className="mt-1 block w-full p-2 border border-gray-300 rounded-lg shadow-sm focus:ring-blue-500 focus:border-blue-500"
              placeholder="123-456-7890"
              required
            />
          </div>
          <div className="flex-1">
            <label
              htmlFor="phone"
              className="block text-sm font-medium text-gray-700"
            >
              NIF
            </label>
            <input
              type="text"
              id="nif"
              name="nif"
              value={nif}
              onChange={(e) => setNif(e.target.value)}
              className="mt-1 block w-full p-2 border border-gray-300 rounded-lg shadow-sm focus:ring-blue-500 focus:border-blue-500"
              placeholder="n123"
              required
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default ContactCard;
