import React, { useEffect, useRef } from 'react';
import { toast } from 'react-toastify';
import { useLocation } from 'react-router-dom';
import io from 'socket.io-client';
import useUserStore from '@/store/userStore';
import useNotificationStore from '@/store/Notificationstore';
import { updateDemandNotifications } from '@/services/demand';

const NotificationHandler = () => {
  const { user, logout } = useUserStore();
  const { addNotification } = useNotificationStore();
  const location = useLocation();
  const socket = useRef(null);

  useEffect(() => {
    const API_BASE_URL = import.meta.env.VITE_API_BASE_URL;

    // Configurar el socket
    if (!socket.current) {
      socket.current = io(API_BASE_URL);
      socket.current.emit('setup', { user });
    }

    // Manejadores de eventos
    const handlers = {
      receiveMessage: async (receivedMessage) => {
        const pathParts = window.location.pathname.split('/');

        const currentChatId = pathParts[3];
        if (receivedMessage.chat != currentChatId) {
          addNotification({
            message: 'Nuevo mensaje recibido',
            type: 'message',
            chat: receivedMessage.chat,
          });
          toast.success('Nuevo mensaje recibido');

          await updateDemandNotifications({ id: receivedMessage.chat });
        }
      },

      receiveDemand: (demand) => {
        toast.success('Nuevo caso recibido');
        addNotification({
          message: 'Nuevo caso recibido',
          type: 'demand',
          demand: demand._id,
        });
      },
      receiveOutsourcing: (outsourcing) => {
        toast.success('Nuevo Outsourcing recibido');
        addNotification({
          message: 'Nuevo Outsourcing recibido',
          type: 'Outsourcing',
          outsourcing: outsourcing._id,
        });
      },
      receiveOffer: (offer) => {
        toast.success('Nueva oferta recibida');
        addNotification({
          message: 'Nueva oferta recibida',
          type: 'offer',
          offer: offer._id,
        });
      },
      receiveOrder: (order) => {
        toast.success('Tu oferta fue aceptada');
        addNotification({
          message: 'Tu oferta fue aceptada',
          type: 'order',
          order: order._id,
        });
      },
      receiveUpdateOrder: (order) => {
        addNotification({
          message: 'Pedido actualizado',
          type: 'order',
          order: order._id,
        });
      },
      receiveUpdateCase: (demand) => {
        toast.success('Caso actualizado');
        addNotification({
          message: 'Caso actualizado',
          type: 'demand',
          demand: demand._id,
        });
      },
      receiveAcceptedBillLabCaseSeller: (billLabCase) => {
        toast.success('Factura aprobada');
        addNotification({
          message: 'Factura aprobada',
          type: 'billLabCase',
          billLabCase: billLabCase._id,
        });
      },
      receiveAcceptedBillLabCaseBuller: (billLabCase) => {
        toast.success('Factura generada');
        addNotification({
          message: 'Factura generada',
          type: 'billLabCase',
          billLabCase: billLabCase._id,
        });
      },
      receiveRejectedBillLabCase: (billLabCase) => {
        toast.success('Factura rechazada');
        addNotification({
          message: 'Factura rechazada',
          type: 'billLabCase',
          billLabCase: billLabCase._id,
        });
      },
      receiveBanUser: () => {
        toast.success('Usuario baneado');
        logout();
      },
    };

    // Registrar eventos
    Object.entries(handlers).forEach(([event, handler]) => {
      socket.current.on(event, handler);
    });

    // Cleanup
    return () => {
      Object.entries(handlers).forEach(([event, handler]) => {
        socket.current.off(event, handler);
      });
      socket.current.disconnect();
      socket.current = null; // Resetear referencia del socket
    };
  }, [user, addNotification, logout, location]);

  return null; // Este componente no necesita renderizar nada
};

export default NotificationHandler;
