import React, { useState, useEffect } from 'react';
import { AiOutlineAudio } from 'react-icons/ai';

const AudioRecorder = ({
  onStop,
  audioFile,
  setAudioFile,
  setAudioURL,
  audioURL,
}) => {
  const [recording, setRecording] = useState(false);
  const [mediaRecorder, setMediaRecorder] = useState(null);
  const [error, setError] = useState('');

  useEffect(() => {
    if (!navigator.mediaDevices || !navigator.mediaDevices.getUserMedia) {
      setError('Tu navegador no soporta la API de grabación de audio.');
    }
  }, []);

  const startRecording = async () => {
    try {
      const stream = await navigator.mediaDevices.getUserMedia({ audio: true });
      const recorder = new MediaRecorder(stream);

      recorder.ondataavailable = (event) => {
        const url = URL.createObjectURL(event.data);
        setAudioURL(url);
        setAudioFile(event.data);
      };

      recorder.start();
      setMediaRecorder(recorder);
      setRecording(true);
    } catch (error) {
      console.error('Error al iniciar la grabación:', error);
      setError(
        'No se pudo iniciar la grabación. Asegúrate de que el micrófono está disponible.',
      );
    }
  };

  const stopRecording = () => {
    if (mediaRecorder) {
      mediaRecorder.stop();
      setRecording(false);
      onStop(audioFile);
    }
  };

  // Limpiar recursos cuando el componente se desmonta
  useEffect(() => {
    return () => {
      if (mediaRecorder && recording) {
        mediaRecorder.stream.getTracks().forEach((track) => track.stop());
      }
    };
  }, [mediaRecorder, recording]);

  return (
    <div className="flex flex-col items-center">
      {error && <p className="text-red-500">{error}</p>}
      {!audioFile && (
        <button onClick={recording ? stopRecording : startRecording}>
          {recording ? (
            <AiOutlineAudio size={24} color="red" />
          ) : (
            <AiOutlineAudio size={24} />
          )}
        </button>
      )}

      {/* {audioURL && !recording && audioFile && (
        <div className="mt-2 w-full">
          <button
            onClick={() => {
              setAudioURL('');
              setAudioFile(null);
            }}
            className="text-red-500 mt-1"
          >
            Grabar
          </button>
        </div>
      )} */}
    </div>
  );
};

export default AudioRecorder;
