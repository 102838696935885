import React from 'react';
import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogFooter,
} from '@/components/ui/dialog';
const NeedHelpModal = ({ isOpen, setIsOpen }) => {
  const handleDialogClose = () => setIsOpen(false);

  return (
    <Dialog open={isOpen} onOpenChange={handleDialogClose}>
      <DialogContent>
        <DialogHeader className="text-center">
          ¿Tienes un problema con este pedido? Envía un email a
          socorro@oklusion.com con la información de este caso y te
          contactaremos lo antes posible para solucionarlo.
        </DialogHeader>
      </DialogContent>
    </Dialog>
  );
};

export default NeedHelpModal;
