import React, { useState, useEffect } from 'react';
import { Link, useParams } from 'react-router-dom';
import { Button } from '@/components/ui/button';
import useUserStore from '@/store/userStore';
import useDemandStore from '@/store/DemandStore';
import { getbillLabCase } from '@/services/billLabCase';
import { FaDownload } from 'react-icons/fa';
import BillStepper from '@/components/Cards/BillStepper';
import { Card, CardContent } from '@/components/ui/card';

const CaseBillPage = () => {
  const { user } = useUserStore();
  const { demand, setDemand } = useDemandStore();
  const { billId } = useParams();
  const [billingData, setBillingData] = useState(null);
  const [loading, setLoading] = useState(true);

  const fetchData = async () => {
    try {
      setLoading(true);
      const { demand } = await getbillLabCase(billId);
      setBillingData(demand.billLabCase);
      setDemand(demand);
      setLoading(false);
    } catch (error) {
      console.error('Error fetching billing data:', error);
    }
  };

  useEffect(() => {
    fetchData();
  }, [billId]);
  const getFileName = (url) => {
    return url.substring(url.lastIndexOf('/') + 1);
  };

  const handleDays = (date) => {
    const today = new Date();
    const reviewDate = new Date(date);
    const diffTime = Math.abs(today - reviewDate);
    const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
    return diffDays < 4 ? 'Hoy' : `${diffDays} dias`;
  };

  if (loading) {
    return (
      <div className="flex justify-center items-center min-h-screen font-inter">
        <Button variant="text">
          <svg
            className="animate-spin h-5 w-5 mr-2 text-white" /* svg details */
          />
          Cargando...
        </Button>
      </div>
    );
  }

  if (billingData.status !== 'accepted' && user.role == 'DENTIST_ROLE') {
    return <div>Factura no disponible</div>;
  }

  return (
    <div className="casePage">
      <div className="flex flex-col gap-3 ">
        {user.role == 'SELLER_ROLE' && (
          <BillStepper BillLabCase={billingData} />
        )}

        {billingData.file.url && (
          <Card className="py-4 px-2  gap-8 rounded-lg bg-white ">
            <CardContent className="w-full flex flex-col md:flex-row p-0">
              <div className="mx-auto">
                {billingData.file.mimetype.startsWith('image/') && (
                  <>
                    <img
                      src={billingData.file.url}
                      alt="Message Attachment"
                      className="w-[10rem] h-[10rem] md:w-1/2 md:h-auto mb-2 cursor-pointer"
                    />
                    <a
                      href={billingData.file.url}
                      target="_blank"
                      rel="noopener noreferrer"
                      className="text-blue-500 mt-2 inline-block"
                      download
                    >
                      <FaDownload className="inline mr-2" />
                      Descargar factura
                    </a>
                  </>
                )}

                {/* Document Files */}
                {billingData.file.mimetype.startsWith('application/') && (
                  <>
                    <iframe
                      src={billingData.file.url}
                      title="Document Viewer"
                      className="w-full h-auto mb-2"
                    />
                    {/* Download Button for Documents */}
                    <a
                      href={billingData.file.url}
                      download={getFileName(billingData.file.url)}
                      className="text-blue-500"
                    >
                      <FaDownload className="inline mr-2" />
                      Descargar documento
                    </a>
                  </>
                )}
              </div>
            </CardContent>
          </Card>
        )}
        {user.role == 'SELLER_ROLE' && billingData.status == 'rejected' && (
          <>
            <Link
              to={`/cases/createBill/${demand._id}`}
              className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded mb-4"
            >
              Reenviar Factura
            </Link>
            {billingData.revisions &&
              billingData.revisions.map((revision, index) => (
                <div
                  key={index}
                  className="mt-4 p-4  rounded-lg shadow-md bg-white "
                >
                  <h3 className="text-lg font-semibold text-red-600">
                    Revisión {handleDays(revision.createdAt)}
                  </h3>
                  <p className="text-gray-700 mt-1 border-l-4 border-red-600 pl-2">
                    {revision.description}
                  </p>
                </div>
              ))}
          </>
        )}
      </div>
    </div>
  );
};

export default CaseBillPage;
