const handleProtesis = (selectedOption) => {
  switch (selectedOption) {
    case 'Corona':
      return 'Corona / Puente / Arcada completa';
    case 'Carillas':
      return 'Carillas / Incrustación / onlay';
    case 'Dentadura':
      return 'Dentadura / Parcial / Esquelético';
    case 'Férulas':
      return 'Férulas';
    default:
      return 'Otros';
  }
};
const handleOrtodoncia = (selectedOption) => {
  switch (selectedOption) {
    case 'OrthodonticAppliances':
      return 'Aparatos Ortodoncia';
    case 'Aligners':
      return 'Ortodoncia Invisible';
    case 'Retainers':
      return 'Retenedor';
    default:
      return 'Otros';
  }
};

const handleProducts = (selectedOption) => {
  switch (selectedOption) {
    case 'ProtectorBucal':
      return 'Protector Bucal';
    case 'AntiRonquido':
      return 'Anti Ronquido';
    case 'Grillz':
      return 'Grillz';
    case 'Modelo':
      return 'Modelo';
    case 'ReparacionRebase':
      return 'Reparación / Rebase';
    case 'Biomaterial':
      return 'Biomaterial';
    case 'Otros':
      return 'Otros';
    default:
      return 'Otros';
  }
};

export const handlecaseType = (demand) => {
  switch (demand?.caseType) {
    case 'protesis':
      return handleProtesis(demand?.selectedImplant?.selectedOption);
    case 'ortodoncia':
      return handleOrtodoncia(demand?.ortodonciaReq?.selectedOption);
    default:
      return handleProducts(demand?.selectedProduct);
  }
};
