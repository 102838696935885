import axios from 'axios';

const API_BASE_URL = import.meta.env.VITE_API_BASE_URL;

const axiosInstance = axios.create({
  baseURL: `${API_BASE_URL}/api`,
  headers: {
    'Content-Type': 'application/json',
  },
  withCredentials: true,
});

axiosInstance.interceptors.request.use(
  (config) => {
    const token = localStorage.getItem('token');
    if (token) {
      config.headers['Authorization'] = `Bearer ${token}`;
    }
    return config;
  },
  (error) => Promise.reject(error),
);

axiosInstance.interceptors.response.use(
  (response) => response,
  (error) => {
    if (error.response && error.response.status === 401) {
      console.error('Unauthorized, redirecting to login');
      localStorage.removeItem('token');
    }
    return Promise.reject(error);
  },
);

export const login = async (userData) => {
  try {
    const response = await axiosInstance.post('/auth/login', userData);
    return response.data;
  } catch (error) {
    console.error('Error logging in:', error);
    throw error;
  }
};

export const register = async (userDetails) => {
  try {
    const response = await axiosInstance.post('/auth/register', userDetails);
    return response.data;
  } catch (error) {
    console.error('Error registering:', error);
    throw error;
  }
};

export const logout = () => {
  localStorage.removeItem('token');
};

export const sendResetEmail = async ({ email }) => {
  try {
    const response = await axiosInstance.post('/auth/changePassword', {
      email,
    });
    return response.data;
  } catch (error) {
    console.error('Error sending reset email:', error);
    throw error;
  }
};

export const resetPassword = async ({ token, password }) => {
  try {
    const response = await axiosInstance.post('/auth/newPassword', {
      token,
      password,
    });
    return response.data;
  } catch (error) {
    console.error('Error resetting password:', error);
    throw error;
  }
};

export const checkToken = async (token) => {
  try {
    const response = await axiosInstance.post('/auth/checkToken', { token });
    return response.data;
  } catch (error) {
    console.error('Error checking token:', error);
    throw error;
  }
};

export const verifyEmail = async (token) => {
  try {
    const response = await axiosInstance.post('/auth/verifyEmail', { token });
    return response.data;
  } catch (error) {
    console.error('Error verifying email:', error);
    throw error;
  }
};

export const updateVerifyEmail = async ({ email }) => {
  try {
    const response = await axiosInstance.post('/auth/updateVerifyEmail', {
      email,
    });
    return response.data;
  } catch (error) {
    console.error('Error updating verify email:', error);
    throw error;
  }
};

export const getProfile = async () => {
  try {
    const response = await axiosInstance.get('/auth/profile');
    return response.data;
  } catch (error) {
    console.error('Error getting profile:', error);
    throw error;
  }
};

export const myNotifications = async () => {
  try {
    const response = await axiosInstance.get('/auth/myNotifications');

    console.log('myNotifications', response);
    return response.data;
  } catch (error) {
    console.error('Error getting notifications:', error);
    throw error;
  }
};

export const updateProfile = async (userData) => {
  try {
    const formData = new FormData();

    for (const key in userData) {
      const value = userData[key];

      // Handle arrays (like clinics) by serializing them as JSON strings
      if (Array.isArray(value)) {
        formData.append(key, JSON.stringify(value));
      }
      // Avoid appending empty strings, null, or undefined values
      else if (value !== null && value !== undefined && value !== '') {
        // If it's a file (like avatar), append directly without stringifying
        if (value instanceof File) {
          formData.append(key, value);
        } else {
          formData.append(key, value);
        }
      }
    }

    const response = await axiosInstance.put('/auth/profile', formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });

    return response.data;
  } catch (error) {
    console.error('Error updating profile:', error);
    throw error;
  }
};

export const updateServices = async (userData) => {
  try {
    const response = await axiosInstance.put('/auth/profile', userData);
    return response.data;
  } catch (error) {
    console.error('Error updating profile:', error);
    throw error;
  }
};

export const profileActivate = async (userData) => {
  try {
    const response = await axiosInstance.put('/auth/profileActivate', userData);
    return response.data;
  } catch (error) {
    console.error('Error updating profile:', error);
    throw error;
  }
};

export const updatePassword = async ({ password, newPassword }) => {
  try {
    const response = await axiosInstance.put('/auth/updatePassword', {
      oldPassword: password,
      newPassword,
    });
    return response.data;
  } catch (error) {
    console.error('Error updating password:', error);
    throw error;
  }
};

export const getUsers = async ({ queryString }) => {
  try {
    const response = await axiosInstance.get(`/auth/users?${queryString}`);
    return response.data;
  } catch (error) {
    console.error('Error getting users:', error);
    throw error;
  }
};
export const getUser = async (userId) => {
  try {
    const response = await axiosInstance.get(`/auth/users/${userId}`);
    return response.data;
  } catch (error) {
    console.error('Error getting user:', error);
    throw error;
  }
};

export const updateUser = async (userId, userData) => {
  try {
    const formData = new FormData();

    for (const key in userData) {
      const value = userData[key];

      // Handle arrays (like clinics) by serializing them as JSON strings
      if (Array.isArray(value)) {
        formData.append(key, JSON.stringify(value));
      }
      // Avoid appending empty strings, null, or undefined values
      else if (value !== null && value !== undefined && value !== '') {
        // If it's a file (like avatar), append directly without stringifying
        if (value instanceof File) {
          formData.append(key, value);
        } else {
          formData.append(key, value);
        }
      }
    }
    const response = await axiosInstance.put(
      `/auth/users/${userId}`,
      formData,
      {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      },
    );
    return response.data;
  } catch (error) {
    console.error('Error updating user:', error);
    throw error;
  }
};

export const unactiveUser = async () => {
  try {
    const response = await axiosInstance.put(`/auth/unactiveuser`);
    return response.data;
  } catch (error) {
    console.error('Error unactive user:', error);
    throw error;
  }
};
export const unactiveuserAsAdmin = async (id) => {
  try {
    const response = await axiosInstance.put(
      `/auth/unactiveuser/asAdmin/${id}`,
    );
    return response.data;
  } catch (error) {
    console.error('Error unactive user:', error);
    throw error;
  }
};

export const banUser = async (userId) => {
  try {
    const response = await axiosInstance.put(`/auth/banuser/${userId}`);
    return response.data;
  } catch (error) {
    console.error('Error banning user:', error);
    throw error;
  }
};
