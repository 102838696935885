import React, { useState, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Button } from '@/components/ui/button';
import { Input } from '@/components/ui/input';
import useUserStore from '@/store/userStore';
import { useFetchOutsourcing } from '@/hooks/useOutsourcing';
import { toast } from 'react-toastify';
import {
  getBillingData,
  createbillOutsourcingBuyer,
} from '@/services/billOutsourcingBuyer';
import {
  Card,
  CardContent,
  CardDescription,
  CardHeader,
  CardTitle,
} from '@/components/ui/card';

const BillOutsorcingBuyer = () => {
  const { user } = useUserStore();
  const { outsourcingId } = useParams();
  const navigate = useNavigate();
  const [file, setFile] = useState(null);
  useFetchOutsourcing(outsourcingId);
  const [billingData, setBillingData] = useState(null);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(true);

  const handleFileUpload = (e) => {
    const selectedFile = e.target.files[0];
    if (selectedFile) {
      if (selectedFile.size > 10000000) {
        setError('El tamaño del archivo debe ser menor a 10MB');
        setFile(null);
        return;
      }
      setError(null);
      setFile(selectedFile);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!file) {
      toast.error('Debe seleccionar al menos un archivo');
      return;
    }
    try {
      setLoading(true);
      const billLabCaseData = {
        outsourcing: outsourcingId,
        file,
      };

      const { billOutsourcingBuyer } = await createbillOutsourcingBuyer(
        billLabCaseData,
      );

      if (billOutsourcingBuyer) {
        toast.success('Factura creada correctamente');
        setFile(null);
        navigate(`/outsourcing/${outsourcingId}`);
      }
    } catch (error) {
      toast.error('Error al crear la factura');
      console.error('Error creating bill:', error);
    } finally {
      setLoading(false);
    }
  };

  const fetchData = async () => {
    try {
      setLoading(true);
      const { billData } = await getBillingData(outsourcingId);

      if (billData) {
        setBillingData(billData);
      }
    } catch (error) {
      console.error('Error fetching billing data:', error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (user.role == 'DENTIST_ROLE') {
      navigate(`/cases`);
    }
    fetchData();
  }, [outsourcingId]);

  if (loading) {
    return (
      <div className="flex justify-center items-center min-h-screen font-inter">
        <Button variant="text">
          <svg
            className="animate-spin h-5 w-5 mr-2 text-white" /* svg details */
          />
          Cargando...
        </Button>
      </div>
    );
  }

  if (!billingData) {
    return (
      <div className="flex justify-center items-center min-h-screen font-inter">
        <h1 className="text-2xl font-bold">Caso no encontrado</h1>
      </div>
    );
  }

  return (
    <div className="casePage">
      <div className="flex flex-col gap-3 ">
        <Card className="py-4 px-2  gap-8 rounded-lg bg-white ">
          <CardHeader>
            <CardTitle>Factura</CardTitle>
            <CardDescription>
              Por favor, revise los datos a continuación y adjunte el documento
              correspondiente.
            </CardDescription>
          </CardHeader>
          <CardContent className="w-full flex flex-col md:flex-row ">
            {billingData ? (
              <div className="bg-gray-100 p-4 rounded-lg shadow-md mb-6  w-full">
                <h2 className="text-lg font-semibold mb-2">
                  Información de Facturación
                </h2>
                <div className="flex flex-row gap-4 justify-between">
                  <div>
                    <div className="text-sm text-gray-700">
                      <p>
                        <strong>Nombre:</strong>{' '}
                        {billingData.outsourcing?.user?.name}{' '}
                        {billingData.outsourcing?.user?.lastname}
                      </p>
                      <p>
                        <strong>Razón Social:</strong>{' '}
                        {billingData.outsourcing?.user?.razonSocial}
                      </p>
                      <p>
                        <strong>NIF:</strong>{' '}
                        {billingData.outsourcing?.user?.nif}
                      </p>
                      <p>
                        <strong>Teléfono:</strong>{' '}
                        {billingData.outsourcing?.user?.phone}
                      </p>
                      <p>
                        <strong>Total a facturar:</strong> €{' '}
                        {
                          billingData.outsourcing.selectedLaboratorios[0].offer
                            .total
                        }
                      </p>
                    </div>
                  </div>
                  <div className="text-sm text-gray-700">
                    <h4 className="font-semibold">Dirección:</h4>
                    <p>{billingData.outsourcing?.clinic?.clinicstreet}</p>
                    <p>{billingData.outsourcing?.clinic?.postalCode}</p>
                    <p>{billingData.outsourcing?.clinic?.province}</p>
                    <p>{billingData.outsourcing?.clinic?.city}</p>
                    <p>{billingData.outsourcing?.clinic?.country}</p>
                  </div>
                </div>
              </div>
            ) : (
              <p>Cargando datos de facturación...</p>
            )}
          </CardContent>
        </Card>
        {billingData.outsourcing.billOutsourcingBuyer &&
          billingData.outsourcing.billOutsourcingBuyer?.status !==
            'rejected' && (
            <>
              <a
                href={billingData.outsourcing.billOutsourcingBuyer.file.url}
                target="_blank"
                rel="noopener noreferrer"
                download
                className="text-blue-500"
              >
                Ver factura
              </a>
            </>
          )}
        {billingData.outsourcing.billOutsourcingBuyer?.status == 'rejected' && (
          <form onSubmit={handleSubmit} className="mt-4">
            <div className="mb-4">
              <label
                className="block text-sm font-semibold mb-1"
                htmlFor="revisionFiles"
              >
                Adjuntar Factura
              </label>
              <Input
                id="revisionFiles"
                type="file"
                onChange={handleFileUpload}
              />
              {error && <p className="text-red-500 text-sm mt-1">{error}</p>}
            </div>

            <Button
              variant="gradient"
              size="lg"
              className="w-full bg-blue-500 hover:bg-blue-300 p-4"
              type="submit"
              disabled={loading || !file}
            >
              {loading
                ? 'Cargando...'
                : file
                ? 'Guardar'
                : 'Seleccione un archivo'}
            </Button>
          </form>
        )}
        {!billingData.outsourcing.billOutsourcingBuyer && (
          <form onSubmit={handleSubmit} className="mt-4">
            <div className="mb-4">
              <label
                className="block text-sm font-semibold mb-1"
                htmlFor="revisionFiles"
              >
                Adjuntar Factura
              </label>
              <Input
                id="revisionFiles"
                type="file"
                onChange={handleFileUpload}
              />
              {error && <p className="text-red-500 text-sm mt-1">{error}</p>}
            </div>

            <Button
              variant="gradient"
              size="lg"
              className="w-full bg-blue-500 hover:bg-blue-300 p-4"
              type="submit"
              disabled={loading || !file}
            >
              {loading
                ? 'Cargando...'
                : file
                ? 'Guardar'
                : 'Seleccione un archivo'}
            </Button>
          </form>
        )}
      </div>
    </div>
  );
};

export default BillOutsorcingBuyer;
