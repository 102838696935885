import { create } from 'zustand';
import { persist } from 'zustand/middleware';

export const useOutsourcingStore = create(
  persist(
    (set) => ({
      outsourcing: {},
      outsourcings: [],
      offer: {},
      setOutsourcing: (outsourcing) => set({ outsourcing }),
      setOutsourcings: (outsourcings) => set({ outsourcings }),
      setOffer: (offer) => set({ offer }),
      clearOutsourcingStore: () =>
        set({
          outsourcing: {},
          outsourcings: [],
          offer: {},
        }),
    }),
    {
      name: 'outsourcing-storage',
    },
  ),
);

export default useOutsourcingStore;
