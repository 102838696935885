export const protesisList = [
  {
    name: 'Corona / Puente / Arcada completa',
    selectedOption: 'Corona',
  },

  {
    name: 'Carillas / Incrustación / onlay',
    selectedOption: 'Carillas',
  },
  {
    name: 'Dentadura / Parcial / Esquelético',
    selectedOption: 'Dentadura',
  },
  {
    name: 'Férulas',
    selectedOption: 'Férulas',
  },
];

export const ortodonciaList = [
  {
    name: 'Aparatos Ortodoncia',
    selectedOption: 'OrthodonticAppliances',
  },
  {
    name: 'Ortodoncia Invisible',
    selectedOption: 'Aligners',
  },
  {
    name: 'Retenedor',
    selectedOption: 'Retainers',
  },
];

export const AlignersList = [
  {
    name: 'Invisalign',
    value: 'Invisalign',
  },
  {
    name: 'K-Line',
    value: 'KLine',
  },
  {
    name: 'AngelAlign',
    value: 'AngelAlign',
  },
  {
    name: 'Geniova',
    value: 'Geniova',
  },
  {
    name: 'Otros',
    value: 'OtrosOrtInv',
  },
];

export const ortoType = [
  {
    value: 'QuadHelix',
    name: 'Quad-Helix',
  },
  {
    value: 'Expansor',
    name: 'Expansor',
  },
  {
    value: 'SureSmile',
    name: 'SureSmile',
  },
  {
    value: 'Otros',
    name: 'Otros',
  },
];
export const tipoList = [
  {
    name: 'Fijo',
    value: 'Fijo',
  },
  {
    name: 'Móvil',
    value: 'Movil',
  },
];

export const otrosList = [
  {
    name: 'Protector Bucal',
    value: 'ProtectorBucal',
  },
  {
    name: 'Anti Ronquido',
    value: 'AntiRonquido',
  },
  {
    name: 'Grillz',
    value: 'Grillz',
  },
  {
    name: 'Modelo',
    value: 'Modelo',
  },
  {
    name: 'Reparación / Rebase',
    value: 'ReparacionRebase',
  },
  {
    name: 'Biomaterial',
    value: 'Biomaterial',
  },
  {
    name: 'Otros',
    value: 'Otros',
  },
];

export const listColor = [
  'A1',
  'A2',
  'A3',
  'A3,5',
  'B1',
  'B2',
  'B3',
  'B4',
  'C1',
  'C2',
  'C3',
  'C4',
  'D2',
  'D3',
  'D4',
];

//1
export const MaterialCorona = [
  {
    value: 'Zirconio',
    name: 'Zirconio',
  },
  {
    value: 'Metalceramica',
    name: 'Metal / Cerámica',
  },
  {
    value: 'Composite',
    name: 'Composite',
  },
  {
    value: 'Impreso3D',
    name: 'Impresión 3D',
  },
  {
    value: 'Acrilico',
    name: 'Acrílico',
  },
  {
    value: 'DisilicatoLitio',
    name: 'Disilicato de litio',
  },
  {
    value: 'Resina',
    name: 'Resina',
  },
  {
    value: 'OtrosCorona',
    name: 'Otros',
  },
];

export const MaterialBarra = [
  // {
  //   value: 'CRCO',
  //   name: 'CrCo',
  // },
  // {
  //   value: 'TI',
  //   name: 'Ti',
  // },
  {
    value: 'Metal',
    name: 'Metal',
  },
  {
    value: 'Plastico',
    name: 'Plástico',
  },
  {
    value: 'Resina',
    name: 'Resina',
  },
  {
    value: 'Zirconio',
    name: 'Zirconio',
  },
  {
    value: 'Otros',
    name: 'Otros',
  },
];

export const MaterialBarraDen = [
  {
    value: 'CRCOden',
    name: 'CrCo',
  },
  {
    value: 'TIden',
    name: 'Ti',
  },
  {
    value: 'otrosDen',
    name: 'Otros',
  },
];

export const MaterialTipoBarra = [
  {
    value: 'Fresada',
    name: 'Fresada',
  },
  {
    value: 'Impreso3D',
    name: 'Impresa 3D',
  },
  {
    value: 'Colada',
    name: 'Colada',
  },
];

//2
export const MaterialCarillas = [
  // {
  //   value: 'Amber',
  //   name: 'Amber',
  // },
  // {
  //   value: 'Emax',
  //   name: 'Emax',
  // },
  // {
  //   value: 'Ceramica',
  //   name: 'Cerámica',
  // },
  {
    value: 'Feldespatica',
    name: 'Feldespatica',
  },
  {
    value: 'Zirconio',
    name: 'Zirconio',
  },
  {
    value: 'DisilicatoLitioCar',
    name: 'Disilicato de litio',
  },
  {
    value: 'OtrosCar',
    name: 'Otros',
  },
];
//3
export const MaterialDentadura = [
  {
    value: 'Acrilico',
    name: 'Acrílico',
  },
  {
    value: 'Impreso3D',
    name: 'Impresión 3D',
  },
];

export const RefuerzoList = [
  {
    value: 'Malla',
    name: 'Malla',
  },
  {
    value: 'RefuerzoForjado',
    name: 'Refuerzo forjado',
  },
];

//4
export const TipodeFerula = [
  {
    value: 'Ferulaquirurgica',
    name: 'Férula quirúrjica',
  },
  {
    value: 'Feruladescarga',
    name: 'Férula descarga',
  },
  {
    value: 'Ferulamuscular',
    name: 'Férula muscular',
  },
];

export const MateriaFedula = [
  {
    value: 'Acrilico',
    name: 'Acrílico',
  },
  {
    value: 'PMMA',
    name: 'PMMA',
  },
  {
    value: 'Otros',
    name: 'Otros',
  },
];

export const statusStyles = {
  canceled: {
    bg: 'bg-[#FEF3F2]',
    text: 'text-[#B42318]',
    label: 'Cancelado',
  },
  pending: {
    bg: 'bg-[#FFFAEB]',
    text: 'text-[#B54708]',
    label: 'Propuesta',
  },
  onfabrication: {
    bg: 'bg-[#C9E9FD]',
    text: 'text-[#0770B1]',
    label: 'En fabricación',
  },
  accepted: {
    bg: 'bg-[#ECFDF3]',
    text: 'text-[#027A48]',
    label: 'Aceptado',
  },
  sended: {
    bg: 'bg-[#ECFDF3]',
    text: 'text-[#027A48]',
    label: 'Enviado',
  },
  design: {
    bg: 'bg-[#ECFDF3]',
    text: 'text-[#027A48]',
    label: 'Diseño',
  },
  inReview: {
    bg: 'bg-[#ECFDF3]',
    text: 'text-[#027A48]',
    label: 'En revisión',
  },
  CaseReopened: {
    bg: 'bg-[#ECFDF3]',
    text: 'text-[#027A48]',
    label: 'Reabierto',
  },
  completed: {
    bg: 'bg-[#D1FAE5]',
    text: 'text-[#166534]',
    label: 'Completado',
  },
};

export const BillLabCasestatusStyles = {
  created: {
    bg: 'bg-[#FFFAEB]',
    text: 'text-[#B54708]',
    label: 'Propuesta',
  },
  accepted: {
    bg: 'bg-[#ECFDF3]',
    text: 'text-[#027A48]',
    label: 'Aceptado',
  },
  canceled: {
    bg: 'bg-[#FEF3F2]',
    text: 'text-[#B42318]',
    label: 'Cancelado',
  },

  reSended: {
    bg: 'bg-[#C9E9FD]',
    text: 'text-[#0770B1]',
    label: 'En revisión',
  },
  rejected: {
    bg: 'bg-[#FEF3F2]',
    text: 'text-[#B42318]',
    label: 'Rechazado',
  },
  billed: {
    bg: 'bg-[#D1FAE5]',
    text: 'text-[#166534]',
    label: 'Facturado',
  },
};

export const statusSteps = [
  'pending',
  'design',
  'onfabrication',
  'sended',
  'accepted',
];

export const statusStepsDesign = ['pending', 'design', 'sended', 'accepted'];

export const BillLabCasestatusSteps = ['created', 'accepted'];

export const BillLabStatusSteps = ['created', 'paid'];

export const selectedOptionList = [
  {
    name: 'Corona / Puente / Arcada completa',
    selectedOption: 'Corona',
  },

  {
    name: 'Carillas / Incrustación / onlay',
    selectedOption: 'Carillas',
  },
  {
    name: 'Dentadura / Parcial / Esquelético',
    selectedOption: 'Dentadura',
  },
  {
    name: 'Férulas',
    selectedOption: 'Férulas',
  },
  {
    name: 'Ortodoncia Invisible',
    selectedOption: 'Aligners',
  },
  {
    name: 'Retenedor',
    selectedOption: 'Retainers',
  },
  {
    name: 'ProtectorBucal',
    selectedOption: 'Protector Bucal',
  },
  {
    name: 'AntiRonquido',
    selectedOption: 'Anti Ronquido',
  },
  {
    name: 'Grillz',
    selectedOption: 'Grillz',
  },
  {
    name: 'Modelo',
    selectedOption: 'Modelo',
  },
  {
    name: 'ReparacionRebase',
    selectedOption: 'Reparación / Rebase',
  },
  {
    name: 'Biomaterial',
    selectedOption: 'Biomaterial',
  },
  {
    name: 'Aparatos Ortodoncia',
    selectedOption: 'OrthodonticAppliances',
  },
  {
    name: 'Ortodoncia Invisible',
    selectedOption: 'Aligners',
  },
  {
    name: 'Retenedor',
    selectedOption: 'Retainers',
  },
];

export const MaterialList = [
  {
    value: 'Zirconio',
    name: 'Zirconio',
  },
  {
    value: 'Metalceramica',
    name: 'Metal / Cerámica',
  },
  {
    value: 'Composite',
    name: 'Composite',
  },
  {
    value: 'Impreso3D',
    name: 'Impresión 3D',
  },
  {
    value: 'Acrilico',
    name: 'Acrílico',
  },
  {
    value: 'DisilicatoLitio',
    name: 'Disilicato de litio',
  },
  {
    value: 'Resina',
    name: 'Resina',
  },
  {
    value: 'Metal',
    name: 'Metal',
  },
  {
    value: 'Plastico',
    name: 'Plástico',
  },
  {
    value: 'Fresada',
    name: 'Fresada',
  },
  {
    value: 'Colada',
    name: 'Colada',
  },
  {
    value: 'Feldespatica',
    name: 'Feldespatica',
  },
  {
    value: 'CRCOden',
    name: 'CrCo',
  },
  {
    value: 'TIden',
    name: 'Ti',
  },
  {
    value: 'Otros',
    name: 'Otros',
  },
];
