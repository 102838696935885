import { useEffect, useState, useCallback } from 'react';
import { getReferrals, createReferral } from '@/services/referral';

import { useUserStore } from '@/store/userStore';
import { toast } from 'react-toastify';

export const useFetchReferrals = (searchParams) => {
  const { user } = useUserStore();
  const [referrals, setReferrals] = useState([]);
  const [total, setTotal] = useState(0);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  const fetchReferrals = useCallback(async () => {
    setLoading(true);
    setError(null);

    try {
      const params = Object.fromEntries(searchParams);
      const queryString = new URLSearchParams(params).toString();
      let fetcher;

      fetcher = getReferrals;

      const data = await fetcher({ queryString });
      setReferrals(data.referrals);
      setTotal(data.total);
    } catch (error) {
      setError(error.message || 'Failed to fetch referrals');
      console.error('Failed to fetch referrals:', error);
    } finally {
      setLoading(false);
    }
  }, [searchParams, user.role]);

  useEffect(() => {
    fetchReferrals();
  }, [searchParams]);

  return { referrals, loading, error, total, fetchReferrals };
};

export const useCreateReferral = () => {
  const [link, setLink] = useState(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  const handleCreateReferralLink = async (email) => {
    setLoading(true);
    setError(null);

    try {
      if (!email.includes('@')) {
        toast.error('formato no valido para correo');
        return;
      }
      const data = { email };
      const response = await createReferral(data);
      if (response) {
        toast.success('Referral created successfully');
        setLink(response.referralCode.token);
      } else {
        toast.error('Failed to create referral');
      }
    } catch (error) {
      console.error(error);
      toast.error('Failed to create referral');
    } finally {
      setLoading(false);
    }
  };

  return { link, loading, error, handleCreateReferralLink };
};
