import React, { useState, useEffect, useRef } from 'react';
import { useParams, useSearchParams } from 'react-router-dom';
import { getRevisionsByDemand } from '@/services/revision';
import TableRevisions from '@/components/Tables/TableRevisions';
import useUserStore from '@/store/userStore';
import { toast } from 'react-toastify';
import useNotificationStore from '@/store/Notificationstore';
import RevisionModal from '@/components/Modals/RevisionModal';
import { useFetchDemand, useUpdateDemand } from '@/hooks/useDemand';
import { updateDemandNotifications } from '@/services/demand';
import io from 'socket.io-client';

const Revisions = () => {
  const socket = useRef(null);
  const { user, logout } = useUserStore();
  const { addNotification } = useNotificationStore();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [revisions, setRevisions] = useState([]);
  const [total, setTotal] = useState(0);
  const [searchParams, setSearchParams] = useSearchParams();
  const { caseId } = useParams();

  const { demand, fetchDemand } = useFetchDemand(caseId);
  const { updateDemand } = useUpdateDemand();

  const fetchRevisions = async () => {
    try {
      const { revisions, total } = await getRevisionsByDemand(caseId);
      setRevisions(revisions);
      setTotal(total);
    } catch (error) {
      console.error('Error fetching commissions:', error);
    }
  };

  // Fetch commissions when searchParams or id changes
  useEffect(() => {
    fetchRevisions();
  }, [caseId]);

  useEffect(() => {
    const API_BASE_URL = import.meta.env.VITE_API_BASE_URL;
    socket.current = io(API_BASE_URL);
    socket.current.emit('setup', { user });
    socket.current.emit('joinRoom', caseId);

    const handleReceiveMessage = async (receivedMessage) => {
      toast.success('Nuevo mensaje recibido');
      addNotification({
        message: 'Nuevo mensaje recibido',
        type: 'message',
        chat: receivedMessage.chat,
      });

      await updateDemandNotifications({ id: receivedMessage.chat });
    };

    const handleReceiveOffer = (offer) => {
      fetchDemand();
    };
    const handleReceiveOrder = (order) => {
      fetchDemand();
    };

    const handleReceiveUpdateCase = (demand) => {
      fetchDemand();
      fetchRevisions();
    };
    const handleReceiveAcceptedBillLabCaseSeller = (billLabCase) => {
      fetchDemand();
    };
    const handleReceiveAcceptedBillLabCaseBuller = (billLabCase) => {
      fetchDemand();
    };
    const handleReceiveRejectedBillLabCase = (billLabCase) => {
      fetchDemand();
    };

    socket.current.on('receiveMessage', handleReceiveMessage);
    socket.current.on('receiveOffer', handleReceiveOffer);
    socket.current.on('receiveOrder', handleReceiveOrder);
    socket.current.on('receiveUpdateCase', handleReceiveUpdateCase);
    socket.current.on(
      'receiveAcceptedBillLabCaseSeller',
      handleReceiveAcceptedBillLabCaseSeller,
    );
    socket.current.on(
      'receiveAcceptedBillLabCaseBuller',
      handleReceiveAcceptedBillLabCaseBuller,
    );
    socket.current.on(
      'receiveRejectedBillLabCase',
      handleReceiveRejectedBillLabCase,
    );

    return () => {
      socket.current.emit('leaveRoom', caseId);
      socket.current.off('receiveMessage', handleReceiveMessage);
      socket.current.off('receiveOffer', handleReceiveOffer);
      socket.current.off('receiveOrder', handleReceiveOrder);
      socket.current.off('receiveUpdateCase', handleReceiveUpdateCase);
      socket.current.off(
        'receiveAcceptedBillLabCaseSeller',
        handleReceiveAcceptedBillLabCaseSeller,
      );
      socket.current.off(
        'receiveAcceptedBillLabCaseBuller',
        handleReceiveAcceptedBillLabCaseBuller,
      );
      socket.current.off(
        'receiveRejectedBillLabCase',
        handleReceiveRejectedBillLabCase,
      );
      socket.current.disconnect();
    };
  }, [caseId]);

  const handleStatusChange = async (newStatus) => {
    try {
      await updateDemand(demand._id, { status: newStatus });

      const statusMessages = {
        onfabrication: 'Orden en fabricación',
        sended: 'Orden enviada',
        completed: 'Orden lista',
        accepted: 'Trabajo Completado',
        inReview: 'En revisión',
        design: 'En diseño',
      };

      if (statusMessages[newStatus]) {
        socket.current.emit('updateCase', {
          demand,
          status: statusMessages[newStatus],
          user,
        });
      }
    } catch (error) {
      console.error('Error updating order status:', error);
      toast.error('Error al actualizar el estado del pedido');
    } finally {
      fetchDemand();
    }
  };

  return (
    <div className="w-full p-2 md:px-6 md:py-3 space-y-2">
      <RevisionModal
        caseId={caseId}
        isOpen={isModalOpen}
        setIsOpen={setIsModalOpen}
        handleStatusChange={handleStatusChange}
      />
      <TableRevisions
        revisions={revisions}
        total={total}
        setSearchParams={setSearchParams}
        searchParams={searchParams}
      />

      <div className="flex flex-col md:flex-row w-full justify-end md:space-x-1 space-y-2 md:space-y-0 font-inter">
        {user.role === 'DENTIST_ROLE' && demand.status === 'sended' && (
          <button
            type="button"
            className="btn-neutral w-full md:w-80 text-white font-bold py-2 px-4 rounded flex items-center justify-center"
            onClick={() => setIsModalOpen(true)}
          >
            Solicitar revisión
          </button>
        )}
        {user.role === 'SELLER_ROLE' && demand.status === 'inReview' && (
          <button
            type="button"
            className="bg-red-500 w-full md:w-80 hover:bg-red-700 text-white font-bold py-2 px-4 rounded flex items-center justify-center"
            onClick={() => setIsModalOpen(true)}
          >
            Responder Revisión
          </button>
        )}
      </div>
    </div>
  );
};

export default Revisions;
