import React from 'react';
import {
  MaterialCorona,
  MaterialDentadura,
  MaterialCarillas,
  MaterialBarra,
  MaterialTipoBarra,
  RefuerzoList,
  listColor,
  TipodeFerula,
  MateriaFedula,
  ortoType,
  AlignersList,
  tipoList,
} from '@/constants/caseType';

import { Card } from '@/components/ui/card';
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from '@/components/ui/table';

const CaseTypeCardAdmin = ({ caseData, setCaseData }) => {
  if (!caseData) {
    return null;
  }

  const handleSelectChange = (field, value) => {
    setCaseData((prev) => ({
      ...prev,
      selectedImplant: {
        ...prev.selectedImplant,
        [field]: value,
      },
    }));
  };

  const handleSelectOrtodonciaChange = (field, value) => {
    setCaseData((prev) => ({
      ...prev,
      ortodonciaReq: {
        ...prev.ortodonciaReq,
        [field]: value,
      },
    }));
  };

  const renderTable = (headers, rows) => (
    <Card className="w-fit mx-auto my-2">
      <div className="overflow-x-auto">
        <Table className="w-full rounded-md">
          <TableHeader className="bg-[#e9ecf2]">
            <TableRow>
              {headers.map((header, index) => (
                <TableHead
                  key={index}
                  className="px-1 py-1 border border-gray-300 text-center "
                >
                  {header}
                </TableHead>
              ))}
            </TableRow>
          </TableHeader>
          <TableBody>
            {rows.map((row, rowIndex) => (
              <TableRow key={rowIndex} className="bg-white">
                {row.map((cell, cellIndex) => (
                  <TableCell
                    key={cellIndex}
                    className="border px-2 py-1 text-center"
                  >
                    {typeof cell === 'string' ? cell : cell}
                  </TableCell>
                ))}
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </div>
    </Card>
  );

  let headers = [];
  let rows = [];

  // Case for 'Corona'
  if (caseData.selectedImplant) {
    switch (caseData.selectedImplant.selectedOption) {
      case 'Corona':
        headers = [
          'Opción seleccionada',
          'Material de Implante',
          'Color de implante',
          'Con Implante',
          'Implante con barra',
          'Material de la Barra',
          'Tipo de Barra',
        ];
        rows = [
          [
            caseData.selectedImplant.selectedOption,
            <select
              id="material-corona"
              value={caseData.selectedImplant.material}
              onChange={(e) => handleSelectChange('material', e.target.value)}
              required
              className="border bg-white rounded-md p-2 w-full"
            >
              <option value="">Seleccione una opción</option>
              {MaterialCorona.map((material, index) => (
                <option key={index} value={material.value}>
                  {material.name}
                </option>
              ))}
            </select>,
            <select
              id="color-corona"
              value={caseData.selectedImplant.color}
              onChange={(e) => handleSelectChange('color', e.target.value)}
              required
              className="border bg-white rounded-md p-2 w-full"
            >
              <option value="">Seleccione una opción</option>
              {listColor.map((color, index) => (
                <option key={index} value={color}>
                  {color}
                </option>
              ))}
            </select>,
            <input
              type="checkbox"
              checked={caseData.selectedImplant.Mockup}
              onChange={(e) => handleSelectChange('Mockup', e.target.checked)}
            />,
            <input
              type="checkbox"
              checked={caseData.selectedImplant.onImplant}
              onChange={(e) =>
                handleSelectChange('onImplant', e.target.checked)
              }
            />,
            <input
              type="checkbox"
              checked={caseData.selectedImplant.withBar}
              onChange={(e) => handleSelectChange('withBar', e.target.checked)}
            />,
            <select
              id="material-barra"
              value={caseData.selectedImplant.selectMaterialBarra}
              onChange={(e) =>
                handleSelectChange('selectMaterialBarra', e.target.value)
              }
              className="border bg-white rounded-md p-2 w-full"
              required
            >
              <option value="">Seleccione</option>
              {MaterialBarra.map((material, index) => (
                <option key={index} value={material.value}>
                  {material.name}
                </option>
              ))}
            </select>,
            <select
              id="tipo-barra"
              value={caseData.selectedImplant.selectTipoBarra}
              onChange={(e) =>
                handleSelectChange('selectTipoBarra', e.target.value)
              }
              required
              className="border bg-white rounded-md p-2 w-full"
            >
              <option value="">Seleccione</option>
              {MaterialTipoBarra.map((material, index) => (
                <option key={index} value={material.value}>
                  {material.name}
                </option>
              ))}
            </select>,
          ],
        ];
        break;

      // Add similar cases for 'Dentadura', 'Férulas', etc.
      // Don't forget to call `break;` after each case.
    }
    return renderTable(headers, rows);
  }

  // Case for 'Ortodoncia'
  if (caseData.ortodonciaReq) {
    headers = ['Opción seleccionada:', 'Subselección:'];
    rows = [
      [
        caseData.ortodonciaReq.selectedOption,
        <select
          id="subselection-ortodoncia"
          value={caseData.ortodonciaReq.subSelection}
          onChange={(e) =>
            handleSelectOrtodonciaChange('subSelection', e.target.value)
          }
          required
          className="border bg-white rounded-md p-2 w-full"
        >
          <option value="">Seleccione</option>
          {ortoType.map((material, index) => (
            <option key={index} value={material.value}>
              {material.name}
            </option>
          ))}
        </select>,
      ],
    ];
    return renderTable(headers, rows);
  }

  // Case for 'Productos'
  if (caseData.selectedProduct) {
    headers = ['Producto seleccionado:'];
    rows = [[caseData.selectedProduct]];
    return renderTable(headers, rows);
  }

  return null;
};

export default CaseTypeCardAdmin;
