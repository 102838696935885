import React, { useState } from 'react';
import { Dialog, DialogContent, DialogTitle } from '@/components/ui/dialog';
import { Button } from '@/components/ui/button';
import { Input } from '@/components/ui/input';
import { Label } from '@/components/ui/label';
import { toast } from 'react-toastify';
import { createCommissionplanAsAdmin } from '@/services/commissionplan';

const CommissionPlanFormModal = ({
  id,
  isOpen,
  setIsOpen,
  fetchCommissions,
}) => {
  const [commission, setCommission] = useState('');
  const today = new Date().toISOString().split('T')[0];
  const [expDate, setExpDate] = useState('');
  const [loading, setLoading] = useState(false);

  // Close modal
  const handleDialogClose = () => setIsOpen(false);

  // Reset form fields
  const resetForm = () => {
    setCommission('');
    setExpDate('');
  };

  // Handle form submission
  const handleSubmit = async (event) => {
    event.preventDefault();
    setLoading(true);

    if (!commission || !expDate)
      return toast.error('Por favor, complete todos los campos');

    const commissionplanData = {
      commission,
      expDate,
      id,
    };

    try {
      const { commissionPlan } = await createCommissionplanAsAdmin({
        commissionplanData,
      });

      if (commissionPlan) {
        toast.success('Plan creado exitosamente');
        resetForm();
        fetchCommissions();
        handleDialogClose(); // Close modal after success
      }
    } catch (error) {
      console.error('Failed to create Plan:', error);
      toast.error('Error al crear el plan');
    } finally {
      setLoading(false);
    }
  };

  return (
    <Dialog open={isOpen} onOpenChange={handleDialogClose}>
      <DialogContent>
        <DialogTitle className="flex justify-between items-center">
          Crear Plan de Comisión
        </DialogTitle>
        <form onSubmit={handleSubmit} className="flex flex-col gap-4">
          <div>
            <Label>Fecha válida de la comisión</Label>
            <Input
              type="date"
              value={expDate}
              onChange={(e) => setExpDate(e.target.value)}
              className="mt-1 block w-full p-2 border border-gray-300 rounded-lg shadow-sm"
              min={today}
            />
          </div>
          <div>
            <Label>Comisión</Label>
            <div className="relative">
              <Input
                type="text"
                value={commission === 0 ? '' : commission.toString()}
                onChange={(e) => {
                  const value = e.target.value;
                  const regex = /^\d*\.?\d{0,2}$/; // Allow digits and up to 2 decimal places

                  // If the input matches the regex (valid number format) or is empty
                  if (value === '' || regex.test(value)) {
                    setCommission(value); // Set value as is (string)
                  }
                }}
                onBlur={() => {
                  // Convert the input value to a float with two decimal places on blur
                  setCommission((prevValue) =>
                    prevValue === '' ? 0 : parseFloat(prevValue).toFixed(2),
                  );
                }}
                className="mt-1 block w-full p-2 border border-gray-300 rounded-lg shadow-sm"
              />
              <span className="absolute right-2 top-2/4 transform -translate-y-2/4 text-gray-500">
                %
              </span>
            </div>
          </div>

          <div>
            <Button
              type="submit"
              className="w-full bg-blue-500 text-white hover:bg-blue-400"
              disabled={loading || !commission || !expDate}
            >
              {loading ? 'Creando...' : 'Crear Plan'}
            </Button>
          </div>
        </form>
      </DialogContent>
    </Dialog>
  );
};

export default CommissionPlanFormModal;
