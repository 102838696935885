import axios from 'axios';

const API_BASE_URL = import.meta.env.VITE_API_BASE_URL;

const axiosInstance = axios.create({
  baseURL: `${API_BASE_URL}/api`,
  headers: {
    'Content-Type': 'application/json',
  },
  withCredentials: true,
});

axiosInstance.interceptors.request.use(
  (config) => {
    const token = localStorage.getItem('token');
    if (token) {
      config.headers['Authorization'] = `Bearer ${token}`;
    }
    return config;
  },
  (error) => Promise.reject(error),
);

axiosInstance.interceptors.response.use(
  (response) => response,
  (error) => {
    if (error.response && error.response.status === 401) {
      console.error('Unauthorized, redirecting to login');
      localStorage.removeItem('token');
    }
    return Promise.reject(error);
  },
);

export const createOffer = async (offerData) => {
  try {
    const response = await axiosInstance.post('/offers', offerData);
    const data = response.data;
    return data;
  } catch (error) {
    console.error('Error creating offer:', error);
    throw error;
  }
};

export const getOffer = async (offerId) => {
  try {
    const response = await axiosInstance.get(`/offers/${offerId}`);
    const data = response.data;
    return data;
  } catch (error) {
    console.error('Error getting offer:', error);
    throw error;
  }
};

export const updateOffer = async (offerId, offerData) => {
  try {
    const response = await axiosInstance.put(`/offers/${offerId}`, offerData);
    const data = response.data;
    return data;
  } catch (error) {
    console.error('Error updating offer:', error);
    throw error;
  }
};

//outsourcing
export const createOfferOutsourcing = async (offerOutsourcingData) => {
  try {
    const response = await axiosInstance.post(
      '/offers/outsourcing',
      offerOutsourcingData,
    );
    const data = response.data;
    return data;
  } catch (error) {
    console.error('Error creating offer outsourcing:', error);
    throw error;
  }
};

export const getOfferOutsourcing = async (offerOutsourcingId) => {
  try {
    const response = await axiosInstance.get(
      `/offers/outsourcing/${offerOutsourcingId}`,
    );
    const data = response.data;
    return data;
  } catch (error) {
    console.error('Error getting offer outsourcing:', error);
    throw error;
  }
};

export const updateOfferOutsourcing = async (
  offerOutsourcingId,
  offerOutsourcingData,
) => {
  try {
    const response = await axiosInstance.put(
      `/offers/outsourcing/${offerOutsourcingId}`,
      offerOutsourcingData,
    );
    const data = response.data;
    return data;
  } catch (error) {
    console.error('Error updating offer outsourcing:', error);
    throw error;
  }
};
