import axios from 'axios';

const API_BASE_URL = import.meta.env.VITE_API_BASE_URL;

const axiosInstance = axios.create({
  baseURL: `${API_BASE_URL}/api`,
  headers: {
    'Content-Type': 'application/json',
  },
  withCredentials: true,
});

// Interceptor para añadir token
axiosInstance.interceptors.request.use(
  (config) => {
    const token = localStorage.getItem('token');
    if (token) {
      config.headers['Authorization'] = `Bearer ${token}`;
    }
    return config;
  },
  (error) => Promise.reject(error),
);

// Interceptor para manejar errores
axiosInstance.interceptors.response.use(
  (response) => response,
  (error) => {
    if (error.response && error.response.status === 401) {
      console.error('Unauthorized, redirecting to login');
      localStorage.removeItem('token');
      // Redirigir a la página de inicio de sesión aquí si es necesario
    }
    return Promise.reject(error);
  },
);

// Función genérica para manejar respuestas
const handleResponse = async (request) => {
  try {
    const response = await request();
    return response.data;
  } catch (error) {
    console.error('Error in API request:', error);
    throw error; // Puedes lanzar un error más amigable aquí
  }
};

// Funciones de API
export const createGuide = (guideData) =>
  handleResponse(() => axiosInstance.post('/guides', guideData));

export const getGuides = ({ queryString }) =>
  handleResponse(() => axiosInstance.get(`/guides?${queryString}`));

export const getGuideById = (GuideId) =>
  handleResponse(() => axiosInstance.get(`/guides/${GuideId}`));

export const deleteGuide = (GuideId) =>
  handleResponse(() => axiosInstance.delete(`/guides/${GuideId}`));

export const updateGuide = (GuideId, guideData) =>
  handleResponse(() => axiosInstance.put(`/guides/${GuideId}`, guideData));

export const creategGuidecategory = (guidecategoryData) =>
  handleResponse(() =>
    axiosInstance.post('/guides/guidecategory', guidecategoryData),
  );

export const getGuidecategories = ({ queryString }) =>
  handleResponse(() =>
    axiosInstance.get(`/guides/guidecategory?${queryString}`),
  );

export const getGuidecategoryById = (GuidecategoryId) =>
  handleResponse(() =>
    axiosInstance.get(`/guides/guidecategory/${GuidecategoryId}`),
  );

export const deleteGuidecategory = (GuidecategoryId) =>
  handleResponse(() =>
    axiosInstance.delete(`/guides/guidecategory/${GuidecategoryId}`),
  );

export const updateGuidecategory = (GuidecategoryId, guidecategoryData) =>
  handleResponse(() =>
    axiosInstance.put(
      `/guides/guidecategory/${GuidecategoryId}`,
      guidecategoryData,
    ),
  );
