import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { getGuides } from '@/services/guide';
import {
  Accordion,
  AccordionItem,
  AccordionTrigger,
  AccordionContent,
} from '@/components/ui/accordion';

const Sidebar = () => {
  const [guidesByCategory, setGuidesByCategory] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchGuides = async () => {
      setLoading(true);
      setError(null); // Reset error state on new fetch
      try {
        const params = { skip: 0, limit: 50 };
        const queryString = new URLSearchParams(params).toString();
        const { guidesByCategory } = await getGuides({ queryString });
        setGuidesByCategory(guidesByCategory);
      } catch (error) {
        console.error('Error fetching guides:', error);
        setError('Error al cargar las guías. Intente nuevamente más tarde.');
      } finally {
        setLoading(false);
      }
    };

    fetchGuides();
  }, []);

  return (
    <div className="flex flex-col h-full min-h-screen w-64 bg-gray-800 text-white p-4 overflow-y-auto">
      <div className="mb-4"></div>
      {loading ? (
        <p className="text-center">Cargando guías...</p>
      ) : error ? (
        <p className="text-red-400 text-center">{error}</p>
      ) : (
        <Accordion type="single" collapsible className="space-y-4">
          {guidesByCategory
            .filter((category) => category.guides.length > 0) // Filter out empty categories
            .map((category) => (
              <AccordionItem key={category.category} value={category.category}>
                <AccordionTrigger className="text-xl font-semibold text-gray-300">
                  {category.category}
                </AccordionTrigger>
                <AccordionContent>
                  <ul className="space-y-1">
                    {category.guides.map((guide) => (
                      <li
                        key={guide._id}
                        className="hover:bg-gray-700 rounded-md"
                      >
                        <Link
                          to={`/guides/${guide.slug}`}
                          className="flex items-center p-3"
                        >
                          <span className="mr-2">📖</span>
                          <span className="font-medium">{guide.title}</span>
                        </Link>
                      </li>
                    ))}
                  </ul>
                </AccordionContent>
              </AccordionItem>
            ))}
        </Accordion>
      )}
    </div>
  );
};

export default Sidebar;
