import { Routes, Route } from 'react-router-dom';
import OutsourcingLayout from '@/components/Layouts/OutsourcingLayout';
import OutsourcingPage from './OutsourcingPage.jsx';
import OutsourcingsPage from './OutsourcingsPage.jsx';
import ChatPage from './ChatPage.jsx';
import NewOutsourcing from './NewOutsourcing.jsx';
import { SellerGuard } from '@/guards/SellerGuard.jsx';
import OfferPage from './OfferPage.jsx';
import Revisions from './Revisions.jsx';
import NewBillOutsorcingBuyer from './NewBillOutsorcingBuyer.jsx';
import BillOutsorcingBuyer from './BillOutsorcingBuyer.jsx';

const OutsourcingsRoutes = () => {
  return (
    <Routes>
      <Route element={<SellerGuard />}>
        <Route path="/" element={<OutsourcingsPage />} />
        <Route element={<OutsourcingLayout />}>
          <Route path="/:outsourcingId" element={<OutsourcingPage />} />
          <Route path="/chat/:chatId" element={<ChatPage />} />
          <Route path="/offer/:offerId" element={<OfferPage />} />
          <Route path="/revisions/:outsourcingId" element={<Revisions />} />
          <Route
            path="/createBill/:outsourcingId"
            element={<NewBillOutsorcingBuyer />}
          />
          <Route path="/bill/:billId" element={<BillOutsorcingBuyer />} />
        </Route>
        <Route path="/newoutsourcing" element={<NewOutsourcing />} />
      </Route>
    </Routes>
  );
};

export default OutsourcingsRoutes;
