import { useSearchParams } from 'react-router-dom';
import TablebillLabOutsourcingBuyersList from '@/components/Tables/TablebillLabOutsourcingBuyersList';
import { useFetchBillOutsourcingBuyers } from '@/hooks/useBillOutsourcingBuyer';

const BillLabCasesListAdmin = () => {
  const [searchParams, setSearchParams] = useSearchParams('status=accepted');

  const { billOutsourcingBuyers, loading, total } =
    useFetchBillOutsourcingBuyers(searchParams);

  if (loading) return <p>Loading...</p>;

  return (
    <div className="">
      <section>
        <TablebillLabOutsourcingBuyersList
          billlabcases={billOutsourcingBuyers}
          total={total}
          setSearchParams={setSearchParams}
          searchParams={searchParams}
        />
      </section>
    </div>
  );
};

export default BillLabCasesListAdmin;
