import React from 'react';
import { Card, CardContent } from '@/components/ui/card'; // Ajusta la ruta según tu estructura
import { Avatar, AvatarImage, AvatarFallback } from '@/components/ui/avatar';
import RatingCard from '@/components/ui/RatingCard';
import { Label } from '@/components/ui/label';
import { FaRegUser } from 'react-icons/fa';
import { useDemandStore } from '@/store/DemandStore';

const LabCard = () => {
  const { offer } = useDemandStore();
  return (
    <Card className="px-1 w-full md:px-4 rounded-lg  bg-white flex items-center">
      <CardContent className="w-full flex flex-row gap-4 items-center pt-10 ">
        {/* Avatar responsive */}
        <Avatar className="w-14 h-14 md:w-20 md:h-20 rounded-full flex-shrink-0  flex items-center">
          <AvatarImage src={offer.seller.avatar} alt={offer.seller.name} />
          <AvatarFallback>
            <FaRegUser className="text-4xl" />
          </AvatarFallback>
        </Avatar>

        {/* Información del vendedor */}
        <div className="flex flex-col justify-center  gap-2 font-inter">
          <Label className="text-lg md:text-xl  font-bold text-center sm:text-left">
            {offer.seller.labName}
          </Label>

          <Label className="text-sm md:text-base text-gray-500 text-center sm:text-left">
            Número de colegiado: {offer.seller.colNumber || '1234'}
          </Label>

          <div className="flex justify-center sm:justify-start">
            <RatingCard rating={Math.ceil(offer.seller.score)} />
          </div>
        </div>
      </CardContent>
    </Card>
  );
};

export default LabCard;
