import React, { useState } from 'react';
import { creategGuidecategory } from '@/services/guide'; // Asegúrate de que esta función existe
import { toast } from 'react-toastify';

const GuideCategoryCreate = () => {
  const [name, setName] = useState('');
  const [loading, setLoading] = useState(false);

  const handleSave = async () => {
    setLoading(true);
    try {
      const categoryData = { name };
      await creategGuidecategory(categoryData); // Llama a la función para crear la categoría
      toast.success('Categoría creada con éxito');
      // Limpiar el formulario
      setName('');
    } catch (error) {
      if (error.response && error.response.status === 400) {
        toast.error('Ya existe una categoría con ese nombre');
        return;
      }
      const errorMessage =
        error.response?.data?.message || 'Error al crear la categoría';
      toast.error(errorMessage);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="max-w-md mx-auto p-6 bg-white rounded-lg shadow-md">
      <h1 className="text-2xl font-semibold mb-4 text-gray-800">
        Nueva Categoría de Guía
      </h1>
      <div className="space-y-4">
        <div>
          <label className="block text-gray-700 font-medium mb-2">Nombre</label>
          <input
            type="text"
            value={name}
            onChange={(e) => setName(e.target.value)}
            className="w-full p-3 border border-gray-300 rounded-lg shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
            required
          />
        </div>

        <button
          onClick={handleSave}
          disabled={loading}
          className={`w-full py-3 px-4 ${
            loading ? 'bg-gray-400' : 'bg-blue-500'
          } text-white font-semibold rounded-lg shadow-md hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2`}
        >
          {loading ? 'Creando...' : 'Crear Categoría'}
        </button>
      </div>
    </div>
  );
};

export default GuideCategoryCreate;
