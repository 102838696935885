import axios from 'axios';

const API_BASE_URL = import.meta.env.VITE_API_BASE_URL;

const axiosInstance = axios.create({
  baseURL: `${API_BASE_URL}/api`,
  headers: {
    'Content-Type': 'application/json',
  },
  withCredentials: true,
});

// Interceptor para añadir token
axiosInstance.interceptors.request.use(
  (config) => {
    const token = localStorage.getItem('token');
    if (token) {
      config.headers['Authorization'] = `Bearer ${token}`;
    }
    return config;
  },
  (error) => Promise.reject(error),
);

// Interceptor para manejar errores
axiosInstance.interceptors.response.use(
  (response) => response,
  (error) => {
    if (error.response && error.response.status === 401) {
      console.error('Unauthorized, redirecting to login');
      localStorage.removeItem('token');
      // Redirigir a la página de inicio de sesión aquí si es necesario
    }
    return Promise.reject(error);
  },
);

// Función genérica para manejar respuestas
const handleResponse = async (request) => {
  try {
    const response = await request();
    return response.data;
  } catch (error) {
    console.error('Error in API request:', error);
    throw error; // Puedes lanzar un error más amigable aquí
  }
};

// Funciones de API
export const createDoc = (docData) =>
  handleResponse(() => axiosInstance.post('/docs', docData));

export const getDocs = ({ queryString }) =>
  handleResponse(() => axiosInstance.get(`/docs?${queryString}`));

export const getDocById = (DocId) =>
  handleResponse(() => axiosInstance.get(`/docs/${DocId}`));

export const deleteDoc = (DocId) =>
  handleResponse(() => axiosInstance.delete(`/docs/${DocId}`));

export const updateDoc = (DocId, docData) =>
  handleResponse(() => axiosInstance.put(`/docs/${DocId}`, docData));
