import { Card, CardContent } from '@/components/ui/card';
import { ScrollArea } from '@/components/ui/scroll-area';

const TableRevisions = ({ revisions }) => {
  const dateFormat = (date) => {
    const newDate = new Date(date);
    return newDate.toLocaleDateString('es-ES', {
      year: 'numeric',
      month: 'long',
      day: 'numeric',
    });
  };

  return (
    <div className="overflow-hidden">
      <ScrollArea className="flex-grow overflow-y-auto ">
        {revisions.length > 0 ? (
          revisions.map((revision) => (
            <Card className="h-full w-full px-2 py-2" key={revision._id}>
              <CardContent className="w-full py-5">
                <div className="flex flex-col gap-2">
                  <div className="w-full flex flex-row justify-between">
                    <div>
                      <strong>Motivo revisión:</strong>
                    </div>
                    <div>{dateFormat(revision.createdAt)}</div>
                  </div>
                  <div className="flex text-start">{revision.description}</div>
                </div>
                {revision.revisionResponse && (
                  <div className="flex flex-col gap-2 mt-4 p-4 bg-gray-100 border border-gray-300 rounded-lg">
                    <div className="w-full flex flex-row justify-between">
                      <div>
                        <strong>Solución propuesta:</strong>{' '}
                      </div>
                      <div>
                        <strong> Fecha entrega:</strong>{' '}
                        {dateFormat(
                          revision.revisionResponse.deliveryDateIntent,
                        )}
                      </div>
                    </div>
                    <div className="flex text-start">
                      {revision.revisionResponse.description}
                    </div>
                  </div>
                )}
              </CardContent>
            </Card>
          ))
        ) : (
          <div className="text-center py-4 text-gray-500">
            No hay revisiones
          </div>
        )}
      </ScrollArea>
    </div>
  );
};

export default TableRevisions;
