import React, { useState, useEffect } from 'react';
import TeethShowblue from '../TeethShowblue';
import TeethSel from '../TeethSel';
import { Label } from '@/components/ui/label';
import { ScrollArea } from '@/components/ui/scroll-area';

const TeethDetailsCard = ({ demand = {}, offer = {}, user, setCaseData }) => {
  const [selectedTeeth, setSelectedTeeth] = useState(
    demand.selectedTeeth || [],
  );
  const [description, setDescription] = useState(demand.description || '');

  useEffect(() => {
    if (setCaseData) {
      setCaseData((prevDemand) => ({
        ...prevDemand,
        selectedTeeth,
        description,
      }));
    }
  }, [selectedTeeth, description, setCaseData]);

  const handleSum = (offer) => {
    const subTotal = offer.subTotal || 0;
    const commission = offer.commission || 0;
    const total = subTotal + commission;
    return total;
  };

  return (
    <div className="flex w-full  max-w-md flex-col p-8 bg-white  rounded-md border-2">
      <Label
        color="blue-gray"
        className="mb-4 text-center justify-center text-xl font-bold"
      >
        Información de la Orden
      </Label>

      {demand.selectedTeeth && (
        <div className="mb-4  ">
          {user.role === 'ADMIN_ROLE' ? (
            <TeethSel
              selectedTeeth={selectedTeeth}
              setSelectedTeeth={setSelectedTeeth}
            />
          ) : (
            <TeethShowblue
              selectedTeeth={selectedTeeth}
              setSelectedTeeth={setSelectedTeeth}
            />
          )}
        </div>
      )}

      <div className="mb-2">
        <Label color="blue-gray" className="mb-2 font-bold">
          Descripción del caso:
        </Label>
        <Label>
          {user.role === 'ADMIN_ROLE' ? (
            <textarea
              type="text"
              value={description}
              onChange={(e) => setDescription(e.target.value)}
              className="border border-gray-300 rounded-md p-2 w-full"
              placeholder="Enter description"
            />
          ) : (
            <ScrollArea className="w-full h-24 rounded-md ">
              <p className="px-4 py-4 text-sm">{demand.description}</p>
            </ScrollArea>
          )}
        </Label>
      </div>

      <div className="mb-2 ">
        <Label color="blue-gray" className="mb-2 font-bold">
          Descripción de la orden:
        </Label>

        <ScrollArea className="w-full max-h-24 h-fit  rounded-md  ">
          <p className="px-4 py-2 text-sm">{offer.description}</p>
        </ScrollArea>
      </div>

      <div className="flex flex-row justify-around">
        <div className="flex flex-col space-y-2">
          {user.role == 'DENTIST_ROLE' ? (
            <p>
              <strong>Trabajo </strong> {handleSum(offer)}€
            </p>
          ) : (
            <>
              <p>
                <strong>Trabajo </strong> {offer.subTotal || 'N/A'}€
              </p>
              <p>
                <strong>Comisión </strong> {offer.commission || 0}€
              </p>
            </>
          )}
          <p>
            <strong>Envio</strong> {offer.delivery || 'N/A'}€
          </p>
        </div>
        <div className="flex flex-col space-y-2 text-right">
          <Label color="blue-gray">
            {' '}
            <strong>Precio Total</strong>
          </Label>
          <Label color="blue-gray">
            {offer.total != null ? `${offer.total.toFixed(2)}€` : 'N/A'}
          </Label>
        </div>
      </div>
    </div>
  );
};

export default TeethDetailsCard;
