import { useSearchParams } from 'react-router-dom';
import TablebillLabOutsourcings from '@/components/Tables/TablebillLabOutsourcings';
import { useFetchBillOutsourcingBuyers } from '@/hooks/useBillOutsourcingBuyer';

const BillOutsorcingBuyersAdmin = () => {
  const [searchParams, setSearchParams] = useSearchParams();

  const { billOutsourcingBuyers, loading, total } =
    useFetchBillOutsourcingBuyers(searchParams);

  if (loading) return <p>Loading...</p>;
  return (
    <div className="">
      <section>
        <TablebillLabOutsourcings
          billOutsourcingBuyers={billOutsourcingBuyers}
          total={total}
          setSearchParams={setSearchParams}
          searchParams={searchParams}
        />
      </section>
    </div>
  );
};

export default BillOutsorcingBuyersAdmin;
