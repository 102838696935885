import React from 'react';
import { FaArrowUp, FaArrowDown } from 'react-icons/fa';
import { Label } from '@/components/ui/label';

const OrderCard = ({ title, number, img, money }) => {
  const formatNumber = (number) => {
    return number
      .toFixed(2) // Ensures the number has two decimal places
      .replace('.', ',') // Replace the decimal point with a comma
      .replace(/\B(?=(\d{3})+(?!\d))/g, '.'); // Add periods as thousands separators
  };

  return (
    <div
      className={`flex flex-col w-full justify-center items-start rounded-lg p-4`}
      style={{
        backgroundImage: `url(${img})`,
        backgroundRepeat: 'no-repeat', // Prevents the background from repeating
        backgroundSize: 'cover', // Optional: Makes the background cover the whole div
      }}
    >
      <div className="flex flex-col justify-between items-start w-full">
        <Label className="text-lg font-semibold">{title}</Label>{' '}
        {/* Using Label for the title */}
        <h2 className="text-2xl font-bold">
          {money ? `${formatNumber(number)} €` : number}
        </h2>
      </div>
    </div>
  );
};

export default OrderCard;
