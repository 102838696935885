import { Route } from 'react-router-dom';
import CondicionesGenerales from './CondicionesGenerales';
import Politics from './Politics';
import PrivacyPolicy from './PrivacyPolicy';
import Cookies from './Cookies.jsx';
import CondicionesUso from './CondicionesUso.jsx';
import CondicionesVenta from './CondicionesVenta.jsx';
import { RoutesWithNotFound } from '@/components/RoutesWithNotFound/RoutesWithNotFound';

const ConditionsRoutes = () => {
  return (
    <RoutesWithNotFound>
      <Route path="condiciones-generales" element={<CondicionesGenerales />} />
      <Route path="condiciones-uso" element={<CondicionesUso />} />
      <Route path="condiciones-venta" element={<CondicionesVenta />} />
      <Route path="politicas" element={<Politics />} />
      <Route path="politicas-privacidad" element={<PrivacyPolicy />} />
      <Route path="cookies" element={<Cookies />} />
    </RoutesWithNotFound>
  );
};

export default ConditionsRoutes;
