import { useSearchParams } from 'react-router-dom';
import ReviewCard from '@/components/Cards/ReviewCard';
import { useFetchReviews } from '@/hooks/useReview';

const MyReviews = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const { reviews, loading } = useFetchReviews(searchParams);

  if (loading) {
    return (
      <p>
        <>
          <svg
            className="animate-spin h-5 w-5 mr-2 text-white"
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
          >
            <circle
              className="opacity-25"
              cx="12"
              cy="12"
              r="10"
              stroke="currentColor"
              strokeWidth="4"
            ></circle>
          </svg>
          Cargando...
        </>
      </p>
    );
  }

  return (
    <div>
      <div className="flex flex-col gap-4 mt-4">
        {reviews && reviews.length > 0 ? (
          reviews.map((review) => (
            <ReviewCard key={review._id} review={review} />
          ))
        ) : (
          <p className="text-gray-500">No reviews available yet.</p>
        )}
      </div>
    </div>
  );
};

export default MyReviews;
