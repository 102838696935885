import { toast } from 'react-toastify';
import React, { useState, useRef } from 'react';
import TeethOutsourcingCard from '@/components/Cards/TeethOutsourcingCard';
import CaseTypeCard from '@/components/Cards/CaseTypeCard';
import { Card, CardContent } from '@/components/ui/card';
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from '@/components/ui/table';
import { useOutsourcingStore } from '@/store/OutsourcingStore';
import useUserStore from '@/store/userStore';
import { Button } from '@/components/ui/button';
import { Input } from '@/components/ui/input';
import { Label } from '@/components/ui/label';
import { Progress } from '@/components/ui/progress';
import { X } from 'lucide-react';
import { createFileOutsourcing } from '@/services/file';

const OutsourcingDetailCard = () => {
  const { user } = useUserStore();
  const { outsourcing } = useOutsourcingStore();
  const [files, setFiles] = useState([]);
  const [isAttaching, setIsAttaching] = useState(false);
  const [progress, setProgress] = useState(0);
  const fileInputRef = useRef(null);

  const handleFileChange = async (event) => {
    if (event.target.files && event.target.files.length > 0) {
      setIsAttaching(true);
      setProgress(0);
      const newFiles = Array.from(event.target.files);
      setFiles((prevFiles) => [...prevFiles, ...newFiles]);

      for (const file of newFiles) {
        await simulateFileAttachment(file);
      }

      setIsAttaching(false);
      setProgress(0);
    }
  };

  const simulateFileAttachment = async (file) => {
    return new Promise((resolve) => {
      const totalSize = file.size;
      let loadedSize = 0;
      const chunkSize = totalSize / 10; // Simular 10 pasos de carga

      const loadChunk = () => {
        loadedSize += chunkSize;
        const fileProgress = (loadedSize / totalSize) * 100;
        setProgress((prevProgress) =>
          Math.min(
            prevProgress + (100 / files.length) * (fileProgress / 100),
            100,
          ),
        );

        if (loadedSize < totalSize) {
          setTimeout(loadChunk, 100); // Simular un retraso de 100ms por chunk
        } else {
          resolve();
        }
      };

      loadChunk();
    });
  };

  const handleDeleteFile = (index) => {
    setFiles((prevFiles) => prevFiles.filter((_, i) => i !== index));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      if (files) {
        for (const file of files) {
          const fileData = {
            file,
          };
          await createFileOutsourcing({ id: outsourcing._id, fileData });
        }
        window.location.reload();
        setFiles([]);
        toast.success('Archivo(s) adjuntado(s) exitosamente');
      }
    } catch (error) {
      toast.error('Error al adjuntar archivo(s)');
    }
  };

  const handleSubmitDesign = async (e) => {
    e.preventDefault();
    try {
      if (files) {
        for (const file of files) {
          const fileData = {
            file,
            designFiles: true,
          };
          await createFileOutsourcing({ id: outsourcing._id, fileData });
        }
        window.location.reload();
        setFiles([]);
        toast.success('Archivo(s) adjuntado(s) exitosamente');
      }
    } catch (error) {
      toast.error('Error al adjuntar archivo(s)');
    }
  };

  return (
    <Card className="py-4 px-2  gap-8 rounded-lg bg-white ">
      <CardContent className="w-full flex flex-col md:flex-row p-0">
        <div className="w-full md:w-1/3">
          <TeethOutsourcingCard />
        </div>
        <div className="  w-full md:w-2/3 space-y-3 ">
          <h1 className="font-urbanist px-4 text-2xl">
            <strong>{outsourcing.caseName}</strong>
          </h1>

          <div className="flex flex-row gap-4">
            {outsourcing.design && (
              <p className="px-4 py-2 bg-[#0082F6] bg-opacity-1 text-white text-sm font-inter text-center rounded-md">
                Diseño
              </p>
            )}
            {outsourcing.fabrication && (
              <p className="px-4 py-2 bg-[#0082F6] bg-opacity-1 text-white text-sm font-inter   text-center rounded-md">
                Fabricación
              </p>
            )}
          </div>
          <p className="px-4 py-2 text-sm font-inter">
            {outsourcing.description}
          </p>

          <div className="w-full flex flex-col md:flex-row items-start  md:gap-[40px] px-5 ">
            <CaseTypeCard demand={outsourcing} className="w-full md:w-1/2" />

            <div className="w-full md:w-1/2 px-5">
              <Table>
                <TableHeader>
                  <TableRow>
                    <TableHead className="font-bold text-xs font-urbanist">
                      Archivos
                    </TableHead>
                  </TableRow>
                </TableHeader>
                <TableBody className="block">
                  {outsourcing?.files?.length ? (
                    outsourcing.files.map((file) => (
                      <TableRow key={file._id}>
                        <TableCell className="text-center">
                          <a
                            href={file.url}
                            target="_blank"
                            rel="noopener noreferrer"
                            download
                            className="text-blue-500"
                          >
                            {file.originalName}
                          </a>
                        </TableCell>
                      </TableRow>
                    ))
                  ) : (
                    <TableRow>
                      <TableCell colSpan="2" className="text-center">
                        No hay archivos
                      </TableCell>
                    </TableRow>
                  )}
                </TableBody>
              </Table>
              {user?._id == outsourcing.user?._id && (
                <div className="w-full max-w-md  space-y-3 px-2">
                  <div className="space-y-2">
                    <Label htmlFor="file-upload">
                      {files.length > 0
                        ? 'Adjuntar más archivos'
                        : 'Seleccionar Archivos'}
                      <div className="text-[8px] p-1 w-fit bg-[#BDCAFE]">
                        {' '}
                        .stl, .obj, .ply, .dentalproject, .3oxs, .dcm, .mod,
                        .jpg, .png
                      </div>
                    </Label>
                    <Input
                      id="file-upload"
                      type="file"
                      accept="
                  .stl, .obj, .ply, .dentalproject, .constructioninfo, .3oxs, .dcm, .mod, .jpg, .png"
                      multiple
                      onChange={handleFileChange}
                      disabled={isAttaching}
                      ref={fileInputRef}
                      className=" text-center py-2 content-center btn-neutral input-styles"
                    />
                  </div>
                  {files.length > 0 && (
                    <div>
                      <div className="flex justify-between items-center mb-2">
                        <p className="text-sm text-gray-500">
                          {files.length} archivo
                          {files.length !== 1 ? 's' : ''} adjuntado
                          {files.length !== 1 ? 's' : ''}
                        </p>
                      </div>
                      <ul className="text-sm text-gray-700 space-y-2">
                        {files.map((file, index) => (
                          <li
                            key={index}
                            className="flex justify-between items-center"
                          >
                            <span className="truncate">{file.name}</span>
                            <Button
                              variant="ghost"
                              size="sm"
                              onClick={() => handleDeleteFile(index)}
                              disabled={isAttaching}
                            >
                              <X className="w-4 h-4" />
                              <span className="sr-only">
                                Borrar {file.name}
                              </span>
                            </Button>
                          </li>
                        ))}
                      </ul>
                    </div>
                  )}
                  {(isAttaching || progress > 0) && (
                    <div className="space-y-2">
                      <Progress value={progress} className="w-full" />
                      <p className="text-sm text-center">
                        {isAttaching
                          ? 'Adjuntando archivos...'
                          : `${Math.round(progress)}% completado`}
                      </p>
                    </div>
                  )}
                  {files.length > 0 && (
                    <Button
                      type="submit"
                      className=" btn-primary ml-auto justify-end"
                      onClick={handleSubmit}
                    >
                      Subir archivos
                    </Button>
                  )}
                </div>
              )}
              {outsourcing.design && outsourcing.status != 'pending' && (
                <div className="bg-yellow-50 my-5">
                  <Table>
                    <TableHeader>
                      <TableRow>
                        <TableHead className="font-bold text-xs font-urbanist">
                          Archivos de Diseño
                        </TableHead>
                      </TableRow>
                    </TableHeader>
                    <TableBody className="block">
                      {outsourcing?.designFiles?.length ? (
                        outsourcing.designFiles.map((file) => (
                          <TableRow key={file._id}>
                            <TableCell className="text-center">
                              <a
                                href={file.url}
                                target="_blank"
                                rel="noopener noreferrer"
                                download
                                className="text-blue-500"
                              >
                                {file.originalName}
                              </a>
                            </TableCell>
                          </TableRow>
                        ))
                      ) : (
                        <TableRow>
                          <TableCell colSpan="2" className="text-center">
                            No hay archivos
                          </TableCell>
                        </TableRow>
                      )}
                    </TableBody>
                  </Table>
                  {user?._id !== outsourcing.user?._id && (
                    <div className="w-full max-w-md  space-y-3 px-2">
                      <div className="space-y-2">
                        <Label htmlFor="file-upload">
                          {files.length > 0
                            ? 'Adjuntar más archivos'
                            : 'Seleccionar Archivos'}
                          <div className="text-[8px] p-1 w-fit bg-[#BDCAFE]">
                            {' '}
                            .stl, .obj, .ply, .dentalproject, .3oxs, .dcm, .mod,
                            .jpg, .png
                          </div>
                        </Label>
                        <Input
                          id="file-upload"
                          type="file"
                          accept="
                  .stl, .obj, .ply, .dentalproject, .constructioninfo, .3oxs, .dcm, .mod, .jpg, .png"
                          multiple
                          onChange={handleFileChange}
                          disabled={isAttaching}
                          ref={fileInputRef}
                          className=" text-center py-2 content-center btn-neutral input-styles"
                        />
                      </div>
                      {files.length > 0 && (
                        <div>
                          <div className="flex justify-between items-center mb-2">
                            <p className="text-sm text-gray-500">
                              {files.length} archivo
                              {files.length !== 1 ? 's' : ''} adjuntado
                              {files.length !== 1 ? 's' : ''}
                            </p>
                          </div>
                          <ul className="text-sm text-gray-700 space-y-2">
                            {files.map((file, index) => (
                              <li
                                key={index}
                                className="flex justify-between items-center"
                              >
                                <span className="truncate">{file.name}</span>
                                <Button
                                  variant="ghost"
                                  size="sm"
                                  onClick={() => handleDeleteFile(index)}
                                  disabled={isAttaching}
                                >
                                  <X className="w-4 h-4" />
                                  <span className="sr-only">
                                    Borrar {file.name}
                                  </span>
                                </Button>
                              </li>
                            ))}
                          </ul>
                        </div>
                      )}
                      {(isAttaching || progress > 0) && (
                        <div className="space-y-2">
                          <Progress value={progress} className="w-full" />
                          <p className="text-sm text-center">
                            {isAttaching
                              ? 'Adjuntando archivos...'
                              : `${Math.round(progress)}% completado`}
                          </p>
                        </div>
                      )}
                      {files.length > 0 && (
                        <Button
                          type="submit"
                          className=" btn-primary ml-auto justify-end"
                          onClick={handleSubmitDesign}
                        >
                          Subir archivos
                        </Button>
                      )}
                    </div>
                  )}
                </div>
              )}
            </div>
          </div>
        </div>
      </CardContent>
    </Card>
  );
};

export default OutsourcingDetailCard;
