import React from 'react';

const Politics = () => {
  return (
    <div className="px-12 py-8 text-justify bg-white text-gray-900 leading-relaxed max-w-4xl mx-auto">
      <h1 className="text-3xl font-bold mb-6 text-center">
        Política de Privacidad
      </h1>

      <h2 className="text-2xl font-semibold mt-8 mb-4">1. Introducción</h2>
      <p className="mb-6 indent-8">
        En Oklusion, nos comprometemos a proteger la privacidad y seguridad de
        nuestros usuarios. Esta política de privacidad describe cómo
        recopilamos, utilizamos y protegemos la información personal que nos
        proporcionas a través de nuestra plataforma.
      </p>

      <h2 className="text-2xl font-semibold mt-8 mb-4">
        2. Responsable del Tratamiento
      </h2>
      <ul className="list-disc ml-8 mb-6">
        <li>Titular: Oklusion S.L.</li>
        <li>NIF: B12345678</li>
        <li>Domicilio Social: Calle Ejemplo, 123, 08001 Barcelona, España</li>
        <li>Correo Electrónico: info@oklusion.com</li>
        <li>Teléfono: +34 123 456 789</li>
      </ul>

      <h2 className="text-2xl font-semibold mt-8 mb-4">3. Datos Recopilados</h2>
      <p className="mb-6 indent-8">
        Recopilamos los siguientes tipos de datos personales:
      </p>
      <ul className="list-disc ml-8 mb-6">
        <li>
          <strong>Datos de Identificación:</strong> Nombre, dirección, correo
          electrónico, número de teléfono.
        </li>
        <li>
          <strong>Datos de Pago:</strong> Información de la tarjeta de
          crédito/débito (gestionada por Stripe).
        </li>
        <li>
          <strong>Datos Médicos:</strong> Información médica almacenada en AWS
          S3.
        </li>
        <li>
          <strong>Datos de Uso:</strong> Información sobre cómo utilizas nuestra
          plataforma.
        </li>
      </ul>

      <h2 className="text-2xl font-semibold mt-8 mb-4">
        4. Finalidad del Tratamiento
      </h2>
      <p className="mb-6 indent-8">
        Utilizamos tus datos personales para las siguientes finalidades:
      </p>
      <ul className="list-disc ml-8 mb-6">
        <li>
          <strong>Prestación del Servicio:</strong> Gestionar tu cuenta y
          facilitar las transacciones en el marketplace.
        </li>
        <li>
          <strong>Procesamiento de Pagos:</strong> Gestionar los pagos a través
          de Stripe.
        </li>
        <li>
          <strong>Envíos:</strong> Coordinar los envíos a través de Sendcloud.
        </li>
        <li>
          <strong>Mejora del Servicio:</strong> Analizar el uso de la plataforma
          para mejorar nuestros servicios.
        </li>
        <li>
          <strong>Entrenamiento de Algoritmos:</strong> Utilizar datos
          anonimizados para entrenar algoritmos de inteligencia artificial,
          cumpliendo con la normativa vigente.
        </li>
      </ul>

      <h2 className="text-2xl font-semibold mt-8 mb-4">
        5. Base Legal para el Tratamiento
      </h2>
      <p className="mb-6 indent-8">
        Tratamos tus datos personales basándonos en las siguientes bases
        legales:
      </p>
      <ul className="list-disc ml-8 mb-6">
        <li>
          <strong>Ejecución de un Contrato:</strong> Para la prestación de
          nuestros servicios.
        </li>
        <li>
          <strong>Consentimiento:</strong> Para el uso de datos médicos y el
          entrenamiento de algoritmos de inteligencia artificial.
        </li>
        <li>
          <strong>Interés Legítimo:</strong> Para mejorar nuestros servicios y
          garantizar la seguridad de la plataforma.
        </li>
      </ul>

      <h2 className="text-2xl font-semibold mt-8 mb-4">
        6. Conservación de los Datos
      </h2>
      <p className="mb-6 indent-8">
        Conservamos tus datos personales durante el tiempo necesario para
        cumplir con las finalidades para las que fueron recopilados y para
        cumplir con nuestras obligaciones legales.
      </p>

      <h2 className="text-2xl font-semibold mt-8 mb-4">
        7. Derechos del Usuario
      </h2>
      <p className="mb-6 indent-8">Tienes derecho a:</p>
      <ul className="list-disc ml-8 mb-6">
        <li>Acceder a tus datos personales.</li>
        <li>Rectificar datos inexactos o incompletos.</li>
        <li>Suprimir tus datos personales cuando ya no sean necesarios.</li>
        <li>Oponerte al tratamiento de tus datos personales.</li>
        <li>
          Limitar el tratamiento de tus datos en determinadas circunstancias.
        </li>
        <li>Portabilidad de tus datos a otro proveedor de servicios.</li>
      </ul>
      <p className="mb-6 indent-8">
        Para ejercer estos derechos, puedes contactar con nosotros en
        info@oklusion.com.
      </p>

      <h2 className="text-2xl font-semibold mt-8 mb-4">
        8. Seguridad de los Datos
      </h2>
      <p className="mb-6 indent-8">
        Implementamos medidas de seguridad técnicas y organizativas para
        proteger tus datos personales contra el acceso no autorizado, la
        alteración, la divulgación o la destrucción.
      </p>

      <h2 className="text-2xl font-semibold mt-8 mb-4">
        9. Cambios en la Política de Privacidad
      </h2>
      <p className="mb-6 indent-8">
        Nos reservamos el derecho de modificar esta política de privacidad en
        cualquier momento. Te notificaremos cualquier cambio a través de nuestra
        plataforma o por correo electrónico.
      </p>

      <h2 className="text-2xl font-semibold mt-8 mb-4">10. Contacto</h2>
      <p className="mb-6 indent-8">
        Si tienes alguna pregunta sobre esta política de privacidad, puedes
        contactarnos en:
      </p>
      <ul className="list-disc ml-8 mb-6">
        <li>Correo Electrónico: info@oklusion.com</li>
        <li>Teléfono: +34 123 456 789</li>
      </ul>
    </div>
  );
};

export default Politics;
