import { useSearchParams } from 'react-router-dom';
import TablebillLabCases from '@/components/Tables/TablebillLabCases';
import { useFetchBillLabCases } from '@/hooks/useBillCase';
import { useFetchBillOutsourcingBuyers } from '@/hooks/useBillOutsourcingBuyer';
import TablebillLabOutsourcings from '@/components/Tables/TablebillLabOutsourcings';

import { Tabs, TabsContent, TabsList, TabsTrigger } from '@/components/ui/tabs';

const MyBillsLaborary = () => {
  const [searchParams, setSearchParams] = useSearchParams();

  const { billLabCases, totalBillLabCases } =
    useFetchBillLabCases(searchParams);

  const { billOutsourcingBuyers, total } =
    useFetchBillOutsourcingBuyers(searchParams);

  // if (loading) {
  //   return (
  //     <div className="flex justify-center items-center min-h-screen font-inter">
  //       <Button variant="text">
  //         <svg
  //           className="animate-spin h-5 w-5 mr-2 text-white" /* svg details */
  //         />
  //         Cargando...
  //       </Button>
  //     </div>
  //   );
  // }
  return (
    <div>
      <Tabs defaultValue="cases">
        <TabsList className="grid w-full grid-cols-2">
          <TabsTrigger value="cases">Dentistas</TabsTrigger>
          <TabsTrigger value="outsorcings">Laboratorios</TabsTrigger>
        </TabsList>
        <TabsContent value="cases">
          <TablebillLabCases
            billlabcases={billLabCases}
            total={totalBillLabCases}
            setSearchParams={setSearchParams}
            searchParams={searchParams}
          />
        </TabsContent>
        <TabsContent value="outsorcings">
          <TablebillLabOutsourcings
            billOutsourcingBuyers={billOutsourcingBuyers}
            total={total}
            setSearchParams={setSearchParams}
            searchParams={searchParams}
            roleType={'seller'}
          />
        </TabsContent>
      </Tabs>
    </div>
  );
};

export default MyBillsLaborary;
