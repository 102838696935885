import React from 'react';
import { NavLink, Link } from 'react-router-dom';
import { FaRegUser, FaLock, FaBook } from 'react-icons/fa';
import { CiMoneyBill } from 'react-icons/ci';
import { MdOutlineReviews } from 'react-icons/md';
import { Button } from '@/components/ui/button';
import { cn } from '@/lib/utils';
import {
  Sidebar,
  SidebarContent,
  SidebarMenu,
  SidebarMenuItem,
  SidebarHeader,
} from '@/components/ui/sidebar';
import { useUserStore } from '@/store/userStore';
import LogoOklusion from '@/assets/Logo Oklusion.png';

import {
  User,
  Receipt,
  Book,
  MessageSquareCode,
  Lock,
  Download,
} from 'lucide-react';

const links = [
  { to: '/admin/user', label: 'Usuarios', icon: User },
  { to: '/admin/cases', label: 'Casos', icon: Lock },
  { to: '/admin/outsourcings', label: 'Outsourcings', icon: Lock },
  {
    to: '/admin/billLabCases',
    label: 'Facturación trabajos',
    icon: Receipt,
  },
  {
    to: '/admin/billLabCasesList',
    label: 'Facturar',
    icon: Download,
  },
  { to: '/admin/billLabs', label: 'Facturación comisiones', icon: Download },

  //
  // {
  //   to: '/admin/billOutsorcingBuyers',
  //   label: 'Facturación trabajos',
  //   icon: Receipt,
  // },
  // {
  //   to: '/admin/billOutsorcingBuyerList',
  //   label: 'Facturar',
  //   icon: Download,
  // },
  // {
  //   to: '/admin/billOutsorcingSellerList',
  //   label: 'Facturación comisiones',
  //   icon: Download,
  // },

  //
  { to: '/admin/reviews', label: 'Reseñas', icon: MessageSquareCode },
  { to: '/doc/create', label: 'Crear documento', icon: Book },
  { to: '/guides/create', label: 'Crear guía', icon: Book },
  {
    to: '/guides/category/create',
    label: 'Crear categoría para guías',
    icon: Book,
  },
];

function SidebarLink({ to, label, icon: Icon }) {
  return (
    <SidebarMenuItem>
      <NavLink
        to={to}
        className={({ isActive }) =>
          cn('w-full', isActive && 'pointer-events-none')
        }
      >
        {({ isActive }) => (
          <Button
            variant={isActive ? 'default' : 'ghost'}
            className={cn(
              'w-full justify-start',
              isActive
                ? 'bg-[#0224AA] text-primary-foreground'
                : 'hover:bg-gray-100',
            )}
          >
            <Icon className="h-4 w-4 md:mr-2" />
            <span className="hidden md:inline">{label}</span>
          </Button>
        )}
      </NavLink>
    </SidebarMenuItem>
  );
}

export function AppSidebar() {
  const { user } = useUserStore();
  return (
    <Sidebar className="border-r bg-white text-black px-2">
      <SidebarHeader className="mx-auto bg-white">
        <div className="flex justify-start w-full md:w-auto  pb-2">
          <h1 className="text-xl font-bold">
            <Link
              to={user ? '/dashboard' : '/'}
              className="hover:text-gray-300"
            >
              <img
                src={LogoOklusion}
                alt="Logo Oklusion"
                className="h-5 w-30"
              />
            </Link>
          </h1>
        </div>
      </SidebarHeader>
      <SidebarContent className="p-4 md:p-2 bg-white">
        <SidebarMenu>
          {links.map((item) => (
            <SidebarLink key={item.to} {...item} />
          ))}
        </SidebarMenu>
      </SidebarContent>
    </Sidebar>
  );
}
