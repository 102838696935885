import { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import {
  FaArrowDown,
  FaArrowUp,
  FaArrowRight,
  FaArrowLeft,
} from 'react-icons/fa';
import { useUserStore } from '@/store/userStore';
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from '@/components/ui/table';
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from '@/components/ui/select';
import { Button } from '@/components/ui/button';
import { Card } from '@/components/ui/card';
import { BillLabCasestatusStyles } from '@/constants/caseType';
import { Download } from 'lucide-react';
const TablebillLabOutsourcings = ({
  billOutsourcingBuyers,
  setSearchParams,
  searchParams,
  total,
  roleType,
}) => {
  const { user } = useUserStore();
  const [search, setSearch] = useState('');
  // const [roleType, setRoleType] = useState('seller');
  const [showFilters, setShowFilters] = useState(false);
  const [sort, setSort] = useState({
    sortBy: searchParams.get('sortBy') || 'createdAt', // Default sort by creation date
    order: searchParams.get('order') || 'desc', // Default sort order
  });

  const openFilters = () => {
    setShowFilters(!showFilters);
  };

  const limit = searchParams ? Number(searchParams.get('limit')) || 15 : 15;
  const skip = searchParams ? Number(searchParams.get('skip')) || 0 : 0;
  const status = searchParams ? searchParams.get('status') || '' : '';
  const caseName = searchParams ? searchParams.get('caseName') || '' : '';

  const includeAsSeller = searchParams
    ? searchParams.get('includeAsSeller') || ''
    : '';
  const includeAsUser = searchParams
    ? searchParams.get('includeAsUser') || ''
    : '';

  const handleStatus = (status) => {
    const statusData = BillLabCasestatusStyles[status];
    return statusData ? (
      <div
        className={`w-fit p-1 rounded-md   ${statusData.bg} ${statusData.text}`}
      >
        {statusData.label}
      </div>
    ) : null;
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    const newParams = new URLSearchParams(searchParams);
    if (value) {
      newParams.set(name, value);
    } else {
      newParams.delete(name);
    }
    setSearchParams(newParams);
  };
  const handleSort = (field) => {
    const newOrder =
      sort.sortBy === field && sort.order === 'asc' ? 'desc' : 'asc';
    setSort({ sortBy: field, order: newOrder });

    const newParams = new URLSearchParams(searchParams);
    newParams.set('sortBy', field);
    newParams.set('order', newOrder);
    setSearchParams(newParams);
  };

  const handleSelectChange = (name, value) => {
    const newParams = new URLSearchParams(searchParams);
    if (value) {
      newParams.set(name, value);
    } else {
      newParams.delete(name);
    }
    setSearchParams(newParams);
  };

  useEffect(() => {
    const newParams = new URLSearchParams(searchParams);
    if (!billOutsourcingBuyers) return;

    switch (roleType) {
      // case 'all':
      //   newParams.set('includeAsSeller', 'true');
      //   newParams.set('includeAsUser', 'true');
      //   break;
      case 'seller':
        newParams.set('includeAsSeller', 'true');
        newParams.set('includeAsUser', 'false');
        break;
      case 'user':
        newParams.set('includeAsSeller', 'false');
        newParams.set('includeAsUser', 'true');
        break;
      default:
        newParams.delete('includeAsSeller');
        newParams.delete('includeAsUser');
        break;
    }

    setSearchParams(newParams);
  }, [roleType]);

  return (
    <div className="overflow-x-auto">
      <div className="flex flex-col md:flex-row space-y-2 md:space-y-0 md:space-x-2 my-4 px-2">
        {user.role == 'ADMIN_ROLE' && (
          <Select
            value={status}
            onValueChange={(value) => handleSelectChange('status', value)}
            aria-label="Filter by status"
            className="w-full md:w-auto"
          >
            <SelectTrigger className="w-full md:w-[180px] px-2 h-10 border border-[#BDCAFE] rounded-md font-urbanist">
              <SelectValue placeholder="Estado" />
            </SelectTrigger>
            <SelectContent>
              <SelectItem value="created">Pendiente de aprobación</SelectItem>
              <SelectItem value="accepted">Aceptado</SelectItem>
              <SelectItem value="rejected">Rechazado</SelectItem>
              <SelectItem value="reSended">Revisión</SelectItem>
            </SelectContent>
          </Select>
        )}
        {/* {user.role == 'SELLER_ROLE' && (
          //select
          <Select
            value={roleType}
            onValueChange={(value) => setRoleType(value)}
            aria-label="Filter by status"
            className="w-full md:w-auto"
          >
            <SelectTrigger className="w-full md:w-[180px] px-2 h-10 border border-[#BDCAFE] rounded-md font-urbanist">
              <SelectValue placeholder="Tipo de Outsorcings" />
            </SelectTrigger>
            <SelectContent>
              <SelectItem value="all">Todos</SelectItem>
              <SelectItem value="user">Propios</SelectItem>
              <SelectItem value="seller">Mis clientes</SelectItem>
            </SelectContent>
          </Select>
        )} */}
      </div>

      <div className="rounded-lg border w-full">
        <Card className="h-full  ">
          <Table className="table-auto   border border-1 rounded-md ">
            <TableHeader className="bg-[#e9ecf2] table-text font-bold font-urbanist">
              <TableRow>
                <TableHead
                  className="px-4 py-2 table-cell  "
                  // onClick={() => handleSort('createdAt')}
                >
                  Fecha
                  {/* {sort.sortBy === 'createdAt' &&
                    (sort.order === 'asc' ? (
                      <FaArrowUp className="inline ml-2" />
                    ) : (
                      <FaArrowDown className="inline ml-2" />
                    ))} */}
                </TableHead>
                <TableHead
                  className="px-4 py-2  table-cell  "
                  // onClick={() => handleSort('clinicName')}
                >
                  Laboratorio
                  {/* {sort.sortBy === 'clinicName' &&
                    (sort.order === 'asc' ? (
                      <FaArrowUp className="inline ml-2" />
                    ) : (
                      <FaArrowDown className="inline ml-2" />
                    ))} */}
                </TableHead>
                <TableHead
                  className="px-4 py-2  "
                  // onClick={() => handleSort('status')}
                >
                  Caso
                  {/* {sort.sortBy === 'status' &&
                    (sort.order === 'asc' ? (
                      <FaArrowUp className="inline ml-2" />
                    ) : (
                      <FaArrowDown className="inline ml-2" />
                    ))} */}
                </TableHead>
                {user.role !== 'DENTIST_ROLE' && (
                  <TableHead
                    className="px-4 py-2  "
                    onClick={() => handleSort('status')}
                  >
                    Estado
                    {sort.sortBy === 'status' &&
                      (sort.order === 'asc' ? (
                        <FaArrowUp className="inline ml-2" />
                      ) : (
                        <FaArrowDown className="inline ml-2" />
                      ))}
                  </TableHead>
                )}
                <TableHead
                  className="px-4 py-2  "
                  onClick={() => handleSort('bills')}
                >
                  Facturas
                  {sort.sortBy === 'bills' &&
                    (sort.order === 'asc' ? (
                      <FaArrowUp className="inline ml-2" />
                    ) : (
                      <FaArrowDown className="inline ml-2" />
                    ))}
                </TableHead>
              </TableRow>
            </TableHeader>
            <TableBody className="font-inter">
              {billOutsourcingBuyers && billOutsourcingBuyers.length > 0 ? (
                billOutsourcingBuyers.map((billlabcase) => (
                  <TableRow key={billlabcase._id} className="border border-1">
                    <TableCell className="px-4 py-2  table-cell  items-center relative ">
                      {new Date(billlabcase.createdAt).toLocaleDateString()}
                    </TableCell>
                    <TableCell className="px-4 py-2  table-cell ">
                      {billlabcase.buyer?.name} {billlabcase.buyer?.lastname}
                    </TableCell>
                    <TableCell className={`px-4 py-2  items-center  `}>
                      {user.role == 'ADMIN_ROLE' ? (
                        <Link
                          to={
                            '/admin/billOutsorcingBuyers/' +
                            billlabcase.outsourcing._id
                          }
                        >
                          <>
                            <h4>
                              <strong>
                                {billlabcase.outsourcing.caseName.substring(
                                  0,
                                  50,
                                )}
                              </strong>{' '}
                            </h4>
                            <p>
                              {billlabcase.outsourcing.description.substring(
                                0,
                                200,
                              )}
                            </p>
                          </>
                        </Link>
                      ) : (
                        <Link to={'/outsourcing/bill/' + billlabcase._id}>
                          <>
                            <h4>
                              <strong>
                                {billlabcase.outsourcing.caseName.substring(
                                  0,
                                  50,
                                )}
                              </strong>{' '}
                            </h4>
                            <p>
                              {billlabcase.outsourcing.description.substring(
                                0,
                                200,
                              )}
                            </p>
                          </>
                        </Link>
                      )}
                    </TableCell>
                    {user.role !== 'DENTIST_ROLE' && (
                      <TableCell className={`px-4 py-2  items-center  `}>
                        {handleStatus(billlabcase.status)}
                      </TableCell>
                    )}
                    <TableCell className={`px-4 py-2  items-center  `}>
                      <a
                        href={billlabcase.file.url}
                        target="_blank"
                        className="text-blue-500"
                        download
                      >
                        <Download />
                      </a>
                    </TableCell>
                  </TableRow>
                ))
              ) : (
                <tr>
                  <TableCell colSpan="9" className=" px-4 py-2 ">
                    No outsourcings available
                  </TableCell>
                </tr>
              )}
            </TableBody>
          </Table>
          <div className="bg-[#f5f7fa] flex justify-between px-4 py-2 ">
            <Button
              onClick={() =>
                setSearchParams({ limit, skip: Math.max(skip - limit, 0) })
              }
              disabled={skip === 0}
              className="btn-neutral"
              aria-label="Previous page"
            >
              <FaArrowLeft className="inline mr-2" />
              Anterior
            </Button>

            <Button
              onClick={() => setSearchParams({ limit, skip: skip + limit })}
              disabled={skip + limit >= total}
              className="btn-primary"
              aria-label="Next page"
            >
              Siguiente
              <FaArrowRight className="inline ml-2" />
            </Button>
          </div>
        </Card>
      </div>
    </div>
  );
};

export default TablebillLabOutsourcings;
