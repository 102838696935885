import React, { useState, useRef } from 'react';
import { Link, useParams } from 'react-router-dom';
import { Button } from '@/components/ui/button';
import ProgressStepper from '@/components/Cards/ProgressStepper';
import OutsourcingDetailCard from '@/components/Cards/OutsourcingDetailCard';
import useUserStore from '@/store/userStore';
import {
  useFetchOutsourcing,
  useUpdateOutsourcing,
} from '@/hooks/useOutsourcing';
import { toast } from 'react-toastify';
import RevisionOutsourcingModal from '@/components/Modals/RevisionOutsourcingModal';
import ReviewModal from '@/components/Modals/ReviewModal';
import { Progress } from '@/components/ui/progress';

const OutsourcingPage = () => {
  const { user } = useUserStore();
  const { outsourcingId } = useParams();

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isReviewModalOpen, setIsReviewModalOpen] = useState(false);
  const socket = useRef(null);
  const { loading: loadingButton, updateOutsourcing } = useUpdateOutsourcing();
  const {
    outsourcing,
    loadingOutsourcing: loading,
    fetchOutsourcing,
  } = useFetchOutsourcing(outsourcingId);

  const handleStatusChange = async (newStatus) => {
    try {
      await updateOutsourcing(outsourcing._id, { status: newStatus });

      const statusMessages = {
        onfabrication: 'Orden en fabricación',
        sended: 'Orden enviada',
        completed: 'Orden lista',
        accepted: 'Trabajo Completado',
        inReview: 'En revisión',
        design: 'En diseño',
      };

      if (statusMessages[newStatus]) {
        socket.current.emit('updateCase', {
          demand: outsourcing,
          status: statusMessages[newStatus],
          user,
        });
      }
    } catch (error) {
      console.error('Error updating order status:', error);
      toast.error('Error al actualizar el estado del pedido');
    } finally {
      fetchOutsourcing();
    }
  };

  const renderActionButton = () => {
    if (!outsourcing) {
      return null;
    }
    const actions = {
      SELLER: {
        design: outsourcing.fabrication
          ? ['onfabrication', 'Marcar en fabricación']
          : ['sended', 'Enviar'],
        onfabrication: ['sended', 'Enviar'],
      },
      BULLER: {
        sended: ['accepted', 'Trabajo Completado'],
        // accepted: ['sended', 'Reabrir caso'],
      },
    };
    const role = user?._id === outsourcing.user?._id ? 'BULLER' : 'SELLER';

    const [nextStatus, label] = actions[role]?.[outsourcing.status] || [];

    if (nextStatus && label) {
      return (
        <button
          type="button"
          className="bg-blue-500 w-full md:w-80 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded ml-auto flex items-center justify-center font-inter"
          onClick={() => handleStatusChange(nextStatus)}
          disabled={loadingButton}
        >
          {loadingButton ? (
            <>
              <svg
                className="animate-spin h-5 w-5 mr-2 text-white"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
              >
                <circle
                  className="opacity-25"
                  cx="12"
                  cy="12"
                  r="10"
                  stroke="currentColor"
                  strokeWidth="4"
                ></circle>
              </svg>
              Cargando...
            </>
          ) : (
            label
          )}
        </button>
      );
    }

    return null;
  };

  if (loading && !outsourcing) {
    return (
      <div className="flex justify-center items-center min-h-screen font-inter">
        <Button variant="text">
          <Progress value={33} />
          Cargando...
        </Button>
      </div>
    );
  }

  if (!outsourcing && !loading) {
    return (
      <div className="text-center font-inter">Outsourcing no encontrado.</div>
    );
  }
  return (
    <div className="casePage">
      <RevisionOutsourcingModal
        outsourcingId={outsourcingId}
        isOpen={isModalOpen}
        setIsOpen={setIsModalOpen}
        handleStatusChange={handleStatusChange}
      />
      <ReviewModal
        outsourcingId={outsourcingId}
        isOpen={isReviewModalOpen}
        setIsOpen={setIsReviewModalOpen}
        fetchDemand={fetchOutsourcing}
      />
      <div className="flex flex-col gap-3 ">
        <ProgressStepper lab={true} />
        <OutsourcingDetailCard />

        <div className="flex flex-col md:flex-row w-full justify-end md:space-x-1 space-y-2 md:space-y-0 font-inter">
          {user?._id == outsourcing.user?._id &&
            outsourcing.status === 'sended' && (
              <button
                type="button"
                className="btn-neutral w-full md:w-80 text-white font-bold py-2 px-4 rounded flex items-center justify-center"
                onClick={() => setIsModalOpen(true)}
              >
                Solicitar revisión
              </button>
            )}
          {renderActionButton()}
          {user?._id === outsourcing.user?._id &&
            outsourcing.status === 'accepted' && (
              <button
                type="button"
                className="btn-neutral w-full md:w-80 text-white font-bold py-2 px-4 rounded flex items-center justify-center"
                onClick={() => handleStatusChange('sended')}
              >
                Reabrir caso
              </button>
            )}
          {user?._id === outsourcing.user?._id &&
            outsourcing.status == 'accepted' &&
            !outsourcing.review && (
              <button
                type="button"
                className="bg-blue-500 w-full md:w-80 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded flex items-center justify-center"
                onClick={() => setIsReviewModalOpen(true)}
              >
                Valora tu experiencia
              </button>
            )}
          {user?._id != outsourcing.user?._id &&
            outsourcing.status === 'inReview' && (
              // <button
              //   type="button"
              //   className="bg-red-500 w-full md:w-80 hover:bg-red-700 text-white font-bold py-2 px-4 rounded flex items-center justify-center"
              //   onClick={() => setIsModalOpen(true)}
              // >
              //   Responder Revisión
              // </button>
              <Link
                to={`/outsourcing/revisions/${outsourcing._id}`}
                className="bg-blue-500 w-full md:w-80 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded flex items-center justify-center"
              >
                Ver revisión
              </Link>
            )}
          {user?._id != outsourcing.user?._id &&
            outsourcing.status === 'accepted' &&
            !outsourcing.billOutsourcingBuyer && (
              <Link
                to={`/outsourcing/createBill/${outsourcing._id}`}
                className="bg-blue-500 w-full md:w-80 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded flex items-center justify-center"
              >
                Adjuntar factura
              </Link>
            )}
        </div>
      </div>
    </div>
  );
};

export default OutsourcingPage;
