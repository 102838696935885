import { useSearchParams } from 'react-router-dom';
import TablebillLabCasesList from '@/components/Tables/TablebillLabCasesList';
import TablebillLabOutsourcingBuyersList from '@/components/Tables/TablebillLabOutsourcingBuyersList';
import { useFetchBillOutsourcingBuyers } from '@/hooks/useBillOutsourcingBuyer';
import { useFetchBillLabCases } from '@/hooks/useBillCase';

import { Tabs, TabsContent, TabsList, TabsTrigger } from '@/components/ui/tabs';

const BillLabCasesListAdmin = () => {
  const [searchParams, setSearchParams] = useSearchParams('status=accepted');

  const { billLabCases, totalBillLabCases } =
    useFetchBillLabCases(searchParams);

  const { billOutsourcingBuyers, total } =
    useFetchBillOutsourcingBuyers(searchParams);

  return (
    <div className="">
      <Tabs defaultValue="cases">
        <TabsList className="grid w-full grid-cols-2">
          <TabsTrigger value="cases">Trabajos con casos</TabsTrigger>
          <TabsTrigger value="outsorcings">
            Trabajos con outsorcings
          </TabsTrigger>
        </TabsList>

        <TabsContent value="cases">
          <TablebillLabCasesList
            billlabcases={billLabCases}
            total={totalBillLabCases}
            setSearchParams={setSearchParams}
            searchParams={searchParams}
          />
        </TabsContent>
        <TabsContent value="outsorcings">
          <TablebillLabOutsourcingBuyersList
            billlabcases={billOutsourcingBuyers}
            total={total}
            setSearchParams={setSearchParams}
            searchParams={searchParams}
          />
        </TabsContent>
      </Tabs>
    </div>
  );
};

export default BillLabCasesListAdmin;
