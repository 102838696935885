import React, { useState } from 'react';
import LaboratorioCard from '@/components/Cards/LaboratorioCard';
import ReviewLabCard from '@/components/Cards/ReviewLabCard';
import { Button } from '@/components/ui/button';
import { Input } from '@/components/ui/input';
import { Label } from '@/components/ui/label';

const LaboratoriosGrid = ({
  laboratorios = [],
  setPage,
  selectedLaboratorios,
  setSelectedLaboratorios,
  handleSubmit,
  setSearchParams,
  searchParams,
  total,
  user,
  fetchSellers,
  loading,
}) => {
  const [showFilters, setShowFilters] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [laboratoryId, setLaboratoryId] = useState(null);

  const handleModal = (id) => {
    setLaboratoryId(id);
    setIsOpen(true);
  };

  const limit = searchParams ? Number(searchParams.get('limit')) || 10 : 10;
  const skip = searchParams ? Number(searchParams.get('skip')) || 0 : 0;
  const labName = searchParams ? searchParams.get('labName') || '' : '';
  const following = searchParams ? searchParams.get('following') || '' : false;

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    const newParams = new URLSearchParams(searchParams);
    if (value) {
      newParams.set(name, value);
    } else {
      newParams.delete(name);
    }
    setSearchParams(newParams);
  };

  const handleFollowingChange = (e) => {
    const { name, checked } = e.target;
    const newParams = new URLSearchParams(searchParams);
    if (checked) {
      newParams.set(name, checked);
    } else {
      newParams.delete(name);
    }
    setSearchParams(newParams);
  };

  return (
    <div className="p-4">
      <ReviewLabCard
        laboratoryId={laboratoryId}
        isOpen={isOpen}
        setIsOpen={setIsOpen}
      />

      <div className="flex  flex-col md:flex-row  items-center  gap-3">
        <div className="flex flex-row  my-4 w-fit  gap-4 ">
          <Input
            type="text"
            name="labName"
            placeholder="Nombre"
            value={labName}
            onChange={handleInputChange}
            className="w-fit"
          />

          <div className="flex items-center space-x-2 ">
            <Label htmlFor="following">Favoritos:</Label>
            <Input
              type="checkbox"
              name="following"
              checked={following}
              onChange={handleFollowingChange}
              aria-label="Filter by Seguimiento"
            />
          </div>
        </div>
        <h4 className="text-[1.5rem] font-urbanist w-full ">
          Selecciona laboratorios para recibir presupuestos
        </h4>
      </div>

      <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-5 my-6">
        {laboratorios.length > 0 ? (
          laboratorios.map((laboratorio) => (
            <LaboratorioCard
              key={laboratorio._id}
              laboratorio={laboratorio}
              selectedLaboratorios={selectedLaboratorios}
              setSelectedLaboratorios={setSelectedLaboratorios}
              handleModal={handleModal}
              user={user}
              fetchSellers={fetchSellers}
            />
          ))
        ) : (
          <div className="col-span-full text-center">
            No se encontraron laboratorios
          </div>
        )}
      </div>

      <div className="flex justify-between mt-4">
        {skip !== 0 && (
          <Button
            variant="outline"
            onClick={() =>
              setSearchParams({ limit, skip: Math.max(skip - limit, 0) })
            }
            disabled={skip === 0}
          >
            Anterior
          </Button>
        )}

        {skip + limit < total && (
          <Button
            variant="outline"
            onClick={() => setSearchParams({ limit, skip: skip + limit })}
            disabled={skip + limit >= total}
          >
            Siguiente
          </Button>
        )}
      </div>

      <div className=" mt-4 flex justify-end">
        <Button
          type="submit"
          disabled={selectedLaboratorios.length === 0 || loading}
          onClick={handleSubmit}
          className="btn-primary"
        >
          {loading ? 'Cargando' : 'Solicitar presupuestos'}
        </Button>
      </div>
    </div>
  );
};

export default LaboratoriosGrid;
