import { useEffect, useState, useCallback } from 'react';
import {
  getOutsourcingById,
  getOutsourcingByIdAsAdmin,
  getOutsourcings,
  getOutsourcingsAsAdmin,
  updateOutsourcing as updateOutsourcingService,
} from '@/services/outsourcing';
import useOutsourcingStore from '@/store/OutsourcingStore';
import { useUserStore } from '@/store/userStore';

// Hook for fetching a single outsourcing
export const useFetchOutsourcing = (outsourcingId) => {
  const { user } = useUserStore();
  const { outsourcing, setOutsourcing } = useOutsourcingStore();
  const [loadingOutsourcing, setLoadingOutsourcing] = useState(true);
  const [error, setError] = useState(null);

  const fetchOutsourcing = useCallback(async () => {
    if (!outsourcingId) return;

    setLoadingOutsourcing(true);
    setError(null);

    try {
      // const fetcher = getOutsourcingById;
      let fetcher;
      if (user.role === 'SELLER_ROLE') {
        fetcher = getOutsourcingById;
      }
      if (user.role === 'ADMIN_ROLE') {
        fetcher = getOutsourcingByIdAsAdmin;
      }

      const { outsourcing: outsourcingData } = await fetcher(outsourcingId);

      if (outsourcingData) {
        setOutsourcing(outsourcingData);
        // Don't log the outsourcing here; log it in a useEffect when the state updates
      } else {
        setOutsourcing(null);
        throw new Error('Outsourcing not found');
      }
    } catch (error) {
      setError(error.message || 'Failed to fetch outsourcing');
      setOutsourcing(null);
    } finally {
      setLoadingOutsourcing(false);
    }
  }, [outsourcingId, user.role, setOutsourcing]);

  useEffect(() => {
    if (outsourcingId) {
      fetchOutsourcing();
    }
  }, [fetchOutsourcing, outsourcingId]);

  return { user, outsourcing, loadingOutsourcing, error, fetchOutsourcing };
};

// Hook for fetching multiple outsourcings
export const useFetchOutsourcings = (searchParams) => {
  const { user } = useUserStore();
  const [outsourcings, setOutsourcings] = useState([]);
  const [totalOutsourcings, setTotalOutsourcings] = useState(0);
  const [loadingOutsourcings, setLoadingOutsourcings] = useState(true);
  const [error, setError] = useState(null);

  const fetchOutsourcings = useCallback(async () => {
    setLoadingOutsourcings(true);
    setError(null); // Reset error state

    try {
      const params = Object.fromEntries(searchParams);
      const queryString = new URLSearchParams(params).toString();
      let fetcher;

      if (user.role === 'SELLER_ROLE') {
        fetcher = getOutsourcings;
      } else {
        fetcher = getOutsourcingsAsAdmin;
      }

      const data = await fetcher({ queryString });
      setOutsourcings(data.outsourcings);
      setTotalOutsourcings(data.total);
    } catch (error) {
      setError(error.message || 'Failed to fetch outsourcings');
      console.error('Failed to fetch outsourcings:', error);
    } finally {
      setLoadingOutsourcings(false);
    }
  }, [searchParams, user.role]);

  useEffect(() => {
    fetchOutsourcings();
  }, [fetchOutsourcings]);

  return {
    outsourcings,
    loadingOutsourcings,
    error,
    totalOutsourcings,
    fetchOutsourcings,
  };
};

// export const useFetchOutsourcingsAsBuyer = (searchParams) => {
//   const { user } = useUserStore();
//   const [outsourcings, setOutsourcings] = useState([]);
//   const [totalOutsourcings, setTotalOutsourcings] = useState(0);
//   const [loadingOutsourcings, setLoadingOutsourcings] = useState(true);
//   const [error, setError] = useState(null);

//   const fetchOutsourcings = useCallback(async () => {
//     setLoadingOutsourcings(true);
//     setError(null); // Reset error state

//     try {
//       const params = Object.fromEntries(searchParams);
//       const queryString = new URLSearchParams(params).toString();

//       const data = await getOutsourcings({ queryString });
//       setOutsourcings(data.outsourcings);
//       setTotalOutsourcings(data.totalOutsourcings);
//     } catch (error) {
//       setError(error.message || 'Failed to fetch outsourcings');
//       console.error('Failed to fetch outsourcings:', error);
//     } finally {
//       setLoadingOutsourcings(false);
//     }
//   }, [searchParams, user.role]);

//   useEffect(() => {
//     fetchOutsourcings();
//   }, [fetchOutsourcings]);

//   return {
//     outsourcings,
//     loadingOutsourcings,
//     error,
//     totalOutsourcings,
//     fetchOutsourcings,
//   };
// };

// Hook for updating a outsourcing

export const useUpdateOutsourcing = () => {
  const [outsourcing, setOutsourcing] = useState(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  const updateOutsourcing = useCallback(async (outsourcingId, data) => {
    setLoading(true);
    setError(null); // Reset error state

    try {
      const { outsourcing: updatedOutsourcing } =
        await updateOutsourcingService(outsourcingId, data);
      if (updatedOutsourcing) {
        setOutsourcing(updatedOutsourcing);
      } else {
        throw new Error('Error updating outsourcing');
      }
    } catch (error) {
      setError(error.message || 'Error updating outsourcing');
      console.error('Error updating outsourcing:', error);
    } finally {
      setLoading(false);
    }
  }, []);

  return { outsourcing, loading, error, updateOutsourcing };
};
