import { useState } from 'react';
import { CiSearch } from 'react-icons/ci';
import { Link } from 'react-router-dom';
import { FaArrowDown, FaArrowUp } from 'react-icons/fa';
import { Card } from '@/components/ui/card';
import { Label } from '@/components/ui/label';
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from '@/components/ui/table';

import {
  editCommissionplan,
  deleteCommissionplan,
} from '@/services/commissionplan';

const TablecommissionPlan = ({
  CommissionPlans,
  setSearchParams,
  searchParams,
  total,
  fetchCommissions,
}) => {
  const [showFilters, setShowFilters] = useState(false);
  const [sort, setSort] = useState({
    sortBy: searchParams.get('sortBy') || 'createdAt',
    order: searchParams.get('order') || 'desc',
  });

  const limit = Number(searchParams.get('limit')) || 5;
  const skip = Number(searchParams.get('skip')) || 0;

  const handleSort = (field) => {
    const newOrder =
      sort.sortBy === field && sort.order === 'asc' ? 'desc' : 'asc';
    setSort({ sortBy: field, order: newOrder });

    const newParams = new URLSearchParams(searchParams);
    newParams.set('sortBy', field);
    newParams.set('order', newOrder);
    setSearchParams(newParams);
  };

  const handlePreviousPage = () => {
    const newSkip = Math.max(skip - limit, 0);
    setSearchParams({ limit, skip: newSkip });
  };

  const handleNextPage = () => {
    const newSkip = skip + limit;
    setSearchParams({ limit, skip: newSkip });
  };

  const handleDelete = async (CommissionPlan) => {
    const confirmed = window.confirm(
      '¿Estás seguro de que quieres borrar el plan?',
    );
    if (!confirmed) {
      return;
    }
    try {
      const response = await deleteCommissionplan({ id: CommissionPlan._id });

      if (response) {
        fetchCommissions();
      }
    } catch (error) {
      console.error('Error deleting CommissionPlan:', error);
    }
  };

  return (
    <div className="w-full">
      <div className="rounded-lg border overflow-hidden">
        <Card className="h-full w-full">
          <Table className="table-fixed w-full border-collapse border rounded-lg border-gray-300">
            <TableHeader className="bg-[#e9ecf2]">
              <TableRow>
                <TableHead
                  className="px-4 py-2 table-cell text-center "
                  onClick={() => handleSort('name')}
                >
                  Laboratorio
                  {sort.sortBy === 'name' &&
                    (sort.order === 'asc' ? (
                      <FaArrowUp className="inline ml-2" />
                    ) : (
                      <FaArrowDown className="inline ml-2" />
                    ))}
                </TableHead>
                <TableHead
                  className="px-4 py-2 text-center "
                  onClick={() => handleSort('expDate')}
                >
                  Fecha de expiración
                  {sort.sortBy === 'expDate' &&
                    (sort.order === 'asc' ? (
                      <FaArrowUp className="inline ml-2" />
                    ) : (
                      <FaArrowDown className="inline ml-2" />
                    ))}
                </TableHead>
                <TableHead
                  className="px-4 py-2 text-center "
                  onClick={() => handleSort('commission')}
                >
                  Comisión
                  {sort.sortBy === 'commission' &&
                    (sort.order === 'asc' ? (
                      <FaArrowUp className="inline ml-2" />
                    ) : (
                      <FaArrowDown className="inline ml-2" />
                    ))}
                </TableHead>
                <TableHead
                  className="px-4 py-2 table-cell text-center "
                  onClick={() => handleSort('status')}
                >
                  Estado
                  {sort.sortBy === 'status' &&
                    (sort.order === 'asc' ? (
                      <FaArrowUp className="inline ml-2" />
                    ) : (
                      <FaArrowDown className="inline ml-2" />
                    ))}
                </TableHead>
                <TableHead className="px-4 py-2 table-cell text-center">
                  Acciones
                </TableHead>
              </TableRow>
            </TableHeader>
            <TableBody>
              {CommissionPlans?.length ? (
                CommissionPlans.map((CommissionPlan, index) => (
                  <TableRow key={index} className="border border-1">
                    <TableCell className="px-4 py-2 text-center">
                      {CommissionPlan.user.name}
                    </TableCell>
                    <TableCell className="px-4 py-2 text-center">
                      {new Date(CommissionPlan.expDate).toLocaleDateString()}
                    </TableCell>
                    <TableCell className="px-4 py-2 text-center">
                      {CommissionPlan.commission}
                    </TableCell>
                    <TableCell className="px-4 py-2 text-center">
                      {CommissionPlan.status ? 'Activo' : 'Inactivo'}
                    </TableCell>
                    <TableCell className="px-4 py-2 text-center">
                      <div className="flex justify-center space-x-2">
                        {/* <button
                          onClick={() => editCommissionplan(CommissionPlan)}
                          className="btn-primary"
                        >
                          Editar
                        </button> */}
                        <button
                          onClick={() => handleDelete(CommissionPlan)}
                          className="bg-red-500 hover:bg-blue-200 font-bold py-2 px-4 rounded flex flex-row gap-3 items-center"
                        >
                          Eliminar
                        </button>
                      </div>
                    </TableCell>
                  </TableRow>
                ))
              ) : (
                <TableRow>
                  <TableCell colSpan="5" className="text-center py-4">
                    No se encontraron resultados
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
          <div className="bg-[#e9ecf2] flex justify-between px-4 py-2 border-collapse">
            <button
              onClick={handlePreviousPage}
              disabled={skip === 0}
              className="btn-neutral"
              aria-label="Previous page"
            >
              Anterior
            </button>
            <Label>
              Página {Math.floor(skip / limit) + 1} de{' '}
              {Math.ceil(total / limit)}
            </Label>
            <button
              onClick={handleNextPage}
              disabled={skip + limit >= total}
              className="btn-primary"
              aria-label="Next page"
            >
              Siguiente
            </button>
          </div>
        </Card>
      </div>
    </div>
  );
};

export default TablecommissionPlan;
