import { useState } from 'react';
import { Link } from 'react-router-dom';
import { FaArrowDown, FaArrowUp } from 'react-icons/fa';
import { useUserStore } from '@/store/userStore';
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from '@/components/ui/table';
import { Button } from '@/components/ui/button';
import { Card } from '@/components/ui/card';
import { Input } from '@/components/ui/input';
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from '@/components/ui/select';
import { Label } from '@/components/ui/label';

const TableOrders = ({ orders, setSearchParams, searchParams, total }) => {
  const [showFilters, setShowFilters] = useState(false);
  const [sort, setSort] = useState({
    sortBy: searchParams.get('sortBy') || 'createdAt', // Default sort by creation date
    order: searchParams.get('order') || 'desc', // Default sort order
  });

  const openFilters = () => {
    setShowFilters(!showFilters);
  };

  const limit = searchParams ? Number(searchParams.get('limit')) || 5 : 5;
  const skip = searchParams ? Number(searchParams.get('skip')) || 0 : 0;
  const status = searchParams ? searchParams.get('status') || '' : '';

  const handleStatus = (status) => {
    switch (status) {
      case 'pending':
        return 'text-yellow-500';
      case 'completed':
        return 'text-green-500';
      case 'design':
        return 'text-blue-500';
      case 'onfabrication':
        return 'text-orange-500';
      case 'sended':
        return 'text-purple-500';
      case 'inReview':
        return 'text-pink-500';
      case 'payed':
        return 'text-green-400';
      default:
        return '';
    }
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target || e; // Account for both synthetic event and custom `Select` events
    const newParams = new URLSearchParams(searchParams);

    if (value) {
      newParams.set(name, value);
    } else {
      newParams.delete(name); // Remove the param if empty
    }

    setSearchParams(newParams);
  };

  const handleSort = (field) => {
    const newOrder =
      sort.sortBy === field && sort.order === 'asc' ? 'desc' : 'asc';
    setSort({ sortBy: field, order: newOrder });

    const newParams = new URLSearchParams(searchParams);
    newParams.set('sortBy', field);
    newParams.set('order', newOrder);
    newParams.set('skip', 0); // Resetting the pagination to start at the first page
    setSearchParams(newParams);
  };

  const countRevisions = (order) => {
    return order.revisions?.length || 0;
  };

  return (
    <div className="w-full">
      <div className="flex flex-col md:flex-row justify-between mb-4">
        <div className="flex flex-row mb-4 md:mb-0">
          <input
            type="date"
            className="p-2 border border-[#BDCAFE] rounded-md mr-2"
            placeholder="Desde"
            aria-label="Start date filter"
            onChange={handleInputChange}
            name="startDate" // Ensure the name matches what you're expecting
            value={searchParams.get('startDate') || ''} // Sync value with searchParams
          />

          <input
            type="date"
            className="p-2 border border-[#BDCAFE] rounded-md mr-2"
            placeholder="Hasta"
            aria-label="End date filter"
            onChange={handleInputChange}
            name="endDate"
            value={searchParams.get('endDate') || ''} // Sync value with searchParams
          />

          <button
            className="bg-white p-2 rounded-md border border-[#BDCAFE] hover:bg-gray-200 "
            onClick={openFilters}
            aria-label="Toggle filters"
            type="button"
          >
            Más filtros
          </button>
        </div>

        {/* <div className="relative">
          <Input
            type="text"
            placeholder="Search..."
            name="caseName"
            value={caseName}
            onChange={handleInputChange}
            className="p-2 pl-10  rounded-md"
            aria-label="Search demands"
          />
          <CiSearch className="absolute right-2 top-2/4 transform -translate-y-2/4 text-gray-500" />
        </div> */}
      </div>

      {showFilters && (
        <div className="flex flex-col md:flex-row space-y-2 md:space-y-0 md:space-x-2 mb-4">
          <Select
            onValueChange={(value) =>
              handleInputChange({ name: 'status', value })
            }
            value={status}
            className="input-styles w-fit"
            aria-label="Filter by status"
          >
            <SelectTrigger className="w-[180px]">
              <SelectValue value="" placeholder="Tipo de caso" />
            </SelectTrigger>
            <SelectContent>
              <SelectItem value="completed">Completed</SelectItem>
              <SelectItem value="inReview">In Review</SelectItem>
              <SelectItem value="CaseReopened">Case Reopened</SelectItem>
              <SelectItem value="sended">Sended</SelectItem>
              <SelectItem value="onfabrication">On Fabrication</SelectItem>
              <SelectItem value="design">Design</SelectItem>
              <SelectItem value="pending">Pending</SelectItem>
            </SelectContent>
          </Select>
        </div>
      )}
      <div className="rounded-lg border  overflow-hidden">
        <Card className="h-full w-full">
          <Table className="table-fixed w-full border-collapse border rounded-lg border-gray-300">
            <TableHeader className="bg-[#e9ecf2]">
              <TableRow>
                <TableHead
                  className="px-4 py-2  hidden lg:table-cell text-center "
                  onClick={() => handleSort('createdAt')}
                >
                  Fecha
                  {sort.sortBy === 'createdAt' &&
                    (sort.order === 'asc' ? (
                      <FaArrowUp className="inline ml-2" />
                    ) : (
                      <FaArrowDown className="inline ml-2" />
                    ))}
                </TableHead>
                <TableHead
                  className="px-4 py-2  text-center "
                  // onClick={() => handleSort('caseName')}
                >
                  Nombre del dentista
                  {/* {sort.sortBy === 'caseName' &&
                    (sort.order === 'asc' ? (
                      <FaArrowUp className="inline ml-2" />
                    ) : (
                      <FaArrowDown className="inline ml-2" />
                    ))} */}
                </TableHead>
                <TableHead
                  className="px-4 py-2  text-center "
                  // onClick={() => handleSort('caseName')}
                >
                  Nombre del laboratorio
                  {/* {sort.sortBy === 'caseName' &&
                    (sort.order === 'asc' ? (
                      <FaArrowUp className="inline ml-2" />
                    ) : (
                      <FaArrowDown className="inline ml-2" />
                    ))} */}
                </TableHead>
                <TableHead
                  className="px-4 py-2  text-center "
                  // onClick={() => handleSort('caseName')}
                >
                  Revisiones
                  {/* {sort.sortBy === 'caseName' &&
                    (sort.order === 'asc' ? (
                      <FaArrowUp className="inline ml-2" />
                    ) : (
                      <FaArrowDown className="inline ml-2" />
                    ))} */}
                </TableHead>

                <TableHead
                  className="px-4 py-2  text-center "
                  onClick={() => handleSort('status')}
                >
                  Estado
                  {sort.sortBy === 'status' &&
                    (sort.order === 'asc' ? (
                      <FaArrowUp className="inline ml-2" />
                    ) : (
                      <FaArrowDown className="inline ml-2" />
                    ))}
                </TableHead>
              </TableRow>
            </TableHeader>
            <TableBody>
              {orders ? (
                orders.map((order, index) => (
                  <TableRow key={index} className="border border-1">
                    <TableCell className="px-4 py-2  text-center">
                      {new Date(order.createdAt).toLocaleDateString()}
                    </TableCell>
                    <TableCell className="px-4 py-2  text-center hover:text-blue-500">
                      <Link to={'/admin/order/' + order._id}>
                        {order.buyer.name + ' ' + order.buyer.lastname}
                      </Link>
                    </TableCell>
                    <TableCell className="px-4 py-2  hidden lg:table-cell text-center">
                      <Link to={'/admin/order/' + order._id}>
                        {order.seller.name}
                      </Link>
                    </TableCell>
                    <TableCell className="px-4 py-2  hidden lg:table-cell text-center">
                      {countRevisions(order)}
                    </TableCell>
                    <TableCell
                      className={`px-4 py-2  text-center ${handleStatus(
                        order.status,
                      )}`}
                    >
                      {order.status}
                    </TableCell>
                  </TableRow>
                ))
              ) : (
                <TableRow>
                  <TableCell colSpan="9" className="text-center px-4 py-2 ">
                    No demands available
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
          <div className="bg-[#f5f7fa] flex justify-between px-4 py-2 border-collapse ">
            <Button
              onClick={() =>
                setSearchParams({ limit, skip: Math.max(skip - limit, 0) })
              }
              disabled={skip === 0}
              className="btn-neutral"
              aria-label="Previous page"
            >
              Anterior
            </Button>
            <Label>
              Page {Math.floor(skip / limit) + 1} of {Math.ceil(total / limit)}
            </Label>
            <Button
              onClick={() => setSearchParams({ limit, skip: skip + limit })}
              disabled={skip + limit >= total}
              className="btn-primary"
              aria-label="Next page"
            >
              Siguiente
            </Button>
          </div>
        </Card>
      </div>
    </div>
  );
};

export default TableOrders;
