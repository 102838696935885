import { useEffect, useState, useRef } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import io from 'socket.io-client';
import { toast } from 'react-toastify';
import OrderCard from '@/components/Cards/OrderCard';
import Dashboard1 from '@/assets/Dashboard1.png';
import Dashboard2 from '@/assets/Dashboard2.png';
import Dashboard3 from '@/assets/Dashboard3.png';
import {
  getDemands,
  getDemandsAsSeller,
  getDemandsAsAdmin,
  updateDemandNotifications,
} from '@/services/demand';
import { getOrders } from '@/services/order';
import TableCases from '@/components/Tables/TableCases';
import { useUserStore } from '@/store/userStore';
import { useNotificationStore } from '@/store/Notificationstore';

const Dashboard = () => {
  const { user, logout } = useUserStore();
  const navigate = useNavigate();
  const { addNotification } = useNotificationStore();
  const [demands, setDemands] = useState([]);
  const [total, setTotal] = useState(0);
  const [totalOrder, setTotalOrder] = useState(0);
  const [totalOrders, setTotalOrders] = useState(0);
  const [uniqueBuyersCount, setUniqueBuyersCount] = useState(0);
  const [uniqueSellersCount, setUniqueSellersCount] = useState(0);

  const [searchParams, setSearchParams] = useSearchParams();
  const socket = useRef(null);

  const fetchData = async () => {
    const params = Object.fromEntries([...searchParams]);
    const queryString = new URLSearchParams(params).toString();

    if (user.role === 'SELLER_ROLE') {
      const data = await getDemandsAsSeller({ queryString });
      setDemands(data.demands);
      setTotal(data.total);
    } else if (user.role === 'DENTIST_ROLE') {
      const data = await getDemands({ queryString });
      setDemands(data.demands);
      setTotal(data.total);
    } else {
      const data = await getDemandsAsAdmin({ queryString });
      setDemands(data.demands);
      setTotal(data.total);
    }
    const ordersData = await getOrders({});
    setTotalOrder(ordersData.total);
    setTotalOrders(ordersData.totalOrders);
    setUniqueBuyersCount(ordersData.uniqueBuyersCount);
    setUniqueSellersCount(ordersData.uniqueSellersCount);
  };

  useEffect(() => {
    fetchData();
  }, [searchParams]);

  useEffect(() => {
    const API_BASE_URL = import.meta.env.VITE_API_BASE_URL;
    socket.current = io(API_BASE_URL);
    socket.current.emit('setup', { user });

    const handleReceiveMessage = async (receivedMessage) => {
      fetchData();
    };

    const handleReceiveDemand = (demand) => {
      fetchData();
    };

    const handleReceiveOffer = (offer) => {
      fetchData();
    };

    const handleReceiveOrder = (order) => {
      fetchData();
    };

    const handleReceiveUpdateOrder = (order) => {
      fetchData();
    };
    const handleReceiveUpdateCase = (demand) => {
      fetchData();
    };

    socket.current.on('receiveMessage', handleReceiveMessage);
    socket.current.on('receiveDemand', handleReceiveDemand);
    socket.current.on('receiveOffer', handleReceiveOffer);
    socket.current.on('receiveOrder', handleReceiveOrder);
    socket.current.on('receiveUpdateOrder', handleReceiveUpdateOrder);
    socket.current.on('receiveUpdateCase', handleReceiveUpdateCase);

    return () => {
      socket.current.off('receiveMessage', handleReceiveMessage);
      socket.current.off('receiveDemand', handleReceiveDemand);
      socket.current.off('receiveOffer', handleReceiveOffer);
      socket.current.off('receiveOrder', handleReceiveOrder);
      socket.current.off('receiveUpdateOrder', handleReceiveUpdateOrder);
      socket.current.off('receiveUpdateCase', handleReceiveUpdateCase);
      socket.current.disconnect();
    };
  }, []);

  useEffect(() => {
    if (!user) {
      navigate('/login');
    }
    if (user && !user.active) {
      navigate('/setting/activeProfile');
    }
  }, [user]);

  return (
    <div className="px-4 md:px-10 py-10">
      <section>
        <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-6 font-urbanist">
          <OrderCard
            title={
              user.role === 'SELLER_ROLE'
                ? 'Clientes totales'
                : 'Laboratorios cotizados'
            }
            number={
              user.role === 'SELLER_ROLE'
                ? uniqueBuyersCount
                : uniqueSellersCount
            }
            img={Dashboard1}
          />
          <OrderCard
            title="Cantidad facturada"
            number={totalOrders}
            img={Dashboard2}
            money={true}
          />
          <OrderCard
            title="Cantidad de pedidos"
            number={totalOrder}
            img={Dashboard3}
          />
        </div>
      </section>

      <section>
        <h3 className="py-3"></h3>
        <TableCases
          demands={demands}
          total={total}
          setSearchParams={setSearchParams}
          searchParams={searchParams}
        />
      </section>
    </div>
  );
};

export default Dashboard;
