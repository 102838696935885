import React, { useState, useRef } from 'react';
import { Avatar, AvatarFallback, AvatarImage } from '@/components/ui/avatar';
import { Dialog, DialogTrigger, DialogContent } from '@/components/ui/dialog';
import { FaRegUser, FaPlay, FaDownload } from 'react-icons/fa';

const MessageBubble = ({ message, isCurrentUser }) => {
  const [isImageOpen, setIsImageOpen] = useState(false);
  const audioRef = useRef(null);

  const formatTime = (isoString) => {
    const date = new Date(isoString);
    return date.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' });
  };

  const handlePlayAudio = () => {
    if (audioRef.current) {
      audioRef.current.play();
    }
  };

  const getFileName = (url) => {
    return url.substring(url.lastIndexOf('/') + 1);
  };
  const handleDownload = (url) => {
    const link = document.createElement('a');
    link.href = url;
    link.download = getFileName(url); // Usa el nombre de archivo extraído
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link); // Elimina el enlace después de hacer clic
  };

  return (
    <div
      className={`message flex ${
        isCurrentUser ? 'justify-end' : 'justify-start'
      } mb-4`}
    >
      {!isCurrentUser && (
        <Avatar className="w-[3rem] h-[3rem] md:w-[5rem] md:h-[5rem] ml-1">
          <AvatarImage
            src={message.sender?.avatar}
            alt={message.sender?.name || 'User'}
          />
          <AvatarFallback>
            <FaRegUser size="2rem" />
          </AvatarFallback>
        </Avatar>
      )}

      <div
        className={`p-3 rounded-lg ${
          isCurrentUser ? 'bg-gray-300 text-right' : 'bg-[#c7e8fc] text-left'
        } max-w-xs break-words`}
      >
        {/* Message content */}
        {message.file && message.file.mimetype && (
          <>
            {/* Image Files */}
            {message.file.mimetype.startsWith('image/') && (
              <>
                <img
                  src={message.file.url}
                  alt="Message Attachment"
                  className="w-[10rem] h-[10rem] md:w-full md:h-auto mb-2 cursor-pointer"
                  onClick={() => setIsImageOpen(true)}
                />
                <Dialog open={isImageOpen} onOpenChange={setIsImageOpen}>
                  <DialogContent>
                    <img
                      src={message.file.url}
                      alt="Expanded Attachment"
                      className="w-full h-auto"
                    />
                    {/* Download Button for Image */}
                    <a
                      href={message.file.url}
                      target="_blank"
                      rel="noopener noreferrer"
                      className="text-blue-500 mt-2 inline-block"
                      download
                    >
                      <FaDownload className="inline mr-2" />
                      Descargar imagen
                    </a>
                  </DialogContent>
                </Dialog>
              </>
            )}

            {/* Document Files */}
            {message.file.mimetype.startsWith('application/') && (
              <>
                <iframe
                  src={message.file.url}
                  title="Document Viewer"
                  className="w-full h-auto mb-2"
                />
                {/* Download Button for Documents */}
                <a
                  href={message.file.url}
                  download={getFileName(message.file.url)}
                  className="text-blue-500"
                >
                  <FaDownload className="inline mr-2" />
                  Descargar documento
                </a>
              </>
            )}

            {/* Audio Files */}
            {message.file.mimetype.startsWith('audio/') && (
              <div className="flex items-center mb-2">
                <audio ref={audioRef} className="hidden">
                  <source src={message.file.url} type={message.file.mimetype} />
                  Your browser does not support the audio element.
                </audio>
                <button
                  onClick={handlePlayAudio}
                  className="flex items-center text-blue-500"
                >
                  <FaPlay className="mr-2" /> Reproducir audio
                </button>
                {/* Download Button for Audio */}
                <a
                  href={message.file.url}
                  download={getFileName(message.file.url)}
                  className="text-blue-500 ml-4"
                >
                  <FaDownload className="inline mr-2" />
                  Descargar audio
                </a>
              </div>
            )}
          </>
        )}

        {/* Message Text */}
        <p>{message.text}</p>
        <p className="text-xs text-gray-500">{formatTime(message.createdAt)}</p>
      </div>

      {isCurrentUser && (
        <Avatar className="w-[3rem] h-[3rem] md:w-[5rem] md:h-[5rem] ml-1">
          <AvatarImage
            src={message.sender?.avatar}
            alt={message.sender?.name || 'Me'}
          />
          <AvatarFallback>
            <FaRegUser size="2rem" />
          </AvatarFallback>
        </Avatar>
      )}
    </div>
  );
};

export default MessageBubble;
