import { NavLink, useNavigate, Link } from 'react-router-dom';
import { useOutsourcingStore } from '@/store/OutsourcingStore';
import { useUserStore } from '@/store/userStore';
import { useState } from 'react';
import OfferOutsourcingFormDialog from '@/components/Modals/OfferOutsourcingFormModal';
import NeedHelpModal from '@/components/Modals/NeedHelpModal';

import {
  Select,
  SelectContent,
  SelectGroup,
  SelectItem,
  SelectLabel,
  SelectTrigger,
  SelectValue,
} from '@/components/ui/select';
import TableOutsourcingSidabar from '@/components/Tables/TableOutsourcingSidabar';

import {
  Sidebar,
  SidebarContent,
  SidebarHeader,
  SidebarFooter,
  SidebarMenu,
  SidebarMenuItem,
} from '@/components/ui/sidebar';
import LogoOklusion from '@/assets/Logo Oklusion.png';
import { useFetchOutsourcings } from '@/hooks/useOutsourcing';

function AppSidebar() {
  const { outsourcing } = useOutsourcingStore();
  const { user } = useUserStore();
  //const [outsourcings, setOutsourcings] = useState([]);
  const [isOpen, setIsOpen] = useState(false);
  const [isOpenHelp, setIsOpenHelp] = useState(false);
  const [searchParams, setSearchParams] = useState('');

  const navigate = useNavigate();

  const { outsourcings } = useFetchOutsourcings(searchParams);

  // const fetchData = async () => {
  //   const params = { skip: 0, limit: 10 };
  //   const queryString = new URLSearchParams(params).toString();
  //   setLoading(true);
  //   setError(null);

  //   try {
  //     const { outsourcings } =
  //       outsourcing.user == user?._id
  //         ? await getOutsourcings({ queryString })
  //         : await getOutsourcingsAsSeller({ queryString });

  //     setOutsourcings(outsourcings);
  //   } catch (err) {
  //     setError('Failed to fetch outsourcings');
  //   } finally {
  //     setLoading(false);
  //   }
  // };
  // useEffect(() => {
  //   if (user.role) fetchData();
  // }, [user.role]);

  const handleStatusOffer = (status) => {
    switch (status) {
      case 'pending':
        return 'Propuesta';
      case 'accepted':
        return 'Pedido  y dirección';
      default:
        return 'Pedido  y dirección';
    }
  };

  const handleOpenModalOffer = () => {
    setIsOpen(true);
  };

  const CustomNavLink = ({ to, children }) => (
    <NavLink
      to={to}
      className={({ isActive }) =>
        `p-2 min-w-max rounded-md text-blue-500 hover:underline ${
          isActive ? 'bg-blue-500 text-white' : ''
        }`
      }
    >
      {children}
    </NavLink>
  );

  const handleNotifications = (chat) => {
    const notifications = chat.notifications;
    if (!notifications) {
      return 0;
    }

    const notification = notifications.find(
      (n) => n.user.toString() === user?._id,
    );

    // Check if the user is already on the chat page
    const isChatOpen =
      window.location.pathname === `/outsourcing/chat/${chat._id}`;

    // If the chat is open, return 0 to hide the icon
    if (isChatOpen) {
      return 0;
    }

    if (notification && notification.count > 0) {
      return notification.count;
    } else {
      return 0;
    }
  };

  const handleBillOutsourcingBuyerstatus = (status) => {
    switch (status) {
      case 'billed':
        return true;
      case 'accepted':
        return true;
      default:
        return false;
    }
  };

  if (!outsourcing) return null;

  return (
    <Sidebar className="sideBar-size">
      <SidebarHeader className="mx-auto">
        <div className="flex justify-start w-full md:w-auto  pb-2">
          <h1 className="text-xl font-bold">
            <Link
              to={user ? '/outsourcing' : '/'}
              className="hover:text-gray-300"
            >
              <img
                src={LogoOklusion}
                alt="Logo Oklusion"
                className="h-5 w-30"
              />
            </Link>
          </h1>
        </div>
      </SidebarHeader>
      <SidebarContent className="p-4 md:p-0  ">
        {user?._id != outsourcing.user?._id && isOpen && (
          <OfferOutsourcingFormDialog
            outsourcing={outsourcing}
            isOpen={isOpen}
            setIsOpen={setIsOpen}
            user={user}
          />
        )}
        <NeedHelpModal isOpen={isOpenHelp} setIsOpen={setIsOpenHelp} />

        {/* Case Select Dropdown */}
        <div className="font-urbanist">
          <Select
            variant="outlined"
            size="lg"
            onValueChange={(value) => navigate(`/outsourcing/${value}`)}
            value={outsourcing._id}
          >
            <SelectTrigger>
              <SelectValue placeholder="ver un caso" />
            </SelectTrigger>
            <SelectContent>
              <SelectGroup>
                <SelectLabel className="font-urbanist">Casos</SelectLabel>
                {outsourcings.map((outsourcing) => (
                  <SelectItem
                    key={outsourcing._id}
                    value={outsourcing._id}
                    className="hover:bg-[#dbe2ff] w-full px-8 font-urbanist"
                  >
                    {outsourcing?.caseName.substring(0, 23)}
                  </SelectItem>
                ))}
              </SelectGroup>
            </SelectContent>
          </Select>
        </div>

        {/* Sidebar Table */}
        <TableOutsourcingSidabar outsourcing={outsourcing} />

        {/* Links */}
        <SidebarMenu className="bg-[#e9ecf2]  rounded-md md:p-2 font-urbanist overflow-x-auto">
          <SidebarMenuItem className="flex flex-col  px-2 md:px-4 bg-[#e9ecf2] w-full ">
            <CustomNavLink to={`/outsourcing/${outsourcing._id}`}>
              Detalles
            </CustomNavLink>
          </SidebarMenuItem>

          {outsourcing.selectedLaboratorios &&
            outsourcing.selectedLaboratorios.map((laboratorio) => (
              <SidebarMenuItem
                key={laboratorio._id}
                className="flex flex-col px-2 md:px-4 w-full"
              >
                {user?._id == outsourcing.user?._id &&
                  outsourcing.status === 'pending' && (
                    <p className="text-left text-sm font-semibold mb-1">
                      <strong>{laboratorio.seller?.labName}</strong>
                    </p>
                  )}
                {user?._id !== outsourcing.user?._id && !laboratorio.offer && (
                  <button
                    className="bg-blue-300 p-2 text-white hover:bg-blue-700 w-full text-left pl-2 rounded-md mb-1"
                    onClick={handleOpenModalOffer}
                  >
                    Propuesta
                  </button>
                )}

                {laboratorio.offer && (
                  <div className="flex flex-col">
                    <CustomNavLink
                      to={`/outsourcing/offer/${laboratorio.offer._id}`}
                    >
                      {/* {outsourcing.status === 'pending' ? 'Propuesta' : 'Pedido'} */}
                      {handleStatusOffer(outsourcing.status)}
                    </CustomNavLink>
                    <CustomNavLink
                      to={`/outsourcing/revisions/${outsourcing._id}`}
                    >
                      Revisiones
                    </CustomNavLink>
                  </div>
                )}
                <CustomNavLink to={`/outsourcing/chat/${laboratorio.chat._id}`}>
                  <div className="flex flex-row items-center gap-2 relative">
                    Chat
                    {handleNotifications(laboratorio.chat) > 0 && (
                      <span className="absolute -top-1 -left-3 inline-block w-2 h-2 bg-red-500 text-white rounded-full text-sm   items-center justify-center"></span>
                    )}
                  </div>
                </CustomNavLink>

                {handleBillOutsourcingBuyerstatus(
                  outsourcing.billOutsourcingBuyer?.status,
                ) &&
                  user?._id == outsourcing.user?._id && (
                    <div className="flex flex-col">
                      <CustomNavLink
                        to={`/outsourcing/bill/${outsourcing.billOutsourcingBuyer?._id}`}
                      >
                        Factura
                      </CustomNavLink>
                    </div>
                  )}

                {(outsourcing.billOutsourcingBuyer?.status == 'billed' &&
                  user?._id !== outsourcing.user?._id) ||
                  (outsourcing.billOutsourcingBuyer?.status == 'accepted' &&
                    user?._id !== outsourcing.user?._id && (
                      <div className="flex flex-col">
                        <CustomNavLink
                          to={`/outsourcing/bill/${outsourcing.billOutsourcingBuyer?._id}`}
                        >
                          Factura
                        </CustomNavLink>
                      </div>
                    ))}
              </SidebarMenuItem>
            ))}
        </SidebarMenu>
      </SidebarContent>

      <SidebarFooter>
        <button
          className="btn-neutral p-2 w-full text-left pl-2 rounded-md mb-1"
          onClick={() => setIsOpenHelp(true)}
        >
          ¿Necesitas ayuda?
        </button>
      </SidebarFooter>
    </Sidebar>
  );
}

export default AppSidebar;
