import { useEffect, useState } from 'react';
import { getProfile, updateServices } from '@/services/auth';
import { toast } from 'react-toastify';

const JobTipe = () => {
  const [services, setServices] = useState({
    protesis: {
      material: {
        Acrilico: false,
        AcrilicoDen: false,
        AcrilicoFer: false,
        Composite: false,
        DisilicatoLitio: false,
        DisilicatoLitioCar: false,
        Feldespatica: false,
        Impreso3D: false,
        Impreso3Dden: false,
        // Amber: false,
        // Emax: false,
        // Ceramica: false,
        OtrosCar: false,
        OtrosCorona: false,
        otrosFerulas: false,
        Metalceramica: false,
        PMMA: false,
        Resina: false,
        Zirconio: false,
        ZirconioCar: false,
      },
      Mockup: false,
      onImplant: false,
      withBar: false,
      selectedOption: {
        Dentadura: false,
        Férulas: false,
        Corona: false,
        Carillas: false,
      },
      refuerzoType: {
        Malla: false,
        RefuerzoForjado: false,
      },
      materialBarra: {
        // CRCO: false,
        // TI: false,
        CRCOden: false,
        Metal: false,
        Plastico: false,
        Otros: false,
        otrosDen: false,
        Resina: false,
        TIden: false,
        Zirconio: false,
      },
      selectTipoBarra: {
        Fresada: false,
        Impreso3D: false,
        Colada: false,
      },
      ferulasType: {
        Ferulaquirurgica: false,
        Feruladescarga: false,
        Ferulamuscular: false,
      },
    },
    ortodoncia: {
      selectedOption: {
        OrthodonticAppliances: false,
        Aligners: false,
        Retainers: false,
      },
      subSelection: {
        QuadHelix: false,
        Expansor: false,
        Invisalign: false,
        KLine: false,
        AngelAlign: false,
        Fijo: false,
        Movil: false,
      },
    },
    productos: {
      ProtectorBucal: false,
      AntiRonquido: false,
      Grillz: false,
      Modelo: false,
      ReparacionRebase: false,
      Biomaterial: false,
    },
  });

  const handleSubmit = async (e) => {
    e.preventDefault();
    const updatedProfile = { services };

    try {
      const response = await updateServices(updatedProfile);
      if (response) {
        toast.success('Perfil actualizado correctamente');
        fetchProfile();
      }
    } catch (error) {
      console.error('Error updating profile:', error);
      toast.error('Error al actualizar el perfil');
    }
  };

  const fetchProfile = async () => {
    try {
      const profile = await getProfile();
      setServices(profile.services || {});
    } catch (error) {
      console.error('Error fetching profile:', error);
    }
  };

  useEffect(() => {
    fetchProfile();
  }, []);

  const handleName = (material) => {
    switch (material) {
      case 'ZirconioCar':
        return 'Zirconio';
      case 'DisilicatoLitioCar':
        return 'Disilicato de litio';
      case 'OtrosCar':
        return 'Otros';
      default:
        return material;
    }
  };

  return (
    <div className=" md:p-6 space-y-6">
      <form onSubmit={handleSubmit}>
        <section className="bg-white p-1 md:p-6 border border-gray-200 rounded-lg shadow-md">
          <div>
            <p className="text-gray-600 text-sm mb-6 px-4">
              Seleccione los tipos de trabajo que realiza en su laboratorio.
            </p>

            <div className="space-y-8">
              {/* Prótesis Options */}
              <div className="bg-[#355dfc] px-2 py-3  md:p-6 border  rounded-lg">
                <h4 className="text-lg font-bold text-white mb-4">Prótesis</h4>

                <div className="bg-white px-2 md:px-8 w-full font-semibold py-2 rounded-md my-2 border-2">
                  <h3 className="font-bold">
                    Corona / Puente / Arcada completa
                  </h3>
                  <div className="flex gap-4 mt-2 flex-wrap">
                    <div className="flex items-center space-x-2 py-2 md:pr-1 md:pl-2 rounded-md">
                      <input
                        type="checkbox"
                        onChange={(e) =>
                          setServices({
                            ...services,
                            protesis: {
                              ...services.protesis,
                              Mockup: e.target.checked,
                            },
                          })
                        }
                        checked={services.protesis.Mockup}
                        className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                      />
                      <label className="text-sm font-medium leading-none peer-disabled:cursor-not-allowed peer-disabled:opacity-70">
                        Prueba estética
                      </label>
                    </div>
                    <div className="flex items-center space-x-2 py-2 md:pr-1 md:pl-2 rounded-md">
                      <input
                        type="checkbox"
                        onChange={(e) =>
                          setServices({
                            ...services,
                            protesis: {
                              ...services.protesis,
                              onImplant: e.target.checked,
                            },
                          })
                        }
                        checked={services.protesis.onImplant}
                        className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                      />
                      <label className="text-sm font-medium leading-none peer-disabled:cursor-not-allowed peer-disabled:opacity-70">
                        Sobre Implante
                      </label>
                    </div>
                  </div>
                  Material:
                  <div className="flex flex-wrap md:gap-4 mt-2">
                    <div className="flex items-center space-x-2 py-2 pr-1 pl-1 rounded-md ">
                      <input
                        type="checkbox"
                        onChange={(e) =>
                          setServices({
                            ...services,
                            protesis: {
                              ...services.protesis,
                              material: {
                                ...services.protesis.material,
                                Zirconio: e.target.checked,
                              },
                            },
                          })
                        }
                        checked={services.protesis.material.Zirconio}
                        className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                      />
                      <label className="text-sm font-medium leading-none peer-disabled:cursor-not-allowed peer-disabled:opacity-70">
                        Zirconio
                      </label>
                    </div>
                    <div className="flex items-center space-x-2 py-2 pr-1 pl-1 rounded-md">
                      <input
                        type="checkbox"
                        onChange={(e) =>
                          setServices({
                            ...services,
                            protesis: {
                              ...services.protesis,
                              material: {
                                ...services.protesis.material,
                                Metalceramica: e.target.checked,
                              },
                            },
                          })
                        }
                        checked={services.protesis.material.Metalceramica}
                        className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                      />
                      <label className="text-sm font-medium leading-none peer-disabled:cursor-not-allowed peer-disabled:opacity-70">
                        Metal / Cerámica
                      </label>
                    </div>
                    <div className="flex items-center space-x-2 py-2 pr-1 pl-1 rounded-md">
                      <input
                        type="checkbox"
                        onChange={(e) =>
                          setServices({
                            ...services,
                            protesis: {
                              ...services.protesis,
                              material: {
                                ...services.protesis.material,
                                Composite: e.target.checked,
                              },
                            },
                          })
                        }
                        checked={services.protesis.material.Composite}
                        className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                      />
                      <label className="text-sm font-medium leading-none peer-disabled:cursor-not-allowed peer-disabled:opacity-70">
                        Composite
                      </label>
                    </div>
                    <div className="flex items-center space-x-2 py-2 pr-1 pl-1 rounded-md">
                      <input
                        type="checkbox"
                        onChange={(e) =>
                          setServices({
                            ...services,
                            protesis: {
                              ...services.protesis,
                              material: {
                                ...services.protesis.material,
                                Impreso3D: e.target.checked,
                              },
                            },
                          })
                        }
                        checked={services.protesis.material.Impreso3D}
                        className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                      />
                      <label className="text-sm font-medium leading-none peer-disabled:cursor-not-allowed peer-disabled:opacity-70">
                        Impresión 3D
                      </label>
                    </div>
                    <div className="flex items-center space-x-2 py-2 pr-1 pl-1 rounded-md">
                      <input
                        type="checkbox"
                        onChange={(e) =>
                          setServices({
                            ...services,
                            protesis: {
                              ...services.protesis,
                              material: {
                                ...services.protesis.material,
                                Acrilico: e.target.checked,
                              },
                            },
                          })
                        }
                        checked={services.protesis.material.Acrilico}
                        className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                      />
                      <label className="text-sm font-medium leading-none peer-disabled:cursor-not-allowed peer-disabled:opacity-70">
                        Acrílico
                      </label>
                    </div>
                    <div className="flex items-center space-x-2 py-2 pr-1 pl-1 rounded-md">
                      <input
                        type="checkbox"
                        onChange={(e) =>
                          setServices({
                            ...services,
                            protesis: {
                              ...services.protesis,
                              material: {
                                ...services.protesis.material,
                                DisilicatoLitio: e.target.checked,
                              },
                            },
                          })
                        }
                        checked={services.protesis.material.DisilicatoLitio}
                        className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                      />
                      <label className="text-sm font-medium leading-none peer-disabled:cursor-not-allowed peer-disabled:opacity-70">
                        Disilicato de litio
                      </label>
                    </div>
                    <div className="flex items-center space-x-2 py-2 pr-1 pl-1 rounded-md">
                      <input
                        type="checkbox"
                        onChange={(e) =>
                          setServices({
                            ...services,
                            protesis: {
                              ...services.protesis,
                              material: {
                                ...services.protesis.material,
                                Resina: e.target.checked,
                              },
                            },
                          })
                        }
                        checked={services.protesis.material.Resina}
                        className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                      />
                      <label className="text-sm font-medium leading-none peer-disabled:cursor-not-allowed peer-disabled:opacity-70">
                        Resina
                      </label>
                    </div>
                    <div className="flex items-center space-x-2 py-2 pr-1 pl-1 rounded-md">
                      <input
                        type="checkbox"
                        onChange={(e) =>
                          setServices({
                            ...services,
                            protesis: {
                              ...services.protesis,
                              material: {
                                ...services.protesis.material,
                                OtrosCorona: e.target.checked,
                              },
                            },
                          })
                        }
                        checked={services.protesis.material.OtrosCorona}
                        className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                      />
                      <label className="text-sm font-medium leading-none peer-disabled:cursor-not-allowed peer-disabled:opacity-70">
                        Otros
                      </label>
                    </div>
                  </div>
                  <div className="py-5 px-2 mx-5 rounded-md">
                    <h3>Estructura</h3>
                    Material:
                    <div className="flex gap-4 mt-2 flex-wrap">
                      {/* <div className="flex items-center space-x-2 py-2 pr-1 pl-2 rounded-md">
                        <input
                          type="checkbox"
                          onChange={(e) =>
                            setServices({
                              ...services,
                              protesis: {
                                ...services.protesis,
                                materialBarra: {
                                  ...services.protesis.materialBarra,
                                  CRCO: e.target.checked,
                                },
                              },
                            })
                          }
                          checked={services.protesis.materialBarra.CRCO}
                          className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                        />
                        <label className="text-sm font-medium leading-none peer-disabled:cursor-not-allowed peer-disabled:opacity-70">
                          CrCo
                        </label>
                      </div>
                      <div className="flex items-center space-x-2 py-2 pr-1 pl-2 rounded-md">
                        <input
                          type="checkbox"
                          onChange={(e) =>
                            setServices({
                              ...services,
                              protesis: {
                                ...services.protesis,
                                materialBarra: {
                                  ...services.protesis.materialBarra,
                                  TI: e.target.checked,
                                },
                              },
                            })
                          }
                          checked={services.protesis.materialBarra.TI}
                          className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                        />
                        <label className="text-sm font-medium leading-none peer-disabled:cursor-not-allowed peer-disabled:opacity-70">
                          Ti
                        </label>
                      </div> */}
                      <div className="flex items-center space-x-2 py-2 pr-1 pl-2 rounded-md">
                        <input
                          type="checkbox"
                          onChange={(e) =>
                            setServices({
                              ...services,
                              protesis: {
                                ...services.protesis,
                                materialBarra: {
                                  ...services.protesis.materialBarra,
                                  Metal: e.target.checked,
                                },
                              },
                            })
                          }
                          checked={services.protesis.materialBarra.Metal}
                          className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                        />
                        <label className="text-sm font-medium leading-none peer-disabled:cursor-not-allowed peer-disabled:opacity-70">
                          Metal
                        </label>
                      </div>
                      <div className="flex items-center space-x-2 py-2 pr-1 pl-2 rounded-md">
                        <input
                          type="checkbox"
                          onChange={(e) =>
                            setServices({
                              ...services,
                              protesis: {
                                ...services.protesis,
                                materialBarra: {
                                  ...services.protesis.materialBarra,
                                  Plastico: e.target.checked,
                                },
                              },
                            })
                          }
                          checked={services.protesis.materialBarra.Plastico}
                          className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                        />
                        <label className="text-sm font-medium leading-none peer-disabled:cursor-not-allowed peer-disabled:opacity-70">
                          Plástico
                        </label>
                      </div>

                      <div className="flex items-center space-x-2 py-2 pr-1 pl-2 rounded-md">
                        <input
                          type="checkbox"
                          onChange={(e) =>
                            setServices({
                              ...services,
                              protesis: {
                                ...services.protesis,
                                materialBarra: {
                                  ...services.protesis.materialBarra,
                                  Resina: e.target.checked,
                                },
                              },
                            })
                          }
                          checked={services.protesis.materialBarra.Resina}
                          className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                        />
                        <label className="text-sm font-medium leading-none peer-disabled:cursor-not-allowed peer-disabled:opacity-70">
                          Resina
                        </label>
                      </div>

                      <div className="flex items-center space-x-2 py-2 pr-1 pl-2 rounded-md">
                        <input
                          type="checkbox"
                          onChange={(e) =>
                            setServices({
                              ...services,
                              protesis: {
                                ...services.protesis,
                                materialBarra: {
                                  ...services.protesis.materialBarra,
                                  Zirconio: e.target.checked,
                                },
                              },
                            })
                          }
                          checked={services.protesis.materialBarra.Zirconio}
                          className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                        />
                        <label className="text-sm font-medium leading-none peer-disabled:cursor-not-allowed peer-disabled:opacity-70">
                          Zirconio
                        </label>
                      </div>

                      <div className="flex items-center space-x-2 py-2 pr-1 pl-2 rounded-md">
                        <input
                          type="checkbox"
                          onChange={(e) =>
                            setServices({
                              ...services,
                              protesis: {
                                ...services.protesis,
                                materialBarra: {
                                  ...services.protesis.materialBarra,
                                  Otros: e.target.checked,
                                },
                              },
                            })
                          }
                          checked={services.protesis.materialBarra.Otros}
                          className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                        />
                        <label className="text-sm font-medium leading-none peer-disabled:cursor-not-allowed peer-disabled:opacity-70">
                          Otros
                        </label>
                      </div>
                    </div>
                    Tipo de estructura:
                    <div className="flex gap-4 mt-2 flex-wrap">
                      <div className="flex items-center space-x-2 py-2 pr-1 pl-2 rounded-md">
                        <input
                          type="checkbox"
                          onChange={(e) =>
                            setServices({
                              ...services,
                              protesis: {
                                ...services.protesis,
                                selectTipoBarra: {
                                  ...services.protesis.selectTipoBarra,
                                  Fresada: e.target.checked,
                                },
                              },
                            })
                          }
                          checked={services.protesis.selectTipoBarra.Fresada}
                          className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                        />
                        <label className="text-sm font-medium leading-none peer-disabled:cursor-not-allowed peer-disabled:opacity-70">
                          Fresada
                        </label>
                      </div>
                      <div className="flex items-center space-x-2 py-2 pr-1 pl-2 rounded-md">
                        <input
                          type="checkbox"
                          onChange={(e) =>
                            setServices({
                              ...services,
                              protesis: {
                                ...services.protesis,
                                selectTipoBarra: {
                                  ...services.protesis.selectTipoBarra,
                                  Impreso3D: e.target.checked,
                                },
                              },
                            })
                          }
                          checked={services.protesis.selectTipoBarra.Impreso3D}
                          className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                        />
                        <label className="text-sm font-medium leading-none peer-disabled:cursor-not-allowed peer-disabled:opacity-70">
                          Impresa 3D
                        </label>
                      </div>

                      <div className="flex items-center space-x-2 py-2 pr-1 pl-2 rounded-md">
                        <input
                          type="checkbox"
                          onChange={(e) =>
                            setServices({
                              ...services,
                              protesis: {
                                ...services.protesis,
                                selectTipoBarra: {
                                  ...services.protesis.selectTipoBarra,
                                  Colada: e.target.checked,
                                },
                              },
                            })
                          }
                          checked={services.protesis.selectTipoBarra.Colada}
                          className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                        />
                        <label className="text-sm font-medium leading-none peer-disabled:cursor-not-allowed peer-disabled:opacity-70">
                          Colada
                        </label>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="bg-white px-4 sm:px-8 w-full font-semibold py-2 rounded-md my-2 border-2">
                  <h3 className="font-bold">Carillas / Incrustación / Onlay</h3>
                  <p>Material:</p>
                  <div className="flex flex-wrap gap-4 mt-2">
                    {[
                      // 'Amber',
                      // 'Emax',
                      // 'Ceramica',
                      'Feldespatica',
                      'ZirconioCar',
                      'DisilicatoLitioCar',
                      'OtrosCar',
                    ].map((material) => (
                      <div
                        key={material}
                        className="flex items-center space-x-1 py-2 pr-1 pl-1 rounded-md"
                      >
                        <input
                          type="checkbox"
                          onChange={(e) =>
                            setServices({
                              ...services,
                              protesis: {
                                ...services.protesis,
                                material: {
                                  ...services.protesis.material,
                                  [material]: e.target.checked,
                                },
                              },
                            })
                          }
                          checked={services.protesis.material[material]}
                          className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                        />
                        <label className="text-sm font-medium leading-none peer-disabled:cursor-not-allowed peer-disabled:opacity-70">
                          {handleName(material)}
                        </label>
                      </div>
                    ))}
                  </div>
                </div>

                <div className="bg-white px-4 md:px-8 w-full font-semibold py-2 rounded-md my-2 border-2">
                  <h3 className="font-bold ">
                    Dentadura / Parcial / Esquelético
                  </h3>
                  <div className="mt-2">
                    <div className="text-sm md:text-base">Material:</div>
                    <div className="flex flex-wrap gap-4 mt-2">
                      <div className="flex items-center space-x-2">
                        <input
                          type="checkbox"
                          onChange={(e) =>
                            setServices({
                              ...services,
                              protesis: {
                                ...services.protesis,
                                material: {
                                  ...services.protesis.material,
                                  AcrilicoDen: e.target.checked,
                                },
                              },
                            })
                          }
                          checked={services.protesis.material.AcrilicoDen}
                          className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500"
                        />
                        <label className="text-sm font-medium leading-none">
                          Acrílico
                        </label>
                      </div>
                      <div className="flex items-center space-x-2">
                        <input
                          type="checkbox"
                          onChange={(e) =>
                            setServices({
                              ...services,
                              protesis: {
                                ...services.protesis,
                                material: {
                                  ...services.protesis.material,
                                  Impreso3Dden: e.target.checked,
                                },
                              },
                            })
                          }
                          checked={services.protesis.material.Impreso3Dden}
                          className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500"
                        />
                        <label className="text-sm font-medium leading-none">
                          Impresión 3D
                        </label>
                      </div>
                    </div>

                    <div className="text-sm md:text-base mt-4">Refuerzo:</div>
                    <div className="flex flex-wrap gap-4 mt-2">
                      <div className="flex items-center space-x-2">
                        <input
                          type="checkbox"
                          onChange={(e) =>
                            setServices({
                              ...services,
                              protesis: {
                                ...services.protesis,
                                refuerzoType: {
                                  ...services.protesis.refuerzoType,
                                  Malla: e.target.checked,
                                },
                              },
                            })
                          }
                          checked={services.protesis.refuerzoType.Malla}
                          className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500"
                        />
                        <label className="text-sm font-medium leading-none">
                          Malla
                        </label>
                      </div>
                      <div className="flex items-center space-x-2">
                        <input
                          type="checkbox"
                          onChange={(e) =>
                            setServices({
                              ...services,
                              protesis: {
                                ...services.protesis,
                                refuerzoType: {
                                  ...services.protesis.refuerzoType,
                                  RefuerzoForjado: e.target.checked,
                                },
                              },
                            })
                          }
                          checked={
                            services.protesis.refuerzoType.RefuerzoForjado
                          }
                          className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500"
                        />
                        <label className="text-sm font-medium leading-none">
                          Refuerzo forjado
                        </label>
                      </div>
                    </div>

                    <div className="text-sm md:text-base mt-4">Barra:</div>
                    <div className="flex flex-wrap gap-4 mt-2">
                      <div className="flex items-center space-x-2">
                        <input
                          type="checkbox"
                          onChange={(e) =>
                            setServices({
                              ...services,
                              protesis: {
                                ...services.protesis,
                                materialBarra: {
                                  ...services.protesis.materialBarra,
                                  CRCOden: e.target.checked,
                                },
                              },
                            })
                          }
                          checked={services.protesis.materialBarra.CRCOden}
                          className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500"
                        />
                        <label className="text-sm font-medium leading-none">
                          CrCo
                        </label>
                      </div>
                      <div className="flex items-center space-x-2">
                        <input
                          type="checkbox"
                          onChange={(e) =>
                            setServices({
                              ...services,
                              protesis: {
                                ...services.protesis,
                                materialBarra: {
                                  ...services.protesis.materialBarra,
                                  TIden: e.target.checked,
                                },
                              },
                            })
                          }
                          checked={services.protesis.materialBarra.TIden}
                          className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500"
                        />
                        <label className="text-sm font-medium leading-none">
                          Ti
                        </label>
                      </div>
                      <div className="flex items-center space-x-2">
                        <input
                          type="checkbox"
                          onChange={(e) =>
                            setServices({
                              ...services,
                              protesis: {
                                ...services.protesis,
                                materialBarra: {
                                  ...services.protesis.materialBarra,
                                  otrosDen: e.target.checked,
                                },
                              },
                            })
                          }
                          checked={services.protesis.materialBarra.otrosDen}
                          className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500"
                        />
                        <label className="text-sm font-medium leading-none">
                          Otros
                        </label>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="bg-white px-8 w-full font-semibold py-2 rounded-md my-2 border-2">
                  <h3 className="font-bold">Férulas</h3>
                  Tipo:
                  <div className="flex flex-wrap gap-4 mt-2">
                    <div className="flex items-center space-x-2 py-2 pr-1 pl-2 rounded-md w-1/2 md:w-auto">
                      <input
                        type="checkbox"
                        onChange={(e) =>
                          setServices({
                            ...services,
                            protesis: {
                              ...services.protesis,
                              ferulasType: {
                                ...services.protesis.ferulasType,
                                Ferulaquirurgica: e.target.checked,
                              },
                            },
                          })
                        }
                        checked={services.protesis.ferulasType.Ferulaquirurgica}
                        className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                      />
                      <label className="text-sm font-medium leading-none peer-disabled:cursor-not-allowed peer-disabled:opacity-70">
                        Férula quirúrgica
                      </label>
                    </div>
                    <div className="flex items-center space-x-2 py-2 pr-1 pl-2 rounded-md w-1/2 md:w-auto">
                      <input
                        type="checkbox"
                        onChange={(e) =>
                          setServices({
                            ...services,
                            protesis: {
                              ...services.protesis,
                              ferulasType: {
                                ...services.protesis.ferulasType,
                                Feruladescarga: e.target.checked,
                              },
                            },
                          })
                        }
                        checked={services.protesis.ferulasType.Feruladescarga}
                        className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                      />
                      <label className="text-sm font-medium leading-none peer-disabled:cursor-not-allowed peer-disabled:opacity-70">
                        Férula descarga
                      </label>
                    </div>
                    <div className="flex items-center space-x-2 py-2 pr-1 pl-2 rounded-md w-1/2 md:w-auto">
                      <input
                        type="checkbox"
                        onChange={(e) =>
                          setServices({
                            ...services,
                            protesis: {
                              ...services.protesis,
                              ferulasType: {
                                ...services.protesis.ferulasType,
                                Ferulamuscular: e.target.checked,
                              },
                            },
                          })
                        }
                        checked={services.protesis.ferulasType.Ferulamuscular}
                        className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                      />
                      <label className="text-sm font-medium leading-none peer-disabled:cursor-not-allowed peer-disabled:opacity-70">
                        Férula muscular
                      </label>
                    </div>
                  </div>
                  Material:
                  <div className="flex gap-4 mt-2 flex-wrap">
                    <div className="flex items-center space-x-2 py-2 pr-1 pl-2 rounded-md w-1/2 md:w-auto">
                      <input
                        type="checkbox"
                        onChange={(e) =>
                          setServices({
                            ...services,
                            protesis: {
                              ...services.protesis,
                              material: {
                                ...services.protesis.material,
                                AcrilicoFer: e.target.checked,
                              },
                            },
                          })
                        }
                        checked={services.protesis.material.AcrilicoFer}
                        className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                      />
                      <label className="text-sm font-medium leading-none peer-disabled:cursor-not-allowed peer-disabled:opacity-70">
                        Acrílico
                      </label>
                    </div>
                    <div className="flex items-center space-x-2 py-2 pr-1 pl-2 rounded-md w-1/2 md:w-auto">
                      <input
                        type="checkbox"
                        onChange={(e) =>
                          setServices({
                            ...services,
                            protesis: {
                              ...services.protesis,
                              material: {
                                ...services.protesis.material,
                                PMMA: e.target.checked,
                              },
                            },
                          })
                        }
                        checked={services.protesis.material.PMMA}
                        className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                      />
                      <label className="text-sm font-medium leading-none peer-disabled:cursor-not-allowed peer-disabled:opacity-70">
                        PMMA
                      </label>
                    </div>
                    <div className="flex items-center space-x-2 py-2 pr-1 pl-2 rounded-md w-1/2 md:w-auto">
                      <input
                        type="checkbox"
                        onChange={(e) =>
                          setServices({
                            ...services,
                            protesis: {
                              ...services.protesis,
                              material: {
                                ...services.protesis.material,
                                otrosFerulas: e.target.checked,
                              },
                            },
                          })
                        }
                        checked={services.protesis.material.otrosFerulas}
                        className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                      />
                      <label className="text-sm font-medium leading-none peer-disabled:cursor-not-allowed peer-disabled:opacity-70">
                        Otros
                      </label>
                    </div>
                  </div>
                </div>
              </div>

              {/* Ortodoncia Options */}
              <div className="bg-[#c09cff] px-2 py-6 md:p-6 border rounded-lg">
                <h4 className="text-lg font-bold mb-4">Ortodoncia</h4>

                <div className="bg-white font-semibold px-5 md:px-10 w-full py-2 rounded-md my-2 border-2 gap-10">
                  Aparatos Ortodoncia:
                  <div className="flex flex-wrap gap-4 md:gap-10 mt-2 py-2">
                    {['QuadHelix', 'Expansor', 'SureSmile', 'Otros'].map(
                      (item) => (
                        <div key={item} className="flex items-center space-x-2">
                          <input
                            type="checkbox"
                            onChange={(e) =>
                              setServices({
                                ...services,
                                ortodoncia: {
                                  ...services.ortodoncia,
                                  subSelection: {
                                    ...services.ortodoncia.subSelection,
                                    [item]: e.target.checked,
                                  },
                                },
                              })
                            }
                            checked={services.ortodoncia.subSelection[item]}
                            className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500"
                          />
                          <label className="text-sm font-medium leading-none">
                            {item}
                          </label>
                        </div>
                      ),
                    )}
                  </div>
                  Ortodoncia Invisible:
                  <div className="flex flex-wrap gap-4 md:gap-10 mt-2 py-2">
                    {[
                      'Invisalign',
                      'KLine',
                      'AngelAlign',
                      'Geniova',
                      'OtrosOrtInv',
                    ].map((item) => (
                      <div key={item} className="flex items-center space-x-2">
                        <input
                          type="checkbox"
                          onChange={(e) =>
                            setServices({
                              ...services,
                              ortodoncia: {
                                ...services.ortodoncia,
                                subSelection: {
                                  ...services.ortodoncia.subSelection,
                                  [item]: e.target.checked,
                                },
                              },
                            })
                          }
                          checked={services.ortodoncia.subSelection[item]}
                          className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500"
                        />
                        <label className="text-sm font-medium leading-none">
                          {item}
                        </label>
                      </div>
                    ))}
                  </div>
                  Retenedor:
                  <div className="flex flex-wrap gap-4 md:gap-10 mt-2 py-2">
                    {['Fijo', 'Movil'].map((item) => (
                      <div key={item} className="flex items-center space-x-2">
                        <input
                          type="checkbox"
                          onChange={(e) =>
                            setServices({
                              ...services,
                              ortodoncia: {
                                ...services.ortodoncia,
                                subSelection: {
                                  ...services.ortodoncia.subSelection,
                                  [item]: e.target.checked,
                                },
                              },
                            })
                          }
                          checked={services.ortodoncia.subSelection[item]}
                          className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500"
                        />
                        <label className="text-sm font-medium leading-none">
                          {item}
                        </label>
                      </div>
                    ))}
                  </div>
                </div>
              </div>

              {/* Productos Options */}
              <div className="bg-[#f7ed8d] px-2 py-6 md:p-6 border rounded-lg">
                <h4 className="text-lg font-bold mb-4">Otros</h4>

                <div className="bg-white px-1 w-full py-2 rounded-md my-2 border-2">
                  <div className="flex gap-1 mt-2 flex-wrap">
                    <div className="flex items-center space-x-1  py-2 pr-1 pl-2 rounded-md">
                      <input
                        type="checkbox"
                        onChange={(e) =>
                          setServices({
                            ...services,
                            productos: {
                              ...services.productos,
                              ProtectorBucal: e.target.checked,
                            },
                          })
                        }
                        checked={services.productos.ProtectorBucal}
                        className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500"
                      />
                      <label className="text-sm font-medium">
                        Protector Bucal
                      </label>
                    </div>
                    <div className="flex items-center space-x-1  py-2 pr-1 pl-2 rounded-md">
                      <input
                        type="checkbox"
                        onChange={(e) =>
                          setServices({
                            ...services,
                            productos: {
                              ...services.productos,
                              AntiRonquido: e.target.checked,
                            },
                          })
                        }
                        checked={services.productos.AntiRonquido}
                        className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500"
                      />
                      <label className="text-sm font-medium">
                        Anti Ronquido
                      </label>
                    </div>
                    <div className="flex items-center space-x-1  py-2 pr-1 pl-2 rounded-md">
                      <input
                        type="checkbox"
                        onChange={(e) =>
                          setServices({
                            ...services,
                            productos: {
                              ...services.productos,
                              Grillz: e.target.checked,
                            },
                          })
                        }
                        checked={services.productos.Grillz}
                        className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500"
                      />
                      <label className="text-sm font-medium">Grillz</label>
                    </div>
                    <div className="flex items-center space-x-1  py-2 pr-1 pl-2 rounded-md">
                      <input
                        type="checkbox"
                        onChange={(e) =>
                          setServices({
                            ...services,
                            productos: {
                              ...services.productos,
                              Modelo: e.target.checked,
                            },
                          })
                        }
                        checked={services.productos.Modelo}
                        className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500"
                      />
                      <label className="text-sm font-medium">Modelo</label>
                    </div>
                    <div className="flex items-center space-x-1  py-2 pr-1 pl-2 rounded-md">
                      <input
                        type="checkbox"
                        onChange={(e) =>
                          setServices({
                            ...services,
                            productos: {
                              ...services.productos,
                              ReparacionRebase: e.target.checked,
                            },
                          })
                        }
                        checked={services.productos.ReparacionRebase}
                        className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500"
                      />
                      <label className="text-sm font-medium">
                        Reparación / Rebase
                      </label>
                    </div>
                    <div className="flex items-center space-x-1  py-2 pr-1 pl-2 rounded-md">
                      <input
                        type="checkbox"
                        onChange={(e) =>
                          setServices({
                            ...services,
                            productos: {
                              ...services.productos,
                              Biomaterial: e.target.checked,
                            },
                          })
                        }
                        checked={services.productos.Biomaterial}
                        className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500"
                      />
                      <label className="text-sm font-medium">Biomaterial</label>
                    </div>
                    <div className="flex items-center space-x-1  py-2 pr-1 pl-2 rounded-md">
                      <input
                        type="checkbox"
                        onChange={(e) =>
                          setServices({
                            ...services,
                            productos: {
                              ...services.productos,
                              Otros: e.target.checked,
                            },
                          })
                        }
                        checked={services.productos.Otros}
                        className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500"
                      />
                      <label className="text-sm font-medium">Otros</label>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="flex justify-end mt-8">
            <button
              className="bg-blue-500 hover:bg-blue-700 text-white font-semibold py-2 px-4 rounded"
              type="submit"
            >
              Guardar
            </button>
          </div>
        </section>
      </form>
    </div>
  );
};

export default JobTipe;
