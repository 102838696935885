import { useSearchParams } from 'react-router-dom';
import TablebillLabCases from '@/components/Tables/TablebillLabCases';
import { useFetchBillLabCases } from '@/hooks/useBillCase';
import { Button } from '@/components/ui/button';

const MyBills = () => {
  const [searchParams, setSearchParams] = useSearchParams();

  const { billLabCases, loading, totalBillLabCases } =
    useFetchBillLabCases(searchParams);

  if (loading) {
    return (
      <div className="flex justify-center items-center min-h-screen font-inter">
        <Button variant="text">
          <svg
            className="animate-spin h-5 w-5 mr-2 text-white" /* svg details */
          />
          Cargando...
        </Button>
      </div>
    );
  }

  return (
    <div>
      <section>
        <TablebillLabCases
          billlabcases={billLabCases}
          total={totalBillLabCases}
          setSearchParams={setSearchParams}
          searchParams={searchParams}
        />
      </section>
    </div>
  );
};

export default MyBills;
