import { useState } from 'react';
import { Link } from 'react-router-dom';
import {
  FaArrowDown,
  FaArrowUp,
  FaArrowRight,
  FaArrowLeft,
} from 'react-icons/fa';
import { useUserStore } from '@/store/userStore';
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from '@/components/ui/table';
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from '@/components/ui/select';
import { Button } from '@/components/ui/button';
import { Card } from '@/components/ui/card';
import { BillLabCasestatusStyles } from '@/constants/caseType';

const TablebillLabOutsourcingBuyersList = ({
  billlabcases,
  setSearchParams,
  searchParams,
  total,
}) => {
  const { user } = useUserStore();
  const [search, setSearch] = useState('');
  const [showFilters, setShowFilters] = useState(false);
  const [sort, setSort] = useState({
    sortBy: searchParams.get('sortBy') || 'createdAt', // Default sort by creation date
    order: searchParams.get('order') || 'desc', // Default sort order
  });

  const openFilters = () => {
    setShowFilters(!showFilters);
  };

  const limit = searchParams ? Number(searchParams.get('limit')) || 15 : 15;
  const skip = searchParams ? Number(searchParams.get('skip')) || 0 : 0;
  const status = searchParams ? searchParams.get('status') || '' : '';
  const caseName = searchParams ? searchParams.get('caseName') || '' : '';

  const handleStatus = (status) => {
    const statusData = BillLabCasestatusStyles[status];
    return statusData ? (
      <div
        className={`w-fit p-1 rounded-md   ${statusData.bg} ${statusData.text}`}
      >
        {statusData.label}
      </div>
    ) : null;
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    const newParams = new URLSearchParams(searchParams);
    if (value) {
      newParams.set(name, value);
    } else {
      newParams.delete(name);
    }
    setSearchParams(newParams);
  };
  const handleSort = (field) => {
    const newOrder =
      sort.sortBy === field && sort.order === 'asc' ? 'desc' : 'asc';
    setSort({ sortBy: field, order: newOrder });

    const newParams = new URLSearchParams(searchParams);
    newParams.set('sortBy', field);
    newParams.set('order', newOrder);
    setSearchParams(newParams);
  };

  const handleSelectChange = (name, value) => {
    const newParams = new URLSearchParams(searchParams);
    if (value) {
      newParams.set(name, value);
    } else {
      newParams.delete(name);
    }
    setSearchParams(newParams);
  };

  return (
    <div className="overflow-x-auto">
      <div className="flex flex-col md:flex-row space-y-2 md:space-y-0 md:space-x-2 my-4 px-2">
        {/* <input
          type="text"
          name="caseName"
          placeholder="Nombre del caso"
          value={caseName}
          onChange={handleInputChange}
          className="p-2 border border-[#BDCAFE] rounded-md h-10 w-full md:w-auto font-urbanist"
          aria-label="Filter by case number"
        /> */}
        {/* <Select
            value={status}
            onValueChange={(value) => handleSelectChange('status', value)}
            aria-label="Filter by status"
            className="w-full md:w-auto"
          >
            <SelectTrigger className="w-full md:w-[180px] px-2 h-10 border border-[#BDCAFE] rounded-md font-urbanist">
              <SelectValue placeholder="Estado" />
            </SelectTrigger>
            <SelectContent>
              <SelectItem value="created">Pendiente de aprobación</SelectItem>
              <SelectItem value="accepted">Aceptado</SelectItem>
              <SelectItem value="rejected">Rechazado</SelectItem>
              <SelectItem value="reSended">Revisión</SelectItem>
            </SelectContent>
          </Select> */}
      </div>

      <div className="rounded-lg border w-full">
        <Card className="h-full  ">
          <Table className="table-auto   border border-1 rounded-md ">
            <TableHeader className="bg-[#e9ecf2] table-text font-bold font-urbanist">
              <TableRow>
                <TableHead
                  className="px-4 py-2 table-cell  "
                  // onClick={() => handleSort('createdAt')}
                >
                  Fecha
                  {/* {sort.sortBy === 'createdAt' &&
                    (sort.order === 'asc' ? (
                      <FaArrowUp className="inline ml-2" />
                    ) : (
                      <FaArrowDown className="inline ml-2" />
                    ))} */}
                </TableHead>
                <TableHead
                  className="px-4 py-2  table-cell  "
                  // onClick={() => handleSort('clinicName')}
                >
                  Laboratorio
                  {/* {sort.sortBy === 'clinicName' &&
                    (sort.order === 'asc' ? (
                      <FaArrowUp className="inline ml-2" />
                    ) : (
                      <FaArrowDown className="inline ml-2" />
                    ))} */}
                </TableHead>
                <TableHead
                  className="px-4 py-2  "
                  // onClick={() => handleSort('status')}
                >
                  Caso
                  {/* {sort.sortBy === 'status' &&
                    (sort.order === 'asc' ? (
                      <FaArrowUp className="inline ml-2" />
                    ) : (
                      <FaArrowDown className="inline ml-2" />
                    ))} */}
                </TableHead>
                {/* <TableHead
                  className="px-4 py-2  "
                  onClick={() => handleSort('status')}
                >
                  Estado
                  {sort.sortBy === 'status' &&
                    (sort.order === 'asc' ? (
                      <FaArrowUp className="inline ml-2" />
                    ) : (
                      <FaArrowDown className="inline ml-2" />
                    ))}
                </TableHead> */}
              </TableRow>
            </TableHeader>
            <TableBody className="font-inter">
              {billlabcases ? (
                billlabcases.map((billlabcase, index) => (
                  <TableRow key={index} className="border border-1">
                    <TableCell className="px-4 py-2  table-cell  items-center relative ">
                      {new Date(billlabcase.createdAt).toLocaleDateString()}
                    </TableCell>
                    <TableCell className="px-4 py-2  table-cell ">
                      {billlabcase.buyer?.name} {billlabcase.buyer?.lastname}
                    </TableCell>
                    <TableCell className={`px-4 py-2  items-center  `}>
                      {user.role == 'ADMIN_ROLE' ? (
                        <Link
                          to={
                            '/admin/billOutsorcingBuyerList/' +
                            billlabcase.outsourcing._id
                          }
                        >
                          <>
                            <h4>
                              <strong>
                                {billlabcase.outsourcing.caseName.substring(
                                  0,
                                  50,
                                )}
                              </strong>{' '}
                            </h4>
                            <p>
                              {billlabcase.outsourcing.description.substring(
                                0,
                                200,
                              )}
                            </p>
                          </>
                        </Link>
                      ) : (
                        <Link to={'/cases/bill/' + billlabcase._id}>
                          <>
                            <h4>
                              <strong>
                                {billlabcase.outsourcing.caseName.substring(
                                  0,
                                  50,
                                )}
                              </strong>{' '}
                            </h4>
                            <p>
                              {billlabcase.outsourcing.description.substring(
                                0,
                                200,
                              )}
                            </p>
                          </>
                        </Link>
                      )}
                    </TableCell>
                    {/* <TableCell className={`px-4 py-2  items-center  `}>
                      {handleStatus(billlabcase.status)}
                    </TableCell> */}
                  </TableRow>
                ))
              ) : (
                <tr>
                  <TableCell colSpan="9" className=" px-4 py-2 ">
                    No outsourcings available
                  </TableCell>
                </tr>
              )}
            </TableBody>
          </Table>
          <div className="bg-[#f5f7fa] flex justify-between px-4 py-2 ">
            <Button
              onClick={() =>
                setSearchParams({ limit, skip: Math.max(skip - limit, 0) })
              }
              disabled={skip === 0}
              className="btn-neutral"
              aria-label="Previous page"
            >
              <FaArrowLeft className="inline mr-2" />
              Anterior
            </Button>

            <Button
              onClick={() => setSearchParams({ limit, skip: skip + limit })}
              disabled={skip + limit >= total}
              className="btn-primary"
              aria-label="Next page"
            >
              Siguiente
              <FaArrowRight className="inline ml-2" />
            </Button>
          </div>
        </Card>
      </div>
    </div>
  );
};

export default TablebillLabOutsourcingBuyersList;
