import { Routes, Route } from 'react-router-dom';
import AdminLayout from '@/components/Layouts/AdminLayout';
import { AdminGuard } from '@/guards/AdminGuard';
import { UserPage, UsersPage, UserCommissionPlans } from './users';
import { OrdersPage, OrderPageAdmin } from './orders';
import { CasesPageAdmin, CasePageAdmin } from './cases';
import { OutsourcingsPage, OutsourcingPageAdmin } from './outsourcings';
import {
  BillLabCasesAdmin,
  BillLabCaseAdmin,
  BillLabCasesListAdmin,
  BillLabCaseListAdmin,
  NewCaseBillPage,
} from './billLabCases';
import { BillLabsAdmin, BillLapPage } from './billLab';
import { ReviewsAdmin } from './review';
import {
  BillOutsorcingBuyersAdmin,
  BillOutsorcingBuyersListAdmin,
  BillOutsorcingBuyerListAdmin,
  BillOutsorcingBuyerAdmin,
} from './BillOutsorcingBuyer';

const SettingRoutes = () => {
  return (
    <Routes>
      <Route element={<AdminGuard />}>
        <Route element={<AdminLayout />}>
          <Route path="user/:id" element={<UserPage />} />
          <Route path="user" element={<UsersPage />} />
          <Route path="cases" element={<CasesPageAdmin />} />
          <Route
            path="outsourcings/:outsourcingId"
            element={<OutsourcingPageAdmin />}
          />
          <Route path="outsourcings" element={<OutsourcingsPage />} />
          <Route path="billLabs/:billId" element={<BillLapPage />} />
          <Route path="billLabs" element={<BillLabsAdmin />} />
          <Route path="createBill/:caseId" element={<NewCaseBillPage />} />
          <Route path="billLabCases/:caseId" element={<BillLabCaseAdmin />} />
          <Route path="billLabCases" element={<BillLabCasesAdmin />} />
          <Route
            path="billLabCasesList/:caseId"
            element={<BillLabCaseListAdmin />}
          />
          <Route path="billLabCasesList" element={<BillLabCasesListAdmin />} />

          <Route
            path="billOutsorcingBuyers/:caseId"
            element={<BillOutsorcingBuyerAdmin />}
          />
          <Route
            path="billOutsorcingBuyers"
            element={<BillOutsorcingBuyersAdmin />}
          />
          <Route
            path="billOutsorcingBuyerList/:caseId"
            element={<BillOutsorcingBuyerListAdmin />}
          />

          <Route
            path="billOutsorcingBuyerList"
            element={<BillOutsorcingBuyersListAdmin />}
          />

          <Route path="orders" element={<OrdersPage />} />
          <Route path="reviews" element={<ReviewsAdmin />} />
          <Route path="order/:orderId" element={<OrderPageAdmin />} />
          <Route path="cases/:caseId" element={<CasePageAdmin />} />
          <Route
            path="user/:id/commissionplans"
            element={<UserCommissionPlans />}
          />
        </Route>
      </Route>
    </Routes>
  );
};

export default SettingRoutes;
