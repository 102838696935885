import React from 'react';
import { MdFavorite } from 'react-icons/md';
import { followSeller } from '@/services/demand';
import { Switch } from '@/components/ui/switch';
import { Card, CardContent } from '@/components/ui/card';
import { Avatar, AvatarFallback, AvatarImage } from '@/components/ui/avatar';
import { Label } from '@/components/ui/label';

const LaboratorioCard = ({
  laboratorio,
  selectedLaboratorios,
  setSelectedLaboratorios,
  handleModal,
  user,
  fetchSellers,
}) => {
  const isSelected = selectedLaboratorios.includes(laboratorio._id);
  const isFollowing = laboratorio.followers.includes(user?._id);

  const handleToggleChange = () => {
    if (isSelected) {
      setSelectedLaboratorios(
        selectedLaboratorios.filter((_id) => _id !== laboratorio._id),
      );
    } else {
      setSelectedLaboratorios([
        ...new Set([...selectedLaboratorios, laboratorio._id]),
      ]);
    }
  };

  const handleReviewsCount = (laboratorio) => {
    return laboratorio.reviews.length;
  };

  const handleFollow = async () => {
    try {
      await followSeller(laboratorio._id);
      await fetchSellers();
    } catch (error) {
      console.error('Failed to follow seller:', error);
    }
  };

  const handleTime = (time) => {
    if (time < 4) {
      return 'Contesta en pocos minutos';
    } else if (time < 24) {
      return 'Contesta en pocas horas';
    } else {
      return 'Contesta en pocos días ';
    }
  };

  return (
    <Card className=" max-w-lg flex flex-col md:flex-row border border-gray-200 rounded-lg bg-white hover:shadow-lg duration-200 w-full overflow-hidden p-0">
      {/* Image Section */}
      <div
        className="w-full md:w-48 h-48 bg-cover bg-center bg-no-repeat"
        style={{
          backgroundImage: `url(${laboratorio.avatar})`,
        }}
      ></div>

      {/* Details Section */}
      <CardContent className="flex flex-col justify-between p-4 flex-1">
        <div className="flex flex-col space-y-2">
          {/* Stars */}
          <div className="flex items-center">
            <div className="flex items-center space-x-1">
              {[...Array(Math.ceil(laboratorio.score))].map((_, idx) => (
                <svg
                  key={idx}
                  className="w-5 h-5 text-yellow-500"
                  fill="currentColor"
                  viewBox="0 0 20 20"
                >
                  <path d="M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.57 4.843a1 1 0 00.95.69h5.053c.97 0 1.371 1.24.588 1.81l-4.09 2.97a1 1 0 00-.364 1.118l1.57 4.843c.3.921-.755 1.688-1.538 1.118l-4.09-2.97a1 1 0 00-1.175 0l-4.09 2.97c-.783.57-1.838-.197-1.538-1.118l1.57-4.843a1 1 0 00-.364-1.118L2.88 10.27c-.783-.57-.382-1.81.588-1.81h5.053a1 1 0 00.95-.69l1.57-4.843z" />
                </svg>
              ))}
            </div>
            {handleReviewsCount(laboratorio) > 0 ? (
              <button
                type="button"
                onClick={() => handleModal(laboratorio._id)}
                className="ml-2 text-blue-500 hover:underline"
              >
                ({handleReviewsCount(laboratorio)})
              </button>
            ) : (
              <span className="ml-2 text-gray-500">Nuevo en Oklusion</span>
            )}
          </div>

          {/* Labels */}
          <div className="flex flex-col text-left truncate">
            <Label
              className="font-bold text-lg mt-2 font-urbanist hover:underline"
              onClick={() => handleModal(laboratorio._id)}
            >
              {laboratorio.labName}
            </Label>
            <Label className="text-sm mt-2 font-inter truncate">
              Nº Colegiado: {laboratorio.colNumber}
            </Label>
            {laboratorio.averageResponseTime > 0 && (
              <Label className="text-gray-500 mt-1 font-inter truncate">
                {handleTime(laboratorio.averageResponseTime)}
              </Label>
            )}
          </div>
        </div>

        {/* Actions */}
        <div className="flex items-center gap-4 justify-center">
          <input
            className="me-2 mt-[0.3rem] h-3.5 w-8 appearance-none rounded-[0.4375rem] bg-black/25 before:pointer-events-none before:absolute before:h-3.5 before:w-3.5 before:rounded-full before:bg-[transparent] before:content-[''] after:absolute after:z-[2] after:-mt-[0.1875rem] after:h-5 after:w-5 after:rounded-full after:border-none after:bg-[#8B47FF] after:shadow-switch-2 after:transition-[background-color_0.2s,transform_0.2s] after:content-[''] checked:bg-[#8B47FF] checked:after:absolute checked:after:z-[2] checked:after:-mt-[3px] checked:after:ms-[1.0625rem] checked:after:h-5 checked:after:w-5 checked:after:rounded-full checked:after:border-none checked:after:bg-[#8B47FF] checked:after:shadow-switch-1 checked:after:transition-[background-color_0.2s,transform_0.2s] checked:after:content-[''] hover: focus:outline-none focus:before:scale-100 focus:before:opacity-[0.12] focus:before:shadow-switch-3 focus:before:shadow-black/60 focus:before:transition-[box-shadow_0.2s,transform_0.2s] focus:after:absolute focus:after:z-[1] focus:after:block focus:after:h-5 focus:after:w-5 focus:after:rounded-full focus:after:content-[''] checked:focus:border-primary checked:focus:bg-[#8B47FF] checked:focus:before:ms-[1.0625rem] checked:focus:before:scale-100 checked:focus:before:shadow-switch-3 checked:focus:before:transition-[box-shadow_0.2s,transform_0.2s] dark:bg-white/25 dark:after:bg-surface-dark dark:checked:bg-[#8B47FF] dark:checked:after:bg-[#8B47FF]"
            type="checkbox"
            role="switch"
            checked={isSelected}
            onChange={handleToggleChange}
            id="flexSwitchCheckDefault"
          />
          <MdFavorite
            className={`w-6 h-6 cursor-pointer transition-colors duration-200 ${
              isFollowing ? 'text-red-500' : 'text-gray-400'
            }`}
            onClick={handleFollow}
          />
        </div>
      </CardContent>
    </Card>
  );
};

export default LaboratorioCard;
