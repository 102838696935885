import React, { useEffect, useState, useRef } from 'react';
import { toast } from 'react-toastify';
import { useNavigate, useSearchParams } from 'react-router-dom';
import CaseTypeSelection from '@/components/Forms/CaseTypeSelection';
import LaboratoriosGrid from '@/components/Forms/LaboratoriosGrid';
import ImplantSelection from '@/components/Forms/ImplantSelection';
import OrtodonciaMenu from '@/components/Forms/Ortodoncia';
import TeethSel from '@/components/TeethSel';
import ProductSelection from '@/components/ProductSelection';
import {
  createDemand,
  getSellers,
  updateDemandNotifications,
} from '@/services/demand';
import { createFile } from '@/services/file';
import { getProfile } from '@/services/auth';
import io from 'socket.io-client';
import useUserStore from '@/store/userStore';
import useNotificationStore from '@/store/Notificationstore';
import { Input } from '@/components/ui/input';
import { Label } from '@/components/ui/label';
import { Button } from '@/components/ui/button';
import { Textarea } from '@/components/ui/textarea';
import { Progress } from '@/components/ui/progress';
import { X } from 'lucide-react';
import { Card, CardContent } from '@/components/ui/card';
import { cn } from '@/lib/utils';

const NewCase = () => {
  const { user } = useUserStore();
  const { addNotification } = useNotificationStore();
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();
  const [clinics, setClinics] = useState([
    {
      clinicID: '',
      clinicName: '',
      via: '',
      clinicstreet: '',
      clinicPhone: '',
      postalCode: '',
      province: '',
      city: '',
    },
  ]);
  const [selectedClinic, setSelectedClinic] = useState('');
  const [clinic, setClinic] = useState('{}');
  const [caseName, setCaseName] = useState('');
  const [deliveryDate, setDeliveryDate] = useState('');
  const [description, setDescription] = useState('');
  const [caseType, setCaseType] = useState('');
  const [files, setFiles] = useState([]);
  const [progress, setProgress] = useState(0);
  const [isAttaching, setIsAttaching] = useState(false);
  const fileInputRef = useRef(null);
  const [laboratorios, setLaboratorios] = useState([]);
  const [total, setTotal] = useState('');
  const [page, setPage] = useState(0);
  const [selectedTeeth, setSelectedTeeth] = useState([]);
  const [selectedImplant, setSelectedImplant] = useState(null);
  const [selectedImplantTemp, setSelectedImplantTemp] = useState(null);
  const [ortodonciaReq, setOrtodonciaReq] = useState(null);
  const [ortodonciaReqTemp, setOrtodonciaReqTemp] = useState(null);
  const [selectedProduct, setSelectedProduct] = useState('');
  const [selectedLaboratorios, setSelectedLaboratorios] = useState([]);
  const today = new Date().toISOString().split('T')[0];
  const [loading, setLoading] = useState(false);
  const [loadingSellers, setLoadingSellers] = useState(false);
  const socket = useRef(null);

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const demandDataToSend = {
        caseName,
        deliveryDate,
        description,
        caseType,
        selectedTeeth: Array.from(selectedTeeth),
        selectedImplant,
        ortodonciaReq,
        selectedProduct,
        selectedLaboratorios,
        clinic,
      };

      setLoading(true);
      const { demand } = await createDemand(demandDataToSend);

      if (demand) {
        for (const file of files) {
          const fileData = {
            file,
          };
          await createFile({ id: demand._id, fileData });
        }

        toast.success(
          'Caso creado exitosamente espera propuestas de los Laboratorios',
        );
        //navigate to demand created id
        socket.current.emit('createDemand', { demand });
        navigate(`/cases/${demand._id}`);
      }
    } catch (error) {
      console.log(error);
      toast.error('Error al crear el caso');
    } finally {
      setLoading(false);
    }
  };

  const fetchSellers = async () => {
    try {
      setLoadingSellers(true);

      const params = Object.fromEntries([...searchParams]);
      const queryString = new URLSearchParams(params).toString();

      const { sellers = [], total } = await getSellers({
        queryString,
        selectedImplant,
        ortodonciaReq,
        selectedProduct,
      });
      setLaboratorios(sellers);
      setTotal(total);
    } catch (error) {
      console.log(error);
    } finally {
      setLoadingSellers(false);
    }
  };

  useEffect(() => {
    const API_BASE_URL = import.meta.env.VITE_API_BASE_URL;
    socket.current = io(API_BASE_URL);
    socket.current.emit('setup', { user });

    return () => {
      socket.current.disconnect();
    };
  }, []);

  useEffect(() => {
    fetchSellers();
  }, [searchParams]);

  const fetchProfile = async () => {
    const profile = await getProfile();
    setClinics(profile.clinics);
  };
  useEffect(() => {
    fetchProfile();
  }, []);

  useEffect(() => {
    const selectedClinicObject = clinics.find(
      (clinic) => clinic._id === selectedClinic,
    );
    if (selectedClinic) {
      setClinic(selectedClinicObject);
    } else {
      setClinic({
        clinicID: '',
        clinicName: '',
        via: '',
        clinicstreet: '',
        clinicPhone: '',
        postalCode: '',
        province: '',
        city: '',
      });
    }
  }, [selectedClinic]);

  const simulateFileAttachment = async (file) => {
    return new Promise((resolve) => {
      const totalSize = file.size;
      let loadedSize = 0;
      const chunkSize = totalSize / 10; // Simular 10 pasos de carga

      const loadChunk = () => {
        loadedSize += chunkSize;
        const fileProgress = (loadedSize / totalSize) * 100;
        setProgress((prevProgress) =>
          Math.min(
            prevProgress + (100 / files.length) * (fileProgress / 100),
            100,
          ),
        );

        if (loadedSize < totalSize) {
          setTimeout(loadChunk, 100); // Simular un retraso de 100ms por chunk
        } else {
          resolve();
        }
      };

      loadChunk();
    });
  };

  const handleFileChange = async (event) => {
    if (event.target.files && event.target.files.length > 0) {
      setIsAttaching(true);
      setProgress(0);
      const newFiles = Array.from(event.target.files);
      setFiles((prevFiles) => [...prevFiles, ...newFiles]);

      for (const file of newFiles) {
        await simulateFileAttachment(file);
      }

      setIsAttaching(false);
      setProgress(0);
    }
  };

  const handleDeleteFile = (index) => {
    setFiles((prevFiles) => prevFiles.filter((_, i) => i !== index));
  };

  // const validSelect = () => {
  //   if (caseType === 'protesis') {
  //     return selectedImplant.material && selectedImplant.color ? true : false;
  //   }

  //   if (caseType === 'ortodoncia') {
  //     return ortodonciaReq.subSelection ? true : false;
  //   }

  //   return true; // Default case if no specific validation is needed
  // };

  return (
    <div className="px-6 py-2 ">
      {page == 0 && (
        <CaseTypeSelection
          caseType={caseType}
          setCaseType={setCaseType}
          page={page}
          setPage={setPage}
          setSelectedImplant={setSelectedImplantTemp}
          setOrtodonciaReq={setOrtodonciaReqTemp}
          setSelectedProduct={setSelectedProduct}
          setSearchParams={setSearchParams}
        />
      )}
      {page == 1 && (
        <div className="flex flex-col md:flex-row p-2 gap-2 ">
          <div className="pb-2 bg-white w-full md:w-2/5 h-fit rounded-md">
            <TeethSel
              selectedTeeth={selectedTeeth}
              setSelectedTeeth={setSelectedTeeth}
            />
          </div>

          <div
            className={
              'w-full md:w-7/12 px-4 bg-white rounded-md py-2 space-y-2'
            }
          >
            <div className="flex flex-col md:flex-row gap-2">
              <div className="form-grid w-full md:w-1/2">
                <Label
                  htmlFor="caseName"
                  className="text-sm font-medium text-gray-700"
                >
                  Nombre del caso
                </Label>
                <Input
                  type="text"
                  id="caseName"
                  value={caseName}
                  onChange={(e) => setCaseName(e.target.value)}
                  className="input-styles"
                  required
                />
              </div>
              <div className="form-grid w-full md:w-1/2">
                <Label
                  htmlFor="deliveryDate"
                  className="text-sm font-medium text-gray-700"
                >
                  Fecha de entrega
                </Label>
                <Input
                  type="date"
                  id="deliveryDate"
                  value={deliveryDate}
                  onChange={(e) => setDeliveryDate(e.target.value)}
                  className="input-styles"
                  required
                  min={today}
                />
              </div>
            </div>

            {caseType === 'protesis' && (
              <ImplantSelection
                selectedImplant={selectedImplant}
                selectedImplantTemp={selectedImplantTemp}
                setSelectedImplant={setSelectedImplant}
              />
            )}
            {caseType === 'ortodoncia' && (
              <OrtodonciaMenu
                ortodonciaReq={ortodonciaReq}
                ortodonciaReqTemp={ortodonciaReqTemp}
                setOrtodonciaReq={setOrtodonciaReq}
              />
            )}
            {caseType === 'productos' && (
              <ProductSelection
                selectedProduct={selectedProduct}
                setSelectedProduct={setSelectedProduct}
              />
            )}
            <div className="form-grid">
              <Label htmlFor="description">Descripción</Label>
              <Textarea
                id="description"
                value={description}
                onChange={(e) => setDescription(e.target.value)}
                className="input-styles"
              />
            </div>
            <div className="form-grid my-1">
              <Label htmlFor="description">Dirección de envio </Label>

              <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-5 my-6">
                {clinics.length > 0 ? (
                  clinics.map((clinic, optionIndex) => (
                    <Card
                      key={optionIndex}
                      className={cn(
                        'overflow-hidden  transition-colors duration-200',
                        selectedClinic === clinic._id
                          ? 'bg-[#355dfc] text-primary-foreground'
                          : 'hover:bg-secondary',
                      )}
                      onClick={() => setSelectedClinic(clinic._id)}
                    >
                      <CardContent className="p-4">
                        <h3 className="text-sm font-semibold mb-1 truncate text-center">
                          {clinic.clinicName}
                        </h3>
                      </CardContent>
                    </Card>
                  ))
                ) : (
                  <div className="col-span-full text-center">
                    No se encontraron clinicas
                  </div>
                )}
              </div>
            </div>
            <div className="w-full max-w-md  space-y-4">
              <div className="space-y-2">
                <Label htmlFor="file-upload">
                  {files.length > 0
                    ? 'Adjuntar más archivos'
                    : 'Seleccionar Archivos'}
                  <div className="text-[8px] p-1 w-fit bg-[#BDCAFE]">
                    {' '}
                    .stl, .obj, .ply, .dentalproject, .3oxs, .dcm, .mod, .jpg,
                    .png
                  </div>
                </Label>
                <Input
                  id="file-upload"
                  type="file"
                  accept="
                  .stl, .obj, .ply, .dentalproject, .constructioninfo, .3oxs, .dcm, .mod, .jpg, .png"
                  multiple
                  onChange={handleFileChange}
                  disabled={isAttaching}
                  ref={fileInputRef}
                  className=" text-center py-2 content-center btn-neutral input-styles"
                />
              </div>
              {files.length > 0 && (
                <div>
                  <div className="flex justify-between items-center mb-2">
                    <p className="text-sm text-gray-500">
                      {files.length} archivo{files.length !== 1 ? 's' : ''}{' '}
                      adjuntado{files.length !== 1 ? 's' : ''}
                    </p>
                  </div>
                  <ul className="text-sm text-gray-700 space-y-2">
                    {files.map((file, index) => (
                      <li
                        key={index}
                        className="flex justify-between items-center"
                      >
                        <span className="truncate">{file.name}</span>
                        <Button
                          variant="ghost"
                          size="sm"
                          onClick={() => handleDeleteFile(index)}
                          disabled={isAttaching}
                        >
                          <X className="w-4 h-4" />
                          <span className="sr-only">Borrar {file.name}</span>
                        </Button>
                      </li>
                    ))}
                  </ul>
                </div>
              )}
              {(isAttaching || progress > 0) && (
                <div className="space-y-2">
                  <Progress value={progress} className="w-full" />
                  <p className="text-sm text-center">
                    {isAttaching
                      ? 'Adjuntando archivos...'
                      : `${Math.round(progress)}% completado`}
                  </p>
                </div>
              )}
            </div>
            <Button
              type="submit"
              className=" btn-primary ml-auto justify-end"
              disabled={
                caseName === '' ||
                deliveryDate === '' ||
                caseType === '' ||
                !description ||
                !selectedClinic ||
                page == 0
                //  ||
                // !validSelect()
              }
              onClick={() => {
                setPage(2),
                  fetchSellers(),
                  setSelectedImplantTemp(selectedImplant),
                  setOrtodonciaReqTemp(ortodonciaReq);
              }}
            >
              Crear caso
            </Button>
          </div>
        </div>
      )}
      {page == 2 && (
        <form>
          <div>
            <LaboratoriosGrid
              laboratorios={laboratorios}
              total={total}
              searchParams={searchParams}
              setSearchParams={setSearchParams}
              setPage={setPage}
              page={page}
              setSelectedLaboratorios={setSelectedLaboratorios}
              selectedLaboratorios={selectedLaboratorios}
              handleSubmit={handleSubmit}
              user={user}
              fetchSellers={fetchSellers}
              loading={loading}
            />
          </div>
        </form>
      )}
    </div>
  );
};

export default NewCase;
