import { useSearchParams } from 'react-router-dom';
import TableOrders from '@/components/Tables/TableOrders';
import { useFetchOrders } from '@/hooks/useOrder';

const OrdersPage = () => {
  const [searchParams, setSearchParams] = useSearchParams();

  const { orders, total } = useFetchOrders(searchParams);

  return (
    <div className="">
      <section>
        <TableOrders
          orders={orders}
          seller={false}
          total={total}
          setSearchParams={setSearchParams}
          searchParams={searchParams}
        />
      </section>
    </div>
  );
};

export default OrdersPage;
