import { useEffect, useState, useRef } from 'react';
import ContactCardEdit from '@/components/Cards/ContactCardEdit';
import ContactNifCardEdit from '@/components/Cards/ContactNifCardEdit';
import ClinicCardEdit from '@/components/Cards/ClinicCardEdit';
import { getProfile, updateProfile } from '@/services/auth';
import { LuPencil } from 'react-icons/lu';
import { FaPlus } from 'react-icons/fa';
import { toast } from 'react-toastify';
import useUserStore from '@/store/userStore';
import ProfileEditCard from '@/components/Cards/ProfileEditCard';

const MyProfile = () => {
  const { setUser, token } = useUserStore();
  const [editContact, setEditContact] = useState(false);
  const [editNif, setEditNif] = useState(false);
  const [name, setName] = useState('');
  const [lastname, setLastname] = useState('');
  const [labName, setLabName] = useState('');
  const [nif, setNif] = useState('');
  const [razonSocial, setRazonSocial] = useState('');
  const [colNumber, setColNumber] = useState('');
  const [email, setEmail] = useState('');
  const [phone, setPhone] = useState('');
  const [avatar, setAvatar] = useState('');
  const [avatarPreview, setAvatarPreview] = useState('');
  const [reciveCases, setReciveCases] = useState(false); // For reciveCases
  const [clinics, setClinics] = useState([
    {
      clinicID: '',
      clinicName: '',
      via: '',
      clinicstreet: '',
      clinicPhone: '',
      postalCode: '',
      province: '',
      city: '',
    },
  ]);
  const [role, setRole] = useState('');
  const [description, setDescription] = useState('');
  const [stripeEmail, setStripeEmail] = useState('');
  const [allowToReciveCases, setAllowToReciveCases] = useState(false);

  const avatarInputRef = useRef(null);

  const handleAvatarClick = () => {
    avatarInputRef.current.click();
  };

  const handleAvatarChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setAvatarPreview(reader.result);
        setAvatar(file); // Save the file to state for submission
      };
      reader.readAsDataURL(file); // Reads the file and triggers onloadend
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    setLabName(clinics[0].clinicName);
    if (role === 'SELLER_ROLE' && clinics.length) {
      setLabName(clinics[0].clinicName);
    }
    if (!stripeEmail) {
      setReciveCases(false);
    }

    const updatedProfile = {
      name,
      lastname,
      labName,
      email,
      phone,
      avatar, // Avatar file
      reciveCases,
      clinics,
      nif,
      razonSocial,
      colNumber,
      description,
      stripeEmail,
    };

    try {
      const { user } = await updateProfile(updatedProfile);
      if (user) {
        setUser(user, token);
        toast.success('Perfil actualizado correctamente');
        fetchProfile();
      }
    } catch (error) {
      console.error('Error updating profile:', error);
      toast.error('Error al actualizar el perfil');
    }
  };

  const fetchProfile = async () => {
    const profile = await getProfile();
    setName(profile.name);
    setLastname(profile.lastname);
    setEmail(profile.email);
    setPhone(profile.phone);
    setAvatar(profile.avatar);
    setClinics(profile.clinics);
    setReciveCases(profile.reciveCases);
    setRole(profile.role);
    setLabName(profile.labName);
    setNif(profile.nif);
    setRazonSocial(profile.razonSocial);
    setColNumber(profile.colNumber);
    setDescription(profile.description);
    setStripeEmail(profile.paymentMethod.stripeData.stripeEmail);
    setAllowToReciveCases(profile.allowToReciveCases);
  };

  useEffect(() => {
    fetchProfile();
  }, []);

  return (
    <div className="p-1 md:p-4">
      <form onSubmit={handleSubmit} className="space-y-4">
        <section className="w-full flex flex-col md:flex-row md:justify-between items-center md:gap-4 section-profile">
          <ProfileEditCard
            handleAvatarClick={handleAvatarClick}
            avatarPreview={avatarPreview}
            avatar={avatar}
            avatarInputRef={avatarInputRef}
            handleAvatarChange={handleAvatarChange}
            name={name}
            setName={setName}
            lastname={lastname}
            setLastname={setLastname}
            email={email}
            setEmail={setEmail}
            phone={phone}
            setPhone={setPhone}
            editContact={editContact}
            nif={nif}
            setNif={setNif}
            razonSocial={razonSocial}
            setRazonSocial={setRazonSocial}
            colNumber={colNumber}
            setColNumber={setColNumber}
            editNif={editNif}
          />

          <div className="flex flex-col md:flex-col gap-4 items-center my-4 md:mt-0 w-full md:w-auto">
            <button
              className="bg-[#dbe2ff] hover:bg-blue-200 font-bold py-2 px-4 rounded flex justify-center items-center  w-full md:w-auto"
              type="button"
              onClick={() => setEditNif(!editNif)}
            >
              Editar
              <LuPencil />
            </button>

            <button className="btn-primary w-full md:w-auto" type="submit">
              Guardar
            </button>
          </div>
        </section>

        {role === 'SELLER_ROLE' && (
          <>
            <section className="flex flex-col md:flex-row  justify-between items-center h-full section-profile gap-2">
              <div className="w-full md:w-3/4">
                <h1 className="font-bold py-5 text-xl">Descripción</h1>
                <textarea
                  className="w-full p-2 border border-gray-300 rounded focus:outline-none focus:ring focus:ring-blue-300"
                  value={description}
                  onChange={(e) => setDescription(e.target.value)}
                  placeholder="Descripción"
                  maxLength={140} // Corrected from max to maxLength
                  rows={4} // Add rows for better visibility
                />
              </div>

              <div className="flex items-center h-full  w-full my-4 md:w-auto">
                <button
                  className="btn-primary mt-4 md:mt-0 w-full md:w-auto"
                  type="submit"
                >
                  Guardar
                </button>
              </div>
            </section>

            <section className="flex flex-col md:flex-row justify-between section-profile">
              <div
                className="inline-flex items-center pt-2"
                onClick={() => {
                  if (clinics.length === 0) {
                    toast.error(
                      'Por favor, añada una dirección antes de recibir casos.',
                    );
                  }
                }}
              >
                <input
                  type="checkbox"
                  checked={reciveCases}
                  onChange={(e) => setReciveCases(e.target.checked)}
                  className="form-checkbox text-blue-600"
                  disabled={clinics.length === 0 || !allowToReciveCases} // Deshabilitado si no hay clínicas
                />
                <span className="ml-2">Visible para recibir trabajos</span>
              </div>

              <div className="flex items-center h-full  w-full my-4 md:w-auto">
                <button
                  className="btn-primary mt-4 md:mt-0 w-full md:w-auto"
                  type="submit"
                >
                  Guardar
                </button>
              </div>
            </section>

            {/* <section className="flex flex-col md:flex-row  justify-between items-center h-full section-profile gap-2">
              <div className="w-full md:w-3/4">
                <h1 className="font-bold py-5 text-xl">
                  Correo de pago de stripe
                </h1>
                <input
                  className="w-full p-2 border border-gray-300 rounded focus:outline-none focus:ring focus:ring-blue-300"
                  type="email"
                  value={stripeEmail}
                  onChange={(e) => setStripeEmail(e.target.value)}
                  placeholder="stripeEmail"
                />
              </div>

              <div className="flex items-center h-full  w-full my-4 md:w-auto">
                <button
                  className="btn-primary mt-4 md:mt-0 w-full md:w-auto"
                  type="submit"
                >
                  Guardar
                </button>
              </div>
            </section> */}
          </>
        )}

        <section className="section-profile">
          <div className="flex flex-col md:flex-row justify-between items-center gap-2 md:gap-4">
            <h1 className="font-bold py-5 text-base md:text-xl">
              {role === 'DENTIST_ROLE' ? 'Direcciones' : 'Dirección'}
            </h1>

            {role === 'DENTIST_ROLE' && (
              <button
                className="bg-[#dbe2ff] hover:bg-blue-200 text-xs md:text-lg font-bold py-2 my-2 px-2 md:px-4 rounded flex flex-row gap-1 md:gap-3 items-center"
                type="button"
                onClick={() => {
                  setClinics([
                    ...clinics,
                    {
                      clinicID: '',
                      clinicName: '',
                      via: '',
                      clinicstreet: '',
                      clinicPhone: '',
                      postalCode: '',
                      province: '',
                      city: '',
                    },
                  ]);
                }}
              >
                Añadir dirección
                <FaPlus />
              </button>
            )}

            {role === 'SELLER_ROLE' && clinics.length === 0 && (
              <button
                className="bg-[#dbe2ff] hover:bg-blue-200 text-xs md:text-lg font-bold py-2 px-2 md:px-4 rounded flex flex-row gap-1 md:gap-3 items-center"
                type="button"
                onClick={() => {
                  setClinics([
                    ...clinics,
                    {
                      clinicID: '',
                      clinicName: '',
                      via: '',
                      clinicstreet: '',
                      clinicPhone: '',
                      postalCode: '',
                      province: '',
                      city: '',
                      country: '',
                    },
                  ]);
                }}
              >
                Añadir dirección
                <FaPlus />
              </button>
            )}
          </div>

          {clinics.map((clinic, index) => (
            <div key={index} className="mb-6">
              <ClinicCardEdit
                clinic={clinic}
                clinics={clinics}
                setClinics={setClinics}
                index={index}
              />
            </div>
          ))}

          <div className="flex items-center h-full w-full my-4 md:w-auto justify-end">
            <button
              className="btn-primary mt-4 md:mt-0 w-full md:w-auto"
              type="submit"
            >
              Guardar
            </button>
          </div>
        </section>
      </form>
    </div>
  );
};

export default MyProfile;
