import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import ReactQuill, { Quill } from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import {
  getGuideById,
  updateGuide,
  getGuidecategories,
  deleteGuide,
} from '@/services/guide';
import { toast } from 'react-toastify';
import { modules } from '@/constants/QuillEditor';

const Font = Quill.import('formats/font');
Font.whitelist = ['sans-serif', 'monospace', 'serif'];
Quill.register(Font, true);

const GuideEditor = () => {
  const { guideId } = useParams();
  const [title, setTitle] = useState('');
  const [slug, setSlug] = useState('');
  const [content, setContent] = useState('');
  const [category, setCategory] = useState('');
  const [order, setOrder] = useState(0);
  const [categories, setCategories] = useState([]);
  const [loading, setLoading] = useState(true); // New loading state
  const navigate = useNavigate();

  useEffect(() => {
    const fetchGuide = async () => {
      try {
        const { guide } = await getGuideById(guideId);
        setTitle(guide.title);
        setContent(guide.content);
        setCategory(guide.category);
        setSlug(guide.slug);
        setOrder(guide.order);
      } catch (error) {
        console.error('Error fetching guide:', error);
        toast.error('Error al cargar la guía');
      } finally {
        setLoading(false);
      }
    };

    const fetchCategories = async () => {
      try {
        const { categories } = await getGuidecategories({ queryString: '' });
        setCategories(categories || []);
      } catch (error) {
        console.error('Error fetching categories:', error);
        toast.error('Error al cargar las categorías');
      }
    };

    fetchCategories(); // Fetch categories on load
    if (guideId) {
      fetchGuide();
    }
  }, [guideId]);

  const handleSave = async () => {
    if (!title || !slug || !content || !category) {
      toast.error('Todos los campos son obligatorios');
      return;
    }

    try {
      const guideData = { title, content, category, slug, order };
      await updateGuide(guideId, guideData);
      toast.success('Guía actualizada con éxito');
      navigate(`/guides/${slug}`); // Redirigir a la guía actualizada
    } catch (error) {
      console.error('Error updating guide:', error);
      toast.error('Error al actualizar la guía');
    }
  };

  return (
    <div className="max-w-4xl mx-auto p-6 bg-white rounded-lg shadow-md">
      <h1 className="text-2xl font-semibold mb-6 text-gray-800">Editar Guía</h1>
      {loading ? (
        <p>Cargando...</p> // Loading indicator
      ) : (
        <div className="space-y-6">
          <div>
            <label className="block text-gray-700 font-medium mb-2">
              Título
            </label>
            <input
              type="text"
              value={title}
              onChange={(e) => setTitle(e.target.value)}
              className="w-full p-3 border border-gray-300 rounded-lg shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
              required
            />
          </div>
          <div>
            <label className="block text-gray-700 font-medium mb-2">Slug</label>
            <input
              type="text"
              value={slug}
              onChange={(e) => setSlug(e.target.value)}
              className="w-full p-3 border border-gray-300 rounded-lg shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
              required
            />
          </div>
          <div>
            <label className="block text-gray-700 font-medium mb-2">
              Orden
            </label>
            <input
              type="number"
              min={0}
              value={order}
              onChange={(e) => setOrder(e.target.value)}
              className="w-full p-3 border border-gray-300 rounded-lg shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
            />
          </div>
          <div>
            <label className="block text-gray-700 font-medium mb-2">
              Categoría
            </label>
            <select
              value={category}
              onChange={(e) => setCategory(e.target.value)}
              className="w-full p-3 border border-gray-300 rounded-lg shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
              required
            >
              <option value="">Seleccionar categoría</option>
              {categories.map((cat) => (
                <option key={cat._id} value={cat._id}>
                  {cat.name}
                </option>
              ))}
            </select>
          </div>
          <div>
            <label className="block text-gray-700 font-medium mb-2">
              Contenido
            </label>
            <ReactQuill
              value={content}
              onChange={setContent}
              className="bg-white rounded-lg shadow-sm"
              theme="snow"
              modules={modules}
            />
          </div>
          <button
            onClick={handleSave}
            className="w-full py-3 px-4 bg-blue-500 text-white font-semibold rounded-lg shadow-md hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2"
          >
            Guardar Guía
          </button>
          <button
            onClick={async () => {
              try {
                await deleteGuide(guideId);
                toast.success('Guía eliminada con éxito');
                navigate('/guides');
              } catch (error) {
                console.error('Error deleting guide:', error);
                toast.error('Error al eliminar la guía');
              }
            }}
            className="w-full py-3 px-4 bg-red-500 text-white font-semibold rounded-lg shadow-md hover:bg-red-600 focus:outline-none focus:ring-2 focus:ring-red-500 focus:ring-offset-2"
          >
            Eliminar Guía
          </button>
        </div>
      )}
    </div>
  );
};

export default GuideEditor;
