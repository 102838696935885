import axios from 'axios';

const API_BASE_URL = import.meta.env.VITE_API_BASE_URL;

const axiosInstance = axios.create({
  baseURL: `${API_BASE_URL}/api`,
  headers: {
    'Content-Type': 'application/json',
  },
  withCredentials: true,
});

axiosInstance.interceptors.request.use(
  (config) => {
    const token = localStorage.getItem('token');
    if (token) {
      config.headers['Authorization'] = `Bearer ${token}`;
    }
    return config;
  },
  (error) => Promise.reject(error),
);

axiosInstance.interceptors.response.use(
  (response) => response,
  (error) => {
    if (error.response && error.response.status === 401) {
      console.error('Unauthorized, redirecting to login');
      localStorage.removeItem('token');
    }
    return Promise.reject(error);
  },
);

export const createReferral = async (referralData) => {
  try {
    const response = await axiosInstance.post('/referrals', referralData);
    const data = response.data;
    return data;
  } catch (error) {
    console.error('Error creating referral:', error);
    throw error;
  }
};

export const getReferrals = async ({ queryString }) => {
  try {
    const response = await axiosInstance.get(`/referrals?${queryString}`);
    const data = response.data;
    return data;
  } catch (error) {
    console.error('Error fetching referrals:', error);
    throw error;
  }
};
