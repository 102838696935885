import { useEffect, useState } from 'react';
import { Table, TableBody, TableCell, TableRow } from '@/components/ui/table';
import dateFormat from '@/utils/dateFormat';
import { getOffer } from '@/services/offer';

const TableCaseSidabar = ({ demand }) => {
  const [offer, setOffer] = useState('');
  const [loading, setLoading] = useState(true);

  const fetchOffer = async () => {
    setLoading(true);

    const { offer } = await getOffer(
      demand.selectedLaboratorios[0]?.offer?._id,
    );
    if (offer) {
      setOffer(offer);
    }
    setLoading(false);
  };
  useEffect(() => {
    if (
      demand.status !== 'pending' &&
      demand.selectedLaboratorios?.length &&
      demand.selectedLaboratorios[0]?.offer?._id
    ) {
      fetchOffer();
    }
  }, [demand.status, demand.selectedLaboratorios]);

  const handleStatus = (status) => {
    switch (status) {
      case 'pending':
        return 'Propuesta';
      case 'design':
        return 'Diseño';
      case 'onfabrication':
        return 'En fabricación';
      case 'accepted':
        return 'Completado';
      case 'sended':
        return 'Enviado';
      case 'rejected':
        return 'Rechazado';
      case 'inReview':
        return 'En revisión';
      default:
        return 'Desconocido';
    }
  };

  const handleProtesis = (selectedOption) => {
    switch (selectedOption) {
      case 'Corona':
        return 'Corona / Puente / Arcada completa';
      case 'Carillas':
        return 'Carillas / Incrustación / onlay';
      case 'Dentadura':
        return 'Dentadura / Parcial / Esquelético';
      case 'Férulas':
        return 'Férulas';
    }
  };
  const handleOrtodoncia = (selectedOption) => {
    switch (selectedOption) {
      case 'OrthodonticAppliances':
        return 'Aparatos Ortodoncia';
      case 'Aligners':
        return 'Ortodoncia Invisible';
      case 'Retainers':
        return 'Retenedor';
    }
  };

  const handleProducts = (selectedOption) => {
    switch (selectedOption) {
      case 'ProtectorBucal':
        return 'Protector Bucal';
      case 'AntiRonquido':
        return 'Anti Ronquido';
      case 'Grillz':
        return 'Grillz';
      case 'Modelo':
        return 'Modelo';
      case 'ReparacionRebase':
        return 'Reparación / Rebase';
      case 'Biomaterial':
        return 'Biomaterial';
      case 'Otros':
        return 'Otros';
    }
  };

  const handlecaseType = (demand) => {
    switch (demand.caseType) {
      case 'protesis':
        return handleProtesis(demand.selectedImplant?.selectedOption);
      case 'ortodoncia':
        return handleOrtodoncia(demand.ortodonciaReq?.selectedOption);
      default:
        return handleProducts(demand.selectedProduct);
    }
  };
  return (
    <Table className="table-auto rounded-[10px] text-center font-urbanist">
      <TableBody>
        <TableRow className="border-none">
          <TableCell className="bg-[#dbe2ff] rounded-tl-md p-2">
            <strong className="text-sm">Estado</strong>
          </TableCell>
          <TableCell className="bg-white p-2 text-sm">
            {handleStatus(demand.status)}
          </TableCell>
        </TableRow>
        <TableRow className="border-none">
          <TableCell className="bg-[#dbe2ff] p-2">
            <strong className="text-sm">Creación</strong>
          </TableCell>
          <TableCell className="bg-white p-2 text-sm">
            {dateFormat(demand.createdAt)}
          </TableCell>
        </TableRow>
        <TableRow className="border-none">
          <TableCell className="bg-[#dbe2ff] p-2">
            <strong className="text-sm">Entrega</strong>
          </TableCell>
          <TableCell className="bg-white p-2 text-sm">
            {offer
              ? dateFormat(offer.deliveryDateIntent)
              : dateFormat(demand.deliveryDate)}
          </TableCell>
        </TableRow>
        <TableRow className="border-none">
          <TableCell className="bg-[#dbe2ff] p-2">
            <strong className="text-sm">Dentista</strong>
          </TableCell>
          <TableCell className="bg-white p-2 text-sm">
            {demand.user?.name} {demand.user?.lastname}
          </TableCell>
        </TableRow>
        <TableRow className="border-none">
          <TableCell className="bg-[#dbe2ff] rounded-bl-md p-2">
            <strong className="text-sm">Típo de trabajo</strong>
          </TableCell>
          <TableCell className="bg-white rounded-br-md p-2 text-sm">
            {handlecaseType(demand)}
          </TableCell>
        </TableRow>
      </TableBody>
    </Table>
  );
};

export default TableCaseSidabar;
