import React from 'react';

const ProfileEditCard = ({
  handleAvatarClick,
  avatarPreview,
  avatar,
  avatarInputRef,
  handleAvatarChange,
  name,
  setName,
  lastname,
  setLastname,
  email,
  phone,
  setEmail,
  setPhone,
  nif,
  razonSocial,
  colNumber,
  setNif,
  setRazonSocial,
  setColNumber,
  editNif,
}) => {
  return (
    <div className="w-full p-4 md:p-6">
      <div className="w-full flex flex-col">
        <div className="flex flex-col md:flex-row items-start gap-4">
          {editNif ? (
            <div className="flex flex-col w-full">
              <div className="flex flex-col md:flex-row gap-4 md:gap-6 items-center">
                <div className="relative group" onClick={handleAvatarClick}>
                  <img
                    src={avatarPreview || avatar}
                    alt="avatar"
                    className="w-16 h-16 sm:w-20 sm:h-20 rounded-full border-4 border-gray-300 object-cover hover:opacity-80 transition-opacity"
                  />
                  <div className="absolute inset-0 flex items-center justify-center opacity-0 group-hover:opacity-100 transition-opacity bg-black bg-opacity-50 rounded-full">
                    <span className="text-white text-sm">Cambiar</span>
                  </div>
                </div>
                <input
                  type="file"
                  ref={avatarInputRef}
                  onChange={handleAvatarChange}
                  style={{ display: 'none' }}
                  accept="image/*"
                />
                <div className="flex flex-col w-full">
                  <div className="grid grid-cols-1 sm:grid-cols-2 gap-4">
                    <div>
                      <label
                        htmlFor="name"
                        className="block text-sm font-medium text-gray-700"
                      >
                        Nombre
                      </label>
                      <input
                        type="text"
                        id="name"
                        value={name}
                        onChange={(e) => setName(e.target.value)}
                        className="mt-1 w-full p-2 border border-gray-300 rounded-md focus:ring-blue-500 focus:border-blue-500"
                        placeholder="John"
                        required
                      />
                    </div>
                    <div>
                      <label
                        htmlFor="lastname"
                        className="block text-sm font-medium text-gray-700"
                      >
                        Apellido
                      </label>
                      <input
                        type="text"
                        id="lastname"
                        value={lastname}
                        onChange={(e) => setLastname(e.target.value)}
                        className="mt-1 w-full p-2 border border-gray-300 rounded-md focus:ring-blue-500 focus:border-blue-500"
                        placeholder="Doe"
                        required
                      />
                    </div>
                  </div>
                </div>
              </div>

              <div className="grid grid-cols-1 sm:grid-cols-2 gap-4 mt-4">
                <div>
                  <label
                    htmlFor="email"
                    className="block text-sm font-medium text-gray-700"
                  >
                    Email
                  </label>
                  <input
                    type="email"
                    id="email"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    className="mt-1 w-full p-2 border border-gray-300 rounded-md focus:ring-blue-500 focus:border-blue-500"
                    placeholder="you@example.com"
                    required
                  />
                </div>
                <div>
                  <label
                    htmlFor="phone"
                    className="block text-sm font-medium text-gray-700"
                  >
                    Teléfono
                  </label>
                  <input
                    type="text"
                    id="phone"
                    value={phone}
                    onChange={(e) => setPhone(e.target.value)}
                    className="mt-1 w-full p-2 border border-gray-300 rounded-md focus:ring-blue-500 focus:border-blue-500"
                    placeholder="123-456-7890"
                    required
                  />
                </div>
                <div>
                  <label
                    htmlFor="nif"
                    className="block text-sm font-medium text-gray-700"
                  >
                    NIF
                  </label>
                  <input
                    type="text"
                    id="nif"
                    value={nif}
                    onChange={(e) => setNif(e.target.value)}
                    className="mt-1 w-full p-2 border border-gray-300 rounded-md focus:ring-blue-500 focus:border-blue-500"
                    placeholder="NIF"
                    required
                  />
                </div>
                <div>
                  <label
                    htmlFor="razonSocial"
                    className="block text-sm font-medium text-gray-700"
                  >
                    Razón Social
                  </label>
                  <input
                    type="text"
                    id="razonSocial"
                    value={razonSocial}
                    onChange={(e) => setRazonSocial(e.target.value)}
                    className="mt-1 w-full p-2 border border-gray-300 rounded-md focus:ring-blue-500 focus:border-blue-500"
                    placeholder="Razón Social"
                    required
                  />
                </div>
                <div>
                  <label
                    htmlFor="colNumber"
                    className="block text-sm font-medium text-gray-700"
                  >
                    Número de Colegiado
                  </label>
                  <input
                    type="text"
                    id="colNumber"
                    value={colNumber}
                    onChange={(e) => setColNumber(e.target.value)}
                    className="mt-1 w-full p-2 border border-gray-300 rounded-md focus:ring-blue-500 focus:border-blue-500"
                    placeholder="Número de Colegiado"
                    required
                  />
                </div>
              </div>
            </div>
          ) : (
            <div className="flex flex-col items-start gap-4 md:gap-6">
              <div className="flex items-center gap-4">
                <div className="relative group" onClick={handleAvatarClick}>
                  <img
                    src={avatarPreview || avatar}
                    alt="avatar"
                    className="w-14 h-14 md:w-20 md:h-20 rounded-full border-4 border-gray-300 object-cover hover:opacity-80 transition-opacity"
                  />
                  <div className="absolute inset-0 flex items-center justify-center opacity-0 group-hover:opacity-100 transition-opacity bg-black bg-opacity-50 rounded-full">
                    <span className="text-white text-sm">Cambiar</span>
                  </div>
                </div>
                <input
                  type="file"
                  ref={avatarInputRef}
                  onChange={handleAvatarChange}
                  style={{ display: 'none' }}
                  accept="image/*"
                />
                <div className="flex flex-col">
                  <h1 className="text-base md:text-2xl font-semibold text-gray-900">
                    {name} {lastname}
                  </h1>
                </div>
              </div>
              <div className="text-sm md:text-lg text-gray-700 space-y-2">
                <p>
                  <span className="font-semibold">Email: </span>
                  {email}
                </p>
                <p>
                  <span className="font-semibold">Teléfono: </span>
                  {phone}
                </p>
                <p>
                  <span className="font-semibold">NIF: </span>
                  {nif}
                </p>
                <p>
                  <span className="font-semibold">Razón social: </span>
                  {razonSocial}
                </p>
                <p>
                  <span className="font-semibold">Número de colegiado: </span>
                  {colNumber}
                </p>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default ProfileEditCard;
