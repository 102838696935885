import { useSearchParams } from 'react-router-dom';
import TableCases from '@/components/Tables/TableCases';
import { useFetchOutsourcings } from '@/hooks/useOutsourcing';

const OutsourcingsPage = () => {
  const [searchParams, setSearchParams] = useSearchParams();

  const { outsourcings, totalOutsourcings } =
    useFetchOutsourcings(searchParams);

  return (
    <div className="">
      <section>
        <TableCases
          outsourcings={outsourcings}
          seller={false}
          total={totalOutsourcings}
          setSearchParams={setSearchParams}
          searchParams={searchParams}
        />
      </section>
    </div>
  );
};

export default OutsourcingsPage;
