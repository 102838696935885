import React, { useState, useEffect } from 'react';
import { useParams, Link } from 'react-router-dom';
import ProgressStepper from '@/components/Cards/ProgressStepper';
import CaseDetailCard from '@/components/Cards/CaseDetailCard';
import { editDemandAsAdmin } from '@/services/demand';
import { useFetchDemand } from '@/hooks/useDemand';
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from '@/components/ui/select';
import { useUserStore } from '@/store/userStore';

const CasePageAdmin = () => {
  const { user } = useUserStore();
  const { caseId } = useParams();
  const [saving, setSaving] = useState(false);
  const [status, setStatus] = useState(null);

  const {
    demand,
    loadingDemand: loading,
    fetchDemand,
  } = useFetchDemand(caseId);

  const handleEditStatus = async (status) => {
    try {
      setSaving(true);
      await editDemandAsAdmin(caseId, { status });
      fetchDemand();
    } catch (error) {
      console.error('Error editing demand:', error);
    } finally {
      setSaving(false);
    }
  };

  const handleSubmit = () => {
    if (status) {
      handleEditStatus(status);
    }
  };

  useEffect(() => {
    if (demand) {
      setStatus(demand.status);
    }
  }, [demand]);

  if (loading) {
    return (
      <div className="flex justify-center items-center min-h-screen">
        Loading
      </div>
    );
  }

  return (
    <div className="p-2 w-full">
      <div className="flex flex-col gap-2">
        <ProgressStepper />
        <CaseDetailCard />
      </div>
      {user.role === 'ADMIN_ROLE' && (
        <div className=" py-3 flex flex-col md:flex-row w-full justify-end md:space-x-3 space-y-2 md:space-y-0 font-inter">
          <Select
            value={status}
            onValueChange={(value) => setStatus(value)}
            aria-label="Filter by status"
            className="w-full md:w-auto"
          >
            <SelectTrigger className="w-full md:w-[180px] px-2 h-10 border border-[#BDCAFE] rounded-md font-urbanist">
              <SelectValue placeholder="Estado" />
            </SelectTrigger>
            <SelectContent>
              <SelectItem value="design">Diseño</SelectItem>
              <SelectItem value="onfabrication">Fabricación</SelectItem>
              <SelectItem value="sended">Enviado</SelectItem>
              <SelectItem value="inReview">Revisión</SelectItem>
              <SelectItem value="accepted">Completado</SelectItem>
            </SelectContent>
          </Select>
          <button
            onClick={handleSubmit}
            disabled={saving}
            className="btn-neutral w-full md:w-80 text-white font-bold py-2 px-4 rounded flex items-center justify-center"
          >
            {saving ? 'Guardando...' : 'Guardar'}
          </button>
          {demand.status === 'accepted' && !demand.billLabCase && (
            <Link
              to={`/admin/createBill/${demand._id}`}
              className="bg-blue-500 w-full md:w-80 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded flex items-center justify-center"
            >
              Adjuntar factura
            </Link>
          )}
        </div>
      )}
    </div>
  );
};

export default CasePageAdmin;
