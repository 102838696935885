import { useEffect, useState, useCallback } from 'react';
import { getReviews, createReview, getReviewsAsAdmin } from '@/services/review';

import { useUserStore } from '@/store/userStore';
// import { toast } from 'react-toastify';

export const useFetchReviews = (searchParams) => {
  const { user } = useUserStore();
  const [reviews, setReviews] = useState([]);
  const [total, setTotal] = useState(0);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  const fetchReviews = useCallback(async () => {
    setLoading(true);
    setError(null);

    try {
      const params = Object.fromEntries(searchParams);
      const queryString = new URLSearchParams(params).toString();
      let fetcher;

      if (user.role === 'SELLER_ROLE') {
        fetcher = getReviews;
      } else if (user.role === 'DENTIST_ROLE') {
        fetcher = getReviews;
      } else {
        fetcher = getReviewsAsAdmin;
      }

      const data = await fetcher({ queryString });
      setReviews(data.reviews);
      setTotal(data.total);
    } catch (error) {
      setError(error.message || 'Failed to fetch Reviews');
      console.error('Failed to fetch Reviews:', error);
    } finally {
      setLoading(false);
    }
  }, [searchParams, user.role]);

  useEffect(() => {
    fetchReviews();
  }, [searchParams, fetchReviews]);

  return { reviews, loading, error, total, fetchReviews };
};

// export const useCreateReview = () => {
//   const [link, setLink] = useState(null);
//   const [loading, setLoading] = useState(false);
//   const [error, setError] = useState(null);

//   const handleCreateReferralLink = async (email) => {
//     setLoading(true);
//     setError(null);

//     try {
//       if (!email.includes('@')) {
//         toast.error('formato no valido para correo');
//         return;
//       }
//       const data = { email };
//       const response = await createReferral(data);
//       if (response) {
//         toast.success('Referral created successfully');
//         setLink(response.referralCode.token);
//       } else {
//         toast.error('Failed to create referral');
//       }
//     } catch (error) {
//       console.error(error);
//       toast.error('Failed to create referral');
//     } finally {
//       setLoading(false);
//     }
//   };

//   return { link, loading, error, handleCreateReferralLink };
// };
