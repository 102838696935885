import { useState, useEffect } from 'react';
import { cn } from '@/lib/utils';
import { Card } from '@/components/ui/card';
import { useDemandStore } from '@/store/DemandStore';
import { useOutsourcingStore } from '@/store/OutsourcingStore';
import { statusSteps, statusStepsDesign } from '@/constants/caseType';

const handleStatus = (status) => {
  const statusMap = {
    pending: 'Propuesta',
    design: 'Diseño',
    onfabrication: 'Fabricación',
    accepted: 'Cerrado',
    sended: 'Enviado',
    rejected: 'Rechazado',
    inReview: 'En revisión',
  };
  return statusMap[status] || 'Desconocido';
};

export default function ProgressStepper({ lab }) {
  const { demand } = useDemandStore();
  const { outsourcing } = useOutsourcingStore();
  const [activeStep, setActiveStep] = useState(0);
  const steps = lab
    ? outsourcing.fabrication
      ? statusSteps
      : statusStepsDesign
    : statusSteps;

  useEffect(() => {
    const currentStatus = lab ? outsourcing.status : demand.status;
    const currentStep = steps.indexOf(currentStatus);
    if (currentStep !== -1) {
      setActiveStep(currentStep);
    }
  }, [demand?.status, outsourcing?.status, lab, steps]);

  const getProgressWidth = () =>
    steps.length > 1 ? `${(activeStep / (steps.length - 1)) * 100}%` : '100%';

  const renderStep = (step, index) => (
    <div key={index} className="flex flex-col items-center relative z-10">
      <div
        className={cn(
          'w-10 h-10 rounded-full flex items-center justify-center text-sm font-medium',
          index <= activeStep
            ? 'bg-[#0224ab] text-primary-foreground'
            : 'bg-[#DCE3FF] text-secondary-foreground',
        )}
      >
        {index + 1}
      </div>
      <div className="mt-2 font-urbanist text-sm font-medium text-center text-muted-foreground max-w-[100px]">
        {handleStatus(step)}
      </div>
    </div>
  );

  return (
    <Card className="w-full p-4 h-[100px] gap-8 rounded-lg bg-white">
      {((lab && outsourcing.status !== 'inReview') ||
        (!lab && demand.status !== 'inReview')) && (
        <div className="relative flex justify-between mb-8">
          {steps.map(renderStep)}
          <div className="absolute top-5 left-0 right-0 h-1 bg-secondary -translate-y-1/2" />
          <div
            className="absolute top-5 left-0 h-1 bg-[#0224ab] -translate-y-1/2 transition-all duration-300 ease-in-out"
            style={{ width: getProgressWidth() }}
          />
        </div>
      )}
      {((lab && outsourcing.status === 'inReview') ||
        (!lab && demand.status === 'inReview')) && (
        <div className="flex flex-col items-center relative z-10">
          <div
            className={cn(
              'w-10 h-10 rounded-full flex items-center justify-center text-sm font-medium',
              'bg-[#0224ab] text-primary-foreground',
            )}
          >
            1
          </div>
          <div className="mt-2 font-urbanist text-sm font-medium text-center text-muted-foreground max-w-[100px]">
            En revisión
          </div>
        </div>
      )}
    </Card>
  );
}
