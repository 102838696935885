import { useEffect, useState, useCallback } from 'react';
import useDemandStore from '@/store/DemandStore';

import { getOffer, getOfferOutsourcing } from '@/services/offer';

export const useFetchOffer = (offerId) => {
  const { offer, setOffer } = useDemandStore(); // Correct destructuring
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  const fetchOffer = useCallback(async () => {
    if (!offerId) return; // Early return if no offerId

    setLoading(true);
    setError(null); // Reset error state before fetch

    try {
      const { offer: fetchedOffer } = await getOffer(offerId);
      if (fetchedOffer) {
        setOffer(fetchedOffer); // Store fetched offer
      } else {
        throw new Error('No offer found');
      }
    } catch (error) {
      setError(error.message || 'Error fetching offer');
      console.error('Error fetching offer:', error);
    } finally {
      setLoading(false);
    }
  }, [offerId, setOffer]); // Add setOffer to dependencies

  useEffect(() => {
    fetchOffer();
  }, [fetchOffer, offerId]); // Only fetch when offerId changes

  return { offer, loading, error, fetchOffer };
};

export const useFetchOfferOutsourcing = (offerId) => {
  const { offer, setOffer } = useDemandStore(); // Correct destructuring
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  const fetchOffer = useCallback(async () => {
    if (!offerId) return; // Early return if no offerId

    setLoading(true);
    setError(null); // Reset error state before fetch

    try {
      const { offer: fetchedOffer } = await getOfferOutsourcing(offerId);
      if (fetchedOffer) {
        setOffer(fetchedOffer); // Store fetched offer
      } else {
        throw new Error('No offer found');
      }
    } catch (error) {
      setError(error.message || 'Error fetching offer');
      console.error('Error fetching offer:', error);
    } finally {
      setLoading(false);
    }
  }, [offerId, setOffer]); // Add setOffer to dependencies

  useEffect(() => {
    fetchOffer();
  }, [fetchOffer, offerId]); // Only fetch when offerId changes

  return { offer, loading, error, fetchOffer };
};
