import { useSearchParams } from 'react-router-dom';
import TablebillLabCases from '@/components/Tables/TablebillLabCases';
import TablebillLabOutsourcings from '@/components/Tables/TablebillLabOutsourcings';
import { useFetchBillLabCases } from '@/hooks/useBillCase';
import { useFetchBillOutsourcingBuyers } from '@/hooks/useBillOutsourcingBuyer';

import { Tabs, TabsContent, TabsList, TabsTrigger } from '@/components/ui/tabs';

const BillLabCasesAdmin = () => {
  const [searchParams, setSearchParams] = useSearchParams();

  const { billLabCases, totalBillLabCases } =
    useFetchBillLabCases(searchParams);

  const { billOutsourcingBuyers, total } =
    useFetchBillOutsourcingBuyers(searchParams);

  return (
    <div className="">
      <Tabs defaultValue="cases">
        <TabsList className="grid w-full grid-cols-2">
          <TabsTrigger value="cases">Trabajos con casos</TabsTrigger>
          <TabsTrigger value="outsorcings">
            Trabajos con outsorcings
          </TabsTrigger>
        </TabsList>
        <TabsContent value="cases">
          <TablebillLabCases
            billlabcases={billLabCases}
            total={totalBillLabCases}
            setSearchParams={setSearchParams}
            searchParams={searchParams}
          />
        </TabsContent>
        <TabsContent value="outsorcings">
          <TablebillLabOutsourcings
            billOutsourcingBuyers={billOutsourcingBuyers}
            total={total}
            setSearchParams={setSearchParams}
            searchParams={searchParams}
          />
        </TabsContent>
      </Tabs>
    </div>
  );
};

export default BillLabCasesAdmin;
