import React from 'react';
import { Input } from '@/components/ui/input';
import { Label } from '@/components/ui/label';

const GoogleForm = ({ googleData, setGoogleData }) => {
  return (
    <div className="mt-4">
      <Label htmlFor="googleEmail" className="text-sm font-medium">
        Google Email
      </Label>
      <Input
        type="email"
        id="googleEmail"
        value={googleData.email}
        onChange={(e) =>
          setGoogleData({ ...googleData, email: e.target.value })
        }
        className="mt-1"
        placeholder="Enter your Google email"
      />
    </div>
  );
};

export default GoogleForm;
