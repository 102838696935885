import { create } from 'zustand';
import { persist } from 'zustand/middleware';

export const useDemandStore = create(
  persist(
    (set) => ({
      demand: {},
      demands: [],
      offer: {},
      setDemand: (demand) => set({ demand }),
      setDemands: (demands) => set({ demands }),
      setOffer: (offer) => set({ offer }),
      clearDemandStore: () =>
        set({
          demand: {},
          demands: [],
          offer: {},
        }),
    }),
    {
      name: 'demand-storage',
    },
  ),
);

export default useDemandStore;
