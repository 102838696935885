import { useEffect, useState } from 'react';
import { getProfile, updateServices } from '@/services/auth';
import { toast } from 'react-toastify';

const NotificationsSettings = () => {
  const initialNotifications = {
    email: {
      newMessage: false,
      messageResponse: false,
      newDemand: false,
      newOffer: false,
      newOrder: false,
      revisionResponse: false,
      caseClose: false,
    },
  };

  const [notifications, setNotifications] = useState(initialNotifications);
  const [userNot, setUserNot] = useState('');
  const [isLoading, setIsLoading] = useState(false);

  const notificationOptions = [
    {
      id: 'newMessageEmail',
      name: 'newMessage',
      label: 'Nuevos mensajes',
      description: 'Recibir notificaciones cuando recibas nuevos mensajes.',
    },
    {
      id: 'messageResponseEmail',
      name: 'messageResponse',
      label: 'Respuesta a mensajes',
      description:
        'Recibir notificaciones cuando recibas respuestas a tus mensajes.',
    },
    {
      id: 'newDemand',
      name: 'newDemand',
      label: 'Nuevos casos',
      description: 'Recibir notificaciones cuando recibas nuevos casos.',
    },
    {
      id: 'newOfferEmail',
      name: 'newOffer',
      label: 'Nuevas propuestas',
      description: 'Recibir notificaciones cuando haya nuevas propuestas.',
    },
    {
      id: 'newOfferEmail',
      name: 'newOrder',
      label: 'Nuevas Ordenes',
      description: 'Recibir notificaciones cuando haya nuevas ordenes.',
    },
    {
      id: 'revisionResponseEmail',
      name: 'revisionResponse',
      label: 'Respuesta a revisión',
      description:
        'Recibir notificaciones cuando haya respuestas a revisiones.',
    },
    {
      id: 'caseCloseEmail',
      name: 'caseClose',
      label: 'Caso cerrado',
      description: 'Recibir notificaciones cuando un caso se cierre.',
    },
  ];

  const handleChange = (e, category) => {
    const { name, checked } = e.target;

    setNotifications((prevNotifications) => ({
      ...prevNotifications,
      [category]: {
        ...prevNotifications[category],
        [name]: checked,
      },
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);

    // Only modify the email notifications while keeping web and sms from the current profile
    const updatedProfile = {
      notifications: {
        ...userNot, // Keep existing web and sms notifications
        email: notifications.email, // Only update the email notifications
      },
    };

    try {
      const response = await updateServices(updatedProfile);
      if (response) {
        toast.success('Perfil actualizado correctamente');
        await fetchProfile(); // Update profile to reflect changes
      }
    } catch (error) {
      console.error('Error updating profile:', error.response?.data || error);
      toast.error(
        error.response?.data?.message || 'Error al actualizar el perfil',
      );
    } finally {
      setIsLoading(false);
    }
  };

  const fetchProfile = async () => {
    try {
      const profile = await getProfile();

      // Ensure notifications structure is valid
      const notificationsFromProfile =
        profile.notifications || initialNotifications;

      // Make sure it has the email structure
      const emailNotifications = {
        ...initialNotifications.email,
        ...notificationsFromProfile.email,
      };

      setNotifications({
        email: emailNotifications,
      });
      setUserNot(profile.notifications);
    } catch (error) {
      console.error('Error fetching profile:', error);
      toast.error('Error al cargar el perfil');
    }
  };

  useEffect(() => {
    fetchProfile();
  }, []);

  return (
    <div className="max-w-4xl mx-auto p-6 bg-white rounded-lg shadow-md">
      <form onSubmit={handleSubmit}>
        <div className="grid grid-cols-1 md:grid-cols-2 gap-8">
          <div>
            <h3 className="text-xl font-semibold mb-4">
              Notificaciones por email
            </h3>
            <div className="flex flex-col">
              {notificationOptions.map(({ id, name, label, description }) => (
                <div className="flex items-start gap-3 mb-3" key={id}>
                  <input
                    type="checkbox"
                    id={id}
                    name={name}
                    checked={notifications.email[name]}
                    onChange={(e) => handleChange(e, 'email')}
                    className="mt-1 h-4 w-4 text-blue-600 focus:ring-blue-500 border-gray-300 rounded"
                  />
                  <div>
                    <label htmlFor={id} className="font-medium text-gray-700">
                      {label}
                    </label>
                    <p className="text-gray-500 text-sm">{description}</p>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
        <button
          type="submit"
          className={`mt-6 px-4 py-2 bg-blue-600 text-white rounded-md shadow ${
            isLoading ? 'opacity-50 cursor-not-allowed' : 'hover:bg-blue-700'
          }`}
          disabled={isLoading}
        >
          {isLoading ? 'Guardando...' : 'Guardar cambios'}
        </button>
      </form>
    </div>
  );
};

export default NotificationsSettings;
