import React, { useState, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { resetPassword, checkToken } from '@/services/auth';
import { toast } from 'react-toastify';

const ResetPassword = () => {
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [tokenChecked, setTokenChecked] = useState(false);
  const [error, setError] = useState('');
  const [loading, setLoading] = useState(true); // Loading state for token check
  const { resetToken } = useParams(); // To get the reset token from the URL
  const navigate = useNavigate(); // For redirection

  useEffect(() => {
    const checkTokenValidity = async () => {
      try {
        const response = await checkToken(resetToken);
        if (!response) {
          setError('Invalid or expired token.');
        } else {
          setTokenChecked(true);
        }
      } catch (error) {
        console.error('Error checking token:', error);
        setError('An error occurred while verifying the token');
      } finally {
        setLoading(false);
      }
    };

    checkTokenValidity();
  }, [resetToken, navigate]);

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (password !== confirmPassword) {
      setError('Contraseñas no coinciden');
      return;
    }

    try {
      if (!tokenChecked) {
        setError('Token invalido o expirado');
        return;
      }
      const response = await resetPassword({ token: resetToken, password });
      if (response) {
        toast.success('Clave cambiada con exito');
        setError('');
        setTimeout(() => navigate('/login'), 2000);
      } else {
        toast.error(response?.error || 'An error occurred. Please try again.');
      }
    } catch (err) {
      console.error('Error during password reset:', err);
      setError('An error occurred. Please try again.');
    }
  };

  if (loading) {
    return (
      <p>
        {' '}
        <>
          <svg
            className="animate-spin h-5 w-5 mr-2 text-white"
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
          >
            <circle
              className="opacity-25"
              cx="12"
              cy="12"
              r="10"
              stroke="currentColor"
              strokeWidth="4"
            ></circle>
          </svg>
          Cargando...
        </>
      </p>
    ); // Show a loading message while checking the token
  }

  return (
    <div className="flex items-center justify-center min-h-screen bg-gray-100">
      <div className="bg-white p-8 rounded-lg shadow-lg w-full max-w-sm">
        <h2 className="text-2xl font-bold text-center mb-6">Reset Password</h2>
        <form className="space-y-4" onSubmit={handleSubmit}>
          <div>
            <label
              htmlFor="password"
              className="block text-sm font-medium text-gray-700"
            >
              New Password
            </label>
            <input
              type="password"
              id="password"
              name="password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              className="mt-1 block w-full p-2 border border-gray-300 rounded-lg shadow-sm focus:ring-blue-500 focus:border-blue-500"
              placeholder="••••••••"
              required
            />
          </div>
          <div>
            <label
              htmlFor="confirmPassword"
              className="block text-sm font-medium text-gray-700"
            >
              Confirm New Password
            </label>
            <input
              type="password"
              id="confirmPassword"
              name="confirmPassword"
              value={confirmPassword}
              onChange={(e) => setConfirmPassword(e.target.value)}
              className="mt-1 block w-full p-2 border border-gray-300 rounded-lg shadow-sm focus:ring-blue-500 focus:border-blue-500"
              placeholder="••••••••"
              required
            />
          </div>
          <button
            type="submit"
            className="w-full py-2 px-4 bg-black text-white rounded-lg hover:bg-gray-800 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-opacity-50"
          >
            Reset Password
          </button>
        </form>
        {error && <p className="text-red-500 text-sm mt-2">{error}</p>}
      </div>
    </div>
  );
};

export default ResetPassword;
