import React, { useState, useEffect } from 'react';
import { useParams, Link } from 'react-router-dom';
import { getDocById } from '@/services/doc';
import useUserStore from '@/store/userStore';

const DocContent = () => {
  const { user } = useUserStore();
  const { docId } = useParams();
  const [doc, setDoc] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  const fetchDoc = async () => {
    try {
      setLoading(true);
      const { doc } = await getDocById(docId);
      setDoc(doc);
    } catch (err) {
      console.error('Error fetching doc:', err);
      setError('Hubo un problema al cargar la guía.');
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchDoc();
  }, [docId]);

  if (loading) {
    return <p className="text-center text-lg text-gray-500">Cargando...</p>;
  }

  if (error) {
    return <p className="text-center text-lg text-red-500">{error}</p>;
  }

  if (!doc) {
    return (
      <p className="text-center text-lg text-gray-500">
        No se encontró la guía.
      </p>
    );
  }

  return (
    <div className="max-w-4xl mx-auto p-6 bg-white rounded-lg shadow-md">
      <h1 className="text-3xl font-bold mb-6 text-gray-800 text-center">
        {doc.title}
      </h1>
      <div
        className="prose max-w-none px-8"
        dangerouslySetInnerHTML={{ __html: doc.content }}
      />
      {/* Render the Edit button if the user has the ADMIN_ROLE */}
      {user?.role === 'ADMIN_ROLE' && (
        <div className="mt-6">
          <Link
            to={`/doc/edit/${docId}`}
            className="px-4 py-2 bg-blue-600 text-white rounded hover:bg-blue-700"
          >
            Editar Documento
          </Link>
        </div>
      )}
    </div>
  );
};

export default DocContent;
