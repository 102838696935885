import React, { useState } from 'react';
import { IoMdClose } from 'react-icons/io';
import { createRevision, createRevisionResponse } from '@/services/revision';
import { toast } from 'react-toastify';
import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogFooter,
} from '@/components/ui/dialog';
import { Input } from '@/components/ui/input';
import { Button } from '@/components/ui/button';
import useUserStore from '@/store/userStore';

const RevisionModal = ({ caseId, isOpen, setIsOpen, handleStatusChange }) => {
  const [description, setDescription] = useState('');
  // const [files, setFiles] = useState(null);
  const { user } = useUserStore();
  const [deliveryDateIntent, setDeliveryDateIntent] = useState('');
  const [loading, setLoading] = useState(false);
  const today = new Date().toISOString().split('T')[0];

  const handleClose = () => {
    setIsOpen(false);
    resetForm();
  };

  const resetForm = () => {
    setDescription('');
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!description) {
      toast.warn('Both title and description are required');
      return;
    }
    const revisionData = {
      description,
      demand: caseId,
    };
    const responseData = {
      description,
      demand: caseId,
      deliveryDateIntent,
    };

    try {
      setLoading(true);
      let response = null;

      if (user.role === 'DENTIST_ROLE') {
        response = await createRevision(revisionData);
      }
      if (user.role === 'SELLER_ROLE') {
        if (!deliveryDateIntent) {
          toast.warn('Fecha de entrega es requerida');
          return;
        }
        response = await createRevisionResponse(responseData);
      }

      if (response) {
        handleClose();

        user.role === 'DENTIST_ROLE' && (await handleStatusChange('inReview'));
        user.role === 'DENTIST_ROLE' &&
          toast.success('Revisión creada exitosamente');
        user.role === 'SELLER_ROLE' && (await handleStatusChange('design'));
        user.role === 'SELLER_ROLE' &&
          toast.success('Revisión respondida exitosamente');
      }
    } catch (error) {
      toast.error('Error al crear la revisión');
      console.error('Error creating revision:', error);
    } finally {
      setLoading(false);
    }
  };

  if (!isOpen) return null;

  return (
    <Dialog open={isOpen} onOpenChange={setIsOpen}>
      <DialogContent>
        {/* Modal Title */}
        <DialogHeader className="text-center">
          <h4 className="text-xl font-semibold">
            {user.role === 'DENTIST_ROLE'
              ? 'Crear Revisión'
              : 'Responder Revisión'}
          </h4>
        </DialogHeader>

        {/* Form Fields */}
        <form onSubmit={handleSubmit} className="px-6 py-4">
          {/* <div className="mb-4">
            <label
              className="block text-sm font-semibold mb-1"
              htmlFor="revisionTitle"
            >
              Título de revisión
            </label>
            <Input
              id="revisionTitle"
              type="text"
              value={title}
              onChange={(e) => setTitle(e.target.value)}
              placeholder="Ingrese el título"
              required
            />
          </div> */}

          <div className="mb-4">
            <label
              className="block text-sm font-semibold mb-1"
              htmlFor="revisionDetails"
            >
              Detalles
            </label>
            <textarea
              id="revisionDetails"
              value={description}
              onChange={(e) => setDescription(e.target.value)}
              placeholder="Ingrese los detalles"
              required
              rows={4}
              className="w-full p-2 border rounded-md"
            />
          </div>

          {/* <div className="mb-4">
            <label
              className="block text-sm font-semibold mb-1"
              htmlFor="revisionFiles"
            >
              Archivos
            </label>
            <Input
              id="revisionFiles"
              type="file"
              onChange={(e) => setFiles(e.target.files)}
              multiple
            />
          </div> */}
          {user.role === 'SELLER_ROLE' && (
            <div className="mb-4">
              <label
                className="block text-sm font-semibold mb-1"
                htmlFor="deliveryDateIntent"
              >
                Fecha de entrega
              </label>
              <Input
                id="deliveryDateIntent"
                type="date"
                min={today}
                value={deliveryDateIntent}
                onChange={(e) => setDeliveryDateIntent(e.target.value)}
                required
              />
            </div>
          )}

          {/* Save Button */}
          <DialogFooter>
            <Button
              variant="gradient"
              size="lg"
              className="w-full p-4 bg-blue-500 hover:bg-blue-300"
              type="submit"
              disabled={!description || loading}
            >
              {loading ? 'Cargando' : 'Guardar'}
            </Button>
          </DialogFooter>
        </form>
      </DialogContent>
    </Dialog>
  );
};

export default RevisionModal;
