import { useEffect, useState, useCallback } from 'react';
import { getChat, getChatOutsourcing } from '@/services/chat';
import {
  getMessagesByChat,
  getMessagesByChatOutsourcing,
} from '@/services/message';
import { useFetchDemand } from '@/hooks/useDemand';
import { useFetchOutsourcing } from '@/hooks/useOutsourcing';

export const useFetchChat = (chatId) => {
  const [chat, setChat] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [messages, setMessages] = useState([]);

  const fetchChat = useCallback(async () => {
    setLoading(true);
    setError(null); // Reset error on new fetch attempt

    try {
      const [messagesData, chatData] = await Promise.all([
        getMessagesByChat(chatId), // Fetch messages by chat
        getChat(chatId), // Fetch chat details
      ]);

      setMessages(messagesData.messages || []);
      setChat(chatData.chat);
    } catch (error) {
      console.error(error); // Log error for debugging
      setError(error.message || 'Failed to fetch messages. Please try again.');
    } finally {
      setLoading(false);
    }
  }, [chatId]);

  // Fetch demand data based on the chat's demand id
  useFetchDemand(chat?.demand);

  // const fetchMoreMessages = async () => {
  //   //check if total total > messages.legth +take
  //   setLoadingOldMessages(true);
  //   setError(null);
  //   try {
  //     if (total == messages.length) {
  //       return;
  //     }
  //     if (total >= messages.length + limit) {
  //       setSkip(messages.length);
  //     } else if (total < messages.length + limit) {
  //       setSkip(messages.length);
  //       setLimit(total - messages.length);
  //     }
  //     const messagesData = await getMessagesByChat(chatId, queryString);
  //     //add messages to messages at the beggining
  //     setMessages([...messagesData.messages, ...messages]);
  //   } catch (error) {
  //     setError(error.message || 'Failed to fetch messages. Please try again.');
  //   }
  //   setLoadingOldMessages(false);
  // };

  useEffect(() => {
    fetchChat();
  }, [fetchChat]);

  return { chat, loading, error, messages, setMessages, fetchChat };
};

export const useFetchChatOutsourcing = (chatId) => {
  const [chat, setChat] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [messages, setMessages] = useState([]);

  const fetchChat = useCallback(async () => {
    setLoading(true);
    setError(null); // Reset error on new fetch attempt

    try {
      const [messagesData, chatData] = await Promise.all([
        getMessagesByChatOutsourcing(chatId), // Fetch messages by chat
        getChatOutsourcing(chatId), // Fetch chat details
      ]);
      setMessages(messagesData.messages || []);
      setChat(chatData.chat);
    } catch (error) {
      console.error(error); // Log error for debugging
      setError(error.message || 'Failed to fetch messages. Please try again.');
    } finally {
      setLoading(false);
    }
  }, [chatId]);

  // Fetch demand data based on the chat's demand id
  useFetchOutsourcing(chat?.outsourcing);

  // const fetchMoreMessages = async () => {
  //   //check if total total > messages.legth +take
  //   setLoadingOldMessages(true);
  //   setError(null);
  //   try {
  //     if (total == messages.length) {
  //       return;
  //     }
  //     if (total >= messages.length + limit) {
  //       setSkip(messages.length);
  //     } else if (total < messages.length + limit) {
  //       setSkip(messages.length);
  //       setLimit(total - messages.length);
  //     }
  //     const messagesData = await getMessagesByChat(chatId, queryString);
  //     //add messages to messages at the beggining
  //     setMessages([...messagesData.messages, ...messages]);
  //   } catch (error) {
  //     setError(error.message || 'Failed to fetch messages. Please try again.');
  //   }
  //   setLoadingOldMessages(false);
  // };

  useEffect(() => {
    fetchChat();
  }, [fetchChat]);

  return { chat, loading, error, messages, setMessages, fetchChat };
};
