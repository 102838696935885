import { Navigate, Outlet } from 'react-router-dom';
import useUserStore from '@/store/userStore';

export const AdminGuard = () => {
  const { user } = useUserStore();

  return user.role === 'ADMIN_ROLE' ? (
    <Outlet />
  ) : (
    <Navigate to="/dashboard" replace />
  );
};
