import React from 'react';
import {
  Table,
  TableBody,
  TableCaption,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from '@/components/ui/table';
import dateFormat from '@/utils/dateFormat';

const RevisionFilesCard = ({ order }) => {
  return (
    <div className="flex flex-col lg:flex-row bg-white rounded-md border-2 h-auto p-6 justify-between gap-6">
      {/* Revision History Section */}
      <div className="flex flex-col gap-4 w-full lg:w-3/5">
        <h6 className="font-bold text-lg">Historial de Revisiones</h6>
        <div className="overflow-auto max-h-96">
          <Table>
            <TableCaption>Revisiones del pedido</TableCaption>
            <TableHeader>
              <TableRow>
                <TableHead>Fecha</TableHead>
                <TableHead>Título</TableHead>
                <TableHead>Descripción</TableHead>
              </TableRow>
            </TableHeader>
            <TableBody>
              {order.revisions && order.revisions.length > 0 ? (
                order.revisions.map((revision, index) => (
                  <TableRow key={index}>
                    <TableCell className="text-center">
                      {dateFormat(revision.createdAt)}
                    </TableCell>
                    <TableCell className="text-center">
                      {revision.title}
                    </TableCell>
                    <TableCell className="text-center">
                      {revision.description}
                    </TableCell>
                  </TableRow>
                ))
              ) : (
                <TableRow>
                  <TableCell colSpan="3" className="text-center">
                    No hay revisiones
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </div>
      </div>

      {/* Files Section */}
      <div className="flex flex-col gap-4 w-full lg:w-2/5">
        <h6 className="font-bold text-lg">Archivos</h6>
        <div className="overflow-auto max-h-96">
          <Table>
            <TableCaption>Archivos del pedido</TableCaption>
            <TableHeader>
              <TableRow>
                <TableHead>Fecha</TableHead>
                <TableHead>Descripción</TableHead>
              </TableRow>
            </TableHeader>
            <TableBody>
              {order.files && order.files.length > 0 ? (
                order.files.map((file, index) => (
                  <TableRow key={index}>
                    <TableCell className="text-center">
                      {dateFormat(file.deliveryDate)}
                    </TableCell>
                    <TableCell className="text-center">
                      {file.description}
                    </TableCell>
                  </TableRow>
                ))
              ) : (
                <TableRow>
                  <TableCell colSpan="2" className="text-center">
                    No hay archivos
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </div>
      </div>
    </div>
  );
};

export default RevisionFilesCard;
