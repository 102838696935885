import axios from 'axios';

const API_BASE_URL = import.meta.env.VITE_API_BASE_URL;

const axiosInstance = axios.create({
  baseURL: `${API_BASE_URL}/api`,
  headers: {
    'Content-Type': 'application/json',
  },
  withCredentials: true,
});

axiosInstance.interceptors.request.use(
  (config) => {
    const token = localStorage.getItem('token');
    if (token) {
      config.headers['Authorization'] = `Bearer ${token}`;
    }
    return config;
  },
  (error) => Promise.reject(error),
);

axiosInstance.interceptors.response.use(
  (response) => response,
  (error) => {
    if (error.response && error.response.status === 401) {
      console.error('Unauthorized, redirecting to login');
      localStorage.removeItem('token');
    }
    return Promise.reject(error);
  },
);

export const getbillLab = async (billId) => {
  try {
    const response = await axiosInstance.get(`/billLab/${billId}`);
    const data = response.data;
    return data;
  } catch (error) {
    console.error('Error fetching billLabCase:', error);
    throw error;
  }
};

export const getbillLabsAsAdmin = async ({ queryString }) => {
  try {
    const response = await axiosInstance.get(`/billLab/asAdmin?${queryString}`);
    const data = response.data;
    return data;
  } catch (error) {
    console.error('Error getting billLabCases:', error);
    throw error;
  }
};

export const getbillLabAsSeller = async ({ queryString }) => {
  try {
    const response = await axiosInstance.get(
      `/billLab/asSeller?${queryString}`,
    );
    const data = response.data;
    return data;
  } catch (error) {
    console.error('Error getting billLabCases:', error);
    throw error;
  }
};

export const getbillLabs = async ({ queryString }) => {
  try {
    const response = await axiosInstance.get(`/billLab?${queryString}`);
    const data = response.data;
    return data;
  } catch (error) {
    console.error('Error getting billLabCases:', error);
    throw error;
  }
};

export const deletebillLab = async (billLabCaseId) => {
  try {
    const response = await axiosInstance.delete(`/billLab/${billLabCaseId}`);
    const data = response.data;
    return data;
  } catch (error) {
    console.error('Error deleting billLabCase:', error);
    throw error;
  }
};

export const updatebillLab = async (billLabId, billLabData) => {
  try {
    const response = await axiosInstance.put(
      `/billLabCase/${billLabId}`,
      billLabData,
    );
    const data = response.data;
    return data;
  } catch (error) {
    console.error('Error updating billLabCase:', error);
    throw error;
  }
};

export const createbillLab = async ({ billLabCaseId, billLabData }) => {
  try {
    const formData = new FormData();
    for (const key in billLabData) {
      formData.append(key, billLabData[key]);
    }
    const response = await axiosInstance.post(
      `/billLab/${billLabCaseId}`,
      formData,
      {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      },
    );

    const data = response.data;
    return data;
  } catch (error) {
    console.error('Error creating billLabCase:', error);
    throw error;
  }
};
