// components/ui/RatingCard.js
import React from 'react';

const RatingCard = ({ rating }) => {
  // Define el número máximo de estrellas
  const maxRating = 5;

  // Genera un array de estrellas basado en la calificación
  const stars = Array.from({ length: maxRating }, (_, index) => {
    return index < rating ? '★' : '☆';
  });

  return (
    <div className="flex items-center">
      {stars.map((star, index) => (
        <span key={index} className={`text-[#0224ab] text-xl`}>
          {star}
        </span>
      ))}
    </div>
  );
};

export default RatingCard;
