import axios from 'axios';

const API_BASE_URL = import.meta.env.VITE_API_BASE_URL;

const axiosInstance = axios.create({
  baseURL: `${API_BASE_URL}/api`,
  headers: {
    'Content-Type': 'application/json',
  },
  withCredentials: true,
});

axiosInstance.interceptors.request.use(
  (config) => {
    const token = localStorage.getItem('token');
    if (token) {
      config.headers['Authorization'] = `Bearer ${token}`;
    }
    return config;
  },
  (error) => Promise.reject(error),
);

axiosInstance.interceptors.response.use(
  (response) => response,
  (error) => {
    if (error.response && error.response.status === 401) {
      console.error('Unauthorized, redirecting to login');
      localStorage.removeItem('token');
    }
    return Promise.reject(error);
  },
);

export const createCommissionplan = async (commissionplanData) => {
  try {
    const response = await axiosInstance.post(
      '/commissionplans',
      commissionplanData,
    );
    const data = response.data;
    return data;
  } catch (error) {
    console.error('Error creating commissionplan:', error);
    throw error;
  }
};
export const createCommissionplanAsAdmin = async ({ commissionplanData }) => {
  try {
    const response = await axiosInstance.post(
      `/commissionplans/asAdmin`,
      commissionplanData,
    );
    const data = response.data;
    return data;
  } catch (error) {
    console.error('Error creating commissionplan:', error);
    throw error;
  }
};

export const getCommissionplans = async ({ queryString }) => {
  try {
    const response = await axiosInstance.get(`/commissionplans?${queryString}`);
    const data = response.data;
    return data;
  } catch (error) {
    console.error('Error fetching commissionplans:', error);
    throw error;
  }
};

export const getCommissionPlansOfUser = async ({ userId, queryString }) => {
  try {
    const response = await axiosInstance.get(
      `/commissionplans/asAdmin/${userId}?${queryString}`,
    );
    const data = response.data;
    return data;
  } catch (error) {
    console.error('Error fetching commissionplans:', error);
    throw error;
  }
};

export const editCommissionplan = async ({ id, commissionplanData }) => {
  try {
    const response = await axiosInstance.put(
      `/commissionplans/${id}`,
      commissionplanData,
    );
    const data = response.data;
    return data;
  } catch (error) {
    console.error('Error editing commissionplan:', error);
    throw error;
  }
};

export const deleteCommissionplan = async ({ id }) => {
  try {
    const response = await axiosInstance.delete(`/commissionplans/${id}`);
    const data = response.data;
    return data;
  } catch (error) {
    console.error('Error deleting commissionplan:', error);
    throw error;
  }
};
