import axios from 'axios';

const API_BASE_URL = import.meta.env.VITE_API_BASE_URL;

const axiosInstance = axios.create({
  baseURL: `${API_BASE_URL}/api`,
  headers: {
    'Content-Type': 'application/json',
  },
  withCredentials: true,
});

axiosInstance.interceptors.request.use(
  (config) => {
    const token = localStorage.getItem('token');
    if (token) {
      config.headers['Authorization'] = `Bearer ${token}`;
    }
    return config;
  },
  (error) => Promise.reject(error),
);

axiosInstance.interceptors.response.use(
  (response) => response,
  (error) => {
    if (error.response && error.response.status === 401) {
      console.error('Unauthorized, redirecting to login');
      localStorage.removeItem('token');
    }
    return Promise.reject(error);
  },
);

export const getFile = async ({ id }) => {
  try {
    const response = await axiosInstance.get(`/files/${id}`);
    return response.data;
  } catch (error) {
    console.error('Error getting seller:', error);
    throw error;
  }
};

export const createFile = async ({ id, fileData }) => {
  try {
    const formData = new FormData();
    for (const key in fileData) {
      formData.append(key, fileData[key]);
    }
    const response = await axiosInstance.post(`/files/${id}`, formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });
    return response.data;
  } catch (error) {
    console.error('Error getting seller:', error);
    throw error;
  }
};

export const createFileDoc = async ({ fileData }) => {
  try {
    const formData = new FormData();
    for (const key in fileData) {
      formData.append(key, fileData[key]);
    }
    const response = await axiosInstance.post(`/files`, formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });
    return response.data;
  } catch (error) {
    console.error('Error getting seller:', error);
    throw error;
  }
};

export const createFileOutsourcing = async ({ id, fileData }) => {
  try {
    const formData = new FormData();
    for (const key in fileData) {
      formData.append(key, fileData[key]);
    }
    const response = await axiosInstance.post(
      `/files/outsourcing/${id}`,
      formData,
      {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      },
    );
    return response.data;
  } catch (error) {
    console.error('Error getting seller:', error);
    throw error;
  }
};
