import { Link, NavLink } from 'react-router-dom';
import { useUserStore } from '@/store/userStore';
import { useDemandStore } from '@/store/DemandStore';
import { useOutsourcingStore } from '@/store/OutsourcingStore';
import { useNotificationStore } from '@/store/Notificationstore';
import { IoIosNotificationsOutline } from 'react-icons/io';
import { FaChevronDown } from 'react-icons/fa';
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuLabel,
  DropdownMenuSeparator,
  DropdownMenuTrigger,
} from '@/components/ui/dropdown-menu';
import LogoOklusion from '@/assets/Logo Oklusion.png';
import { Button } from '@/components/ui/button';
import { Avatar, AvatarFallback, AvatarImage } from '@/components/ui/avatar';
import { CiCirclePlus } from 'react-icons/ci';
import { CiSearch } from 'react-icons/ci';
import { ImLab } from 'react-icons/im';
import { GiHamburgerMenu } from 'react-icons/gi';

const Header = () => {
  const { user, logout } = useUserStore();
  const { clearDemandStore } = useDemandStore();
  const { clearOutsourcingStore } = useOutsourcingStore();

  const { notifications, clearNotifications } = useNotificationStore();

  const handleClearNotifications = () => {
    clearNotifications();
  };

  const handleLogout = () => {
    logout();
    clearDemandStore();
    clearOutsourcingStore();
    clearNotifications();
    window.location.href = '/login';
  };

  return (
    <header className="bg-white border-b border-gray-200 px-4 md:px-20 ">
      <div className="container mx-auto py-2 flex justify-between items-center">
        {/* Logo aligned to the left */}
        <div className="flex justify-start w-full md:w-auto">
          <h1 className="text-xl font-bold">
            <Link
              to={user ? '/dashboard' : '/'}
              className="hover:text-gray-300"
            >
              <img
                src={LogoOklusion}
                alt="Logo Oklusion"
                className="h-5 w-30"
              />
            </Link>
          </h1>
        </div>

        <nav className="flex items-center space-x-4 w-full md:w-auto justify-end">
          {user ? (
            <>
              {user.role === 'ADMIN_ROLE' && user.active && (
                <NavLink
                  to="/admin/user"
                  className={({ isActive }) =>
                    isActive ? 'text-blue-500' : 'hover:text-gray-300 text-sm'
                  }
                  style={{ fontSize: '1.1rem' }}
                >
                  Usuarios
                </NavLink>
              )}

              {/* New Case: "CiCirclePlus" icon for small screens */}
              {user.role === 'DENTIST_ROLE' && user.active && (
                <>
                  {/* Show on small screens */}
                  <NavLink
                    to="/cases/newcase"
                    className={({ isActive }) =>
                      isActive
                        ? 'text-blue-500 md:hidden'
                        : 'hover:text-gray-300 md:hidden text-sm'
                    }
                    style={{ fontSize: '1.1rem' }}
                  >
                    <CiCirclePlus size={25} />
                  </NavLink>
                  {/* Show on medium and large screens */}
                  <NavLink
                    to="/cases/newcase"
                    className={({ isActive }) =>
                      isActive
                        ? 'text-blue-500 hidden md:block'
                        : 'hover:text-gray-300 hidden md:block text-sm'
                    }
                    style={{ fontSize: '1.1rem' }}
                  >
                    Nuevo caso
                  </NavLink>
                </>
              )}

              {user.role === 'SELLER_ROLE' && user.active && (
                <>
                  {/* Show on small screens */}
                  <NavLink
                    to="/cases/newcase"
                    className={({ isActive }) =>
                      isActive
                        ? 'text-blue-500 md:hidden'
                        : 'hover:text-gray-300 md:hidden text-sm'
                    }
                    style={{ fontSize: '1.1rem' }}
                  >
                    <CiCirclePlus size={25} />
                  </NavLink>
                  {/* Show on medium and large screens */}
                  <NavLink
                    to="/outsourcing/newoutsourcing"
                    className={({ isActive }) =>
                      isActive
                        ? 'text-blue-500 hidden md:block'
                        : 'hover:text-gray-300 hidden md:block text-sm'
                    }
                    style={{ fontSize: '1.1rem' }}
                  >
                    Nuevo caso
                  </NavLink>
                </>
              )}

              {user.active && (
                <>
                  {user.role == 'DENTIST_ROLE' && (
                    <>
                      {/* Work: "CiSearch" icon for small screens */}
                      <NavLink
                        to="cases"
                        className={({ isActive }) =>
                          isActive
                            ? 'text-blue-500 md:hidden'
                            : 'hover:text-gray-300 md:hidden text-sm'
                        }
                        style={{ fontSize: '1.1rem' }}
                      >
                        <CiSearch size={25} />
                      </NavLink>
                      {/* Show "Work" on medium and large screens */}
                      <NavLink
                        to="cases"
                        className={({ isActive }) =>
                          isActive
                            ? 'text-blue-500 hidden md:block'
                            : 'hover:text-gray-300 hidden md:block text-sm'
                        }
                        style={{ fontSize: '1.1rem' }}
                      >
                        Trabajos
                      </NavLink>
                    </>
                  )}
                  {user.role == 'SELLER_ROLE' && (
                    <>
                      <NavLink
                        to="outsourcing"
                        className={({ isActive }) =>
                          isActive
                            ? 'text-blue-500 md:hidden'
                            : 'hover:text-gray-300 md:hidden text-sm'
                        }
                        style={{ fontSize: '1.1rem' }}
                      >
                        <CiSearch size={25} />
                      </NavLink>
                      {/* Show "Work" on medium and large screens */}
                      <NavLink
                        to="outsourcing"
                        className={({ isActive }) =>
                          isActive
                            ? 'text-blue-500 hidden md:block'
                            : 'hover:text-gray-300 hidden md:block text-sm'
                        }
                        style={{ fontSize: '1.1rem' }}
                      >
                        Trabajos
                      </NavLink>
                      {/* Work: "CiSearch" icon for small screens */}
                      {/* <NavLink
                        to="outsourcing/asSeller"
                        className={({ isActive }) =>
                          isActive
                            ? 'text-blue-500 md:hidden'
                            : 'hover:text-gray-300 md:hidden text-sm'
                        }
                        style={{ fontSize: '1.1rem' }}
                      >
                        <ImLab size={25} />
                      </NavLink> */}
                      {/* Show "Work" on medium and large screens */}
                      {/* <NavLink
                        to="outsourcing/asSeller"
                        className={({ isActive }) =>
                          isActive
                            ? 'text-blue-500 hidden md:block'
                            : 'hover:text-gray-300 hidden md:block text-sm'
                        }
                        style={{ fontSize: '1.1rem' }}
                      >
                        Outsourcing
                      </NavLink> */}
                    </>
                  )}

                  <Link
                    className="relative flex items-center"
                    variant="text"
                    size="sm"
                    to="setting/notifications"
                    onClick={handleClearNotifications}
                    style={{ fontSize: '1.1rem' }}
                  >
                    <IoIosNotificationsOutline size={25} />
                    {notifications.length > 0 && (
                      <span className="absolute top-0 right-0 inline-block w-2 h-2 bg-red-500 text-white rounded-full text-xs flex items-center justify-center"></span>
                    )}
                  </Link>
                </>
              )}

              {/* Profile Dropdown */}
              <DropdownMenu>
                <DropdownMenuTrigger asChild>
                  <div className="flex items-center ">
                    <Avatar className="w-8 h-8 rounded-full object-cover">
                      <AvatarImage src={user?.avatar} />
                      <AvatarFallback>
                        {user?.name?.[0]}
                        {user?.lastname?.[0]}
                      </AvatarFallback>
                    </Avatar>
                    <FaChevronDown className="ml-2" />
                  </div>
                </DropdownMenuTrigger>
                <DropdownMenuContent className="bg-white shadow-lg">
                  <DropdownMenuLabel className="text-center">
                    {user.name} {user?.lastname}
                    <p className="text-gray-500">{user.email}</p>
                  </DropdownMenuLabel>
                  <DropdownMenuSeparator />

                  {user.active && (
                    <>
                      <DropdownMenuItem>
                        <NavLink
                          to="setting/myprofile"
                          className={({ isActive }) =>
                            isActive
                              ? 'text-blue-500'
                              : 'hover:bg-gray-100 px-4 rounded'
                          }
                          style={{ fontSize: '1.1rem' }}
                        >
                          Perfil
                        </NavLink>
                      </DropdownMenuItem>
                      <DropdownMenuSeparator />

                      <DropdownMenuItem>
                        <NavLink
                          to="setting/security"
                          className={({ isActive }) =>
                            isActive
                              ? 'text-blue-500'
                              : 'hover:bg-gray-100 px-4 rounded'
                          }
                          style={{ fontSize: '1.1rem' }}
                        >
                          Seguridad
                        </NavLink>
                      </DropdownMenuItem>
                      <DropdownMenuSeparator />
                      <DropdownMenuItem>
                        <NavLink
                          to="setting/notificationSettings"
                          className={({ isActive }) =>
                            isActive
                              ? 'text-blue-500'
                              : 'hover:bg-gray-100 px-4 rounded'
                          }
                          style={{ fontSize: '1.1rem' }}
                        >
                          Notificaciones
                        </NavLink>
                      </DropdownMenuItem>
                      <DropdownMenuSeparator />
                    </>
                  )}

                  <DropdownMenuItem>
                    <Button
                      variant="text"
                      size="sm"
                      color="red"
                      onClick={handleLogout}
                      className="hover:bg-gray-100 px-4 rounded"
                      style={{ fontSize: '1.1rem' }}
                    >
                      Cerrar sesión
                    </Button>
                  </DropdownMenuItem>
                </DropdownMenuContent>
              </DropdownMenu>
            </>
          ) : (
            <>
              {/* Buttons visible on larger screens */}
              <div className="hidden md:flex flex-col sm:flex-row gap-2 w-full sm:w-auto">
                <NavLink
                  to="/register"
                  className="btn-neutral font-normal text-center justify-center py-2 px-4 w-full sm:w-auto"
                  style={{ fontSize: '1.1rem' }}
                >
                  Registrarme
                </NavLink>
                <NavLink
                  to="/login"
                  className="btn-primary bg-[#355DFD] font-normal text-center justify-center py-2 px-4 w-full sm:w-auto"
                  style={{ fontSize: '1.1rem' }}
                >
                  Iniciar sesión
                </NavLink>
              </div>

              {/* Dropdown for mobile */}
              <div className="md:hidden">
                <DropdownMenu>
                  <DropdownMenuTrigger asChild>
                    <div className="flex items-center">
                      <GiHamburgerMenu className="ml-2" />
                    </div>
                  </DropdownMenuTrigger>
                  <DropdownMenuContent className="bg-white shadow-lg">
                    <DropdownMenuItem>
                      <NavLink
                        to="/login"
                        className={({ isActive }) =>
                          isActive
                            ? 'text-blue-500'
                            : 'hover:bg-gray-100 px-4 rounded'
                        }
                        style={{ fontSize: '1.1rem' }}
                      >
                        Iniciar sesión
                      </NavLink>
                    </DropdownMenuItem>
                    <DropdownMenuItem>
                      <NavLink
                        to="/register"
                        className={({ isActive }) =>
                          isActive
                            ? 'text-blue-500'
                            : 'hover:bg-gray-100 px-4 rounded'
                        }
                        style={{ fontSize: '1.1rem' }}
                      >
                        Registrarme
                      </NavLink>
                    </DropdownMenuItem>
                  </DropdownMenuContent>
                </DropdownMenu>
              </div>
            </>
          )}
        </nav>
      </div>
    </header>
  );
};

export default Header;
