import React from 'react';

const CondicionesGenerales = () => {
  return (
    <div className="px-12 py-8 text-justify bg-white text-gray-900 leading-relaxed max-w-4xl mx-auto">
      <h1 className="text-3xl font-bold mb-6 text-center">Aviso Legal</h1>

      <h2 className="text-2xl font-semibold mt-8 mb-4">
        1. Información General
      </h2>
      <p className="mb-6 indent-8">
        En cumplimiento con el deber de información recogido en el artículo 10
        de la Ley 34/2002, de 11 de julio, de Servicios de la Sociedad de la
        Información y del Comercio Electrónico, a continuación se reflejan los
        siguientes datos:
      </p>
      <ul className="list-disc ml-8 mb-6">
        <li>
          <strong>Titular:</strong> Oklusion S.L.
        </li>
        <li>
          <strong>NIF:</strong> B12345678
        </li>
        <li>
          <strong>Domicilio Social:</strong> Calle Ejemplo, 123, 08001
          Barcelona, España
        </li>
        <li>
          <strong>Correo Electrónico:</strong> info@oklusion.com
        </li>
        <li>
          <strong>Teléfono:</strong> +34 123 456 789
        </li>
        <li>
          <strong>Registro Mercantil:</strong> Inscrita en el Registro Mercantil
          de Barcelona, Tomo 12345, Folio 67, Hoja B-123456.
        </li>
      </ul>

      <h2 className="text-2xl font-semibold mt-8 mb-4">2. Objeto</h2>
      <p className="mb-6 indent-8">
        Oklusion (en adelante, “la Plataforma”) es un marketplace que facilita
        la compra y venta de productos y servicios. La Plataforma utiliza Stripe
        como plataforma de pago y Sendcloud como plataforma para los envíos. La
        Plataforma cobra una comisión por cada venta realizada.
      </p>

      <h2 className="text-2xl font-semibold mt-8 mb-4">
        3. Protección de Datos
      </h2>
      <p className="mb-6 indent-8">
        La Plataforma almacena datos médicos en AWS S3 y garantiza su protección
        conforme a la normativa vigente en materia de protección de datos
        personales. La Plataforma se reserva el derecho de utilizar los datos
        almacenados para entrenar algoritmos de inteligencia artificial, siempre
        cumpliendo con las normativas aplicables.
      </p>

      <h2 className="text-2xl font-semibold mt-8 mb-4">
        4. Propiedad Intelectual e Industrial
      </h2>
      <p className="mb-6 indent-8">
        Todos los contenidos de la Plataforma, incluyendo textos, gráficos,
        imágenes, diseño y software, son propiedad de Oklusion S.L. o de sus
        licenciantes, y están protegidos por las leyes de propiedad intelectual
        e industrial.
      </p>

      <h2 className="text-2xl font-semibold mt-8 mb-4">
        5. Uso de la Plataforma
      </h2>
      <p className="mb-6 indent-8">
        El acceso y uso de la Plataforma implica la aceptación de las presentes
        condiciones. El usuario se compromete a hacer un uso adecuado de los
        contenidos y servicios ofrecidos a través de la Plataforma y a no
        emplearlos para incurrir en actividades ilícitas o contrarias a la buena
        fe y al orden público.
      </p>

      <h2 className="text-2xl font-semibold mt-8 mb-4">
        6. Limitación de Responsabilidad
      </h2>
      <p className="mb-6 indent-8">
        Oklusion S.L. no se hace responsable de los daños y perjuicios de
        cualquier naturaleza que pudieran derivarse del uso de la Plataforma,
        incluyendo errores u omisiones en los contenidos, falta de
        disponibilidad de la Plataforma o la transmisión de virus o programas
        maliciosos.
      </p>

      <h2 className="text-2xl font-semibold mt-8 mb-4">7. Modificaciones</h2>
      <p className="mb-6 indent-8">
        Oklusion S.L. se reserva el derecho de efectuar sin previo aviso las
        modificaciones que considere oportunas en la Plataforma, pudiendo
        cambiar, suprimir o añadir tanto los contenidos y servicios que se
        presten a través de la misma como la forma en la que estos aparezcan
        presentados o localizados en la Plataforma.
      </p>

      <h2 className="text-2xl font-semibold mt-8 mb-4">
        8. Legislación Aplicable y Jurisdicción
      </h2>
      <p className="mb-6 indent-8">
        La relación entre Oklusion S.L. y el usuario se regirá por la normativa
        española vigente y cualquier controversia se someterá a los juzgados y
        tribunales de la ciudad de Barcelona.
      </p>
    </div>
  );
};

export default CondicionesGenerales;
