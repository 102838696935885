import { useState } from 'react';
import { cn } from '@/lib/utils';
import { Card } from '@/components/ui/card';
import { BillLabStatusSteps } from '@/constants/caseType';

export default function BillLabStepper({ BillLabCase }) {
  const handleStatus = (status) => {
    switch (status) {
      case 'created':
        return 'Creado';
      case 'paid':
        return 'Pagado';
      default:
        return 'Creado';
    }
  };
  const currentStep = BillLabStatusSteps.indexOf(BillLabCase.status);

  const [activeStep, setActiveStep] = useState(currentStep);

  const getProgressWidth = () => {
    if (BillLabStatusSteps.length > 1) {
      const stepWidth = (activeStep / (BillLabStatusSteps.length - 1)) * 100;
      return `${stepWidth}%`;
    }
    return '100%';
  };
  return (
    <Card className="w-full p-4 h-[100px] gap-8 rounded-lg bg-white">
      {
        <div className="relative flex justify-between mb-8">
          {BillLabStatusSteps.map((step, index) => (
            <div
              key={index}
              className="flex flex-col items-center relative z-10"
            >
              <div
                className={cn(
                  'w-10 h-10 rounded-full flex items-center justify-center text-sm  md:font-medium',
                  index <= activeStep
                    ? 'bg-[#0224ab] text-primary-foreground'
                    : 'bg-[#DCE3FF] text-secondary-foreground',
                )}
              >
                {index + 1}
              </div>
              <div className="mt-2 font-urbanist text-[10px] md:text-sm font-medium text-center text-muted-foreground max-w-[100px]">
                {handleStatus(step)}
              </div>
            </div>
          ))}
          <div className="absolute top-5 left-0 right-0 h-1 bg-secondary -translate-y-1/2" />
          <div
            className="absolute top-5 left-0 h-1 bg-[#0224ab] -translate-y-1/2 transition-all duration-300 ease-in-out"
            style={{ width: getProgressWidth() }}
          />
        </div>
      }
    </Card>
  );
}
