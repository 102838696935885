import { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { login, updateVerifyEmail } from '@/services/auth';
import { useUserStore } from '@/store/userStore';
import LogoOklusion from '@/assets/Login.png';
import { Button } from '@/components/ui/button';
import { Input } from '@/components/ui/input';
import { Label } from '@/components/ui/label';
import { toast } from 'react-toastify';

const LoginPage = () => {
  const { setUser } = useUserStore();
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');
  const [showVerifyButton, setShowVerifyButton] = useState(false);
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    setError('');

    try {
      const userData = { email, password };
      const { user, token } = await login(userData);

      if (token) {
        setUser(user, token);

        navigate('/dashboard');
      }
    } catch (err) {
      setError('Invalid email or password');
      if (err.response && err.response.status === 403) {
        toast.error(err.response.data.msg);
        setShowVerifyButton(true);
      }
    } finally {
      setLoading(false);
    }
  };

  const handleReguestVerifyEmail = async () => {
    try {
      const response = await updateVerifyEmail({ email });
      if (response) {
        toast.success('Enviamos un correo');
        setShowVerifyButton(false);
      }
    } catch (err) {
      toast.error(err.response.data.msg);
    }
  };

  if (loading) {
    return (
      <div className="flex justify-center items-center min-h-screen">
        <Button variant="text">
          {' '}
          <>
            <svg
              className="animate-spin h-5 w-5 mr-2 text-white"
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
            ></svg>
            Cargando...
          </>
        </Button>
      </div>
    );
  }

  return (
    <div className="flex justify-center items-center min-h-screen bg-gray-100 p-8">
      <div className="flex w-full h-full lg:w-3/4 max-w-6xl shadow-lg rounded-lg overflow-hidden">
        <div
          className="hidden lg:flex lg:w-1/2 items-center justify-center bg-cover bg-center"
          style={{
            backgroundImage: `url(${LogoOklusion})`,
            backgroundPosition: 'center',
            backgroundRepeat: 'no-repeat',
            backgroundSize: 'cover',
          }}
        ></div>

        <div className="w-full lg:w-1/2 bg-white p-8">
          <div className="max-w-sm mx-auto">
            <h2 className="text-2xl font-bold text-center mb-6">
              Bienvenido a Oklusion
            </h2>
            <form className="space-y-1" onSubmit={handleSubmit}>
              <div className="form-grid">
                <Label htmlFor="email">Email</Label>
                <Input
                  type="email"
                  id="email"
                  name="email"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  className="input-styles"
                  placeholder="you@domain.com"
                  required
                />
              </div>

              <div className="form-grid">
                <Label htmlFor="password">Password</Label>
                <Input
                  type="password"
                  id="password"
                  name="password"
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                  className="input-styles"
                  placeholder="••••••••"
                  required
                />
              </div>

              <div className="mt-4 text-right">
                <Link
                  to="/forgotpassword"
                  className="text-sm text-black hover:text-blue-500 underline"
                >
                  Olvidé mi contraseña
                </Link>
              </div>
              <Button
                type="submit"
                className="w-full py-2 px-4 bg-black text-white rounded-lg hover:bg-gray-800 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-opacity-50"
              >
                Iniciar Sesion
              </Button>
            </form>
            {error && <p className="text-red-500 text-sm mt-2">{error}</p>}
            {showVerifyButton && email && (
              <button
                type="button"
                onClick={handleReguestVerifyEmail}
                className="text-sm  hover:text-blue-500 underline mt-4"
              >
                Verificar Email
              </button>
            )}
            <div className="mt-4 text-center">
              <p className="text-sm text-gray-600">
                ¿No tienes una cuenta aún?{' '}
                <Link
                  to="/register"
                  className="text-black hover:text-blue-500 underline"
                >
                  Registrarme
                </Link>
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default LoginPage;
