import i18n from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import { initReactI18next } from 'react-i18next';
// import Backend from 'i18next-http-backend';

import resources from "./resources"

export const defaultNS = "common";

i18n
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    resources,
    fallbackLng: "en",
    ns: [
      "translations",
      "common",
    ],
    defaultNS,
  });

export default i18n;